import React, { useState, forwardRef, useImperativeHandle, useContext } from "react";
import { TextField, Grid, FormLabel } from '@mui/material';
import { verifyRequireField } from "../../../utils";
import { ClassesContext } from "../../../../../contexts/Classes";

const PatientContact = forwardRef( ({canEdit},ref) => {
    const [ errors, setErrors ] = useState({});
    const [ email, setEmail ] = useState('');
    const [ phoneNumber, setPhoneNumber ] = useState('');
    const { hiddenFormInputStyle } = useContext(ClassesContext);
    useImperativeHandle(ref,()=>({
        setData(data){
            setPhoneNumber(data ? data.phoneNumber : '')
            setEmail(data ? data.email : '')
        },
        verifyData(){
            let requireFields = [
            ]
            let {isRequireFieldsValid,errorObject} = verifyRequireField(requireFields,getFormData());
            setErrors({ ...errorObject });
            return isRequireFieldsValid
        },
        getData(){
            return getFormData()
        }
    }))
    
    const getFormData = () => {
        return {phoneNumber,email}
    }

    return ( 
        <React.Fragment>
                <Grid container sx={{mt:1}}>
                    <Grid item xs={12} md={12}>
                        <FormLabel >Patient 's contact information</FormLabel>
                    </Grid>
                </Grid>
                <Grid container spacing={1} sx={{mt:1,ml:2}}>
                    <Grid item xs={11} md={4}>
                        <TextField 
                            sx={{
                                ...hiddenFormInputStyle({value: phoneNumber , canEdit}),
                                resize:"both"
                            }}
                            multiline  
                            fullWidth
                            name="phoneNumber" 
                            value={ phoneNumber } 
                            onChange={e=>setPhoneNumber(e.target.value) } 
                            error={ errors.phoneNumber ? true : false } 
                            size='small' 
                            label="Tel" /> 
                    </Grid>
                    <Grid item xs={11} md={4}>
                        <TextField 
                            sx={{
                                ...hiddenFormInputStyle({value: email , canEdit}),
                                resize:"both"
                            }}
                            multiline  
                            fullWidth
                            name="email" 
                            value={ email } 
                            onChange={e=>setEmail(e.target.value) } 
                            error={ errors.email ? true : false } 
                            size='small' 
                            label="Email" /> 
                    </Grid>
                </Grid>
        </React.Fragment>
     );
});
 
export default PatientContact;