import React, { useState, forwardRef, useImperativeHandle,useEffect } from "react";
import { TextField, Grid, Autocomplete, Button } from '@mui/material';
import { verifyRequireField } from "../../../utils";
import axiosInstance from "../../../../../common/axios";

const Surgeon = forwardRef( ({canEdit,topic},ref) => {
    const [ errors, setErrors ] = useState({});
    const [ surgeon,setSurgeon ] = useState(null);
    const [ otherSurgeon,setOtherSurgeon ] = useState(null);
    const [ assistSurgeons,setAssistSurgeons ] = useState([null]);
    const [ surgeonList, setSurgeonsList ] = useState([]);
    const [ otherAssistSurgeons, setOtherAssistSurgeons] = useState([null]);
    useImperativeHandle(ref,()=>({
        setData(data){
            if(data){
                setSurgeon(data.surgeon);
                setOtherSurgeon(data.otherSurgeon)
                setAssistSurgeons(data.assistSurgeons)
                setOtherAssistSurgeons(data.otherAssistSurgeons)
            }
        },
        verifyData(){
            let requireFields = [
                { field: 'surgeon', errorName: 'surgeon', },
                { field: 'assistSurgeons', errorName: 'assistSurgeons', condition: 'length', conditionForCompare: 'gt' ,valueForCompare: 0 },
            ]
            let {isRequireFieldsValid,errorObject} = verifyRequireField(requireFields,getFormData());
            let {isOtherSurgeonValid, errorOtherSurgeon } = verifyOtherSurgeon()
            let { isOtherAssistSurgeonValid, errorOtherAssistSurgeons } = verifyOtherAssistSurgeon()
            setErrors({ ...errorObject, ...errorOtherSurgeon, otherAssistSurgeons: errorOtherAssistSurgeons });
            return isRequireFieldsValid && isOtherSurgeonValid && isOtherAssistSurgeonValid
        },
        getData(){
            return getFormData()
        }
    }));

    const getFormData = () => {
        let newAssistSurgeons = []
        let newOtherAssistSurgeons = []
        for( let i = 0 ; i < assistSurgeons.length ; i ++ ){
            let assistSurgeon = assistSurgeons[i];
            let otherAssistSurgeon = otherAssistSurgeons[i];
            if( assistSurgeon !== null ){
                newAssistSurgeons.push(assistSurgeon);
                newOtherAssistSurgeons.push(otherAssistSurgeon)
            }
        }

        return {
            surgeon: surgeon,
            otherSurgeon: otherSurgeon,
            assistSurgeons: newAssistSurgeons ,
            otherAssistSurgeons: newOtherAssistSurgeons
        }
    }

    const verifyOtherSurgeon = () => {
        let isOtherSurgeonValid = true;
        if( surgeon && surgeon.id === 'other' && !otherSurgeon ){
            isOtherSurgeonValid = false
        }   
        return { isOtherSurgeonValid, errorOtherSurgeon : { otherSurgeon: !isOtherSurgeonValid } }
    }

    const verifyOtherAssistSurgeon = () => {
        let isOtherAssistSurgeonValid = true;
        let errorOtherAssistSurgeons = []
        for( let i = 0 ; i < assistSurgeons.length ; i ++ ){
            let assistSurgeon = assistSurgeons[i];
            let otherAssistSurgeon = otherAssistSurgeons[i];
            if( assistSurgeon && assistSurgeon.id === 'other' && !otherAssistSurgeon ){
                isOtherAssistSurgeonValid = false
                errorOtherAssistSurgeons.push(true)
            }else{
                errorOtherAssistSurgeons.push(false)
            }
        }

        return { isOtherAssistSurgeonValid, errorOtherAssistSurgeons }
    }

    useEffect(() => {
        if(topic){
            let hospital = typeof topic.hospital === 'object' ? topic.hospital.id : topic.hospital;
            let specialty = typeof topic.specialty === 'object' ? topic.specialty.id : topic.specialty;
            axiosInstance.get(`/core/surgeon/?is_active=1&hospital__id=${hospital}&specialty__id=${specialty}`).then((res)=>{
                if(res.data.count > 0){
                    let surgeons = [
                        ...res.data.results,
                        {
                            id:"other",
                            title: null,
                            name: "Other"
                        },
                    ]
                    setSurgeonsList([...surgeons])
                }
            })
        }
    }, [topic])// eslint-disable-line react-hooks/exhaustive-deps

    const removeAssistSurgeon = (removeIndex) =>{
        let newAssistSurgeons = assistSurgeons.filter( (item,index) => index !== removeIndex  )
        let newOtherAssistSurgeons = otherAssistSurgeons.filter( (item,index) => index !== removeIndex  )
        if(newAssistSurgeons.length > 0){
            setAssistSurgeons([...newAssistSurgeons])
            setOtherAssistSurgeons([...newOtherAssistSurgeons])
        }
    }

    const setAssistSurgeonValue = (index,value) => {
        let assistSurgeonExist = value.id !== 'other' && assistSurgeons.filter((item)=> item !== null && value !== null && item.id === value.id ).length > 0
        let surgeonExist = value && surgeon && value.id !== 'other'  && surgeon.id === value.id

        if( !assistSurgeonExist && !surgeonExist ) {
            let newAssistSurgeons = assistSurgeons
            newAssistSurgeons[index] = value
            setAssistSurgeons([...newAssistSurgeons])
        }else{
            let newAssistSurgeons = assistSurgeons
            newAssistSurgeons[index] = null
            setAssistSurgeons([...newAssistSurgeons])
        }
    }

    const addAssistSurgeon = (e) => {
        let newAssistSurgeons = assistSurgeons;
        let newOtherAssistSurgeons = otherAssistSurgeons;
        newAssistSurgeons.push(null);
        newOtherAssistSurgeons.push(null)
        setAssistSurgeons([...newAssistSurgeons]);
        setOtherAssistSurgeons([...newOtherAssistSurgeons])
    }

    const setSurgeonValue = (surgeon) => {
        let assistSurgeonExist = surgeon.id !== 'other' && assistSurgeons.filter( (item)=> item !== null && surgeon !== null && item.id === surgeon.id ).length > 0
        if( !assistSurgeonExist ){
            setSurgeon(surgeon);
        }else{
            setSurgeon(null);
        }
    }
    

    const setOtherAssistSurgeonValue = (index,value) => {
        let newOtherAssistSurgeons = otherAssistSurgeons;
        newOtherAssistSurgeons[index] = value
        setOtherAssistSurgeons([...newOtherAssistSurgeons])
    }

    return ( 
        <React.Fragment>
            <Grid container spacing={1} sx={{mt:1}}>
                <Grid item xs={12} md={4}>
                    <Autocomplete
                        name="surgeon"
                        value={ surgeon }
                        onChange={(event, surgeon) => {
                            setSurgeonValue(surgeon)
                        }}
                        getOptionLabel={(option) => option.title ? option.title + ' ' + option.name : option.name }
                        options={surgeonList}
                        renderInput={(params) => <TextField {...params} size="small" label="Surgeon*" name="surgeon" error={errors.surgeon ? true : false} />}
                    />
                </Grid>
                { surgeon && surgeon.id === 'other' && (
                    <Grid item xs={12} md={4}>
                        <TextField
                            size="small"
                            fullWidth
                            value={ otherSurgeon }
                            name="otherSurgeon"
                            onChange={ (e)=> {
                                setOtherSurgeon(e.target.value)
                            } }
                            label="Surgeon*"
                            error={errors.otherSurgeon ? true : false}
                        />
                    </Grid>
                ) }

                <Grid item xs={12} md={4}>
                    {   canEdit && (
                        <Button variant="outlined" onClick={addAssistSurgeon}>
                            Add assist.surgeon
                        </Button>
                    ) }

                </Grid>
            </Grid>
            { assistSurgeons.length > 0 && assistSurgeons.map((item,index)=> ( 
                <Grid  key={index} container spacing={1} sx={{mt:1}}>
                        <React.Fragment >
                            <Grid item xs={12} md={4}>
                                <Autocomplete
                                    name={ 'assistSurgeon'+ index }
                                    value={ assistSurgeons[index] ? assistSurgeons[index] : null }
                                    onChange={(event, assistSurgeon) => {
                                        setAssistSurgeonValue( index, assistSurgeon )
                                    }}
                                    getOptionLabel={(option) => option.title ? option.title + ' ' + option.name : option.name }
                                    options={surgeonList}
                                    renderInput={(params) => {
                                        return <TextField {...params}  name={ 'assistSurgeon'+ index }size="small" label={`Assist surgeon ${index+1} *`} error={errors.assistSurgeons ? true : false} />
                                    }}
                                />
                            </Grid>
                            { assistSurgeons[index] && assistSurgeons[index].id === 'other' && (
                                <Grid item xs={12} md={4}>
                                    <TextField
                                        size="small"
                                        fullWidth
                                        value={ otherAssistSurgeons[index] ? otherAssistSurgeons[index] : null }
                                        name={ "otherAssistSurgeon"+index }
                                        onChange={ (e)=> {
                                            setOtherAssistSurgeonValue(index,e.target.value)
                                        }}
                                        label={`Assist surgeon ${index+1} *`}
                                        error={ errors.otherAssistSurgeons && errors.otherAssistSurgeons[index] ? true : false}
                                    />
                                </Grid>
                            )}

                            { canEdit && (
                                <Grid item xs={12} md={4}>
                                    <Button variant="outlined" color="error" 
                                        onClick={ (e)=> removeAssistSurgeon(index) }  
                                    >
                                        Remove
                                    </Button>
                                </Grid>
                            )}

                        </React.Fragment>
                </Grid>
            ))}
        </React.Fragment>
     );
});
 
export default Surgeon;