import React, { useEffect, useState,useContext, useRef } from 'react';
import { Card, CardHeader, Divider, Button, CardActions, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@mui/material';
import { AuthContext } from '../contexts/AuthContext';
import axiosInstance from '../common/axios';
import PatientDetailForm from '../components/topic/PatientDetailForm';
import TopicDetailForm from '../components/topic/TopicDetailForm';
import { TEMPLATE_TYPE_CONSULTATION } from '../store/action/actionTypes';
import Loading from '../components/Loading';
import Alert from '../components/Alert';
import { CONSULTATION_TEMPLATES, TOPIC_STATUS_DRAFT, TOPIC_STATUS_PROCESS } from '../config/form';
import { useNavigate,useParams } from 'react-router-dom';



const EditTopic = () => {
    const navigate = useNavigate();
    const patientDetailRef = useRef();
    const topicDetailRef = useRef();
    const consultationTemplateRef = useRef();
    const { topicId } = useParams();
    const [ topicDetail, setTopicDetail ] = useState({})
    const [ loading, setLoading ] = useState(false);
    const [ alertOpen, setAlertOpen ] = useState(false);
    const [ alertMessage, setAlertMessage ] = useState(null);
    const [ specialties, setSpecialty] = useState([]);
    const [ specialtySelected, setSpecialtySelected ] = useState('');
    const [ hospitals, setHospitals] = useState([]);
    const [ hospitalSelected, setHospitalSelected ] = useState('');
    const [ ConsultationComponent, setConsultationComponent ] = useState(null);
    const [ consultationTemplateId, setConsultationTemplateId ] = useState(null);
    const [ consultationForm, setConsultationForm ] = useState(null);
    const [ deleteTopicDialogOpen, setDeleteTopicDialogOpen ] = useState(false);


    const handleSpecialtyChange = (e) => {
        setSpecialtySelected(e.target.value)
    }

    const handleHospitalChange = (e) => {
        setHospitalSelected(e.target.value)
    }

    const handleEditTopicSubmit = (e) =>{
        e.preventDefault()
    }

    const setDefaultData = async () => {
        axiosInstance.get('/auth/users/get_user_detail/').then((res)=>{
            if(res.data){
                setSpecialty(res.data.specialties)
                setHospitals(res.data.hospitals)
            }
        }).catch((error)=>{
            console.log(error)
        })
    } 

    const verifyUserPermission = (callback) => {
        axiosInstance.get(`/topic/topic/${topicId}/`).then((res)=>{
            callback(true)
        }).catch((error)=>{
            callback(false)
        });
    }

    useEffect(()=>{
        if(specialtySelected){
            setConsultationComponent(null)
            axiosInstance.get(`/core/specialty/${specialtySelected}/`).then((res)=>{
                if( res.data ){
                    for(let i=0;i < res.data.templates.length; i ++) {
                        let template = res.data.templates[i]
                        if( template.type === TEMPLATE_TYPE_CONSULTATION ){
                            let TemplateComponent = CONSULTATION_TEMPLATES[template.id];
                            setConsultationComponent( TemplateComponent ? TemplateComponent : null )
                            setConsultationTemplateId( template.id )
                        }
                    }
                }  
            })
        }
    }
    ,[specialtySelected])

    useEffect(()=>{
        if(consultationForm && ConsultationComponent){
            let data = consultationForm.data;
            consultationTemplateRef.current.setData(data);      
        }
    },[ConsultationComponent,consultationForm]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect( ()=>{
        if(specialties && hospitals){
            axiosInstance.get(`/topic/topic/${topicId}/`).then((res)=>{
                let topic = res.data;
                setSpecialtySelected(topic.specialty)
                setHospitalSelected(topic.hospital)
                setTopicDetail(topic)
                topicDetailRef.current.setData(topic)
                if(topic.patient_visit){
                    patientDetailRef.current.setData(topic.patient_visit_detail)
                }
                if(topic.consultation_form){
                    setConsultationForm( topic.consultation_form_detail )
                }
            })
        }
    }, [specialties,hospitals] ) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect( () =>{
        setDefaultData();
    },[])// eslint-disable-line react-hooks/exhaustive-deps

    const deleteTopicFile = async(file ) => {
        try {
            let res = await axiosInstance.delete(`/topic/topic-file/${file.id}/`)
            return res.data
        } catch (error) {
            return null
        }
    }
    const uploadTopicFile = async (file, topic) =>{
        if(file.id){
            try {
                let formData = new FormData();
                formData.append('description', file.description === null ? '' : file.description);
                let res = await axiosInstance.patch(`/topic/topic-file/${file.id}/`, formData)
                return res.data
            } catch (error) {
                return null
            }
        }else{
            let fileType = ['image','video' ].includes(file.file.type.split('/')[0]) ? file.file.type.split('/')[0] : 'other';
            let formData = new FormData();
            formData.append('type', fileType);
            formData.append('file', file.file);
            formData.append('size',file.size)
            formData.append('name', file.name);
            if(file.description){
                formData.append('description', file.description);
            }
            formData.append('topic',topic.id);
            try{
                let res = await axiosInstance.post('/topic/topic-file/',formData)
                return res.data
            }catch{
                return null
            }
        }

        
    }

    const createOrUpdateConsultationForm = async (consultationForm,formData) => {
        let token =  localStorage.getItem('token') ? JSON.parse(localStorage.getItem('token')).access : null; 
        if(token){
            let tokenParts = JSON.parse(atob(token.split('.')[1]));
            let author = tokenParts.user_id;
            let form = { 
                template: consultationTemplateId , 
                data: formData
            }
            if(consultationForm){
                form['updated_by'] = author
                try {
                    let res = await axiosInstance.patch(`/topic/form/${consultationForm.id}/`,form)
                    return res.data
                } catch (error) {
                    return null
                }
            }else{
                form["created_by"] = author
                try {
                    let res = await axiosInstance.post('/topic/form/',form)
                    return res.data
                } catch (error) {
                    return null
                }
            }
        }else{
            return null
        }
    }

    const updateTopicDetail = async (detail,patientDetailData,status,consultationForm) => {
        let token =  localStorage.getItem('token') ? JSON.parse(localStorage.getItem('token')).access : null; 
        if(token){
            let tokenParts = JSON.parse(atob(token.split('.')[1]));
            let author = tokenParts.user_id;
            let topic = { 
                name: detail , 
                hospital: hospitalSelected, 
                patient_visit: patientDetailData.id ? patientDetailData.id : null , 
                specialty: specialtySelected,
                consultation_form: consultationForm ? consultationForm.id : null,
                status: status,
                created_by: author,
                updated_by: author
            }
            try {
                let res = await axiosInstance.patch(`/topic/topic/${topicId}/`,topic)
                return res.data
            } catch (error) {
                return null
            }
        }else{
            return null
        }
    }

    const create_notification_edit_topic = () => {
        axiosInstance.post('/topic/topic/create_notification_edit_topic/',{'topic_id':topicId})
    }

    const submitEditTopic = (status) =>{
        let isTopicDetailValid = topicDetailRef.current.verifyRequireField();

        let verifyData = isTopicDetailValid;
        if(status === TOPIC_STATUS_PROCESS){
            let isPatientDetailValid = patientDetailRef.current.verifyRequireField();
            let isConsultationValid = ConsultationComponent ? consultationTemplateRef.current.verifyRequireField() : true;
            verifyData = verifyData && isPatientDetailValid && isConsultationValid
        }

        if(  verifyData  ){
            setLoading(true);
            verifyUserPermission( async (havePermission)=>{
                if(havePermission){
                    let consultationData = ConsultationComponent ? consultationTemplateRef.current.getData() : null;
                    let topicDetailData = topicDetailRef.current.getData();
                    let patientDetailData = patientDetailRef.current.getData();
                    let consultationFormDetail = consultationData ? await createOrUpdateConsultationForm(consultationForm,consultationData) : null;
                    let topic = await updateTopicDetail(topicDetailData.detail,patientDetailData,status,consultationFormDetail)
                    for ( let i=0 ; i < topicDetailData.files.length ; i ++) {
                        await uploadTopicFile(topicDetailData.files[i], topic);
                    }
                    for ( let i=0 ; i < topicDetailData.deletedFiles.length ; i ++) {
                        await deleteTopicFile(topicDetailData.deletedFiles[i])
                    }
                    if( topicDetail.status === TOPIC_STATUS_PROCESS && status === TOPIC_STATUS_PROCESS){
                        create_notification_edit_topic()
                    }
                    
                }
                setLoading(false);
                navigate('/topic/'+topicId)
            })

        }else{
            setAlertMessage({header: 'warning !!!', content: 'please complete require fields' })
            setAlertOpen(true)
        }
    }

    const deleteTopic = () => {
        verifyUserPermission( async (havePermission)=>{
            if(havePermission){
                await axiosInstance.delete(`/topic/topic/${topicId}/`)
                navigate('/')
            }
        })
    }

    return ( 
        <React.Fragment>
            <Card elevation={0}>
                <CardHeader title="Edit Topic" />
                <Divider ></Divider>
                <form noValidate autoComplete="off" onSubmit={handleEditTopicSubmit}>
                    <TopicDetailForm canEdit={true} specialtySelected={specialtySelected} handleSpecialtyChange={handleSpecialtyChange} specialties={specialties} hospitalSelected={hospitalSelected} handleHospitalChange={handleHospitalChange} hospitals={hospitals} ref={topicDetailRef} />
                    <PatientDetailForm canEdit={true} hospitalSelected={hospitalSelected} ref={patientDetailRef} />
                    { ConsultationComponent && <ConsultationComponent canEdit={true} ref={consultationTemplateRef} /> }
                    <CardActions sx={{ p: 1.25, pt: 0, justifyContent: 'center' }}>
                        <Button variant="outlined" color="error" onClick={()=>{ setDeleteTopicDialogOpen(true) }}>Delete</Button>
                        { topicDetail.status !== TOPIC_STATUS_PROCESS && <Button color="secondary" variant="outlined" onClick={()=>{submitEditTopic(TOPIC_STATUS_DRAFT)}}>Draft</Button> }
                        <Button variant="outlined" onClick={()=>{submitEditTopic(TOPIC_STATUS_PROCESS)}}>Submit</Button>
                        <Button variant="outlined" onClick={()=>{ navigate('/topic/'+topicId) }}>Close</Button>
                    </CardActions>
                </form>
            </Card>       
            <Dialog 
                open={deleteTopicDialogOpen}
                onClose={ (e) => setDeleteTopicDialogOpen(false)}
            >
                <DialogContent>
                    <DialogContentText>
                        Would you like to delete this topic? Click confirm to delete.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button variant='outlined' onClick={(e) => setDeleteTopicDialogOpen(false)}>Cancel</Button>
                    <Button color='error' variant='outlined' onClick={deleteTopic} autoFocus>
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>
            <Loading loading={loading} />
            <Alert open={alertOpen} message={alertMessage} handleClose={()=>{ setAlertOpen(false) }} />
        </React.Fragment> 
    );
}
 
export default EditTopic;