import { Modal,Box, CardActions, TextField, Grid, Button, Checkbox,FormGroup,FormControlLabel, Autocomplete } from "@mui/material";
import { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import axiosInstance from "../common/axios";

const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "30%",
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
  };

const EditUser = ({refreshData,showEditUserModal,setShowEditUserModal, userEdit, specialties,hospitals}) => {
    const [ user, setUser ] = useState(null);
    const navigate = useNavigate();

    useEffect(()=>{
        axiosInstance.get('/auth/users/get_user_detail/').then((res)=>{
            if(res.data.is_staff ){
                if(userEdit){
                    setUser(userEdit);
                }

            }else{
                navigate("/")
            }
        })
        
    },[userEdit])// eslint-disable-line react-hooks/exhaustive-deps

    const updateUser = async () => {
        axiosInstance.patch(`/auth/users/${user.id}/`,{is_active:user.is_active}).then(res=>{
        })
    }

    const updateUserHospital =  async () => {
        let hospitalsId = user.hospitals.map(item=>item.id)
        let deleteHospital = userEdit.hospitals.map(item=>item.id).filter(id=> !hospitalsId.includes(id) )
        let addHospital = hospitalsId.filter( id=> !userEdit.hospitals.map(item=>item.id).includes(id)  )
        let deleteUserHospitalId = []
        for( let i = 0; i < deleteHospital.length; i ++ ) {
            let hospitalId = deleteHospital[i];
            await axiosInstance.get(`/core/user-hospital/?user__id=${user.id}&hospital__id=${hospitalId}`).then(res=>{
                if(res.data.count > 0){
                    deleteUserHospitalId.push(res.data.results[0].id)
                }
            }) 
        }

        for( let i = 0; i < deleteUserHospitalId.length; i ++ ) {
            let userHospitalId = deleteUserHospitalId[i];
            await axiosInstance.delete(`/core/user-hospital/${userHospitalId}/`)
        }

        for( let i = 0; i < addHospital.length; i ++ ) {
            let hospitalId = addHospital[i];
            await axiosInstance.post(`/core/user-hospital/`,{ hospital:hospitalId,user:user.id }).then(res=>{}) 
        }

        // await axiosInstance.post(`/core/user-hospital/send_email_notification_to_user/`,{ hospitals:addHospital,user:user.id }).then(res=>{}) 

    }
    const updateUserSpecialty =  async () => {
        let specialtiesId = user.specialties.map(item=>item.id)
        let deleteSpecialty = userEdit.specialties.map(item=>item.id).filter(id=> !specialtiesId.includes(id) )
        let addSpecialty = user.specialties.filter( specialty=> !userEdit.specialties.map(item=>item.id).includes(specialty.id)  )
        let updateSpecialty = user.specialties.filter( specialty=> !addSpecialty.map( item=>item.id  ).includes(specialty.id)  )

        let deleteUserSpecialtyId = [];
        for( let i = 0; i < deleteSpecialty.length; i ++ ) {
            let specialtyId = deleteSpecialty[i];
            await axiosInstance.get(`/core/user-specialty/?user__id=${user.id}&specialty__id=${specialtyId}`).then(res=>{
                if(res.data.count > 0){
                    deleteUserSpecialtyId.push(res.data.results[0].id)
                }
            }) 
        }

        for( let i = 0; i < updateSpecialty.length; i ++ ) {
            let specialty = updateSpecialty[i];
            let updateUserSpecialtyId = null
            await axiosInstance.get(`/core/user-specialty/?user__id=${user.id}&specialty__id=${specialty.id}`).then(res=>{
                if(res.data.count > 0){
                    updateUserSpecialtyId = res.data.results[0].id
                }
            }) 
            if(updateUserSpecialtyId){
                await axiosInstance.patch(`/core/user-specialty/${updateUserSpecialtyId}/`,{is_expert:specialty.is_expert})
            }
        }

        for( let i = 0; i < deleteUserSpecialtyId.length; i ++ ) {
            let userSpecialtyId = deleteUserSpecialtyId[i];
            await axiosInstance.delete(`/core/user-specialty/${userSpecialtyId}/`)
        }

        for( let i = 0; i < addSpecialty.length; i ++ ) {
            let specialty = addSpecialty[i];
            await axiosInstance.post(`/core/user-specialty/`,{ specialty:specialty.id,is_expert:specialty.is_expert,user:user.id }).then(res=>{}) 
        }
        
        // await axiosInstance.post(`/core/user-specialty/send_email_notification_to_user/`,{ specialties: addSpecialty.map((item)=>item.id),user:user.id }).then(res=>{}) 

    }

    const handleSave = async () => {
        let isStaff = false;
        await axiosInstance.get('/auth/users/get_user_detail/').then((res)=>{
            if(res.data.is_staff){
                isStaff = true
            }else{
                navigate("/")
            }
        })
        if(isStaff){
            await updateUser()
            await updateUserHospital()
            await updateUserSpecialty()
            refreshData()
            setShowEditUserModal(false)
        }
    }

    const setSpecialtiesExpert = (specialtyEdit,is_expert) => {
        let newSpecialties =[]
        for(let i=0; i <user.specialties.length; i ++){
            let specialty = user.specialties[i];
            if(specialtyEdit.id === specialty.id ){
                specialty.is_expert = is_expert
            }
            newSpecialties.push(specialty)
        }
        setUser({...user, specialties: newSpecialties })
    }

    return ( 
        <Modal
            open={showEditUserModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            >

            <Box sx={modalStyle}>
                <Grid container spacing={1}>
                    <Grid item xs={12} md={12}>
                        <TextField fullWidth size="small" value={user? user.username: ''} disabled label="Username" />
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <TextField fullWidth size="small" value={user? user.first_name: ''} disabled label="First name" />
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <TextField fullWidth size="small" value={user? user.last_name: ''} disabled label="Last name" />
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <TextField fullWidth size="small" value={user? user.email: ''} disabled label="Email" />
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <Autocomplete 
                            multiple
                            value={ user ? user.hospitals : [] }
                            onChange={(event, hospitals) => {
                                setUser({...user,hospitals: hospitals })
                            }}
                            options={hospitals}
                            getOptionLabel={(option) => `${option.name} (${option.code})` }
                            renderInput={(params) => (
                                <TextField {...params} size="small" label="Hospitals" placeholder="hospitals" />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <Autocomplete 
                            multiple
                            value={ user ? user.specialties : [] }
                            onChange={(event, specialties) => {
                                setUser({...user, specialties: specialties })
                            }}
                            options={specialties.map( item => ({ ...item,is_expert:false }) )}
                            getOptionLabel={(option) => option.name}
                            renderInput={(params) => (
                                <TextField {...params} size="small" label="Specialties" placeholder="specialties" />
                            )}
                        />
                    </Grid>
                    { user && user.specialties && user.specialties.map((item)=> <Grid key={item.id} item xs={12} md={12}>
                        <FormGroup >
                            <FormControlLabel 
                                control={
                                    <Checkbox checked={ item.is_expert } 
                                    onChange={(e)=> { setSpecialtiesExpert(item,e.target.checked) } } 
                                />} 
                                label={"is expert : " + item.name }
                            />
                        </FormGroup>
                    </Grid> ) }


                    <Grid item xs={12} md={12}>
                        <FormGroup>
                            <FormControlLabel control={<Checkbox checked={ user ? user.is_active : false } onChange={(e)=> setUser({ ...user, is_active: e.target.checked }) } />} label="is active" />
                        </FormGroup>
                    </Grid>

                </Grid>
                <CardActions sx={{ p: 1.25, pt: 0, mt: 2, justifyContent: 'center' }}>
                    <Button variant="outlined" onClick={(e)=> handleSave()}>save</Button>
                    <Button variant="outlined" onClick={(e)=> setShowEditUserModal(false) }>close</Button>
                </CardActions>
            </Box>
        </Modal> 
     );
}
 
export default EditUser;