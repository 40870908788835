import React, { useState,useEffect,useRef } from "react";
import { Box, Button, Card, CardHeader } from "@mui/material";
import Moment from 'moment';
import { EXPERT_FORM_TEMPLATE, EXPERT_SUMMARY_TEMPLATE, FOLLOW_UP_TEMPLATE, FORM_TYPE_EXPERT_FORM, FORM_TYPE_EXPERT_SUMMARY, FORM_TYPE_FOLLOW_UP, FORM_TYPE_OPERATIVE_NOTE, OPERATIVE_NOTE_TEMPLATE, INCLUDE_FILE_FORM_ID } from "../../config/form";
import ImageList from "../common/ImageList";
import FileList from "../common/FileList";
import VideoList from "../common/VideoList";
import ImageDialog from "../common/ImageDialog";
import axiosInstance from "../../common/axios";
import { useNavigate } from "react-router-dom";
import fileDownload from 'js-file-download';
import axios from 'axios';

const FormCommentCard = ({index,comment,editCommentHandle}) => {
    const formRef = useRef();
    const [ showEditBtn, setShowEditBtn ] = useState(false);
    const [FormComponent,setFormComponent] = useState(null);
    const [ includeFileComment, setIncludeFileComment ] = useState(false);
    const [ imageFiles, setImageFiles ] = useState( [] )
    const [ videoFiles, setVideoFiles ] = useState([])
    const [ otherFiles, setOtherFiles ] = useState([] )
    const [ showImageDialog, setShowImageDialog ] =  useState(false);
    const [ imageIndex, setImageIndex ] =  useState(0);
    const navigate = useNavigate();
    
    const downloadFile = (fileObject) => {
        axiosInstance.get(`/topic/topic/${comment.topic.id}/`).then((res)=>{
            let token = localStorage.getItem('token') ? JSON.parse(localStorage.getItem('token')).access : null; 
            axios.get(`${process.env.REACT_APP_BACKEND_URL}/topic/get-comment-file/${fileObject.id}/${token}`,{
                responseType: 'blob',
            }).then((res) => {
                fileDownload(res.data, fileObject.name)
            })
        }).catch((error)=>{
            navigate('/')
        });
    }
    
    const closeImage = ( ) =>{
        setShowImageDialog(false)
    }

    const imageClick = (index) => {
        setImageIndex(index)
        setShowImageDialog(true)
    }

    useEffect(()=>{
        if(FormComponent){
            formRef.current.setData(comment.form.data)
        }

    },[FormComponent,comment])// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(()=>{
        let token = localStorage.getItem('token') ? JSON.parse(localStorage.getItem('token')).access : null ;
        if (token && comment ) {
            const tokenParts = JSON.parse(atob(token.split('.')[1]));
            setShowEditBtn(tokenParts.user_id === comment.author.id )
            setIncludeFileComment(INCLUDE_FILE_FORM_ID.includes(comment.form.template.id))
            setImageFiles(comment.files.filter( (file) => file.type === 'image' )) 
            setVideoFiles(comment.files.filter( (file) => file.type === 'video'  )) 
            setOtherFiles( comment.files.filter( (file) => file.type === 'other'  ))

            switch (comment.form.template.type) {
                case FORM_TYPE_EXPERT_FORM.key:
                    setFormComponent(EXPERT_FORM_TEMPLATE[comment.form.template.id])
                    break;
                case FORM_TYPE_OPERATIVE_NOTE.key:
                    setFormComponent(OPERATIVE_NOTE_TEMPLATE[comment.form.template.id])
                    break;
                case FORM_TYPE_EXPERT_SUMMARY.key:
                    setFormComponent(EXPERT_SUMMARY_TEMPLATE[comment.form.template.id])
                    break;
                case FORM_TYPE_FOLLOW_UP.key:
                    setFormComponent( FOLLOW_UP_TEMPLATE[comment.form.template.id] )
                    break;
                default:
                    break;
            }

        }
    },[comment])
    return ( 
        <Card sx={{mt:3}} elevation={1}>
            <CardHeader 
                action={ showEditBtn? <Button variant="outlined" size="small" onClick={()=>{ editCommentHandle(comment) }}>Edit</Button> : null } 
                subheader={ `Comment #${index+1} created on ${Moment(comment.created).format('MMMM Do YYYY, h:mm:ss a')} by ${comment.author.first_name} ${comment.author.last_name}` } 
            />
            <Box sx={{ padding: 2 }}>
                { FormComponent && <FormComponent canEdit={false} topic={comment.topic} ref={formRef} /> }
                { includeFileComment && (
                    <React.Fragment>
                        <ImageList imageFiles={imageFiles} imageClick={imageClick} />
                        <VideoList videoFiles={videoFiles} />
                        <FileList otherFiles={otherFiles} downloadFile={downloadFile} /> 
                    </React.Fragment>
                )}
            </Box>
            <ImageDialog showImageDialog={showImageDialog} closeImage={closeImage} imageIndex={imageIndex} setImageIndex={setImageIndex} imageFiles={imageFiles} downloadFile={downloadFile} />
        </Card>
     );
}
 
export default FormCommentCard;