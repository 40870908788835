import { Box, CardActions, Modal, CircularProgress } from '@mui/material';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "30%",
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
  };

const Loading = ({loading}) => {
    return ( 
        <Modal
            open={loading}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            >
            <Box sx={style}>
                <CardActions sx={{ p: 1.25, pt: 0, mt: 2, justifyContent: 'center' }}>
                    <CircularProgress />
                </CardActions>
            </Box>
        </Modal> 
    );
}
 
export default Loading;