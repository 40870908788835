import React, {useEffect, useState, useContext} from 'react';
import { Box, Card, CardHeader, Pagination, CardActions, ListItem, List, ListItemButton, ListItemText, Divider, Button } from "@mui/material";
import WebSocketInstance from '../components/notification/Websocket';
import axiosInstance from '../common/axios';
import { NotificationContext } from '../contexts/NotificationContext';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';


const Notification = () => {
    const itemPerPage = 10;
    const [pageCount,setPageCount] = useState(1);
    const [page,setPage] = useState(1);
    const { notifications,dispatch } = useContext(NotificationContext);
    const [ notificationsForDisplay,setNotificationsForDisplay ] = useState([])
    const navigate = useNavigate();
    
    const handleChangePage = (event, page) => {
        setPage(page);
    };

    const verifyUserPermission = (callback) => {
        axiosInstance.get('/auth/users/get_user_detail/').then((res)=>{
            callback(true);
        }).catch((error)=>{
            callback(false)
        })
    }

    useEffect(()=>{
        let start = (page - 1) * itemPerPage;
        let end = ((page - 1) * itemPerPage) + itemPerPage ;
        setNotificationsForDisplay( notifications.slice( start , end) )
        setPageCount( Math.ceil(notifications.length / itemPerPage) )
    },[notifications,page])// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(()=>{
        WebSocketInstance.setVerifyUserPermission( verifyUserPermission )
        WebSocketInstance.addNotificationDispatch( async (data)=>{
            dispatch(data)
        });
        WebSocketInstance.connect();
        return () => {
         }
    },[])// eslint-disable-line react-hooks/exhaustive-deps

    const readNotification= async (notification) => {
        if(notification.is_read === false){
            await axiosInstance.patch('/notification/notification/'+notification.id +'/',{is_read:true})
        }
        navigate(`/topic/${notification.comment !== null ? notification.comment.topic.id : notification.topic.id}` )
    }

    const markReadAll = ()=>{
        let notificationNotRead = notifications.filter(item=> item.is_read === false)
        axiosInstance.post('/notification/notification/mark_read_all/',{notifications:notificationNotRead})
    }

    return ( 
        <React.Fragment>
             <Card sx={{mt:2}} elevation={0}>
                <CardHeader 
                    title="Inbox" 
                />    
                <Divider ></Divider>
                <Box sx={{ padding: 3 }}>
                    <List
                        sx={{ width: '100%', bgcolor: 'background.paper' }}
                    >
                    { notificationsForDisplay.map(item=> (
                        <React.Fragment key={item.id}>
                        <ListItem component="div" disablePadding>
                            <ListItemButton
                                onClick={()=> readNotification(item)}
                                selected={ !item.is_read }
                            >
                                <ListItemText
                                    primary={item.content }
                                    secondary={
                                        moment(item.created).format('MMMM Do YYYY, h:mm:ss a')
                                    }
                                />
                                
                            </ListItemButton>
                        </ListItem>
                        <Divider />
                        </React.Fragment>
                    ) ) }
                    </List>
                </Box>
                <CardActions sx={{ p: 1.25, pt: 0, mr:2, justifyContent: 'right' }}>
                    <Button variant='outlined' onClick={()=> markReadAll() }>Mark Read all</Button>
                </CardActions>
                <CardActions sx={{ p: 1.25, pt: 0, justifyContent: 'center' }}>
                    <Pagination onChange={handleChangePage} count={pageCount} page={page} variant="outlined" color="primary" />
                </CardActions>
            </Card>
        </React.Fragment>
     );
}
 
export default Notification;