import React, { useState, forwardRef, useImperativeHandle, useContext } from "react";
import { FormControl, FormControlLabel, Grid, TextField, FormGroup, Checkbox } from '@mui/material';
import { getDataUpdate, verifyRequireField } from "../../../utils";
import { ClassesContext } from "../../../../../contexts/Classes";

const SummaryOfDiscussion = forwardRef( ({canEdit},ref) => {
    const [ errors, setErrors ] = useState({});
    const [ formData, setFormData ] = useState({});
    const { hiddenFormInputStyle  } = useContext(ClassesContext);

    useImperativeHandle(ref,()=>({
        setData(data){
            if(data){
                setFormData(data)
            }
            
        },
        verifyData(){
            let requireFields = []
            let {isRequireFieldsValid,errorObject} = verifyRequireField(requireFields,formData)
            setErrors({ ...errorObject });
            return isRequireFieldsValid
        },
        getData(){
            return formData
        }
    }))

    const updateFormData = async (e) => {
        let dataUpdate = getDataUpdate(e);
        setFormData({...formData,...dataUpdate})
    }
    
    return ( 
        <React.Fragment>
            <Grid container spacing={0}>
                <Grid item xs={12} md={12} sx={{ml:2}}>
                    <FormControl>
                        <FormGroup sx={{display:'inline'}}>
                            <FormControlLabel control={
                                <Checkbox checked={ formData.conservativeTreatment ? formData.conservativeTreatment : false } onChange={updateFormData} name="conservativeTreatment" />
                            } label="Conservative treatment" sx={{...hiddenFormInputStyle({value:formData.conservativeTreatment, canEdit})}} />
                            <FormControlLabel control={
                                <Checkbox checked={ formData.surgicalTreatmentBy ? formData.surgicalTreatmentBy : false } onChange={updateFormData} name="surgicalTreatmentBy" />
                            } label="Surgical treatment (please fill in on page 2) by" sx={{...hiddenFormInputStyle({value:formData.surgicalTreatmentBy, canEdit})}} />
                            <FormControlLabel control={
                                <Checkbox checked={ formData.PAMT ? formData.PAMT : false } onChange={updateFormData} name="PAMT" />
                            } label="PAMT" sx={{...hiddenFormInputStyle({value:formData.PAMT, canEdit})}} />
                            <FormControlLabel control={
                                <Checkbox checked={ formData.attendingSurgeon ? formData.attendingSurgeon : false } onChange={updateFormData} name="attendingSurgeon" />
                            } label="Attending surgeon" sx={{...hiddenFormInputStyle({value:formData.attendingSurgeon, canEdit})}} />
                            <FormControlLabel control={
                                <Checkbox checked={ formData.referToAnotherHospital  ? formData.referToAnotherHospital : false } onChange={updateFormData} name="referToAnotherHospital" />
                            } label="Refer to another hospital" sx={{...hiddenFormInputStyle({value:formData.referToAnotherHospital, canEdit})}} />
                            <FormControlLabel control={
                                <Checkbox checked={ formData.BHQ  ? formData.BHQ : false } onChange={updateFormData} name="BHQ" />
                            } label="BHQ" sx={{...hiddenFormInputStyle({value:formData.BHQ, canEdit})}} />
                        </FormGroup>
                    </FormControl>
                </Grid>
                <Grid item xs={12} md={12} sx={{ml:2}}>
                    <FormControl>
                        <FormGroup sx={{display:'inline'}}>
                            <FormControlLabel control={
                                <Checkbox checked={ formData.otherBDMS ? formData.otherBDMS : false } onChange={updateFormData} name="otherBDMS" />
                            } label="Other BDMS" sx={{...hiddenFormInputStyle({value:formData.otherBDMS, canEdit})}} />
                        </FormGroup>
                    </FormControl>
                </Grid>
                { formData.otherBDMS && (
                    <Grid item xs={12} md={12} sx={{ml:2}}>
                        <TextField sx={{...hiddenFormInputStyle({value:formData.otherBDMSText, canEdit}), resize:"both"}} multiline  label="Other-bdms" name="otherBDMSText" size="small" error={ errors.otherBDMSText ? true : false } fullWidth value={ formData.otherBDMSText } onChange={ updateFormData } />
                    </Grid> 
                    )
                }
                <Grid item xs={12} md={12} sx={{ml:2}}>
                    <FormControl>
                        <FormGroup sx={{display:'inline'}}>
                            <FormControlLabel control={
                                <Checkbox checked={ formData.nonBDMS ? formData.nonBDMS : false } onChange={updateFormData} name="nonBDMS" />
                            } label="Non-BDMS" sx={{...hiddenFormInputStyle({value:formData.nonBDMS, canEdit})}} />
                        </FormGroup>
                    </FormControl>
                </Grid>
                { formData.nonBDMS && (
                    <Grid item xs={12} md={12} sx={{ml:2}}>
                        <TextField sx={{...hiddenFormInputStyle({value:formData.nonBDMSText, canEdit}), resize:"both" }} multiline  label="Non-bdms" name="nonBDMSText" size="small" error={ errors.nonBDMSText ? true : false } fullWidth value={ formData.nonBDMSText } onChange={ updateFormData } />
                    </Grid> 
                    )
                }
            </Grid>
        </React.Fragment>
     );
});
 
export default SummaryOfDiscussion;