import React, { useEffect, useState } from "react";
import axiosInstance from "../common/axios";
import { Box, Button, Card, Typography, Pagination, CardActions, Grid, TextField, Tabs, Tab, List } from "@mui/material";
import TopicListCard from "../components/topic/TopicListCard";
import Loading from '../components/Loading';
import { TOPIC_STATUS_PROCESS } from '../config/form';


const TopicList = () => {
    const [ topics, setTopics ] = useState([]);
    const itemPerPage = 10;
    const [pageCount,setPageCount] = useState(1);
    const [page,setPage] = useState(1);
    const [search,setSearch] = useState('');
    const [ tab, setTab ] = useState('allTopic');
    const [ loading, setLoading ] = useState(false)

    const handleChangePage = (event, page) => {
        setPage(page);
        getTopicData(page,tab,search);
    };

    const handleSearch = (event) => {
        setPage(1);
        getTopicData(1,tab,search);
    }

    const getTopicData = (page,tab,search) => {
        setLoading(true);
        let offset = (page - 1) * itemPerPage;
        let url = `/topic/topic/?limit=${itemPerPage}&offset=${offset}&search=${search}`;
        switch (tab) {
            case 'allTopic':
                url = url + '&status='+TOPIC_STATUS_PROCESS;
                break;
            case 'myTopic':
                url = url + '&user_only=true';
                break;
            case 'myFavoriteTopic':
                url = url + '&my_favorite_topic=true';
                break;
            default:
                break;
        }
        axiosInstance.get(url).then((res)=>{
            if(res.data){
                setPageCount(Math.ceil(res.data.count / itemPerPage))
                setTopics(res.data.results)
            }
            setLoading(false)
        }).catch((error)=>{
            setLoading(false)
            console.log(error)
        })
    }

    useEffect(()=>{
        getTopicData(page,tab,search);
    },[])// eslint-disable-line react-hooks/exhaustive-deps

    const handleChangeTab =(event,newTab) =>{
        setTab(newTab)
        setPage(1);
        setSearch('');
        getTopicData(1,newTab,'');
    }

    const handleSearchKeyPress = (e)=>{
        if(e.charCode === 13){
            handleSearch(e)
         }
    }

    return ( 
        <React.Fragment>
             <Card sx={{mt:2}} elevation={0}>
                    <Tabs
                        value={tab}
                        onChange={handleChangeTab}
                        variant="fullWidth"
                        
                    >
                        <Tab value="allTopic" label="all topic" />
                        <Tab value="myTopic" label="my topic" />
                        <Tab value="myFavoriteTopic" label="my favorite topic" />
                    </Tabs>
 
                <Box sx={{ padding: 3 }}>
                    <Grid container spacing={1}>
                        <Grid item xs={12} md={2}>
                            <TextField 
                                value={ search ? search : '' } 
                                onKeyPress={handleSearchKeyPress} 
                                label="search" 
                                fullWidth
                                size="small" 
                                onChange={(e)=> setSearch(e.target.value) } 
                            />
                        </Grid>
                        <Grid item xs={12} md={1}>
                            <Button fullWidth onClick={handleSearch} variant="outlined">Search</Button>
                        </Grid>
                    </Grid>

                    <List>
                        { topics && topics.map((topic)=>{ return (
                                <TopicListCard key={topic.id} topic={topic} />
                            ) 
                        }) }
                    </List>
                </Box>
                <CardActions sx={{ p: 1.25, pt: 0, justifyContent: 'center' }}>
                    <Pagination onChange={handleChangePage} count={pageCount} page={page} variant="outlined" color="primary" />
                </CardActions>
            </Card>
            <Loading loading={loading} />
        </React.Fragment>
     );
}
 
export default TopicList;