import React from "react";
import EditFormComment from "./EditFormComment";

import EditNormalComment from "./EditNormalComment";


const EditComment = ({ refreshCommentTypeInNewComment, showEditCommentDialog, setShowEditCommentDialog, comment, sendComment}) => {

    return ( 
        <React.Fragment>
            { comment && comment.form ? (
                <EditFormComment refreshCommentTypeInNewComment={refreshCommentTypeInNewComment} showEditCommentDialog={showEditCommentDialog} setShowEditCommentDialog={setShowEditCommentDialog} sendComment={sendComment} comment = {comment} />
            ) : (
                <EditNormalComment showEditCommentDialog={showEditCommentDialog} setShowEditCommentDialog={setShowEditCommentDialog} sendComment={sendComment} comment = {comment} />
            ) }
        </React.Fragment>
     );
}
 
export default EditComment;