import React, { useState, forwardRef, useImperativeHandle, useContext } from "react";
import { FormControl, FormLabel, FormControlLabel, FormGroup, Checkbox, Grid, TextField } from '@mui/material';
import { getDataUpdate, verifyRequireField } from "../../../utils";
import { REG_FLOAT_WITH_ONE_DEC, REG_NUMBER_WITHOUT_DEC } from "../../../../../config/format";
import { ClassesContext } from "../../../../../contexts/Classes";

const ImplantInformation = forwardRef( ({canEdit},ref) => {
    const [ errors, setErrors ] = useState({});
    const [ formData, setFormData ] = useState({});
    const { hiddenFormInputStyle } = useContext(ClassesContext);
    useImperativeHandle(ref,()=>({
        setData(data){
            setFormData(data)
        },
        verifyData(){
            let requireFields = [
                {   
                    errorName: 'implantInformation',
                    fields: [
                        'screw',
                        'plating',
                    ] ,
                    condition: 'or'  
                },
            ]
            let {isRequireFieldsValid,errorObject} = verifyRequireField(requireFields,formData);
            setErrors({ ...errorObject });
            return isRequireFieldsValid
        },
        getData(){
            return formData
        }
    }))

    const updateFormData = async (e) => {
        let dataUpdate = getDataUpdate(e);
        setFormData({...formData,...dataUpdate})
    }

    const updateFormDataWithPattern = async (e,dataPattern) => {
        let pattern = new RegExp(dataPattern, 'g');
        let isValidPattern = pattern.test(e.target.value)
        if( isValidPattern ){
            let dataUpdate = getDataUpdate(e);
            setFormData({...formData,...dataUpdate})
        }
    }
    
    return ( 
        <React.Fragment>
            <Grid container spacing={1} sx={{mt: 2}}>
                <Grid item xs={12} md={12}>
                    <FormControl>
                        <FormLabel error={ errors.implantInformation ? true: false } >Implant information*</FormLabel>
                    </FormControl>
                </Grid>
                <Grid item xs={12} md={12} sx={{ ...hiddenFormInputStyle({value:formData.plating,canEdit}), ml:2 }}>
                    <FormControl>
                        <FormGroup>
                            <FormControlLabel control={
                                <Checkbox checked={ formData.plating ? formData.plating : false } onChange={updateFormData} name="plating" />
                            } label="Plating" />
                        </FormGroup>
                    </FormControl>
                </Grid>
            </Grid>
            <Grid container spacing={1} sx={{ml: 4}}>
                <Grid item xs={10} md={12} >
                    <FormControl>
                        <FormLabel error={ errors.anterior ? true: false } >Anterior</FormLabel>
                    </FormControl>
                </Grid>
            </Grid>
            <Grid container spacing={1} sx={{
                ...hiddenFormInputStyle({value:formData.anteriorPubicSymphysis || formData.anteriorPubicSymphysisSize ||  formData.anteriorPubicSymphysisAmount , canEdit}),
                ml : 6 
            }}>
                <Grid item xs={10} md={3}>
                    <FormControl>
                        <FormGroup>
                            <FormControlLabel control={
                                <Checkbox checked={ formData.anteriorPubicSymphysis ? formData.anteriorPubicSymphysis : false } onChange={updateFormData} name="anteriorPubicSymphysis" />
                            } label="Pubic symphysis" sx={{ 
                                ...hiddenFormInputStyle({value:formData.anteriorPubicSymphysis, canEdit}) 
                            }} />
                        </FormGroup>
                    </FormControl>
                </Grid>
                <Grid item xs={10} md={3}>
                    <TextField 
                        value={ formData.anteriorPubicSymphysisSize ? formData.anteriorPubicSymphysisSize : '' }
                        onChange={ (e)=> updateFormDataWithPattern(e, REG_FLOAT_WITH_ONE_DEC )} 
                        label="Size"
                        name="anteriorPubicSymphysisSize"
                        size="small"
                        fullWidth
                        sx={{ 
                            ...hiddenFormInputStyle({value:formData.anteriorPubicSymphysisSize, canEdit}) 
                        }}
                    />
                </Grid>
                <Grid item xs={10} md={3}>
                    <TextField 
                        value={ formData.anteriorPubicSymphysisAmount ? formData.anteriorPubicSymphysisAmount : '' }
                        onChange={ (e)=> updateFormDataWithPattern(e, REG_NUMBER_WITHOUT_DEC )} 
                        label="Amount"
                        name="anteriorPubicSymphysisAmount"
                        size="small"
                        fullWidth
                        sx={{ 
                            ...hiddenFormInputStyle({value:formData.anteriorPubicSymphysisAmount, canEdit}) 
                        }}
                    />
                </Grid>
            </Grid>
            <Grid container spacing={1} sx={{
                ...hiddenFormInputStyle({value:formData.anteriorRamus || formData.anteriorRamusSize ||  formData.anteriorRamusAmount , canEdit}),
                ml : 6
            }}>
                <Grid item xs={10} md={3}>
                    <FormControl>
                        <FormGroup>
                            <FormControlLabel control={
                                <Checkbox checked={ formData.anteriorRamus ? formData.anteriorRamus : false } onChange={updateFormData} name="anteriorRamus" />
                            } label="Ramus" sx={{ 
                                ...hiddenFormInputStyle({value:formData.anteriorRamus, canEdit}) 
                            }} />
                        </FormGroup>
                    </FormControl>
                </Grid>
                <Grid item xs={10} md={3}>
                    <TextField 
                        value={ formData.anteriorRamusSize ? formData.anteriorRamusSize : '' }
                        onChange={ (e)=> updateFormDataWithPattern(e, REG_FLOAT_WITH_ONE_DEC )} 
                        label="Size"
                        name="anteriorRamusSize"
                        size="small"
                        fullWidth
                        sx={{ 
                            ...hiddenFormInputStyle({value:formData.anteriorRamusSize, canEdit}) 
                        }} 
                    />
                </Grid>
                <Grid item xs={10} md={3}>
                    <TextField 
                        value={ formData.anteriorRamusAmount ? formData.anteriorRamusAmount : '' }
                        onChange={ (e)=> updateFormDataWithPattern(e, REG_NUMBER_WITHOUT_DEC )} 
                        label="Amount"
                        name="anteriorRamusAmount"
                        size="small"
                        fullWidth
                        sx={{ 
                            ...hiddenFormInputStyle({value:formData.anteriorRamusAmount, canEdit}) 
                        }} 
                    />
                </Grid>
            </Grid>
            <Grid container spacing={1} sx={{
                ...hiddenFormInputStyle({value:formData.anteriorSuprapectinial || formData.anteriorSuprapectinialSize ||  formData.anteriorSuprapectinialAmount , canEdit}),
                ml : 6 
            }}>
                <Grid item xs={10} md={3}>
                    <FormControl>
                        <FormGroup>
                            <FormControlLabel control={
                                <Checkbox checked={ formData.anteriorSuprapectinial ? formData.anteriorSuprapectinial : false } onChange={updateFormData} name="anteriorSuprapectinial" />
                            } label="Suprapectinial" sx={{ 
                                ...hiddenFormInputStyle({value:formData.anteriorSuprapectinial, canEdit}) 
                            }}  />
                        </FormGroup>
                    </FormControl>
                </Grid>
                <Grid item xs={10} md={3}>
                    <TextField 
                        value={ formData.anteriorSuprapectinialSize ? formData.anteriorSuprapectinialSize : '' }
                        onChange={ (e)=> updateFormDataWithPattern(e, REG_FLOAT_WITH_ONE_DEC )} 
                        label="Size"
                        name="anteriorSuprapectinialSize"
                        size="small"
                        fullWidth
                        sx={{ 
                            ...hiddenFormInputStyle({value:formData.anteriorSuprapectinialSize, canEdit}) 
                        }}
                    />
                </Grid>
                <Grid item xs={10} md={3}>
                    <TextField 
                        value={ formData.anteriorSuprapectinialAmount ? formData.anteriorSuprapectinialAmount : '' }
                        onChange={ (e)=> updateFormDataWithPattern(e, REG_NUMBER_WITHOUT_DEC )} 
                        label="Amount"
                        name="anteriorSuprapectinialAmount"
                        size="small"
                        fullWidth
                        sx={{ 
                            ...hiddenFormInputStyle({value:formData.anteriorSuprapectinialAmount, canEdit}) 
                        }}
                    />
                </Grid>
            </Grid>
            <Grid container spacing={1} sx={{
                ...hiddenFormInputStyle({value:formData.anteriorInfrapectinial || formData.anteriorInfrapectinialSize ||  formData.anteriorInfrapectinialAmount , canEdit}),
                ml : 6 
            }}>
                <Grid item xs={10} md={3}>
                    <FormControl>
                        <FormGroup>
                            <FormControlLabel control={
                                <Checkbox checked={ formData.anteriorInfrapectinial ? formData.anteriorInfrapectinial : false } onChange={updateFormData} name="anteriorInfrapectinial" />
                            } label="Infrapectinial" sx={{ 
                                ...hiddenFormInputStyle({value:formData.anteriorInfrapectinial, canEdit}) 
                            }} />
                        </FormGroup>
                    </FormControl>
                </Grid>
                <Grid item xs={10} md={3}>
                    <TextField 
                        value={ formData.anteriorInfrapectinialSize ? formData.anteriorInfrapectinialSize : '' }
                        onChange={ (e)=> updateFormDataWithPattern(e, REG_FLOAT_WITH_ONE_DEC )} 
                        label="Size"
                        name="anteriorInfrapectinialSize"
                        size="small"
                        fullWidth
                        sx={{ 
                            ...hiddenFormInputStyle({value:formData.anteriorInfrapectinialSize, canEdit}) 
                        }}
                    />
                </Grid>
                <Grid item xs={10} md={3}>
                    <TextField 
                        value={ formData.anteriorInfrapectinialAmount ? formData.anteriorInfrapectinialAmount : '' }
                        onChange={ (e)=> updateFormDataWithPattern(e, REG_NUMBER_WITHOUT_DEC )} 
                        label="Amount"
                        name="anteriorInfrapectinialAmount"
                        size="small"
                        fullWidth
                        sx={{ 
                            ...hiddenFormInputStyle({value:formData.anteriorInfrapectinialAmount, canEdit}) 
                        }}
                    />
                </Grid>
            </Grid>
            <Grid container spacing={1} sx={{
                ...hiddenFormInputStyle({value:formData.anteriorIliacCrest || formData.anteriorIliacCrestSize ||  formData.anteriorIliacCrestAmount , canEdit}),
                ml : 6 
            }}>
                <Grid item xs={10} md={3}>
                    <FormControl>
                        <FormGroup>
                            <FormControlLabel control={
                                <Checkbox checked={ formData.anteriorIliacCrest ? formData.anteriorIliacCrest : false } onChange={updateFormData} name="anteriorIliacCrest" />
                            } label="Iliac crest" sx={{ 
                                ...hiddenFormInputStyle({value:formData.anteriorIliacCrest, canEdit}) 
                            }}/>
                        </FormGroup>
                    </FormControl>
                </Grid>
                <Grid item xs={10} md={3}>
                    <TextField 
                        value={ formData.anteriorIliacCrestSize ? formData.anteriorIliacCrestSize : '' }
                        onChange={ (e)=> updateFormDataWithPattern(e, REG_FLOAT_WITH_ONE_DEC )} 
                        label="Size"
                        name="anteriorIliacCrestSize"
                        size="small"
                        fullWidth
                        sx={{ 
                            ...hiddenFormInputStyle({value:formData.anteriorIliacCrestSize, canEdit}) 
                        }}
                    />
                </Grid>
                <Grid item xs={10} md={3}>
                    <TextField 
                        value={ formData.anteriorIliacCrestAmount ? formData.anteriorIliacCrestAmount : '' }
                        onChange={ (e)=> updateFormDataWithPattern(e, REG_NUMBER_WITHOUT_DEC )} 
                        label="Amount"
                        name="anteriorIliacCrestAmount"
                        size="small"
                        fullWidth
                        sx={{ 
                            ...hiddenFormInputStyle({value:formData.anteriorIliacCrestAmount, canEdit}) 
                        }}
                    />
                </Grid>
            </Grid>
            <Grid container spacing={1} sx={{
                ...hiddenFormInputStyle({value:formData.anteriorIlium || formData.anteriorIliumSize ||  formData.anteriorIliumAmount , canEdit}),
                ml : 6
            }}>
                <Grid item xs={10} md={3}>
                    <FormControl>
                        <FormGroup>
                            <FormControlLabel control={
                                <Checkbox checked={ formData.anteriorIlium ? formData.anteriorIlium : false } onChange={updateFormData} name="anteriorIlium" />
                            } label="Ilium" sx={{ 
                                ...hiddenFormInputStyle({value:formData.anteriorIlium, canEdit}) 
                            }} />
                        </FormGroup>
                    </FormControl>
                </Grid>
                <Grid item xs={10} md={3}>
                    <TextField 
                        value={ formData.anteriorIliumSize ? formData.anteriorIliumSize : '' }
                        onChange={ (e)=> updateFormDataWithPattern(e, REG_FLOAT_WITH_ONE_DEC )} 
                        label="Size"
                        name="anteriorIliumSize"
                        size="small"
                        fullWidth
                        sx={{ 
                            ...hiddenFormInputStyle({value:formData.anteriorIliumSize, canEdit}) 
                        }}
                    />
                </Grid>
                <Grid item xs={10} md={3}>
                    <TextField 
                        value={ formData.anteriorIliumAmount ? formData.anteriorIliumAmount : '' }
                        onChange={ (e)=> updateFormDataWithPattern(e, REG_NUMBER_WITHOUT_DEC )} 
                        label="Amount"
                        name="anteriorIliumAmount"
                        size="small"
                        fullWidth
                        sx={{ 
                            ...hiddenFormInputStyle({value:formData.anteriorIliumAmount, canEdit}) 
                        }}
                    />
                </Grid>
            </Grid>
            <Grid container spacing={1} sx={{
                ...hiddenFormInputStyle({value:formData.anteriorAnteriorPlating || formData.anteriorAnteriorPlatingSize ||  formData.anteriorAnteriorPlatingAmount , canEdit}),
                ml : 6
            }}>
                <Grid item xs={10} md={3}>
                    <FormControl>
                        <FormGroup>
                            <FormControlLabel control={
                                <Checkbox checked={ formData.anteriorAnteriorPlating ? formData.anteriorAnteriorPlating : false } onChange={updateFormData} name="anteriorAnteriorPlating" />
                            } label="Anterior plating" sx={{ 
                                ...hiddenFormInputStyle({value:formData.anteriorAnteriorPlating, canEdit}) 
                            }} />
                        </FormGroup>
                    </FormControl>
                </Grid>
                <Grid item xs={10} md={3}>
                    <TextField 
                        value={ formData.anteriorAnteriorPlatingSize ? formData.anteriorAnteriorPlatingSize : '' }
                        onChange={ (e)=> updateFormDataWithPattern(e, REG_FLOAT_WITH_ONE_DEC )} 
                        label="Size"
                        name="anteriorAnteriorPlatingSize"
                        size="small"
                        fullWidth
                        sx={{ 
                            ...hiddenFormInputStyle({value:formData.anteriorAnteriorPlatingSize, canEdit}) 
                        }}
                    />
                </Grid>
                <Grid item xs={10} md={3}>
                    <TextField 
                        value={ formData.anteriorAnteriorPlatingAmount ? formData.anteriorAnteriorPlatingAmount : '' }
                        onChange={ (e)=> updateFormDataWithPattern(e, REG_NUMBER_WITHOUT_DEC )} 
                        label="Amount"
                        name="anteriorAnteriorPlatingAmount"
                        size="small"
                        fullWidth
                        sx={{ 
                            ...hiddenFormInputStyle({value:formData.anteriorAnteriorPlatingAmount, canEdit}) 
                        }}
                    />
                </Grid>
            </Grid>
            <Grid container spacing={1} sx={{
                ...hiddenFormInputStyle({value:formData.anteriorOther || formData.anteriorOtherSize ||  formData.anteriorOtherAmount || formData.anteriorOtherText , canEdit}),
                ml : 6 
            }}>
                <Grid item xs={10} md={3}>
                    <FormControl>
                        <FormGroup>
                            <FormControlLabel control={
                                <Checkbox checked={ formData.anteriorOther ? formData.anteriorOther : false } onChange={updateFormData} name="anteriorOther" />
                            } label="Other" sx={{ 
                                ...hiddenFormInputStyle({value:formData.anteriorOther, canEdit}) 
                            }} />
                        </FormGroup>
                    </FormControl>
                </Grid>
                <Grid item xs={10} md={3}>
                    <TextField 
                        value={ formData.anteriorOtherSize ? formData.anteriorOtherSize : '' }
                        onChange={ (e)=> updateFormDataWithPattern(e, REG_FLOAT_WITH_ONE_DEC )} 
                        label="Size"
                        name="anteriorOtherSize"
                        size="small"
                        fullWidth
                        sx={{ 
                            ...hiddenFormInputStyle({value:formData.anteriorOtherSize, canEdit}) 
                        }}
                    />
                </Grid>
                <Grid item xs={10} md={3}>
                    <TextField 
                        value={ formData.anteriorOtherAmount ? formData.anteriorOtherAmount : '' }
                        onChange={ (e)=> updateFormDataWithPattern(e, REG_NUMBER_WITHOUT_DEC )} 
                        label="Amount"
                        name="anteriorOtherAmount"
                        size="small"
                        fullWidth
                        sx={{ 
                            ...hiddenFormInputStyle({value:formData.anteriorOtherAmount, canEdit}) 
                        }}
                    />
                </Grid>
                <Grid item xs={10} md={2}>
                    <TextField 
                        value={ formData.anteriorOtherText ? formData.anteriorOtherText : '' }
                        onChange={updateFormData}
                        label="other"
                        name="anteriorOtherText"
                        size="small"
                        fullWidth
                        sx={{ 
                            ...hiddenFormInputStyle({value:formData.anteriorOtherText, canEdit}) 
                        }}
                    />
                </Grid>
            </Grid>

            <Grid container spacing={1} sx={{ml : 4 }}>
                <Grid item xs={10} md={12} >
                    <FormControl>
                        <FormLabel error={ errors.posterior ? true: false } >Posterior</FormLabel>
                    </FormControl>
                </Grid>
            </Grid>

            <Grid container spacing={1} sx={{
                ...hiddenFormInputStyle({value:formData.posteriorIliacCrest || formData.posteriorIliacCrestSize ||  formData.posteriorIliacCrestAmount , canEdit}),
                ml : 6
            }}>
                <Grid item xs={10} md={3}>
                    <FormControl>
                        <FormGroup>
                            <FormControlLabel control={
                                <Checkbox checked={ formData.posteriorIliacCrest ? formData.posteriorIliacCrest : false } onChange={updateFormData} name="posteriorIliacCrest" />
                            } label="Iliac crest" sx={{ 
                                ...hiddenFormInputStyle({value:formData.posteriorIliacCrest, canEdit}) 
                            }} />
                        </FormGroup>
                    </FormControl>
                </Grid>
                <Grid item xs={10} md={3}>
                    <TextField 
                        value={ formData.posteriorIliacCrestSize ? formData.posteriorIliacCrestSize : '' }
                        onChange={ (e)=> updateFormDataWithPattern(e, REG_FLOAT_WITH_ONE_DEC )} 
                        label="Size"
                        name="posteriorIliacCrestSize"
                        size="small"
                        fullWidth
                        sx={{ 
                            ...hiddenFormInputStyle({value:formData.posteriorIliacCrestSize, canEdit}) 
                        }}
                    />
                </Grid>
                <Grid item xs={10} md={3}>
                    <TextField 
                        value={ formData.posteriorIliacCrestAmount ? formData.posteriorIliacCrestAmount : '' }
                        onChange={ (e)=> updateFormDataWithPattern(e, REG_NUMBER_WITHOUT_DEC )} 
                        label="Amount"
                        name="posteriorIliacCrestAmount"
                        size="small"
                        fullWidth
                        sx={{ 
                            ...hiddenFormInputStyle({value:formData.posteriorIliacCrestAmount, canEdit}) 
                        }}
                    />
                </Grid>
            </Grid>
            <Grid container spacing={1} sx={{
                ...hiddenFormInputStyle({value:formData.posteriorIlium || formData.posteriorIliumSize ||  formData.posteriorIliumAmount , canEdit}),
                ml : 6
            }}>
                <Grid item xs={10} md={3}>
                    <FormControl>
                        <FormGroup>
                            <FormControlLabel control={
                                <Checkbox checked={ formData.posteriorIlium ? formData.posteriorIlium : false } onChange={updateFormData} name="posteriorIlium" />
                            } label="Ilium" sx={{ 
                                ...hiddenFormInputStyle({value:formData.posteriorIlium, canEdit}) 
                            }} />
                        </FormGroup>
                    </FormControl>
                </Grid>
                <Grid item xs={10} md={3}>
                    <TextField 
                        value={ formData.posteriorIliumSize ? formData.posteriorIliumSize : '' }
                        onChange={ (e)=> updateFormDataWithPattern(e, REG_FLOAT_WITH_ONE_DEC )} 
                        label="Size"
                        name="posteriorIliumSize"
                        size="small"
                        fullWidth
                        sx={{ 
                            ...hiddenFormInputStyle({value:formData.posteriorIliumSize, canEdit}) 
                        }}
                    />
                </Grid>
                <Grid item xs={10} md={3}>
                    <TextField 
                        value={ formData.posteriorIliumAmount ? formData.posteriorIliumAmount : '' }
                        onChange={ (e)=> updateFormDataWithPattern(e, REG_NUMBER_WITHOUT_DEC )} 
                        label="Amount"
                        name="posteriorIliumAmount"
                        size="small"
                        fullWidth
                        sx={{ 
                            ...hiddenFormInputStyle({value:formData.posteriorIliumAmount, canEdit}) 
                        }}
                    />
                </Grid>
            </Grid>
            <Grid container spacing={1} sx={{
                ...hiddenFormInputStyle({value:formData.posteriorTensionBand || formData.posteriorTensionBandSize ||  formData.posteriorTensionBandAmount , canEdit}),
                ml : 6
            }}>
                <Grid item xs={10} md={3}>
                    <FormControl>
                        <FormGroup>
                            <FormControlLabel control={
                                <Checkbox checked={ formData.posteriorTensionBand ? formData.posteriorTensionBand : false } onChange={updateFormData} name="posteriorTensionBand" />
                            } label="Tension band" sx={{ 
                                ...hiddenFormInputStyle({value:formData.posteriorTensionBand, canEdit}) 
                            }} />
                        </FormGroup>
                    </FormControl>
                </Grid>
                <Grid item xs={10} md={3}>
                    <TextField 
                        value={ formData.posteriorTensionBandSize ? formData.posteriorTensionBandSize : '' }
                        onChange={ (e)=> updateFormDataWithPattern(e, REG_FLOAT_WITH_ONE_DEC )} 
                        label="Size"
                        name="posteriorTensionBandSize"
                        size="small"
                        fullWidth
                        sx={{ 
                            ...hiddenFormInputStyle({value:formData.posteriorTensionBandSize, canEdit}) 
                        }}
                    />
                </Grid>
                <Grid item xs={10} md={3}>
                    <TextField 
                        value={ formData.posteriorTensionBandAmount ? formData.posteriorTensionBandAmount : '' }
                        onChange={ (e)=> updateFormDataWithPattern(e, REG_NUMBER_WITHOUT_DEC )} 
                        label="Amount"
                        name="posteriorTensionBandAmount"
                        size="small"
                        fullWidth
                        sx={{ 
                            ...hiddenFormInputStyle({value:formData.posteriorTensionBandAmount, canEdit}) 
                        }}
                    />
                </Grid>
            </Grid>
            <Grid container spacing={1} sx={{
                ...hiddenFormInputStyle({value:formData.posteriorSpring || formData.posteriorSpringSize ||  formData.posteriorSpringAmount , canEdit}),
                ml : 6 
            }}>
                <Grid item xs={10} md={3}>
                    <FormControl>
                        <FormGroup>
                            <FormControlLabel control={
                                <Checkbox checked={ formData.posteriorSpring ? formData.posteriorSpring : false } onChange={updateFormData} name="posteriorSpring" />
                            } label="Spring" sx={{ 
                                ...hiddenFormInputStyle({value:formData.posteriorSpring, canEdit}) 
                            }} />
                        </FormGroup>
                    </FormControl>
                </Grid>
                <Grid item xs={10} md={3}>
                    <TextField 
                        value={ formData.posteriorSpringSize ? formData.posteriorSpringSize : '' }
                        onChange={ (e)=> updateFormDataWithPattern(e, REG_FLOAT_WITH_ONE_DEC )} 
                        label="Size"
                        name="posteriorSpringSize"
                        size="small"
                        fullWidth
                        sx={{ 
                            ...hiddenFormInputStyle({value:formData.posteriorSpringSize, canEdit}) 
                        }}
                    />
                </Grid>
                <Grid item xs={10} md={3}>
                    <TextField 
                        value={ formData.posteriorSpringAmount ? formData.posteriorSpringAmount : '' }
                        onChange={ (e)=> updateFormDataWithPattern(e, REG_NUMBER_WITHOUT_DEC )} 
                        label="Amount"
                        name="posteriorSpringAmount"
                        size="small"
                        fullWidth
                        sx={{ 
                            ...hiddenFormInputStyle({value:formData.posteriorSpringAmount, canEdit}) 
                        }}
                    />
                </Grid>
            </Grid>
            <Grid container spacing={1} sx={{
                ...hiddenFormInputStyle({value:formData.posteriorOther || formData.posteriorOtherSize ||  formData.posteriorOtherAmount || formData.posteriorOtherText , canEdit}),
                ml : 6
            }}>
                <Grid item xs={10} md={3} >
                    <FormControl>
                        <FormGroup>
                            <FormControlLabel control={
                                <Checkbox checked={ formData.posteriorOther ? formData.posteriorOther : false } onChange={updateFormData} name="posteriorOther" />
                            } label="Other" sx={{ 
                                ...hiddenFormInputStyle({value:formData.posteriorOther, canEdit}) 
                            }} />
                        </FormGroup>
                    </FormControl>
                </Grid>
                <Grid item xs={10} md={3}>
                    <TextField 
                        value={ formData.posteriorOtherSize ? formData.posteriorOtherSize : '' }
                        onChange={ (e)=> updateFormDataWithPattern(e, REG_FLOAT_WITH_ONE_DEC )} 
                        label="Size"
                        name="posteriorOtherSize"
                        size="small"
                        fullWidth
                        sx={{ 
                            ...hiddenFormInputStyle({value:formData.posteriorOtherSize, canEdit}) 
                        }}
                    />
                </Grid>
                <Grid item xs={10} md={3}>
                    <TextField 
                        value={ formData.posteriorOtherAmount ? formData.posteriorOtherAmount : '' }
                        onChange={ (e)=> updateFormDataWithPattern(e, REG_NUMBER_WITHOUT_DEC )} 
                        label="Amount"
                        name="posteriorOtherAmount"
                        size="small"
                        fullWidth
                        sx={{ 
                            ...hiddenFormInputStyle({value:formData.posteriorOtherAmount, canEdit}) 
                        }}
                    />
                </Grid>
                <Grid item xs={10} md={2}>
                    <TextField 
                        value={ formData.posteriorOtherText ? formData.posteriorOtherText : '' }
                        onChange={updateFormData}
                        label="other"
                        name="posteriorOtherText"
                        size="small"
                        fullWidth
                        sx={{ 
                            ...hiddenFormInputStyle({value:formData.posteriorOtherText, canEdit}) 
                        }}
                    />
                </Grid>
            </Grid>

            <Grid container spacing={1}  >
                <Grid item xs={12} md={12} sx={{ml:2}}>
                    <FormControl>
                        <FormGroup>
                            <FormControlLabel control={
                                <Checkbox checked={ formData.screw ? formData.screw : false } onChange={updateFormData} name="screw" />
                            } label="Screw" sx={{ 
                                ...hiddenFormInputStyle({value:formData.screw, canEdit}) 
                            }} />
                        </FormGroup>
                    </FormControl>
                </Grid>
            </Grid>
            <Grid container spacing={1} sx={{
                ...hiddenFormInputStyle({value:formData.screwAnteriorColumn || formData.screwAnteriorColumnSize ||  formData.screwAnteriorColumnAmount , canEdit}),
                ml : 6
            }} >
                <Grid item xs={10} md={3}>
                    <FormControl>
                        <FormGroup>
                            <FormControlLabel control={
                                <Checkbox checked={ formData.screwAnteriorColumn ? formData.screwAnteriorColumn : false } onChange={updateFormData} name="screwAnteriorColumn" />
                            } label="Anterior column" sx={{ 
                                ...hiddenFormInputStyle({value:formData.screwAnteriorColumn, canEdit}) 
                            }} />
                        </FormGroup>
                    </FormControl>
                </Grid>
                <Grid item xs={10} md={3}>
                    <TextField 
                        value={ formData.screwAnteriorColumnSize ? formData.screwAnteriorColumnSize : '' }
                        onChange={ (e)=> updateFormDataWithPattern(e, REG_FLOAT_WITH_ONE_DEC )} 
                        label="Size"
                        name="screwAnteriorColumnSize"
                        size="small"
                        fullWidth
                        sx={{ 
                            ...hiddenFormInputStyle({value:formData.screwAnteriorColumnSize, canEdit}) 
                        }}
                    />
                </Grid>
                <Grid item xs={10} md={3}>
                    <TextField 
                        value={ formData.screwAnteriorColumnAmount ? formData.screwAnteriorColumnAmount : '' }
                        onChange={ (e)=> updateFormDataWithPattern(e, REG_NUMBER_WITHOUT_DEC )} 
                        label="Amount"
                        name="screwAnteriorColumnAmount"
                        size="small"
                        fullWidth
                        sx={{ 
                            ...hiddenFormInputStyle({value:formData.screwAnteriorColumnAmount, canEdit}) 
                        }}
                    />
                </Grid>
            </Grid>
            <Grid container spacing={1} sx={{
                ...hiddenFormInputStyle({value:formData.screwLateralCompressionScrew || formData.screwLateralCompressionScrewSize ||  formData.screwLateralCompressionScrewAmount , canEdit}),
                ml : 6
            }} >
                <Grid item xs={10} md={3}>
                    <FormControl>
                        <FormGroup>
                            <FormControlLabel control={
                                <Checkbox checked={ formData.screwLateralCompressionScrew ? formData.screwLateralCompressionScrew : false } onChange={updateFormData} name="screwLateralCompressionScrew" />
                            } label="Lateral compression screw" sx={{ 
                                ...hiddenFormInputStyle({value:formData.screwLateralCompressionScrew, canEdit}) 
                            }} />
                        </FormGroup>
                    </FormControl>
                </Grid>
                <Grid item xs={10} md={3}>
                    <TextField 
                        value={ formData.screwLateralCompressionScrewSize ? formData.screwLateralCompressionScrewSize : '' }
                        onChange={ (e)=> updateFormDataWithPattern(e, REG_FLOAT_WITH_ONE_DEC )} 
                        label="Size"
                        name="screwLateralCompressionScrewSize"
                        size="small"
                        fullWidth
                        sx={{ 
                            ...hiddenFormInputStyle({value:formData.screwLateralCompressionScrewSize, canEdit}) 
                        }}
                    />
                </Grid>
                <Grid item xs={10} md={3}>
                    <TextField 
                        value={ formData.screwLateralCompressionScrewAmount ? formData.screwLateralCompressionScrewAmount : '' }
                        onChange={ (e)=> updateFormDataWithPattern(e, REG_NUMBER_WITHOUT_DEC )} 
                        label="Amount"
                        name="screwLateralCompressionScrewAmount"
                        size="small"
                        fullWidth
                        sx={{ 
                            ...hiddenFormInputStyle({value:formData.screwLateralCompressionScrewAmount, canEdit}) 
                        }}
                    />
                </Grid>
            </Grid>
            <Grid container spacing={1} sx={{
                ...hiddenFormInputStyle({value:formData.screwIliacCrestScrew || formData.screwIliacCrestScrewSize ||  formData.screwIliacCrestScrewAmount , canEdit}),
                ml : 6 
            }}>
                <Grid item xs={10} md={3}>
                    <FormControl>
                        <FormGroup>
                            <FormControlLabel control={
                                <Checkbox checked={ formData.screwIliacCrestScrew ? formData.screwIliacCrestScrew : false } onChange={updateFormData} name="screwIliacCrestScrew" />
                            } label="Iliac crest screw" sx={{ 
                                ...hiddenFormInputStyle({value:formData.screwIliacCrestScrew, canEdit}) 
                            }} />
                        </FormGroup>
                    </FormControl>
                </Grid>
                <Grid item xs={10} md={3}>
                    <TextField 
                        value={ formData.screwIliacCrestScrewSize ? formData.screwIliacCrestScrewSize : '' }
                        onChange={ (e)=> updateFormDataWithPattern(e, REG_FLOAT_WITH_ONE_DEC )} 
                        label="Size"
                        name="screwIliacCrestScrewSize"
                        size="small"
                        fullWidth
                        sx={{ 
                            ...hiddenFormInputStyle({value:formData.screwIliacCrestScrewSize, canEdit}) 
                        }}
                    />
                </Grid>
                <Grid item xs={10} md={3}>
                    <TextField 
                        value={ formData.screwIliacCrestScrewAmount ? formData.screwIliacCrestScrewAmount : '' }
                        onChange={ (e)=> updateFormDataWithPattern(e, REG_NUMBER_WITHOUT_DEC )} 
                        label="Amount"
                        name="screwIliacCrestScrewAmount"
                        size="small"
                        fullWidth
                        sx={{ 
                            ...hiddenFormInputStyle({value:formData.screwIliacCrestScrewAmount, canEdit}) 
                        }}
                    />
                </Grid>
            </Grid>
            <Grid container spacing={1} sx={{
                ...hiddenFormInputStyle({value:formData.screwPosteriorColumnScrew || formData.screwPosteriorColumnScrewSize ||  formData.screwPosteriorColumnScrewAmount , canEdit}),
                ml : 6
            }}>
                <Grid item xs={10} md={3}>
                    <FormControl>
                        <FormGroup>
                            <FormControlLabel control={
                                <Checkbox checked={ formData.screwPosteriorColumnScrew ? formData.screwPosteriorColumnScrew : false } onChange={updateFormData} name="screwPosteriorColumnScrew" />
                            } label="Posterior column screw" sx={{ 
                                ...hiddenFormInputStyle({value:formData.screwPosteriorColumnScrew, canEdit}) 
                            }} />
                        </FormGroup>
                    </FormControl>
                </Grid>
                <Grid item xs={10} md={3}>
                    <TextField 
                        value={ formData.screwPosteriorColumnScrewSize ? formData.screwPosteriorColumnScrewSize : '' }
                        onChange={ (e)=> updateFormDataWithPattern(e, REG_FLOAT_WITH_ONE_DEC )} 
                        label="Size"
                        name="screwPosteriorColumnScrewSize"
                        size="small"
                        fullWidth
                        sx={{ 
                            ...hiddenFormInputStyle({value:formData.screwPosteriorColumnScrewSize, canEdit}) 
                        }}
                    />
                </Grid>
                <Grid item xs={10} md={3}>
                    <TextField 
                        value={ formData.screwPosteriorColumnScrewAmount ? formData.screwPosteriorColumnScrewAmount : '' }
                        onChange={ (e)=> updateFormDataWithPattern(e, REG_NUMBER_WITHOUT_DEC )} 
                        label="Amount"
                        name="screwPosteriorColumnScrewAmount"
                        size="small"
                        fullWidth
                        sx={{ 
                            ...hiddenFormInputStyle({value:formData.screwPosteriorColumnScrewAmount, canEdit}) 
                        }}
                    />
                </Grid>
            </Grid>
            <Grid container spacing={1} sx={{
                ...hiddenFormInputStyle({value:formData.screwSIScrew || formData.screwSIScrewSize ||  formData.screwSIScrewAmount , canEdit}),
                ml : 6 
            }}>
                <Grid item xs={10} md={3}>
                    <FormControl>
                        <FormGroup>
                            <FormControlLabel control={
                                <Checkbox checked={ formData.screwSIScrew ? formData.screwSIScrew : false } onChange={updateFormData} name="screwSIScrew" />
                            } label="SI screw" sx={{ 
                                ...hiddenFormInputStyle({value:formData.screwSIScrew, canEdit}) 
                            }} />
                        </FormGroup>
                    </FormControl>
                </Grid>
                <Grid item xs={10} md={3}>
                    <TextField 
                        value={ formData.screwSIScrewSize ? formData.screwSIScrewSize : '' }
                        onChange={ (e)=> updateFormDataWithPattern(e, REG_FLOAT_WITH_ONE_DEC )} 
                        label="Size"
                        name="screwSIScrewSize"
                        size="small"
                        fullWidth
                        sx={{ 
                            ...hiddenFormInputStyle({value:formData.screwSIScrewSize, canEdit}) 
                        }}
                    />
                </Grid>
                <Grid item xs={10} md={3}>
                    <TextField 
                        value={ formData.screwSIScrewAmount ? formData.screwSIScrewAmount : '' }
                        onChange={ (e)=> updateFormDataWithPattern(e, REG_NUMBER_WITHOUT_DEC )} 
                        label="Amount"
                        name="screwSIScrewAmount"
                        size="small"
                        fullWidth
                        sx={{ 
                            ...hiddenFormInputStyle({value:formData.screwSIScrewAmount, canEdit}) 
                        }}
                    />
                </Grid>
            </Grid>
            <Grid container spacing={1} sx={{
                ...hiddenFormInputStyle({value:formData.screwTransIliacScrew || formData.screwTransIliacScrewSize ||  formData.screwTransIliacScrewAmount , canEdit}),
                ml : 6
            }}>
                <Grid item xs={10} md={3}>
                    <FormControl>
                        <FormGroup>
                            <FormControlLabel control={
                                <Checkbox checked={ formData.screwTransIliacScrew ? formData.screwTransIliacScrew : false } onChange={updateFormData} name="screwTransIliacScrew" />
                            } label="Trans iliac screw" sx={{ 
                                ...hiddenFormInputStyle({value:formData.screwTransIliacScrew, canEdit}) 
                            }} />
                        </FormGroup>
                    </FormControl>
                </Grid>
                <Grid item xs={10} md={3}>
                    <TextField 
                        value={ formData.screwTransIliacScrewSize ? formData.screwTransIliacScrewSize : '' }
                        onChange={ (e)=> updateFormDataWithPattern(e, REG_FLOAT_WITH_ONE_DEC )} 
                        label="Size"
                        name="screwTransIliacScrewSize"
                        size="small"
                        fullWidth
                        sx={{ 
                            ...hiddenFormInputStyle({value:formData.screwTransIliacScrewSize, canEdit}) 
                        }}
                    />
                </Grid>
                <Grid item xs={10} md={3}>
                    <TextField 
                        value={ formData.screwTransIliacScrewAmount ? formData.screwTransIliacScrewAmount : '' }
                        onChange={ (e)=> updateFormDataWithPattern(e, REG_NUMBER_WITHOUT_DEC )} 
                        label="Amount"
                        name="screwTransIliacScrewAmount"
                        size="small"
                        fullWidth
                        sx={{ 
                            ...hiddenFormInputStyle({value:formData.screwTransIliacScrewAmount, canEdit}) 
                        }}
                    />
                </Grid>
            </Grid>
            <Grid container spacing={1} sx={{
                ...hiddenFormInputStyle({value:formData.screwOther || formData.screwOtherSize ||  formData.screwOtherAmount || formData.screwOtherText , canEdit}),
                ml : 6
            }}>
                <Grid item xs={10} md={3} >
                    <FormControl>
                        <FormGroup>
                            <FormControlLabel control={
                                <Checkbox checked={ formData.screwOther ? formData.screwOther : false } onChange={updateFormData} name="screwOther" />
                            } label="Other" sx={{ 
                                ...hiddenFormInputStyle({value:formData.screwOther, canEdit}) 
                            }} />
                        </FormGroup>
                    </FormControl>
                </Grid>
                <Grid item xs={10} md={3}>
                    <TextField 
                        value={ formData.screwOtherSize ? formData.screwOtherSize : '' }
                        onChange={ (e)=> updateFormDataWithPattern(e, REG_FLOAT_WITH_ONE_DEC )} 
                        label="Size"
                        name="screwOtherSize"
                        size="small"
                        fullWidth
                        sx={{ 
                            ...hiddenFormInputStyle({value:formData.screwOtherSize, canEdit}) 
                        }}
                    />
                </Grid>
                <Grid item xs={10} md={3}>
                    <TextField 
                        value={ formData.screwOtherAmount ? formData.screwOtherAmount : '' }
                        onChange={ (e)=> updateFormDataWithPattern(e, REG_NUMBER_WITHOUT_DEC )} 
                        label="Amount"
                        name="screwOtherAmount"
                        size="small"
                        fullWidth
                        sx={{ 
                            ...hiddenFormInputStyle({value:formData.screwOtherAmount, canEdit}) 
                        }}
                    />
                </Grid>
                <Grid item xs={10} md={2}>
                    <TextField 
                        value={ formData.screwOtherText ? formData.screwOtherText : '' }
                        onChange={updateFormData}
                        label="other"
                        name="screwOtherText"
                        size="small"
                        fullWidth
                        sx={{ 
                            ...hiddenFormInputStyle({value:formData.screwOtherText, canEdit}) 
                        }}
                    />
                </Grid>
            </Grid>

        </React.Fragment>
     );
});
 
export default ImplantInformation;