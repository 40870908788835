import { forwardRef, useState, useImperativeHandle } from 'react';
import { Box, Button, CircularProgress, Grid, TextField, Typography, Alert } from "@mui/material";
import axiosInstance from '../../common/axios';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme)=>{
    return {
        root: (canEdit) => {
            let style = {}
            if(!canEdit){
                style["& .MuiFormLabel-root"] = {pointerEvents: 'none'}
                style['pointerEvents'] = 'none' 
            }
            return style
        },
        hidden: (canEdit) => {
            let style = {}
            if(!canEdit){
                style['display'] = 'none !important'  
            }
            return style
        }
    }
})


const PatientDetailForm = forwardRef( ({hospitalSelected, canEdit},ref) => {
    const classes = useStyles(canEdit)
    const [ enError,setEnError ] = useState(false);
    const [ patientError, setPatientError ] = useState(false);
    const [ loading, setLoading ] = useState(false);
    const [ formData, setFormData ] = useState({});
    const [ patientDetailError, setPatientDetailError ] = useState(false)

    useImperativeHandle(ref,()=>({
        setData(data){
            data.visit_date = data.visit_date ? data.visit_date.split("T")[0] :null;
            setFormData(data)
        },
        verifyRequireField(){
            let isValid = formData.id ? true : false;
            setPatientDetailError(!isValid)
            return isValid;
        },
        getData() {
            return formData
        }
    }));

    const enAndVisitDateOnchange = (en,visit_date) => {
        let dataUpdate = {};
        dataUpdate["en"] = en;
        dataUpdate['visit_date'] = visit_date
        setFormData( { ...dataUpdate } )
    }

    

    const searchPatient = () => {
        setEnError( !formData.en )
        enAndVisitDateOnchange(formData.en,formData.visit_date)
        if(hospitalSelected && formData.en ){
            setLoading(true)
            axiosInstance.post('/topic/patient-visit/create_patient_from_coe_service/',{
                en: formData.en,
                hospital: hospitalSelected,
                visit_date: formData.visit_date ? formData.visit_date : null
            }).then((res)=>{
                if( res.data.id ){
                    setFormData({...res.data,
                        visit_date: res.data.visit_date ? res.data.visit_date.split("T")[0] :null})
                    setPatientError(false)
                    setPatientDetailError(false)
                }else{
                    setPatientError(true)
                }
                setLoading(false);
            }).catch((error)=>{
                setPatientError(true);
                setLoading(false);
            })
        }

    }
    
    return ( 
        <Box  className={classes.root} sx={{padding:3}}>
            <Typography color={ patientDetailError ? "error" : "black" } sx={{mb:2,fontWeight: "bold"}} variant="h6" component="div">
                Patient detail *
            </Typography>
            <Grid container spacing={3}>
                <Grid item xs={12} md={3}>
                    <TextField size="small"
                        fullWidth
                        label="EN"
                        variant="outlined"
                        name='en'
                        value={ formData.en ? formData.en : '' }
                        error={enError}
                        onChange={ e=>{
                            enAndVisitDateOnchange(e.target.value,formData.visit_date)
                        }}
                    />
                </Grid>
                <Grid item xs={12} md={1}>
                    <Button  className={classes.hidden} fullWidth variant="outlined" onClick={searchPatient}>
                        { loading ? <CircularProgress size={25} sx={{align:"center"}} /> : 'Search' }
                    </Button>
                </Grid>
            </Grid>
            { patientError && (     
                <Grid container spacing={3} sx={{mt:1}}>
                    <Grid item xs={12} md={3}>
                        <Alert severity="error" >Patient Not Found</Alert>
                    </Grid>
                </Grid> ) 
            }

            <Grid container spacing={3} sx={{mt:1}}>
                <Grid item xs={12} md={3} >
                    <TextField size="small"
                        fullWidth
                        label="HN"
                        value={ formData.id ? formData.patient_detail.hn : '' }
                        variant="outlined"
                        name='hn'
                        InputProps={{
                            readOnly: true,
                        }}
                    />
                </Grid>
                <Grid item xs={12} md={3} >
                    <TextField
                        label="Visit date"
                        type="date"
                        value={ formData.visit_date ? formData.visit_date : '' }
                        size="small"
                        fullWidth
                        InputLabelProps={{
                            shrink: true,
                        }}
                        onChange={ e=>{
                            enAndVisitDateOnchange(formData.en,e.target.value)
                        }}
                        InputProps={{
                            readOnly: !canEdit,
                        }}
                    />
                </Grid>
                <Grid item xs={12} md={3} >
                    <TextField size="small"
                            InputProps={{
                                readOnly: true,
                            }}
                            fullWidth
                            label="Name"
                            value={ formData.id ? formData.patient_detail.name : '' }
                            variant="outlined"
                            name='name'
                    />
                </Grid>
                <Grid item xs={12} md={3} >
                    <TextField size="small"
                            InputProps={{
                                readOnly: true,
                            }}
                            fullWidth
                            label="Gender"
                            value={ formData.id ? formData.patient_detail.gender : '' }
                            variant="outlined"
                            name='gender'
                    />
                </Grid>
                <Grid item xs={12} md={3} >
                    <TextField
                        label="Date of birth"
                        type="date"
                        value={ formData.id ? formData.patient_detail.date_of_birth : '' }
                        size="small"
                        fullWidth
                        InputLabelProps={{
                            shrink: true,
                        }}
                        InputProps={{
                            readOnly: true,
                        }}
                    />
                </Grid>
                <Grid item xs={12} md={3} >
                    <TextField size="small"
                            InputProps={{
                                readOnly: true,
                            }}
                            fullWidth
                            label="Age (years)"
                            value={ formData.id ? formData.age : '' }
                            variant="outlined"
                            name='age'
                    />
                </Grid>
                <Grid item xs={12} md={3} >
                    <TextField size="small"
                            fullWidth
                            InputProps={{
                                readOnly: true,
                            }}
                            label="Weight (kg.)"
                            value={ formData.id && formData.vital_sign.weight  ? formData.vital_sign.weight : '' }
                            variant="outlined"
                            name='weight'
                    />
                </Grid>
                <Grid item xs={12} md={3} >
                    <TextField size="small"
                            fullWidth
                            InputProps={{
                                readOnly: true,
                            }}
                            label="Height (cm.)"
                            value={ formData.id && formData.vital_sign.height ? formData.vital_sign.height : '' }
                            variant="outlined"
                            name='height'
                    />
                </Grid>
                <Grid item xs={12} md={6} >
                    <TextField size="small"
                            fullWidth
                            InputProps={{
                                readOnly: true,
                            }}
                            multiline
                            label="Attending surgeon"
                            value={ formData.id && formData.care_provider_name ? formData.care_provider_name : '' }
                            variant="outlined"
                            name='height'
                    />
                </Grid>
            </Grid>
        </Box>
    );
});
 
export default PatientDetailForm;