import { Divider, Typography, Box } from "@mui/material";
import React from "react";
import ReactPlayer from 'react-player'

const VideoList = ({videoFiles}) => {

    return ( 
        <React.Fragment>
            { videoFiles.length > 0 && <Divider sx={{mt:2}}> Video </Divider> }
            { videoFiles.length > 0 && videoFiles.map((video,index)=> (
                <Box key={index}>
                    <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                        {video.description}
                    </Typography>
                    <ReactPlayer  controls={true} className='react-player' url={`${process.env.REACT_APP_BACKEND_URL}` + video.url} />
                </Box>
            )) }
        </React.Fragment>
     );
}
 
export default VideoList;