
import React from "react";
import NormalCommentCard from "./NormalCommentCard";
import FormCommentCard from "./FormCommentCard";


const CommentCard = ({index,comment,editCommentHandle}) => {
    return ( 
        <React.Fragment>
            { comment.form && (
                <FormCommentCard index={index} comment={comment} editCommentHandle={editCommentHandle} />
            ) }
            { !comment.form && (
                <NormalCommentCard index={index} comment={comment} editCommentHandle={editCommentHandle} />
            ) }
        </React.Fragment>
     );
}
 
export default CommentCard;