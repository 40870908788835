import React, { useState, forwardRef, useImperativeHandle, useContext } from "react";
import { Box, FormLabel, OutlinedInput, Grid, InputAdornment } from '@mui/material';
import { checkDataPattern, verifyRequireField } from "../../../utils";
import { ClassesContext } from "../../../../../contexts/Classes";
import { REG_NUMBER_WITHOUT_DEC } from "../../../../../config/format";

const EstimateBloodLoss = forwardRef( ({canEdit},ref) => {
    const [ errors, setErrors ] = useState({});
    const [ estimateBloodLoss, setEstimateBloodLoss ] = useState('');
    const { hiddenFormInputStyle } = useContext(ClassesContext);
    useImperativeHandle(ref,()=>({
        setData(data){
            setEstimateBloodLoss(data)
        },
        verifyData(){
            let requireFields = [
                { field: 'estimateBloodLoss' ,errorName: 'estimateBloodLoss' },
            ]
            let {isRequireFieldsValid,errorObject} = verifyRequireField(requireFields,{estimateBloodLoss});
            setErrors({ ...errorObject });
            return isRequireFieldsValid
        },
        getData(){
            return estimateBloodLoss
        }
    }))
    
    return ( 
        <React.Fragment>
                <Grid container spacing={1} sx={{mt:1}}>
                    <Grid item xs={12} md={4}>
                        <FormLabel error={ errors.estimateBloodLoss ? true: false } >Estimate Blood loss*</FormLabel>
                        <Box sx={{display:'flex', ml:2}}>
                            <OutlinedInput
                                name="estimateBloodLoss"
                                value={ estimateBloodLoss }
                                onChange={e=> {
                                    if( !e.target.value || checkDataPattern(e.target.value,REG_NUMBER_WITHOUT_DEC)){
                                        setEstimateBloodLoss(e.target.value)
                                    }
                                }}
                                inputProps={{
                                    min:0
                                }}
                                endAdornment={<InputAdornment position="end">ml.</InputAdornment>}
                                size="small"
                                sx={{ 
                                    ...hiddenFormInputStyle({value:estimateBloodLoss,canEdit}) 
                                }}
                            />
                        </Box>
                    </Grid>
                </Grid>
        </React.Fragment>
     );
});
 
export default EstimateBloodLoss;