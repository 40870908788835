import React, { useState, forwardRef, useImperativeHandle, useContext } from "react";
import { TextField, Grid } from '@mui/material';
import { verifyRequireField } from "../../../utils";
import { ClassesContext } from "../../../../../contexts/Classes";

const OtherRemark = forwardRef( ({canEdit},ref) => {
    const [ errors, setErrors ] = useState({});
    const [ otherRemark, setOtherRemark ] = useState('');
    const { hiddenFormInputStyle } = useContext(ClassesContext);
    useImperativeHandle(ref,()=>({
        setData(data){
            setOtherRemark(data ? data : '')
        },
        verifyData(){
            let requireFields = [
            ]
            let {isRequireFieldsValid,errorObject} = verifyRequireField(requireFields,{otherRemark});
            setErrors({ ...errorObject });
            return isRequireFieldsValid
        },
        getData(){
            return otherRemark
        }
    }))
    
    return ( 
        <React.Fragment>
                <Grid container spacing={1} sx={{mt:1}}>
                    <Grid item xs={12} md={12}>
                        <TextField 
                            sx={{
                                ...hiddenFormInputStyle({value: otherRemark, canEdit}),
                                resize:"both"
                            }}
                            multiline  
                            name="otherRemark" 
                            value={ otherRemark } 
                            onChange={e=>setOtherRemark(e.target.value) } 
                            error={ errors.otherRemark ? true : false } 
                            size='small' fullWidth 
                            label="Other remark" /> 
                    </Grid>
                </Grid>
        </React.Fragment>
     );
});
 
export default OtherRemark;