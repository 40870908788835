import React, { useEffect, useState,useContext, useRef } from 'react';
import { Card, CardHeader, Divider, Button, CardActions } from '@mui/material';
import { AuthContext } from '../contexts/AuthContext';
import axiosInstance from '../common/axios';
import PatientDetailForm from '../components/topic/PatientDetailForm';
import TopicDetailForm from '../components/topic/TopicDetailForm';
import { TEMPLATE_TYPE_CONSULTATION } from '../store/action/actionTypes';
import Loading from '../components/Loading';
import Alert from '../components/Alert';
import { CONSULTATION_TEMPLATES, TOPIC_STATUS_DRAFT, TOPIC_STATUS_PROCESS } from '../config/form';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';



const CreateTopic = () => {
    const navigate = useNavigate();
    const patientDetailRef = useRef();
    const topicDetailRef = useRef();
    const consultationTemplateRef = useRef();
    const { user } = useContext(AuthContext);
    const [ loading, setLoading ] = useState(false);
    const [ alertOpen, setAlertOpen ] = useState(false);
    const [ alertMessage, setAlertMessage ] = useState(null);
    const [ specialties, setSpecialty] = useState([]);
    const [ specialtySelected, setSpecialtySelected ] = useState('');
    const [ hospitals, setHospitals] = useState([]);
    const [ hospitalSelected, setHospitalSelected ] = useState('');
    const [ consultationTemplateSelected, setConsultationTemplateSelected ] = useState(null);
    const [ consultationTemplateId, setConsultationTemplateId ] = useState(null);

    const handleSpecialtyChange = (e) => {
        setSpecialtySelected(e.target.value)
    }

    const handleHospitalChange = (e) => {
        setHospitalSelected(e.target.value)
    }

    const handleCreateTopicSubmit = (e) =>{
        e.preventDefault()
    }
    const getSpecialty = () => {
        axiosInstance.get('/auth/users/get_user_detail/').then((res)=>{
            if(res.data){
                setSpecialty(res.data.specialties)
                setSpecialtySelected(res.data.specialties[0].id)
                setHospitals(res.data.hospitals)
                setHospitalSelected(res.data.hospitals[0].id)
            }
        }).catch((error)=>{
            console.log(error)
        })
    } 

    const verifyUserPermission = (callback) => {
        axiosInstance.get('/auth/users/get_user_detail/').then((res)=>{
            if(res.data){
                let checkHospital = false;
                let checkSpecialty = false;
                for(let i=0;i < res.data.specialties.length; i ++){
                    if( specialtySelected === res.data.specialties[i].id){
                        checkSpecialty = true
                        break;
                    }
                }
                for(let i=0;i < res.data.hospitals.length; i ++){
                    if( hospitalSelected === res.data.hospitals[i].id){
                        checkHospital = true
                        break;
                    }
                }
                callback(checkHospital && checkSpecialty)
            }else{
                callback(false)
            }
        }).catch((error)=>{
            callback(false)
        })
    }

    useEffect(()=>{
        if(specialtySelected){
            setConsultationTemplateSelected(null)
            axiosInstance.get(`/core/specialty/${specialtySelected}/`).then((res)=>{
                if( res.data ){
                    for(let i=0;i < res.data.templates.length; i ++) {
                        let template = res.data.templates[i]
                        if( template.type === TEMPLATE_TYPE_CONSULTATION ){
                            let TemplateComponent = CONSULTATION_TEMPLATES[template.id];
                            setConsultationTemplateSelected( TemplateComponent ? <TemplateComponent canEdit={true} ref={consultationTemplateRef} /> : null )
                            setConsultationTemplateId( template.id )
                        }
                    }
                }  
            })
        }
    }
    ,[specialtySelected])

    const setDefaultFormData = async () => {
        let now = new Date();
        let userDetails = await axiosInstance.get('/auth/users/get_user_detail/');
        let data = {
            attendingSurgeonAndDateTime: {
                dateTime: moment(now).format('YYYY-MM-DDTHH:mm'),
                attendingSurgeon: `${userDetails.data.first_name} ${userDetails.data.last_name}`
            }
        }  
        consultationTemplateRef.current.setData(data);
    }

    useEffect(()=>{
        if(consultationTemplateSelected){
            setDefaultFormData()
        }
    },[consultationTemplateSelected])

    useEffect(()=>{
        getSpecialty();
    },[user])

    const uploadTopicFile = async (file, topic) =>{
        let fileType = ['image','video' ].includes(file.file.type.split('/')[0]) ? file.file.type.split('/')[0] : 'other';
        let formData = new FormData();
        formData.append('type', fileType);
        formData.append('file', file.file);
        formData.append('size',file.size)
        formData.append('name', file.name);
        if(file.description){
            formData.append('description', file.description);
        }
        formData.append('topic',topic.id);
        try{
            let res = await axiosInstance.post('/topic/topic-file/',formData)
            return res.data
        }catch{
            return null
        }
        
    }

    const createConsultationForm = async (formData) => {
        let token =  localStorage.getItem('token') ? JSON.parse(localStorage.getItem('token')).access : null; 
        if(token){
            let tokenParts = JSON.parse(atob(token.split('.')[1]));
            let author = tokenParts.user_id;
            let form = { 
                template: consultationTemplateId , 
                data: formData, 
                created_by: author,
            }
            try {
                let res = await axiosInstance.post('topic/form/',form)
                return res.data
            } catch (error) {
                return null
            }
        }else{
            return null
        }
    }

    const createTopicDetail = async (detail,patientDetailData,status,consultationForm) => {
        let token =  localStorage.getItem('token') ? JSON.parse(localStorage.getItem('token')).access : null; 
        if(token){
            let tokenParts = JSON.parse(atob(token.split('.')[1]));
            let author = tokenParts.user_id;
            let topic = { 
                name: detail , 
                hospital: hospitalSelected, 
                patient_visit: patientDetailData.id ? patientDetailData.id : null , 
                specialty: specialtySelected,
                consultation_form: consultationForm ? consultationForm.id : null,
                status: status,
                created_by: author,
            }
            try {
                let res = await axiosInstance.post('topic/topic/',topic)
                return res.data
            } catch (error) {
                return null
            }
        }else{
            return null
        }
    }

    const submitTopic = (status) =>{
        let isTopicDetailValid = topicDetailRef.current.verifyRequireField();

        let verifyData = isTopicDetailValid;
        if(status === TOPIC_STATUS_PROCESS){
            let isPatientDetailValid = patientDetailRef.current.verifyRequireField();
            let isConsultationValid = consultationTemplateSelected ? consultationTemplateRef.current.verifyRequireField() : true;
            verifyData = verifyData && isPatientDetailValid && isConsultationValid
        }

        if(  verifyData  ){
            setLoading(true);
            verifyUserPermission( async (havePermission)=>{
                let topic = null;
                if(havePermission){
                    let consultationData = consultationTemplateSelected ? consultationTemplateRef.current.getData() : null;
                    let topicDetailData = topicDetailRef.current.getData();
                    let patientDetailData = patientDetailRef.current.getData();
                    let consultationForm = consultationData ? await createConsultationForm(consultationData) : null;
                    topic = await createTopicDetail(topicDetailData.detail,patientDetailData,status,consultationForm)
                    for ( let i=0 ; i < topicDetailData.files.length ; i ++) {
                        await uploadTopicFile(topicDetailData.files[i], topic);
                    }
                }
                setLoading(false);
                let topicId = topic !== null ? topic.id : ''
                navigate(`/topic/${topicId}` )
            })

        }else{
            setAlertMessage({header: 'warning !!!', content: 'please complete require fields' })
            setAlertOpen(true)
        }
    }
    return ( 
        <React.Fragment>
            <Card elevation={0}>
                <CardHeader title="Create Topic" />
                <Divider ></Divider>
                <form noValidate autoComplete="off" onSubmit={handleCreateTopicSubmit}>
                    <TopicDetailForm canEdit={true} specialtySelected={specialtySelected} handleSpecialtyChange={handleSpecialtyChange} specialties={specialties} hospitalSelected={hospitalSelected} handleHospitalChange={handleHospitalChange} hospitals={hospitals} ref={topicDetailRef} />
                    <PatientDetailForm canEdit={true} hospitalSelected={hospitalSelected} ref={patientDetailRef} />
                    {consultationTemplateSelected}
                    <CardActions sx={{ p: 1.25, pt: 0, justifyContent: 'center' }}>
                        <Button color="secondary" variant="outlined" onClick={()=>{submitTopic(TOPIC_STATUS_DRAFT)}}>Draft</Button>
                        <Button variant="outlined" onClick={()=>{submitTopic(TOPIC_STATUS_PROCESS)}}>Submit</Button>
                    </CardActions>
                </form>
            </Card>       
            <Loading loading={loading} />
            <Alert open={alertOpen} message={alertMessage} handleClose={()=>{ setAlertOpen(false) }} />
        </React.Fragment> 
    );
}
 
export default CreateTopic;