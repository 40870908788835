import React, { useState, forwardRef, useImperativeHandle, useContext } from "react";
import { FormControl, FormLabel, FormControlLabel, Grid, RadioGroup, Radio, TextField } from '@mui/material';
import { verifyRequireField } from "../../../utils";
import { ClassesContext } from "../../../../../contexts/Classes";

const QualityOfReduction = forwardRef( ({canEdit},ref) => {
    const [ errors, setErrors ] = useState({});
    const [ qualityOfReduction, setQualityOfReduction ] = useState(null);
    const [ unsatisfactoryText, setUnsatisfactoryText ] = useState('');
    const { hiddenFormInputStyle } = useContext(ClassesContext);
    useImperativeHandle(ref,()=>({
        setData(data){
            setQualityOfReduction(data.qualityOfReduction ? data.qualityOfReduction : null)
            setUnsatisfactoryText(data.unsatisfactoryText ? data.unsatisfactoryText : '' )
        },
        verifyData(){
            let requireFields = [
            ]
            let {isRequireFieldsValid,errorObject} = verifyRequireField(requireFields,{});
            setErrors({ ...errorObject });
            return isRequireFieldsValid
        },
        getData(){
            return {qualityOfReduction,unsatisfactoryText}
        }
    }))
    
    return ( 
        <React.Fragment>
                <Grid container spacing={1} sx={{mt:1}}>
                    <Grid item xs={12} md={12} >
                        <FormLabel error={ errors.qualityOfReduction ? true: false } >Quality of reduction</FormLabel>
                    </Grid>
                    <Grid item xs={12} md={12} sx={{ml:2}}>
                            <FormControl>
                                <RadioGroup
                                    row
                                    value={ qualityOfReduction }
                                    onChange={e=>setQualityOfReduction(e.target.value)}
                                    name="qualityOfReduction"
                                >
                                    <FormControlLabel value="perfect" label="Perfect" control={<Radio  />} sx={{ 
                                        ...hiddenFormInputStyle({value: qualityOfReduction === 'perfect', canEdit})
                                    }} />
                                    <FormControlLabel value="almostPerfect" label="Almost perfect" control={<Radio />} sx={{ 
                                        ...hiddenFormInputStyle({value: qualityOfReduction === 'almostPerfect', canEdit})
                                    }}  />
                                    <FormControlLabel value="satisfactory" label="Satisfactory" control={<Radio />} sx={{ 
                                        ...hiddenFormInputStyle({value: qualityOfReduction === 'satisfactory', canEdit})
                                    }} />
                                    <FormControlLabel value="unsatisfactory" label="Unsatisfactory" control={<Radio />} sx={{ 
                                        ...hiddenFormInputStyle({value: qualityOfReduction === 'unsatisfactory', canEdit})
                                    }} />
                                </RadioGroup>
                            </FormControl>
                    </Grid>
                </Grid> 
                <Grid container sx={{ml:2}}>
                    <Grid item xs={12} md={12}>
                        <TextField 
                            multiline  
                            name="unsatisfactoryText" 
                            value={ unsatisfactoryText } 
                            onChange={e=> setUnsatisfactoryText(e.target.value) } 
                            error={ errors.unsatisfactoryText ? true : false } 
                            sx={{ 
                                ...hiddenFormInputStyle({value: unsatisfactoryText, canEdit}),
                                resize:"both"
                            }}
                            size='small' fullWidth label="Unsatisfactory" /> 
                    </Grid>
                </Grid>
        </React.Fragment>
     );
});
 
export default QualityOfReduction;