import { Divider, ImageList as MuiImageList, ImageListItem, ImageListItemBar } from "@mui/material";
import React from "react";

const ImageList = ({imageFiles, imageClick}) => {

    return ( 
        <React.Fragment>
                { imageFiles.length > 0 && <Divider sx={{mt:2}}> Images </Divider> }
                { imageFiles.length > 0 && (
                    <MuiImageList  cols={3} gap={8} sx={{padding:2}} >
                        { imageFiles.map((file,index)=>{
                            return (
                                <ImageListItem key={file.id}>
                                    <img 
                                        onClick={()=> { imageClick(index) } } 
                                        src={`${process.env.REACT_APP_BACKEND_URL}` + file.url } 
                                        alt={file.name} 
                                        style={{height: 300}}
                                    />
                                    <ImageListItemBar
                                        subtitle={ file.description }
                                    />
                                </ImageListItem>
                            )
                        }) }
                    </MuiImageList>
                    )
                }
        </React.Fragment>
     );
}
 
export default ImageList;