import React, { forwardRef, useImperativeHandle, useRef, useContext } from 'react';
import { Box, Collapse, Typography, Divider } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import PatientHistory from './PatientHistory';
import Diagnosis from './Diagnosis';
import PelvicAndAcetabulumFractureAssociatedInjury from './PelvicAndAcetabulumFractureAssociatedInjury';
import AssociatedNonOrthopedicSurgery from './AssociatedNonOrthopedicSurgery';
import PlanOfTreatment from './PlanOfTreatment';
import Remarks from './Remarks';
import { ClassesContext } from "../../../../../contexts/Classes"

import SummaryOfDiscussion from './SummaryOfDiscussion';
import AttendingSurgeonAndDateTime from '../../../common/AttendingSurgeonAndDateTime';
import FormLabel from '../../../common/FormLabel';


export const Main = forwardRef(({canEdit},ref) => {
    const [expanded, setExpanded] = React.useState(true);
    const { useStyles  } = useContext(ClassesContext);
    const classes = useStyles(canEdit);
    const patientHistoryRef = useRef();
    const diagnosisRef = useRef();
    const pelvicAndAcetabulumFractureAssociatedInjuryRef = useRef();
    const associatedNonOrthopedicSurgeryRef = useRef();
    const planOfTreatmentRef = useRef();
    const remarksRef = useRef();
    const attendingSurgeonAndDateTimeRef = useRef();
    const summaryOfDiscussionRef = useRef();

    useImperativeHandle(ref,()=>({
        setData(data){
            patientHistoryRef.current.setData(data.patientHistory)
            diagnosisRef.current.setData(data.diagnosis)
            pelvicAndAcetabulumFractureAssociatedInjuryRef.current.setData(data.pelvicAndAcetabulumFractureAssociatedInjury)
            associatedNonOrthopedicSurgeryRef.current.setData(data.associatedNonOrthopedicSurgery)
            planOfTreatmentRef.current.setData(data.planOfTreatment)
            remarksRef.current.setData(data.remarks)
            attendingSurgeonAndDateTimeRef.current.setData(data.attendingSurgeonAndDateTime)
            summaryOfDiscussionRef.current.setData(data.summaryDiscussion)
        },
        verifyRequireField(){
            let diagnosis = diagnosisRef.current.verifyData();
            let patientHistory = patientHistoryRef.current.verifyData();
            let pelvicAndAcetabulumFractureAssociatedInjury = pelvicAndAcetabulumFractureAssociatedInjuryRef.current.verifyData()
            let associatedNonOrthopedicSurgery = associatedNonOrthopedicSurgeryRef.current.verifyData();
            let planOfTreatment = planOfTreatmentRef.current.verifyData();
            let remarks = remarksRef.current.verifyData();
            let attendingSurgeonAndDateTime = attendingSurgeonAndDateTimeRef.current.verifyData();
            let fieldValid = (
                patientHistory && 
                diagnosis && 
                pelvicAndAcetabulumFractureAssociatedInjury && 
                associatedNonOrthopedicSurgery &&
                planOfTreatment &&
                remarks && 
                attendingSurgeonAndDateTime
            )
            return fieldValid
        },
        getData() {
            let data = {
                patientHistory : patientHistoryRef.current.getData(),
                diagnosis: diagnosisRef.current.getData(),
                pelvicAndAcetabulumFractureAssociatedInjury :pelvicAndAcetabulumFractureAssociatedInjuryRef.current.getData(),
                associatedNonOrthopedicSurgery : associatedNonOrthopedicSurgeryRef.current.getData(),
                planOfTreatment : planOfTreatmentRef.current.getData(),
                remarks : remarksRef.current.getData(),
                attendingSurgeonAndDateTime : attendingSurgeonAndDateTimeRef.current.getData(),
                summaryDiscussion : summaryOfDiscussionRef.current.getData(),
            }
            return data
        }
    }))

    return (
        <Box className={classes.root} sx={{padding:3}}>
            <FormLabel setExpanded={setExpanded} expanded={expanded} headerText="BDMS Pelvic and Acetabulum Fracture : Discussion Form" />
            <Divider ></Divider>
            <Collapse in={expanded}>
                <Box sx={{mt:2}}>
                    <PatientHistory canEdit={canEdit} ref={patientHistoryRef} />
                    <Diagnosis canEdit={canEdit} ref={diagnosisRef} />
                    <PelvicAndAcetabulumFractureAssociatedInjury canEdit={canEdit} ref={pelvicAndAcetabulumFractureAssociatedInjuryRef} />
                    <AssociatedNonOrthopedicSurgery canEdit={canEdit} ref={associatedNonOrthopedicSurgeryRef} />
                    <PlanOfTreatment canEdit={canEdit} ref={planOfTreatmentRef} />
                    <Remarks canEdit={canEdit} ref={remarksRef} />
                    <AttendingSurgeonAndDateTime canEdit={canEdit} ref={attendingSurgeonAndDateTimeRef} />
                    <Typography sx={{mt: 2, fontWeight: "bold"}} variant="h6" component="div">
                        Summary of discussion
                    </Typography>
                    <SummaryOfDiscussion canEdit={canEdit} ref={summaryOfDiscussionRef} />
                </Box>
            </Collapse>
        </Box>
    );
});