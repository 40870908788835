import React, { useEffect, useState } from "react";
import { Typography  } from "@mui/material";


const FilePreview = ({file}) => {
    const [imageSrc,setImageSrc] = useState(null)
    const changeFileSizeFormat = (fileSize) => {
        var sizeInMB = (fileSize / (1024*1024)).toFixed(2);
        return sizeInMB + ' mb.'
    }

    useEffect(()=>{
        if(file.type === 'image'){
            getImageSrc(file)
        }
        
    },[file])

    const getImageSrc = (file) => {
        if( file && file.id ){
            setImageSrc(`${process.env.REACT_APP_BACKEND_URL}${file.url}`) 
        }else{
            var reader = new FileReader();
            var url = reader.readAsDataURL( file.file);
            reader.onloadend = function (e) {
                setImageSrc(reader.result)
            }
        }

    }


    return (  
        <React.Fragment>
            { file.type === 'image' ? (
                <img src={imageSrc} width={300} height={300} alt={file.name} />
            ) : (
                <Typography>
                    {file.name}
                </Typography>
            ) }

            <Typography sx={{ fontSize: 14 }} color="text.secondary">
                {changeFileSizeFormat(file.size)}
            </Typography>
        </React.Fragment>
    );
}
 
export default FilePreview;