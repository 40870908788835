import { createContext, useReducer } from "react";
import { reducer,initialState } from '../store/reducer/auth';

export const AuthContext = createContext();

export const AuthProvider = (props) => {
    
  const [user, dispatch] = useReducer(reducer, initialState);
 
  return (
    <AuthContext.Provider value={{user,dispatch}}> 
        {props.children}
    </AuthContext.Provider>
  );
  
};