import { Alert, Box, Button, Card, FormControl, InputLabel, MenuItem, Select, CardHeader, CircularProgress, Container, Link, TextField, Autocomplete } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axiosInstance from '../common/axios';



const Register = () => {
    const [ loading, setLoading ] = useState(false);
    const [ errors, setErrors ] = useState({});
    const [ loginType, setLoginType ] = useState('his');
    const [ hospitals, setHospitals ] = useState([]);
    const [ hisHospital, setHisHospital ] = useState('');
    const [ showAlert, setShowAlert ] = useState(false);
    const [ alertMsg, setAlertMsg ] = useState('');
    const navigate = useNavigate()

    useEffect(()=>{
        axiosInstance.get('/common/hospital/?limit=100').then((res)=>{
            if(res.data){
                setHospitals(res.data.results)
            }
        })
    },[])


    const verifyEmailPattern = (email) => {
        let pattern = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g
        return pattern.test(email)
    }

    const verifyRequireField = (data) =>{
        let newError = {};
        for(var k in errors) {
            newError[k] = false;
        }
        let reqFields = ['username','firstName','lastName','email']
        switch (loginType) {
            case 'normal':
                reqFields.push('password')
                break;
            case 'his':
                reqFields.push('hisHospital')
                break;        
            default:
                break;
        }

        let fieldValid = true;
        for(let i=0;i < reqFields.length; i++){
            let field = reqFields[i];
            if( !data.get(field) ){
                fieldValid = false
                newError[field] = true
            }
            if( field === 'hisHospital' ){
                if(data.get(field)==='null'){
                    fieldValid = false
                    newError[field] = true
                }
            }
            if( field === 'email' ){
                if(!verifyEmailPattern(data.get(field))){
                    fieldValid = false
                    newError[field] = true
                }
            }
        }

        return {valid:fieldValid,error: newError}
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        setShowAlert(false)
        let data = new FormData(e.currentTarget);
        data.set('hisHospital', hisHospital ? hisHospital.id : null )
        let verifyFields = verifyRequireField(data);
        
        if(verifyFields.valid){
            axiosInstance.post('/auth/users/create_user/',{
                username:data.get('username'),
                password:data.get('password'),
                login_type: loginType ,
                his_hospital: data.get("hisHospital") ,
                first_name: data.get("firstName") ,
                last_name: data.get("lastName") ,
                email: data.get("email")
            }).then((res)=>{
                if(res.data.error){
                    let newError = {...verifyFields.error}
                    newError[res.data.field] = true
                    setErrors(newError)
                    setShowAlert(true);
                    setAlertMsg(res.data.msg)
                }else{
                    setErrors(verifyFields.error)
                    navigate("/create-user-complete/")   
                }
            })
        }else{
            setErrors(verifyFields.error)
        }
    }

    return ( 
        <Container component="main" maxWidth="xs">
            <Card sx={{mt: 3}}>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    padding: 3,
                }}>
                    <CardHeader title="Register" />
                    <Box sx={{ mt: 1 }} component="form" onSubmit={handleSubmit} noValidate >
                        <TextField
                            error={errors.username ? true : false }
                            margin="normal"
                            required
                            fullWidth
                            size='small'
                            id="username"
                            label="Username"
                            name="username"
                            autoComplete="username"
                            autoFocus
                            />
                        <FormControl fullWidth sx={{mt:1}}>
                            <InputLabel >Login type</InputLabel>
                            <Select
                                value={loginType}
                                size='small'
                                onChange={e=>setLoginType(e.target.value)}
                                label="Login type"
                            >
                            <MenuItem value="his">HIS</MenuItem>
                            <MenuItem value="normal">Local</MenuItem>
                            <MenuItem value="ad">AD</MenuItem>
                            
                            </Select>
                        </FormControl>

                        { loginType === 'his' && <Autocomplete
                            disablePortal
                            options={hospitals}
                            onChange={(event, hospital) => {
                                setHisHospital(hospital)
                            }}
                            getOptionLabel={(option) => `${option.name} (${option.code})`}
                            sx={{ width: "100%", mt: 2 }}
                            renderInput={(params) => <TextField {...params} size="small" name="hisHospital" label="HIS Hospital*" error={errors.hisHospital ? true : false } />}
                        />}
                        
                        { loginType === 'normal' && <TextField
                            error={errors.password ? true : false }
                            margin="normal"
                            required
                            size='small'
                            fullWidth
                            name="password"
                            label="Password"
                            type="password"
                            id="password"
                            autoComplete="current-password"
                        /> }

                        <TextField
                            error={errors.firstName ? true : false }
                            margin="normal"
                            required
                            fullWidth
                            size='small'
                            label="First name"
                            name="firstName"
                            autoComplete="firstName"
                            autoFocus
                            />

                        <TextField
                            error={errors.lastName ? true : false }
                            margin="normal"
                            required
                            fullWidth
                            size='small'
                            id="lastName"
                            label="Last name"
                            name="lastName"
                            autoComplete="lastName"
                            autoFocus
                        />

                        <TextField
                            error={errors.email ? true : false }
                            margin="normal"
                            required
                            fullWidth
                            size='small'
                            id="email"
                            label="Email"
                            name="email"
                            autoComplete="email"
                            autoFocus
                        />

                    
                        <Button 
                            type="submit"
                            fullWidth
                            variant="outlined"
                            sx={{ mt: 3, mb: 2 }}
                        >
                            { loading ? <CircularProgress sx={{align:"center"}} /> : 'Sign up' }
                            
                        </Button>
                        { showAlert && <Alert severity='error' sx={{ mt:2}}>{alertMsg}</Alert> }
                        <Alert severity='info' sx={{ mt:2,fontSize:"11px"}}>
                            <strong>How to select 'Login type'</strong>
                            <br></br>
                            1. <strong>HIS</strong>: For Trakcare, Arcus Air, Bconnect, iMed users.
                            <br></br>
                            2. <strong>Local</strong>: For Phyathai Network hospital users.
                            <br></br>
                            3. <strong>AD</strong>: For users who don't have HIS account.
                        </Alert>
                    </Box>
                </Box>
            </Card>
        </Container>
     );
}
 
export default Register;