import { Button , Box, CardActions, CircularProgress, Dialog, DialogActions, DialogContent, Divider, TextField, DialogContentText } from "@mui/material";
import React, { useEffect, useState } from "react";
import axiosInstance from "../../common/axios";
import EditFileComment from "./EditFileComment";


const EditNormalComment = ({showEditCommentDialog, setShowEditCommentDialog, comment, sendComment}) => {
    const [ isProcess, setIsProcess ] = useState(true);
    const [ details, setDetails] = useState('');
    const [ detailsError, setDetailsError ] = useState(false);
    const [ selectedFiles, setSelectedFiles ] = useState([]);
    const [ deletedFiles, setDeletedFiles ] = useState([]);
    const [ deleteCommentDialogOpen, setDeleteCommentDialogOpen ] = useState(false);


    const updateComment = async (content) => {
        let token =  localStorage.getItem('token') ? JSON.parse(localStorage.getItem('token')).access : null; 
        if(token){
            let tokenParts = JSON.parse(atob(token.split('.')[1]));
            if(tokenParts.user_id === comment.author.id){
                try {
                    let res = await axiosInstance.patch(`topic/comment/${comment.id}/`,{ content: content })
                    return res.data
                } catch (error) {
                    return null
                }
            }

        }else{
            return null
        }
    }

    const updateCommentFile = async (commentFile) => {
        let token =  localStorage.getItem('token') ? JSON.parse(localStorage.getItem('token')).access : null; 
        if(token){
            let tokenParts = JSON.parse(atob(token.split('.')[1]));
            if(tokenParts.user_id === comment.author.id){
                try {
                    let formData = new FormData();
                    formData.append('description', commentFile.description === null ? '' : commentFile.description);
                    let res = await axiosInstance.patch(`topic/comment-file/${commentFile.id}/`, formData)
                    return res.data
                } catch (error) {
                    return null
                }
            }

        }else{
            return null
        }
    }

    const createCommentFile = async (file) =>{
        let token =  localStorage.getItem('token') ? JSON.parse(localStorage.getItem('token')).access : null; 
        if(token){
            let tokenParts = JSON.parse(atob(token.split('.')[1]));
            if(tokenParts.user_id === comment.author.id){
                let fileType = ['image','video' ].includes(file.detail.type.split('/')[0]) ? file.detail.type.split('/')[0] : 'other';
                let formData = new FormData();
                formData.append('type', fileType);
                formData.append('file', file.detail);
                formData.append('size',file.size)
                formData.append('name', file.name);
                if(file.description){
                    formData.append('description', file.description);
                }
                formData.append('comment',comment.id);
                try{
                    let res = await axiosInstance.post('/topic/comment-file/',formData)
                    return res.data
                }catch{
                    return null
                }
            }
        }        
    }

    const deleteCommentFile = async (commentFile) => {
        let token =  localStorage.getItem('token') ? JSON.parse(localStorage.getItem('token')).access : null; 
        if(token){
            let tokenParts = JSON.parse(atob(token.split('.')[1]));
            if(tokenParts.user_id === comment.author.id){
                try {
                    let res = await axiosInstance.delete(`topic/comment-file/${commentFile.id}/`)
                    return res.data
                } catch (error) {
                    return null
                }
            }
        }else{
            return null
        }
    }

    const handleSubmitEditComment = async (e) => {
        setIsProcess(true)
        e.preventDefault()
        setDetailsError( details === '' && selectedFiles.length === 0 )
        if ( selectedFiles.length > 0 || details ) {
            await updateComment(details)
            for ( let i=0 ; i < selectedFiles.length ; i ++) {
                let file = selectedFiles[i];
                if(file.id){
                    await updateCommentFile(file);
                }else{
                    await createCommentFile(file)
                }
            }

            for ( let i=0 ; i < deletedFiles.length ; i ++) {
                let file = deletedFiles[i];
                await deleteCommentFile(file)
            }
            sendComment({ command: 'update_comment', comment_id: comment.id });
            setDetails('');
            setDetailsError(false);
            setSelectedFiles([]);
            setDeletedFiles([]);
            setIsProcess(false);
            setShowEditCommentDialog(false)
        }else{
            setIsProcess(false)
        }   
    }


    const deleteComment = () => {
        setIsProcess(true);
        axiosInstance.delete(`topic/comment/${comment.id}/`).then(()=>{
            sendComment({ command: 'delete_comment', comment_id: comment.id  });
            setDeleteCommentDialogOpen(false)
            setIsProcess(false);
            setShowEditCommentDialog(false)
        }).catch((error)=>{
            setIsProcess(false);
        })
    }


    const loadDefaultCommentData = async (comment) => {
        setIsProcess(true)
        let token = localStorage.getItem('token') ? JSON.parse(localStorage.getItem('token')).access : null ;
        if (token) {
            const tokenParts = JSON.parse(atob(token.split('.')[1]));
            if(tokenParts.user_id === comment.author.id){
                await axiosInstance.get(`/topic/comment/${comment.id}/`).then( (res)=>{
                    let comment = res.data;
                    setDetails(comment.content)
                }).catch((error)=>{
                })
                await axiosInstance.get(`/topic/comment-file/?comment=${comment.id}`).then((res)=>{
                    if(res.data.results){
                        setSelectedFiles(res.data.results)
                    }
                    setIsProcess(false)
                }).catch((error)=>{
                    setIsProcess(false)
                })
            }
        }
    }

    useEffect(()=>{
        if(showEditCommentDialog){
            loadDefaultCommentData(comment)
        }

    },[showEditCommentDialog,comment]) // eslint-disable-line react-hooks/exhaustive-deps

    return ( 
        <Dialog open={showEditCommentDialog} fullWidth maxWidth="lg">
            { isProcess ? (
                <CardActions sx={{ p: 1.25, pt: 0, mt: 2, justifyContent: 'center' }}>
                    <CircularProgress />
                </CardActions>
            ) : (
                <React.Fragment>
                    <form noValidate autoComplete="false" onSubmit={handleSubmitEditComment}  >
                        <DialogContent>
                            <Box sx={{padding:2}}>
                                <TextField value={details} onChange={(e) => setDetails(e.target.value)} label="Comment" variant="outlined" multiline rows={4} fullWidth required error={detailsError} />
                            </Box>
                            <EditFileComment selectedFiles={selectedFiles} setSelectedFiles={setSelectedFiles} deletedFiles={deletedFiles} setDeletedFiles={setDeletedFiles} />
                        </DialogContent>

                        <DialogActions>
                            <Button variant="outlined" type="submit" >Save</Button>
                            <Button color="error" variant="outlined" onClick={()=> setDeleteCommentDialogOpen(true) } >Delete</Button>
                            <Button variant="outlined" onClick={()=>{ setShowEditCommentDialog(false) }}>Close</Button>
                        </DialogActions>
                    </form>

                    <Dialog 
                        open={deleteCommentDialogOpen}
                        onClose={ (e) => setDeleteCommentDialogOpen(false)}
                    >
                        <DialogContent>
                            <DialogContentText>
                                Would you like to delete this comment? Click confirm to delete.
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button variant='outlined' onClick={(e) => setDeleteCommentDialogOpen(false)}>Cancel</Button>
                            <Button color='error' variant='outlined' onClick={deleteComment} autoFocus>
                                Confirm
                            </Button>
                        </DialogActions>
                    </Dialog>
                </React.Fragment>
            ) }

        </Dialog>
     );
}
 
export default EditNormalComment;