import React, { useState, forwardRef, useImperativeHandle, useEffect, useContext } from "react";
import { TextField, Grid } from '@mui/material';
import { verifyRequireField } from "../../../utils";
import moment from 'moment';
import { ClassesContext } from "../../../../../contexts/Classes";

const OperativeTime = forwardRef( ({canEdit,topic},ref) => {
    const [ errors, setErrors ] = useState({});
    const [ formData, setFormData ] = useState({assistSurgeons: [null]});
    const { hiddenFormInputStyle } = useContext(ClassesContext);

    useImperativeHandle(ref,()=>({
        setData(data){
            setFormData(data);
        },
        verifyData(){
            let requireFields = [
                { field: 'startOperativeDateTime', errorName: 'startOperativeDateTime', },
                { field: 'endOperativeDateTime', errorName: 'endOperativeDateTime', },
            ]
            let {isRequireFieldsValid,errorObject} = verifyRequireField(requireFields,formData);
            setErrors({ ...errorObject });
            return isRequireFieldsValid
        },
        getData(){
            return formData
        }
    }));


    useEffect(()=>{
        if( formData.startOperativeDateTime && formData.endOperativeDateTime ){
            var ms = moment(formData.endOperativeDateTime).diff(moment(formData.startOperativeDateTime) );
            var duration = moment.duration(ms);
            var totalOperativeTime = Math.floor(duration.asHours()) + moment.utc(ms).format(":mm");
            setFormData({...formData, totalOperativeTime: totalOperativeTime})
        }else{
            setFormData({...formData, totalOperativeTime: null})
        }
    },[formData.startOperativeDateTime,formData.endOperativeDateTime]) // eslint-disable-line react-hooks/exhaustive-deps


    
    return ( 
        <React.Fragment>
            <Grid container spacing={1} sx={{mt:1}}>
                <Grid item xs={12} md={4} >
                    <TextField
                            label="Start operative date/time*"
                            type="datetime-local"
                            value={ formData.startOperativeDateTime ? formData.startOperativeDateTime : '' }
                            size="small"
                            fullWidth
                            InputLabelProps={{
                                shrink: true,
                            }}
                            error={ errors.startOperativeDateTime ? true : false }
                            onChange={ e => {
                                let start = new Date(e.target.value);
                                if( formData && formData.endOperativeDateTime ){
                                    let end = new Date(formData.endOperativeDateTime);
                                    if(start > end){
                                        setFormData( {...formData, startOperativeDateTime: e.target.value, endOperativeDateTime: e.target.value } );
                                    }else{
                                        setFormData( {...formData, startOperativeDateTime: e.target.value } );
                                    }
                                }else{
                                    setFormData( {...formData, startOperativeDateTime: e.target.value } );
                                }                                
                            }}
                            sx={{ 
                                ...hiddenFormInputStyle({value: formData.startOperativeDateTime, canEdit})
                             }}
                    />
                </Grid>
                <Grid item xs={12} md={4} >
                    <TextField
                            label="End operative date/time*"
                            type="datetime-local"
                            value={ formData.endOperativeDateTime ? formData.endOperativeDateTime : '' }
                            size="small"
                            fullWidth
                            InputLabelProps={{
                                shrink: true,
                            }}
                            error={ errors.endOperativeDateTime ? true : false }
                            onChange={ e => {
                                let end = new Date(e.target.value);
                                if( formData && formData.startOperativeDateTime ){
                                    let start = new Date(formData.startOperativeDateTime);
                                    if(start > end){
                                        setFormData( {...formData, startOperativeDateTime: e.target.value, endOperativeDateTime: e.target.value } );
                                    }else{
                                        setFormData( {...formData, endOperativeDateTime: e.target.value } );
                                    }
                                }else{
                                    setFormData( {...formData, endOperativeDateTime: e.target.value } );
                                }        
                            }}
                            sx={{ 
                                ...hiddenFormInputStyle({value: formData.endOperativeDateTime, canEdit})
                             }}
                    />

                </Grid>
                <Grid item xs={12} md={4} >
                    <TextField fullWidth readOnly size="small" label="Total operative time" value={ formData.totalOperativeTime ? formData.totalOperativeTime : '' } sx={{ 
                        ...hiddenFormInputStyle({value: formData.totalOperativeTime, canEdit})
                    }} />
                </Grid>
            </Grid> 
        </React.Fragment>
     );
});
 
export default OperativeTime;