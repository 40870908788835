import React, { useState } from "react";
import { Box, Card, Button, CardActions, TextField, Snackbar, Alert, CardHeader, Divider } from "@mui/material";
import axiosInstance from "../common/axios";
import { useNavigate,useSearchParams } from "react-router-dom";


const ResetPassword = () => {
    const [ passwordError, setPasswordError ] = useState(false);
    const [ password, setPassword ] = useState('');
    const [ openAlert, setOpenAlert ] = React.useState(false);
    const [ alertMessage, setAlertMessage] = React.useState('');
    const [ alertType, setAlertType ] = React.useState('success');
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();


    const handleSubmit = async (e) => {
            e.preventDefault()
            setPasswordError(false)
            if( password ){
                let token = searchParams.get("token");
                if(token){
                    axiosInstance.post('/auth/users/reset_password/',{password: password, token:token }).then((res)=>{
                        if(res.data.error===false){
                            setAlertType('success');
                            setAlertMessage("reset password success");
                            setOpenAlert(true);
                            setTimeout(()=>{
                                navigate('/login')
                            },1500)
                        }else{
                            setAlertType('error');
                            setAlertMessage(res.data.msg);
                            setOpenAlert(true);   
                        }
                    });
                }else{
                    setAlertType('error');
                    setAlertMessage("token invalid");
                    setOpenAlert(true);  
                }
                
            }else{
                setPasswordError(true)
                setAlertType('error');
                setAlertMessage("please input password");
                setOpenAlert(true);
            }
        
    }

    const handleCloseAlert = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
        setOpenAlert(false);
      };

    return ( 
        <React.Fragment>
            <Card sx={{mt:2}} elevation={0} component="form" onSubmit={handleSubmit} noValidate>
                <CardHeader title="Reset password" />
                <Divider ></Divider>
                <Box sx={{
                    padding: 5
                }}>

                    <CardActions sx={{ p: 1.25, pt: 0, justifyContent: 'center' }}>
                        <TextField
                                    error={passwordError}
                                    value={password}
                                    margin="normal"
                                    sx={{width:{xs:'100%',md: "40%"}}}
                                    label="new password"
                                    name="new_password"
                                    size="small"
                                    type="password"
                                    onChange={(e)=> setPassword( e.target.value ) }
                            />                     
                    </CardActions>
                    <CardActions sx={{ p: 1.25, pt: 0, justifyContent: 'center' }}>
                        <Button type="submit" variant="outlined" >
                            Submit
                        </Button>                        
                    </CardActions>
                </Box>
                <Snackbar open={openAlert} autoHideDuration={6000} onClose={handleCloseAlert}>
                    <Alert onClose={handleCloseAlert} severity={alertType} sx={{ width: '100%' }}>
                        {alertMessage}
                    </Alert>
                </Snackbar>
            </Card>
        </React.Fragment>
     );
}
 
export default ResetPassword;