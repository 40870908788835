import React, {useState} from "react";
import { Divider, FormControlLabel, Switch, Box, Button, Card, CardActions , TextField, CardContent, Grid, Collapse,Chip  } from "@mui/material";
import { DropzoneArea } from 'material-ui-dropzone';
import FilePreview from "./FilePreview";

const FileComment = ({ selectedFiles,setSelectedFiles }) => {
    const [ showFileComment, setShowFileComment ] = useState(false);
    const deleteFile = (index) => {
        setSelectedFiles( selectedFiles.filter((file,f_index)=>{ return f_index !== index }) )
    }

    const fileDescOnChange = (index,value) => {
        let files = selectedFiles.map((file,file_index)=>{
            if(file_index === index){
                file.description = value;
            }
            return file;
        })
        
        setSelectedFiles(files)

    }
    
    const selectFiles = (filesObject) => {
        if(filesObject.length > 0){
            let files = [];
            for(let i = 0; i < filesObject.length; i++){
                files.push({
                    file: filesObject[i],
                    detail: filesObject[i],
                    description : null,
                    name: filesObject[i].name,
                    size: filesObject[i].size,
                    type: [ 'image', 'video' ].includes( filesObject[i].type.split('/')[0] ) ?  filesObject[i].type.split('/')[0] : 'other'
                })
            }
            setSelectedFiles([...selectedFiles,...files]);
        }
    };

    return ( 
        <React.Fragment>
            <Divider sx={{mt: 2, mb:1}} > 
                <FormControlLabel 
                    control={<Switch checked={showFileComment} onChange={ (e)=> setShowFileComment(e.target.checked) } />}
                    label="Drop file(s)"
                />
            </Divider>
            
                {selectedFiles && (
                    <Box sx={{padding:1}} >
                        <Grid container>
                            { selectedFiles.map((file,index)=>{
                                return (
                                    <Grid key={index} item xs={12} md={3}>
                                        <Card elevation={0} key={index}  sx={{mt:2}}  >
                                            <CardContent>
                                                <FilePreview file={file} />
                                                <TextField size="small" onChange={(e)=>{ fileDescOnChange(index,e.target.value) }} sx={{width: '100%', mt: 2}} label="description" variant="outlined"  />
                                            </CardContent>
                                            <CardActions sx={{float: 'right'}}>
                                                <Button onClick={ ()=>{ deleteFile(index) }  } size="small">Remove</Button>
                                            </CardActions>
                                        </Card>
                                    </Grid>
                                )
                            }) }
                        </Grid>
                    </Box>
                )}
            <Collapse in={showFileComment}>
                <Box sx={{padding:3}} >
                    <DropzoneArea clearOnUnmount={false}  key={selectedFiles.length} initialFiles={[]} showPreviews={false} showPreviewsInDropzone={false} filesLimit={100} maxFileSize={30000000} onChange={selectFiles} />
                </Box>
            </Collapse>
        </React.Fragment>
     );
}
 
export default FileComment;