import React, { useState, forwardRef, useImperativeHandle, useContext } from "react";
import { TextField, Grid } from '@mui/material';
import { verifyRequireField } from "../../../utils";
import { ClassesContext } from "../../../../../contexts/Classes";

const OperativeFindings = forwardRef( ({canEdit},ref) => {
    const [ errors, setErrors ] = useState({});
    const [ operativeFindings, setOperativeFindings ] = useState('');
    const { hiddenFormInputStyle } = useContext(ClassesContext);

    useImperativeHandle(ref,()=>({
        setData(data){
            setOperativeFindings(data)
        },
        verifyData(){
            let requireFields = [
            ]
            let {isRequireFieldsValid,errorObject} = verifyRequireField(requireFields,{operativeFindings});
            setErrors({ ...errorObject });
            return isRequireFieldsValid
        },
        getData(){
            return operativeFindings
        }
    }))
    
    return ( 
        <React.Fragment>
                <Grid container spacing={1} sx={{ 
                    ...hiddenFormInputStyle({value: operativeFindings, canEdit}) ,
                    mt:1
                 }}>
                    <Grid item xs={12} md={12}>
                        <TextField 
                            sx={{resize:"both"}} 
                            multiline  
                            name="operativeFindings" 
                            value={ operativeFindings } 
                            onChange={e=> setOperativeFindings(e.target.value)} 
                            error={ errors.operativeFindings ? true : false } 
                            size='small' fullWidth 
                            label="Operative findings" /> 
                    </Grid>
                </Grid>
        </React.Fragment>
     );
});
 
export default OperativeFindings;