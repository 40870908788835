import React, { useState, forwardRef, useImperativeHandle, useEffect } from "react";
import { FormLabel, Card, TextField, Grid, ListItem, ListItemText, IconButton } from '@mui/material';
import { getDataUpdate, verifyRequireField } from "../../../utils";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import DeleteIcon from '@mui/icons-material/Delete';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';


const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
  
    return result;
  };
  
  const move = (source, destination, droppableSource, droppableDestination) => {
    const sourceClone = Array.from(source);
    const destClone = Array.from(destination);
    const [removed] = sourceClone.splice(droppableSource.index, 1);
  
    destClone.splice(droppableDestination.index, 0, removed);
  
    const result = {};
    result[droppableSource.droppableId] = sourceClone;
    result[droppableDestination.droppableId] = destClone;
  
    return result;
  };

const Approach = forwardRef( ({canEdit},ref) => {
    const [ errors, setErrors ] = useState({});
    const [ formData, setFormData ] = useState({});
    const [ approachItem,setApproachItem ] = useState([
        { id: 'Ilioinguinal' , label: 'Ilioinguinal' },
        { id: 'stoppa' , label: 'Stoppa' },
        { id: 'pararectus' , label: 'Pararectus' },
        { id: 'kocherLangenbeck' , label: 'Kocher Langenbeck' },
        { id: 'trochantericFlip' , label: 'Trochanteric Flip' },
        { id: 'extendedIliofemoral' , label: 'Extended Iliofemoral' },
        { id: 'smithPeterson' , label: 'Smith Peterson' },
        { id: 'other' , label: 'Other' },
    ])
    const [ approachSelected,setApproachSelected ] = useState([])
    useImperativeHandle(ref,()=>({
        setData(data){
            if( data ){
                let approachId = data.approach ? data.approach.map( item => item.id ) : [];
                setApproachItem( approachItem.filter( item => !approachId.includes(item.id) ) );
                setApproachSelected( data.approach ? data.approach : []);
                setFormData({...data, approach:null});
            }
        },
        verifyData(){
            let requireFields = [
                { 
                    field: 'approach' , 
                    errorName: 'approach' , 
                    condition: 'length',
                    conditionForCompare: 'gt' ,
                    valueForCompare: 0
                },
            ]
            let {isRequireFieldsValid,errorObject} = verifyRequireField(requireFields,{...formData, approach: approachSelected });
            setErrors({ ...errorObject });
            return isRequireFieldsValid
        },
        getData(){
            return {...formData, approach: approachSelected }
        }
    }))

    const updateFormData = async (e) => {
        let dataUpdate = getDataUpdate(e);
        setFormData({...formData,...dataUpdate})
    }

    useEffect(()=>{
        if(approachSelected.filter( (item)=> item.id==='other' ).length === 0){
            setFormData({...formData,otherApproach:null})
        }
    },[approachSelected])// eslint-disable-line react-hooks/exhaustive-deps
    
    
    const deleteApproach = (approachDelete) => {
        setApproachItem([ ...approachItem, approachDelete ])
        setApproachSelected( approachSelected.filter((item)=> item.id !== approachDelete.id ) )
    }
    
    const addApproach = (approachAdd) => {
        setApproachSelected([ ...approachSelected, approachAdd ])
        setApproachItem( approachItem.filter((item)=> item.id !== approachAdd.id ) )
    }

    const approachOnDragEnd = (result) => {
        const { source, destination } = result;
        const id2List = {
            approachItem: approachItem,
            approachSelected: approachSelected
        };
        // dropped outside the list
        if (!destination) {
          return;
        }
    
        if (source.droppableId === destination.droppableId) {
            const items = reorder(
                id2List[source.droppableId],
                source.index,
                destination.index
            );
    
            if (source.droppableId === "approachSelected") {
                    setApproachSelected(items)
            }else{
                    setApproachItem(items)
            }

        } else {
          const result = move(
            id2List[source.droppableId],
            id2List[destination.droppableId],
            source,
            destination
          );
          setApproachItem(result.approachItem)
          setApproachSelected(result.approachSelected)
        }


    };
    return ( 
        <React.Fragment>
            <Grid container spacing={1} sx={{mt:1}}>
                <DragDropContext onDragEnd={approachOnDragEnd}>
                    { canEdit && (
                        <Grid item xs={12} md={4} >
                            <FormLabel > Approach list </FormLabel>
                            <Droppable droppableId="approachItem">
                                {(provided, snapshot) => (
                                    <Card
                                        ref={provided.innerRef}
                                        sx={{minHeight: 200}}
                                    >
                                    { approachItem && approachItem.map((item, index) => (
                                        <Draggable
                                            key={item.id}
                                            draggableId={item.id}
                                            index={index}>
                                            {(provided, snapshot) => (
                                                    <ListItem 
                                                        component="div"
                                                        ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}
                                                        secondaryAction={
                                                            <IconButton edge="end" onClick={(e)=> addApproach(item)} aria-label="delete">
                                                            <AddCircleOutlineIcon />
                                                            </IconButton>
                                                        }
                                                    >
                                                        <ListItemText primary={item.label} />
                                                    </ListItem>
                                            )}
                                        </Draggable>
                                    ))}
                                    {provided.placeholder}
                                    </Card>
                                )}
                            </Droppable>                        
                        </Grid>
                    )}

                    <Grid item xs={12} md={4}>
                        <FormLabel error={errors.approach ? true : false} > Approach selected* </FormLabel>
                        <Droppable droppableId="approachSelected">
                            {(provided, snapshot) => (
                                <Card 
                                    ref={provided.innerRef}
                                    sx={{minHeight: 100}}
                                >
                                    { approachSelected && approachSelected.map((item, index) => (
                                        <Draggable key={item.id} draggableId={item.id} index={index}>
                                            {(provided, snapshot) => (
                                            <ListItem 
                                                component="div"
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                                secondaryAction={
                                                    <IconButton edge="end" onClick={(e)=> deleteApproach(item)} aria-label="delete">
                                                        { canEdit &&  <DeleteIcon /> }
                                                    </IconButton>
                                                  }
                                            >
                                                <ListItemText primary={item.label} />
                                            </ListItem>
                                            )}
                                        </Draggable>
                                    ))}
                                    {provided.placeholder}
                                </Card>
                            )}
                        </Droppable>
                    </Grid>
                </DragDropContext>
                { approachSelected.filter( (item)=> item.id==='other' ).length === 1 && (
                    <Grid item xs={12} md={4}>
                        <TextField multiline fullWidth size="small" label="Other Approach" name="otherApproach" value={ formData.otherApproach ? formData.otherApproach : '' } onChange={updateFormData} />
                    </Grid>
                )}

            </Grid>
        </React.Fragment>
     );
});
 
export default Approach;