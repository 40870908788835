import React, { useState, forwardRef, useImperativeHandle, useContext } from "react";
import { TextField, Grid } from '@mui/material';
import { verifyRequireField } from "../../../utils";
import { ClassesContext } from "../../../../../contexts/Classes";

const OperativeProcedureNote = forwardRef( ({canEdit},ref) => {
    const [ errors, setErrors ] = useState({});
    const [ operativeProcedureNote, setOperativeProcedureNote ] = useState('');
    const { hiddenFormInputStyle } = useContext(ClassesContext);
    useImperativeHandle(ref,()=>({
        setData(data){
            setOperativeProcedureNote(data)
        },
        verifyData(){
            let requireFields = [
            ]
            let {isRequireFieldsValid,errorObject} = verifyRequireField(requireFields,{operativeProcedureNote});
            setErrors({ ...errorObject });
            return isRequireFieldsValid
        },
        getData(){
            return operativeProcedureNote
        }
    }))
    
    return ( 
        <React.Fragment>
                <Grid container spacing={1} sx={{ 
                    ...hiddenFormInputStyle({value: operativeProcedureNote, canEdit}) ,
                    mt:1
                 }}>
                    <Grid item xs={12} md={12}>
                        <TextField 
                            sx={{resize:"both"}} 
                            multiline  
                            name="operativeProcedureNote" 
                            value={ operativeProcedureNote } 
                            onChange={e=>setOperativeProcedureNote(e.target.value) } 
                            error={ errors.operativeProcedureNote ? true : false } 
                            size='small' fullWidth 
                            label="Operative procedure note" /> 
                    </Grid>
                </Grid>
        </React.Fragment>
     );
});
 
export default OperativeProcedureNote;