import React, { useState, forwardRef, useImperativeHandle, useContext } from "react";
import { FormControl, FormLabel, FormControlLabel, Grid, RadioGroup, Radio } from '@mui/material';
import { verifyRequireField } from "../../../utils";
import { ClassesContext } from "../../../../../contexts/Classes";

const UseCellSaver = forwardRef( ({canEdit},ref) => {
    const [ errors, setErrors ] = useState({});
    const [ useCellSaver, setUseCellSaver ] = useState(null);
    const { hiddenFormInputStyle } = useContext(ClassesContext);
    useImperativeHandle(ref,()=>({
        setData(data){
            setUseCellSaver(data)
        },
        verifyData(){
            let requireFields = [
                { field: 'useCellSaver' ,errorName: 'useCellSaver' },
            ]
            let {isRequireFieldsValid,errorObject} = verifyRequireField(requireFields,{useCellSaver});
            setErrors({ ...errorObject });
            return isRequireFieldsValid
        },
        getData(){
            return useCellSaver
        }
    }))
    
    return ( 
        <React.Fragment>
                <Grid container spacing={1} sx={{mt:1}}>
                    <Grid item xs={12} md={12} >
                        <FormLabel error={ errors.useCellSaver ? true: false } >Use cell saver*</FormLabel>
                    </Grid>
                    <Grid item xs={12} md={12} sx={{ml:2}}>
                            <FormControl>
                                <RadioGroup
                                    row
                                    value={ useCellSaver }
                                    onChange={ e=> setUseCellSaver(e.target.value) }
                                    name="useCellSaver"
                                >
                                    <FormControlLabel value="no" label="No" control={<Radio  />} sx={{ 
                                        ...hiddenFormInputStyle({value: useCellSaver === 'no', canEdit})
                                    }} />
                                    <FormControlLabel value="yes" label="Yes" control={<Radio />} sx={{ 
                                        ...hiddenFormInputStyle({value: useCellSaver === 'yes', canEdit})
                                    }} />
                                </RadioGroup>
                            </FormControl>
                    </Grid>
                </Grid>
        </React.Fragment>
     );
});
 
export default UseCellSaver;