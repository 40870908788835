import { useContext } from "react";
import routes from "../config/routes";
import { Route, Routes, Navigate } from "react-router-dom";
import { AuthContext } from "../contexts/AuthContext";


const AppRoute = () => {
    const { user } = useContext(AuthContext);
    return (
            <Routes>
                { routes.map((route)=>(           
                <Route 
                    exact
                    key={route.path}
                    path={route.path}
                    element={ route.isPrivate && !user.isAuthenticated ? <Navigate to={ user.logoutClick ? "/login/" : "/login/?next=" + route.path } /> : route.component}
                />         
                )) }
            </Routes>
    )
}

export default AppRoute;