import React, { useState, forwardRef, useImperativeHandle, useContext } from "react";
import { Grid, FormLabel, TextField, FormControl, FormGroup, FormControlLabel, Checkbox } from '@mui/material';
import { verifyRequireField } from "../../../utils";
import { ClassesContext } from "../../../../../contexts/Classes";

const OtherComplications = forwardRef( ({canEdit},ref) => {
    const [ errors, setErrors ] = useState({});
    const [ none, setNone ] = useState(false);
    const [ woundInfection, setWoundInfection ] = useState(false);
    const [ woundInfectionText, setWoundInfectionText ] = useState('');
    const [ deepVeinThrombosis, setDeepVeinThrombosis ] = useState(false);
    const [ deepVeinThrombosisText, setDeepVeinThrombosisText ] = useState('');
    const [ pulmonaryEmboli, setPulmonaryEmboli ] = useState(false);
    const [ pulmonaryEmboliText, setPulmonaryEmboliText ] = useState('');
    const [ heterotopicOssification, setHeterotopicOssification ] = useState(false);
    const [ heterotopicOssificationText, setHeterotopicOssificationText ] = useState('');
    const [ others, setOthers ] = useState(false);
    const [ othersText, setOthersText ] = useState('');
    const { hiddenFormInputStyle } = useContext(ClassesContext);

    useImperativeHandle(ref,()=>({
        setData(data){
            setNone( data ? data.none : false )
            setWoundInfection( data ? data.woundInfection : false )
            setWoundInfectionText( data ? data.woundInfectionText : '' )
            setDeepVeinThrombosis( data ? data.deepVeinThrombosis : false )
            setDeepVeinThrombosisText( data ? data.deepVeinThrombosisText : '' )
            setPulmonaryEmboli( data ? data.pulmonaryEmboli : false )
            setPulmonaryEmboliText( data ? data.pulmonaryEmboliText : '' )
            setHeterotopicOssification( data ? data.heterotopicOssification : false )
            setHeterotopicOssificationText( data ? data.heterotopicOssificationText : '' )
            setOthers( data ? data.others : false )
            setOthersText( data ? data.othersText : '' )
        },
        verifyData(){
            let requireFields = [
                {   
                    errorName: 'otherComplications',
                    fields: [
                        'none', 
                        'woundInfection',
                        'deepVeinThrombosis',
                        'pulmonaryEmboli',
                        'heterotopicOssification',
                        'others',
                    ] ,
                    condition: 'or'  ,
                },
            ]
            let {isRequireFieldsValid,errorObject} = verifyRequireField(requireFields,getFormData());
            setErrors({ ...errorObject });
            return isRequireFieldsValid
        },
        getData(){
            return getFormData()
        }
    }))

    const getFormData = () => {
        return {
            none,
            woundInfection,
            woundInfectionText,
            pulmonaryEmboli,
            pulmonaryEmboliText,
            heterotopicOssification,
            heterotopicOssificationText,
            others,
            othersText,
            deepVeinThrombosis,
            deepVeinThrombosisText
        }
    }

    return ( 
        <React.Fragment>        
            <Grid container>
                <Grid item xs={12} md={12}>
                    <FormControl>
                        <FormLabel error={ errors.otherComplications ? true: false } >4. Other complications*</FormLabel>
                    </FormControl>
                </Grid>
                <Grid item xs={12} md={12} sx={{
                    ml:2,
                    ...hiddenFormInputStyle({value: none, canEdit})
                }}>
                    <FormControl>
                        <FormGroup>
                            <FormControlLabel control={
                                <Checkbox checked={ none } onChange={ e=> setNone(e.target.checked) } name="none" />
                            } label="None" />
                        </FormGroup>
                    </FormControl>
                </Grid>
            </Grid>
            <Grid container>
                <Grid item xs={11} md={12} sx={{ml:2, ...hiddenFormInputStyle({value: woundInfection, canEdit})}}>
                    <FormControl>
                        <FormGroup>
                            <FormControlLabel control={
                                <Checkbox checked={ woundInfection } 
                                onChange={ e=> {
                                    setWoundInfectionText('')
                                    setWoundInfection(e.target.checked);
                                } } name="woundInfection" />
                            } label="Wound infection" />
                        </FormGroup>
                    </FormControl>
                </Grid>
                { woundInfection && (
                    <Grid item xs={11} md={12} sx={{...hiddenFormInputStyle({value: woundInfectionText, canEdit}),ml:2}}>
                        <TextField 
                            value={ woundInfectionText }
                            onChange={ (e)=> setWoundInfectionText(e.target.value )} 
                            name="woundInfectionText"
                            size="small"
                            fullWidth
                            multiline
                            sx={{
                                resize: "both" 
                            }}
                        />
                    </Grid>
                )}
            </Grid>
            <Grid container>
                <Grid item xs={11} md={12} sx={{ml:2, ...hiddenFormInputStyle({value: deepVeinThrombosis, canEdit}) }}>
                    <FormControl>
                        <FormGroup>
                            <FormControlLabel control={
                                <Checkbox checked={ deepVeinThrombosis } 
                                onChange={ e=> {
                                    setDeepVeinThrombosisText('')
                                    setDeepVeinThrombosis(e.target.checked)
                                }} name="deepVeinThrombosis" />
                            } label="Deep vein thrombosis" />
                        </FormGroup>
                    </FormControl>
                </Grid>
                { deepVeinThrombosis && (
                    <Grid item xs={11} md={12} sx={{ ...hiddenFormInputStyle({value: deepVeinThrombosisText, canEdit}), ml:2}}>
                        <TextField 
                            value={ deepVeinThrombosisText }
                            onChange={ (e)=> setDeepVeinThrombosisText(e.target.value )} 
                            name="deepVeinThrombosis"
                            size="small"
                            fullWidth
                            multiline
                            sx={{
                                resize: "both"
                            }}
                        />
                    </Grid>
                )}
            </Grid>
            <Grid container>
                <Grid item xs={11} md={12} sx={{ ...hiddenFormInputStyle({value: pulmonaryEmboli, canEdit}), ml:2}}>
                    <FormControl>
                        <FormGroup>
                            <FormControlLabel control={
                                <Checkbox checked={ pulmonaryEmboli } 
                                    onChange={ e=> {
                                        setPulmonaryEmboliText('');
                                        setPulmonaryEmboli(e.target.checked)
                                    }} name="pulmonaryEmboli" />
                            } label="Pulmonary emboli" />
                        </FormGroup>
                    </FormControl>
                </Grid>
                { pulmonaryEmboli && (
                    <Grid item xs={11} md={12} sx={{...hiddenFormInputStyle({value: pulmonaryEmboliText, canEdit}),ml:2}}>
                        <TextField 
                            value={ pulmonaryEmboliText }
                            onChange={ (e)=> setPulmonaryEmboliText(e.target.value )} 
                            name="pulmonaryEmboliText"
                            size="small"
                            fullWidth
                            multiline
                            sx={{
                                resize: "both"
                            }}
                        />
                    </Grid>
                )}
            </Grid>
            <Grid container>
                <Grid item xs={11} md={12} sx={{ml:2,...hiddenFormInputStyle({value: heterotopicOssification, canEdit})}}>
                    <FormControl>
                        <FormGroup>
                            <FormControlLabel control={
                                <Checkbox checked={ heterotopicOssification } 
                                    onChange={ e=> {
                                        setHeterotopicOssificationText('')
                                        setHeterotopicOssification(e.target.checked)
                                    }} name="heterotopicOssification" />
                            } label="Heterotopic ossification"  />
                        </FormGroup>
                    </FormControl>
                </Grid>
                { heterotopicOssification && (
                    <Grid item xs={11} md={12} sx={{...hiddenFormInputStyle({value: heterotopicOssificationText, canEdit}),ml:2}}>
                        <TextField 
                            value={ heterotopicOssificationText }
                            onChange={ (e)=> setHeterotopicOssificationText(e.target.value )} 
                            name="heterotopicOssificationText"
                            size="small"
                            fullWidth
                            multiline
                            sx={{
                                resize: "both"
                            }}
                        />
                    </Grid>
                )}
            </Grid>
            <Grid container>
                <Grid item xs={11} md={12} sx={{ ...hiddenFormInputStyle({value: others, canEdit}), ml:2}}>
                    <FormControl>
                        <FormGroup>
                            <FormControlLabel control={
                                <Checkbox checked={ others } 
                                    onChange={ e=>{ 
                                        setOthersText('');
                                        setOthers(e.target.checked)
                                    }} name="others" />
                            } label="Others" />
                        </FormGroup>
                    </FormControl>
                </Grid>
                { others && (
                    <Grid item xs={11} md={12} sx={{...hiddenFormInputStyle({value: othersText, canEdit}),ml:2}}>
                        <TextField 
                            value={ othersText }
                            sx={{
                                resize: "both"
                            }}
                            onChange={ (e)=> setOthersText(e.target.value )} 
                            name="othersText"
                            size="small"
                            fullWidth
                            multiline
                        />
                    </Grid>
                )}
            </Grid>
        </React.Fragment>
     );
});
 
export default OtherComplications;