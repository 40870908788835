import React, { forwardRef, useImperativeHandle, useRef, useContext } from 'react';
import { Box, Divider, Collapse} from '@mui/material';
import Surgeon from './Surgeon';
import OperativeProcedure from './OperativeProcedure';
import Anesthesia from './Anesthesia';
import OperationDateTime from './OperationDateTime';
import PlanOperative from './PlanOperative';
import PredictionOfBloodLoss from './PredictionOfBloodLoss';
import OtherOrdersAndRemarks from './OtherOrdersAndRemarks';
import { ClassesContext } from "../../../../../contexts/Classes";
import AttendingSurgeonAndDateTime from '../../../common/AttendingSurgeonAndDateTime';
import FormLabel from '../../../common/FormLabel';

export const Main = forwardRef(({canEdit,topic},ref) => {
    const [expanded, setExpanded] = React.useState(canEdit);
    const { useStyles } = useContext(ClassesContext);
    const classes = useStyles(canEdit);
    const operativeProcedureRef = useRef();
    const anesthesiaRef = useRef();
    const surgeonRef = useRef();
    const operationDateTimeRef = useRef();
    const planOperativeRef = useRef();
    const predictionOfBloodLossRef = useRef();
    const otherOrdersAndRemarksRef = useRef();
    const attendingSurgeonAndDateTimeRef = useRef();

    useImperativeHandle(ref,()=>({
        setData(dataObject){
            operativeProcedureRef.current.setData(dataObject.operativeProcedure);
            surgeonRef.current.setData(dataObject.surgeon);
            operationDateTimeRef.current.setData(dataObject.operationDateTime);
            planOperativeRef.current.setData(dataObject.planOperative);
            predictionOfBloodLossRef.current.setData(dataObject.predictionOfBloodLoss);
            otherOrdersAndRemarksRef.current.setData(dataObject.otherOrdersAndRemarks);
            attendingSurgeonAndDateTimeRef.current.setData(dataObject.attendingSurgeonAndDateTime);
            anesthesiaRef.current.setData(dataObject.anesthesia);
        },
        verifyRequireField(){
            let operativeProcedure = operativeProcedureRef.current.verifyData();
            let surgeon = surgeonRef.current.verifyData();
            let operationDateTime = operationDateTimeRef.current.verifyData();
            let planOperative = planOperativeRef.current.verifyData();
            let predictionOfBloodLoss = predictionOfBloodLossRef.current.verifyData();
            let otherOrdersAndRemarks = otherOrdersAndRemarksRef.current.verifyData();
            let attendingSurgeonAndDateTime = attendingSurgeonAndDateTimeRef.current.verifyData();
            let anesthesia = anesthesiaRef.current.verifyData();
            let fieldValid = (
                operativeProcedure && 
                surgeon &&
                operationDateTime &&
                planOperative &&
                predictionOfBloodLoss &&
                otherOrdersAndRemarks &&
                attendingSurgeonAndDateTime &&
                anesthesia
            )
            return fieldValid
        },
        getData() {
            let data = {
                operativeProcedure : operativeProcedureRef.current.getData(),
                anesthesia : anesthesiaRef.current.getData(),
                surgeon : surgeonRef.current.getData(),
                operationDateTime : operationDateTimeRef.current.getData(),
                planOperative : planOperativeRef.current.getData(),
                predictionOfBloodLoss : predictionOfBloodLossRef.current.getData(),
                otherOrdersAndRemarks : otherOrdersAndRemarksRef.current.getData(),
                attendingSurgeonAndDateTime : attendingSurgeonAndDateTimeRef.current.getData(),
            }
            return data
        }
    }))

    return (
        <Box className={classes.root} sx={{padding:3}}>
            <FormLabel setExpanded={setExpanded} expanded={expanded} headerText="BDMS Pelvic and Acetabulum Fracture : Pre-Operative Order Form" />
            <Divider ></Divider>
            <Collapse in={expanded}>
                <Box sx={{mt:2}}>
                    <OperationDateTime ref={operationDateTimeRef} canEdit={canEdit} />
                    <Surgeon ref={surgeonRef} topic={topic} canEdit={canEdit} />
                    <OperativeProcedure ref={operativeProcedureRef} canEdit={canEdit} /> 
                    <Anesthesia ref={anesthesiaRef} canEdit={canEdit} />
                    <PlanOperative ref={planOperativeRef} canEdit={canEdit} />
                    <PredictionOfBloodLoss ref={predictionOfBloodLossRef} canEdit={canEdit} />
                    <OtherOrdersAndRemarks ref={otherOrdersAndRemarksRef} canEdit={canEdit} />
                    <AttendingSurgeonAndDateTime ref={attendingSurgeonAndDateTimeRef} canEdit={canEdit} />
                </Box>
            </Collapse>

        </Box>
    );
});