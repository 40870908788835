import React, { useState, forwardRef, useImperativeHandle, useContext } from "react";
import { FormControl, FormLabel, OutlinedInput, Grid, InputAdornment } from '@mui/material';
import { verifyRequireField } from "../../../utils";
import { ClassesContext } from "../../../../../contexts/Classes";

const PredictionOfBloodLoss = forwardRef( ({canEdit},ref) => {
    const [ errors, setErrors ] = useState({});
    const [ predictionOfBloodLoss, setPredictionOfBloodLoss ] = useState('');
    const { hiddenFormInputStyle } = useContext(ClassesContext);
    useImperativeHandle(ref,()=>({
        setData(data){
            if(data){
                setPredictionOfBloodLoss(data)
            }
            
        },
        verifyData(){
            let requireFields = [
            ]
            let {isRequireFieldsValid,errorObject} = verifyRequireField(requireFields,{predictionOfBloodLoss});
            setErrors({ ...errorObject });
            return isRequireFieldsValid
        },
        getData(){
            return predictionOfBloodLoss
        }
    }))
    
    return ( 
        <React.Fragment>
                <Grid container spacing={1} sx={{mt:1,...hiddenFormInputStyle({value: predictionOfBloodLoss, canEdit})}}>
                    <Grid item xs={12} md={12}>
                        <FormLabel error={ errors.predictionOfBloodLoss ? true: false } >Prediction of blood loss</FormLabel>
                    </Grid>
                    <Grid item xs={12} md={4} sx={{ml:2}}>
                        <FormControl  >
                            <OutlinedInput
                                name="predictionOfBloodLoss"
                                value={ predictionOfBloodLoss }
                                onChange={ e=> setPredictionOfBloodLoss(e.target.value) }
                                endAdornment={<InputAdornment position="end">CC</InputAdornment>}
                                size="small"
                                inputProps={{
                                    'aria-label': 'CC',
                                }}
                            />    
                        </FormControl>  
                    </Grid>
                </Grid>
        </React.Fragment>
     );
});
 
export default PredictionOfBloodLoss;