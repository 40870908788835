import React, { useEffect, useState, forwardRef, useImperativeHandle, useContext } from "react";
import { CardActions, Typography} from '@mui/material';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { styled } from '@mui/material/styles';

interface ExpandMoreProps extends IconButtonProps {
    expand: boolean;
}

const ExpandMore = styled((props: ExpandMoreProps) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
  })(({ theme, expand }) => ({
    transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  }));

const AttendingSurgeonAndDateTime = ({headerText,setExpanded,expanded},ref) => {
    

    return ( 
        <React.Fragment>
            <CardActions disableSpacing>
                <Typography sx={{fontWeight: "bold"}} variant="h6" component="div">
                    {headerText}
                </Typography>
                <ExpandMore
                    expand={expanded}
                    onClick={()=>setExpanded(!expanded)}
                    aria-expanded={expanded}
                    aria-label="show more"
                    >
                    <ExpandMoreIcon sx={{pointerEvents:'auto'}} />
                </ExpandMore>
            </CardActions>
        </React.Fragment>
     );
}
 
export default AttendingSurgeonAndDateTime;