import React, { useEffect, useState } from "react";
import { Grid,FormControlLabel, Typography, Checkbox, Box, Card, CardHeader, Divider, Button, List, ListItem, ListItemButton, ListItemIcon, ListItemText, ImageListItem, ImageListItemBar } from "@mui/material";
import { useParams, useNavigate } from 'react-router-dom';
import axiosInstance from "../common/axios";
import ReactPlayer from 'react-player';
import moment from "moment";
import fileDownload from 'js-file-download';
import axios from 'axios';

const TopicFile = () => {
    const { topicId } = useParams();
    const [ title, setTitle ] = useState('');
    const [ topicFiles,setTopicFiles ] = useState([]);
    const [ commentFiles, setCommentFiles ] = useState([]);
    const navigate = useNavigate();
    const [ topicFilesChecked, setTopicFilesChecked ] = React.useState([]);
    const [ commentFilesChecked, setCommentFilesChecked ] = React.useState([]);
    const [selectAllChecked, setSelectAllChecked] = useState(false)

    const handleToggleFileChecked = (id,data,setData) => {
        const currentIndex = data.indexOf(id);
        const newChecked = [...data];
        if (currentIndex === -1) {
            newChecked.push(id);
        } else {
            newChecked.splice(currentIndex, 1);
        }
        setData(newChecked);
    };

    const getTopicFiles = async () => {
        axiosInstance.get(`/topic/topic-file/?topic=${topicId}`).then((res)=>{
            if(res.data.count > 0){
                setTopicFiles(res.data.results)
            }
        })
    }

    const getCommentFiles = async () => {
        let comments = []
        await axiosInstance.get(`/topic/comment/?topic=${topicId}`).then((res)=>{
            if( res.data.count > 0 ){
                comments = res.data.results;
            }
        })
        let newCommentLists = []
        for(let i=0;i < comments.length; i++ ){
            let comment = comments[i];
            let res = await axiosInstance.get(`/topic/comment-file/?comment=${comment.id}`)
            comment.files = res.data.results;
            newCommentLists.push(comment)
        }
        setCommentFiles( sortComment(newCommentLists) )
    }

    const sortComment = (comments) => {
        comments = comments.sort(function(a, b) {
                return a.id  - b.id;
        })
        return comments
    }

    useEffect(()=>{
        axiosInstance.get(`/topic/topic/${topicId}/`).then((res)=>{
            if(res.data){
                let topic = res.data
                setTitle(`${topic.specialty_detail.name} / ${topic.hospital_detail.code} / ${ topic.patient_visit_detail !== null ? topic.patient_visit_detail.patient_detail.name : ''}`)
                getTopicFiles()
                getCommentFiles()
            }
        }).catch((error)=>{
            navigate('/')
        });

    },[])// eslint-disable-line react-hooks/exhaustive-deps

    const getFilePreview = (file) => {
        let labelId = `checkbox-list-label-${file.id}`;
        switch (file.type) {
            case 'image':
                return (
                    <React.Fragment>
                        <ImageListItem>
                            <img src={file.file} width="auto" style={{height: 200}} alt={file.description} />
                            { file.description && <ImageListItemBar title={file.description} /> }
                        </ImageListItem>
                    </React.Fragment>
                )        
            case 'video':
                return (
                    <React.Fragment>
                        <Box  >
                            <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                                {file.description}
                            </Typography>
                            <ReactPlayer playing controls={true} url={file.file} />
                        </Box>
                    </React.Fragment>
                )
            default:
                return (
                    <React.Fragment>
                        <ListItemText id={labelId} primary={`${file.name} - ${ file.description ? file.description : '' }`} />
                    </React.Fragment>
                )
        }

    }
    
    const handleSelectAll = (checkAll) => {
        if(checkAll){
            setTopicFilesChecked(topicFiles.map(item => item.id))
            let newCommentFileChecked = []
            commentFiles.forEach((comment,index)=>{
                comment.files.forEach(item=>{
                    newCommentFileChecked.push(item.id);
                })
            })
            setCommentFilesChecked(newCommentFileChecked)
        }else{
            setTopicFilesChecked([])
            setCommentFilesChecked([])
        }

    }

    const handleDownload = async (e)=> {
        for( let i=0; i< topicFilesChecked.length; i++ ){
            let topicFileId = topicFilesChecked[i]
            let type = "get-topic-file";
            await downloadFile(type,topicFileId)
        }

        for( let i=0; i< commentFilesChecked.length; i++ ){
            let commentFileId = commentFilesChecked[i]
            let type = "get-comment-file";
            await downloadFile(type, commentFileId)
        }

        setTopicFilesChecked([]);
        setCommentFilesChecked([]);
        setSelectAllChecked(false)
    }

    const getNameFile = (type,id) => {
        let name = null;
        switch (type) {
            case "get-comment-file":
                commentFiles.forEach((comment,index)=>{
                    comment.files.forEach(item=>{
                        if(item.id===id){
                            name = item.name
                        }
                    })
                })
                break;
            case "get-topic-file":
                topicFiles.forEach((item)=>{
                    if(item.id===id){
                        name = item.name
                    }
                })
                break;
            default:
                break;
        }
        return name
    }

    const downloadFile = async (type,id) => {
        let token = localStorage.getItem('token') ? JSON.parse(localStorage.getItem('token')).access : null; 
        if(token){
            await axios.get(`${process.env.REACT_APP_BACKEND_URL}/topic/${type}/${id}/${token}`,{
                responseType: 'blob',
            }).then((res) => {
                let fileName = getNameFile(type,id)
                fileDownload(res.data, fileName)
            })
        }
    }

    return ( 
        <React.Fragment>
            <Card>
                <Box sx={{ padding: 3,float:"right" }}>
                    <Button variant='outlined' onClick={handleDownload} sx={{ml:1}} >Download</Button>
                    <Button variant='outlined' onClick={e=> navigate(`/topic/${topicId}`)} sx={{ml:1}} >Back</Button>
                </Box> 
            </Card>
            <Card sx={{mt:2}} elevation={0}>
                <CardHeader 
                    title={ title }
                    action={
                        <FormControlLabel control={
                            <Checkbox 
                                checked={ selectAllChecked }
                                onChange={e=>{ 
                                    setSelectAllChecked(e.target.checked)
                                    handleSelectAll(e.target.checked)
                                }}  
                            />
                        } label="All file" />
                    }
                />    
                <Divider ></Divider>
                <Box sx={{ padding: 3 }}>
                    <List >
                        { topicFiles.map((item)=>{
                            return (
                                <ListItem key={item.id} >
                                    <ListItemIcon>
                                        <FormControlLabel control={
                                            <Checkbox 
                                                checked={ topicFilesChecked.indexOf(item.id) !== -1 }
                                                onChange={e=>{ 
                                                    handleToggleFileChecked(item.id,topicFilesChecked,setTopicFilesChecked);
                                                }}  
                                            />
                                        } label="" />
                                    </ListItemIcon>
                                    {getFilePreview(item)}
                                </ListItem>
                            )
                        }) }
                    </List>
                </Box>
            </Card>
            { commentFiles.map((comment)=>{return (
                <React.Fragment>
                    <Card sx={{mt:2}} elevation={0}>
                        <CardHeader 
                            subheader={ 'created on ' + moment(comment.created).format('MMMM Do YYYY, h:mm:ss a') + ` by ${comment.author_detail.first_name} ${comment.author_detail.last_name}` } 
                        />    

                        <Box sx={{ padding: 3 }}>
                            <List >
                                { comment.files.map((item)=>{
                                    return (
                                        <ListItem key={item.id} >
                                            <ListItemIcon>
                                                <FormControlLabel control={
                                                    <Checkbox 
                                                        checked={ commentFilesChecked.indexOf(item.id) !== -1 }
                                                        onChange={e=>{ 
                                                            handleToggleFileChecked(item.id,commentFilesChecked,setCommentFilesChecked);
                                                        }}  
                                                    />
                                                } label="" />
                                            </ListItemIcon>
                                            {getFilePreview(item)}
                                        </ListItem>
                                    )
                                }) }
                            </List> 
                        </Box>
                    </Card>
                </React.Fragment>
            )}) }
        </React.Fragment>
     );
}
 
export default TopicFile;