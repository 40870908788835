import { createContext, useReducer } from "react";
import reducer from '../store/reducer/notification';

export const NotificationContext = createContext();

export const NotificationProvider = (props) => {
    
  const [notifications, dispatch] = useReducer(reducer, []);
 
  return (
    <NotificationContext.Provider value={{ notifications,dispatch }}> 
        {props.children}
    </NotificationContext.Provider>
  );
  
};