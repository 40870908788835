import React, { useState, forwardRef, useImperativeHandle, useContext } from "react";
import { FormControl, FormLabel, FormControlLabel, Grid, TextField, FormGroup, Checkbox } from '@mui/material';
import { getDataUpdate, verifyRequireField } from "../../../utils";
import { ClassesContext } from "../../../../../contexts/Classes";

const PlanOfTreatment = forwardRef( ({canEdit},ref) => {
    const [ errors, setErrors ] = useState({});
    const [ formData, setFormData ] = useState({});
    const { hiddenFormInputStyle } = useContext(ClassesContext);
    

    useImperativeHandle(ref,()=>({
        setData(data){
            setFormData(data ? data : {})
        },
        verifyData(){
            let requireFields = []
            let {isRequireFieldsValid,errorObject} = verifyRequireField(requireFields,formData)
            setErrors({ ...errorObject });
            return isRequireFieldsValid
        },
        getData(){
            return formData
        }
    }))

    const updateFormData = async (e) => {
        let dataUpdate = getDataUpdate(e);
        setFormData({...formData,...dataUpdate})
    }
    
    return ( 
        <React.Fragment>
      
            <Grid container spacing={0} sx={{mt:1}}>
                <Grid item sx={12} md={12}>
                    <FormLabel error={ errors.planOfTreatment ? true: false } id="plan-of-treatment-label">Plan of treatment</FormLabel>
                </Grid>
                <Grid item xs={12} md={12} sx={{ 
                    ml:2,
                    ...hiddenFormInputStyle({
                        value: (formData.planOfTreatmentConservative || formData.planOfTreatmentSurgery), canEdit
                    }) 
                }}>
                    <FormControl>
                        <FormGroup>
                            <FormControlLabel control={
                                <Checkbox checked={ formData.planOfTreatmentConservative ? formData.planOfTreatmentConservative : false } onChange={updateFormData} name="planOfTreatmentConservative" />
                            } label="Conservative" sx={{ 
                                ...hiddenFormInputStyle({value:formData.planOfTreatmentConservative, canEdit}) 
                            }} />
                            <FormControlLabel control={
                                <Checkbox checked={ formData.planOfTreatmentSurgery ? formData.planOfTreatmentSurgery : false } onChange={updateFormData} name="planOfTreatmentSurgery" />
                            } label="Surgery" sx={{ 
                                ...hiddenFormInputStyle({value:formData.planOfTreatmentSurgery, canEdit}) 
                            }} />
                        </FormGroup>
                    </FormControl>
                </Grid>
            </Grid>
            <Grid container spacing={0} sx={{ 
                ml:2,
                ...hiddenFormInputStyle({
                    value: (
                        formData.dateOfPlanOfTreatment ||
                        formData.planOfTreatmentExternalFixator 
                    ), 
                    canEdit
                }) 
            }} >
                <Grid item xs={12} md={2} >
                    <FormControlLabel control={
                                <Checkbox checked={ formData.planOfTreatmentExternalFixator ? formData.planOfTreatmentExternalFixator : false } onChange={updateFormData} name="planOfTreatmentExternalFixator" />
                            } label="External fixator" sx={{ 
                                ...hiddenFormInputStyle({value:formData.planOfTreatmentExternalFixator, canEdit}) 
                    }} />
                </Grid>
                <Grid item xs={12} md={3} >
                    <TextField
                        label="Date of Plan"
                        type="date"
                        sx={{ 
                            ...hiddenFormInputStyle({value:formData.dateOfPlanOfTreatment, canEdit}) 
                        }}
                        value={ formData.dateOfPlanOfTreatment ? formData.dateOfPlanOfTreatment : null }
                        onChange={(e) => {
                            setFormData( {...formData, dateOfPlanOfTreatment: e.target.value } )
                        }}
                        size="small"
                        fullWidth
                        error={ errors.dateOfInjury ? true : false } 
                        InputLabelProps={{
                            shrink: true,
                        }}

                    />
                </Grid>
            </Grid>
        </React.Fragment>
     );
});
 
export default PlanOfTreatment;