import { Divider,Box, Link } from '@mui/material';
import React from 'react';

const FileList = ({otherFiles,downloadFile}) => {
    return ( 
        <React.Fragment>
                { otherFiles.length > 0 && <Divider sx={{mt:2}} > Other files </Divider>  }
                { otherFiles.length > 0 && otherFiles.map((file,index)=>(
                    <Box key={file.id} sx={{padding: 2}}>
                        <Link component="button" variant="body2" onClick={()=>{ downloadFile(file) }} > {file.name} { file.description !== null ? '- '+file.description : '' }  </Link>
                    </Box>
                )) }
        </React.Fragment>
     );
}
 

export default FileList;