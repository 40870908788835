import React, { useState, forwardRef, useImperativeHandle, useContext } from "react";
import { FormHelperText,FormControl, FormLabel, FormControlLabel, FormGroup, Checkbox, Grid, RadioGroup, Radio, Divider } from '@mui/material';
import { getDataUpdate, verifyRequireField } from "../../../utils";
import { ClassesContext } from "../../../../../contexts/Classes"

const Diagnosis = forwardRef( ({canEdit},ref) => {
    const [ errors, setErrors ] = useState({});
    const [ formData, setFormData ] = useState({});
    const { hiddenFormInputStyle } = useContext(ClassesContext);
    useImperativeHandle(ref,()=>({
        setData(data){
            setFormData(data ? data : {})
        },
        verifyData(){
            let requireFields = [
                { field: 'diagnosis' ,errorName: 'diagnosis' },
                { field: 'hipDislocation' ,errorName: 'hipDislocation' },
                { field: 'femoralHeadFracture' ,errorName: 'femoralHeadFracture' },
                {   
                    errorName: 'diagnosisPelvic',
                    fields: [
                        'diagnosisPelvicLeft', 
                        'diagnosisPelvicRight',
                        'diagnosisPelvicCombined',
                    ] ,
                    condition: 'or'  ,
                    parent: { field: 'diagnosisPelvic'  }
                },
                {   
                    errorName: 'diagnosisPelvicAndAcetabulum',
                    fields: [
                        'diagnosisPelvic', 
                        'diagnosisAcetabulum',
                    ] ,
                    condition: 'or'  ,
                },
                {   
                    errorName: 'diagnosisAcetabulum',
                    fields: [
                        'diagnosisAcetabulumLeft', 
                        'diagnosisAcetabulumRight',
                    ] ,
                    condition: 'or'  ,
                    parent: { field: 'diagnosisAcetabulum'  }
                }
            ]
            let {isRequireFieldsValid,errorObject} = verifyRequireField(requireFields,formData);
            setErrors({ ...errorObject });
            return isRequireFieldsValid
        },
        getData(){
            return formData
        }
    }))

    const updateFormData = async (e) => {
        let dataUpdate = getDataUpdate(e);
        setFormData({...formData,...dataUpdate})
    }
    
    return ( 
        <React.Fragment>
            <Grid container spacing={0} sx={{mt: 1}}>
                <Grid item xs={12} md={12}>
                    <FormControl>
                        <FormLabel error={ errors.diagnosis ? true: false } id="diagnosis-label">Diagnosis*</FormLabel>
                    </FormControl>
                </Grid>
                <Grid item xs={12} md={12} sx={{ml:2}}>
                    <FormControl>
                        <RadioGroup
                            row
                            value={ formData.diagnosis ? formData.diagnosis : null }
                            aria-label='diagnosis-label'
                            onChange={updateFormData}
                            name="diagnosis"
                        >
                            <FormControlLabel 
                                value="openFracture" 
                                label="Open Fracture" 
                                control={<Radio  />} 
                                sx={{ 
                                    ...hiddenFormInputStyle({value:formData.diagnosis==='openFracture',canEdit}) 
                                }}
                            />
                            <FormControlLabel 
                                value="closedFracture" 
                                label="Closed Fracture" 
                                sx={{ 
                                    ...hiddenFormInputStyle({value:formData.diagnosis==='closedFracture',canEdit})
                                }}
                                control={<Radio />} 
                            />
                        </RadioGroup>
                    </FormControl>
                </Grid>
                { errors.diagnosisPelvicAndAcetabulum && <Grid item xs={12} md={12} >
                    <FormControl error={errors.diagnosisPelvicAndAcetabulum?true:false}>
                         <FormHelperText>Please select Pelvic or Acetabulum</FormHelperText> 
                    </FormControl>
                </Grid>
                }
                <Grid item xs={12} md={12} sx={{
                    ...hiddenFormInputStyle({value:formData.diagnosisPelvic, canEdit}),
                    ml:2
                }}>
                    <FormControl error={errors.diagnosisPelvic ? true : false}>
                        <FormGroup>
                            <FormControlLabel 
                                control={
                                    <Checkbox 
                                        checked={ formData.diagnosisPelvic ? formData.diagnosisPelvic : false } 
                                        onChange={updateFormData} 
                                        name="diagnosisPelvic" 
                                    />
                                } 
                                label="Pelvic" 
                                
                            />
                            { errors.diagnosisPelvic && <FormHelperText>Select left right or combined</FormHelperText> }
                            
                        </FormGroup>
                    </FormControl>
                </Grid>
                <Grid item xs={12} md={12} sx={{
                    ...hiddenFormInputStyle({value:formData.diagnosisPelvicLeft, canEdit})
                } }>
                    <FormControl sx={{ml:4}}>
                        <FormGroup>
                            <FormControlLabel 
                                    control={
                                        <Checkbox checked={ formData.diagnosisPelvicLeft ? formData.diagnosisPelvicLeft : false } onChange={updateFormData} name="diagnosisPelvicLeft" />
                                    } 
                                    label="Left" 
                                    
                                />
                        </FormGroup>
                    </FormControl>
                </Grid>
                <Grid item 
                    xs={12} 
                    md={12} 
                    sx={{
                        ...hiddenFormInputStyle({
                            value: (
                                formData.diagnosisPelvicLeftLCI || 
                                formData.diagnosisPelvicLeftLCII ||
                                formData.diagnosisPelvicLeftLCIII ||
                                formData.diagnosisPelvicLeftVerticalShear ||
                                formData.diagnosisPelvicLeftAPCI ||
                                formData.diagnosisPelvicLeftAPCII ||
                                formData.diagnosisPelvicLeftAPCIIII
                            ), 
                            canEdit
                        })
                    }}
                >
                    <FormControl sx={{ml: 6}}>
                        <FormGroup sx={{display:'inline'}}>
                                <FormControlLabel 
                                    control={
                                        <Checkbox checked={ formData.diagnosisPelvicLeftLCI ? formData.diagnosisPelvicLeftLCI : false } onChange={updateFormData} name="diagnosisPelvicLeftLCI" />
                                    } 
                                    label="LCI" 
                                    sx={{
                                        ...hiddenFormInputStyle({value:formData.diagnosisPelvicLeftLCI, canEdit})
                                    }}
                                />
                                <FormControlLabel 
                                    control={
                                        <Checkbox checked={ formData.diagnosisPelvicLeftLCII ? formData.diagnosisPelvicLeftLCII : false } onChange={updateFormData} name="diagnosisPelvicLeftLCII" />
                                    } 
                                    label="LCII" 
                                    sx={{
                                        ...hiddenFormInputStyle({value:formData.diagnosisPelvicLeftLCII, canEdit})
                                    }} 
                                />
                                <FormControlLabel 
                                    control={
                                        <Checkbox checked={ formData.diagnosisPelvicLeftLCIII ? formData.diagnosisPelvicLeftLCIII : false } onChange={updateFormData} name="diagnosisPelvicLeftLCIII" />
                                    } 
                                    label="LCIII" 
                                    sx={{
                                        ...hiddenFormInputStyle({value:formData.diagnosisPelvicLeftLCIII, canEdit})
                                    }} 
                                />
                                <FormControlLabel 
                                    control={
                                        <Checkbox checked={ formData.diagnosisPelvicLeftVerticalShear  ? formData.diagnosisPelvicLeftVerticalShear : false } onChange={updateFormData} name="diagnosisPelvicLeftVerticalShear" />
                                    } 
                                    label="Vertical shear" 
                                    sx={{
                                        ...hiddenFormInputStyle({value:formData.diagnosisPelvicLeftVerticalShear, canEdit})
                                    }} 
                                />
                                <FormControlLabel 
                                    control={
                                        <Checkbox checked={ formData.diagnosisPelvicLeftAPCI  ? formData.diagnosisPelvicLeftAPCI : false } onChange={updateFormData} name="diagnosisPelvicLeftAPCI" />
                                    } 
                                    label="APCI" 
                                    sx={{
                                        ...hiddenFormInputStyle({value:formData.diagnosisPelvicLeftAPCI, canEdit})
                                    }} 
                                />
                                <FormControlLabel 
                                    control={
                                        <Checkbox checked={ formData.diagnosisPelvicLeftAPCII  ? formData.diagnosisPelvicLeftAPCII : false } onChange={updateFormData} name="diagnosisPelvicLeftAPCII" />
                                    } 
                                    label="APCII" 
                                    sx={{
                                        ...hiddenFormInputStyle({value:formData.diagnosisPelvicLeftAPCII, canEdit})
                                    }} 
                                />
                                <FormControlLabel 
                                    control={
                                        <Checkbox checked={ formData.diagnosisPelvicLeftAPCIIII  ? formData.diagnosisPelvicLeftAPCIIII : false } onChange={updateFormData} name="diagnosisPelvicLeftAPCIIII" />
                                    } 
                                    label="APC IIII" 
                                    sx={{
                                        ...hiddenFormInputStyle({value:formData.diagnosisPelvicLeftAPCIIII, canEdit})
                                    }}
                                />
                        <Divider></Divider>
                        </FormGroup>
                    </FormControl>
                </Grid>        
                <Grid item xs={12} md={12} sx={{
                    ...hiddenFormInputStyle({value:formData.diagnosisPelvicRight, canEdit})
                }}>
                    <FormControl sx={{ml:4}}>
                        <FormGroup>
                            <FormControlLabel 
                                control={
                                <Checkbox checked={ formData.diagnosisPelvicRight ? formData.diagnosisPelvicRight : false } onChange={updateFormData} name="diagnosisPelvicRight" />
                                } 
                                label="Right" 
                                
                            />
                        </FormGroup>
                    </FormControl>
                </Grid>      
                <Grid item xs={12} md={12} sx={{
                    ...hiddenFormInputStyle({
                        value: (
                            formData.diagnosisPelvicRightLCI ||
                            formData.diagnosisPelvicRightLCII ||
                            formData.diagnosisPelvicRightLCIII ||
                            formData.diagnosisPelvicRightVerticalShear ||
                            formData.diagnosisPelvicRightAPCI ||
                            formData.diagnosisPelvicRightAPCII ||
                            formData.diagnosisPelvicRightAPCIIII
                            ), 
                        canEdit
                    })
                }}>
                    <FormControl sx={{ml: 6}}>
                        <FormGroup sx={{display:'inline'}}>
                                <FormControlLabel 
                                    control={
                                        <Checkbox checked={ formData.diagnosisPelvicRightLCI ? formData.diagnosisPelvicRightLCI : false } onChange={updateFormData} name="diagnosisPelvicRightLCI" />
                                    } 
                                    label="LCI" 
                                    sx={{
                                        ...hiddenFormInputStyle({value:formData.diagnosisPelvicRightLCI, canEdit})
                                    }}
                                />
                                <FormControlLabel 
                                    control={
                                        <Checkbox checked={ formData.diagnosisPelvicRightLCII ? formData.diagnosisPelvicRightLCII : false } onChange={updateFormData} name="diagnosisPelvicRightLCII" />
                                    } 
                                    label="LCII" 
                                    sx={{
                                        ...hiddenFormInputStyle({value:formData.diagnosisPelvicRightLCII, canEdit})
                                    }}
                                />
                                <FormControlLabel 
                                    control={
                                        <Checkbox checked={ formData.diagnosisPelvicRightLCIII ? formData.diagnosisPelvicRightLCIII : false } onChange={updateFormData} name="diagnosisPelvicRightLCIII" />
                                    } 
                                    label="LCIII"
                                    sx={{
                                        ...hiddenFormInputStyle({value:formData.diagnosisPelvicRightLCIII, canEdit})
                                    }}
                                />
                                <FormControlLabel 
                                    control={
                                        <Checkbox checked={ formData.diagnosisPelvicRightVerticalShear  ? formData.diagnosisPelvicRightVerticalShear : false } onChange={updateFormData} name="diagnosisPelvicRightVerticalShear" />
                                    } 
                                    label="Vertical shear" 
                                    sx={{
                                        ...hiddenFormInputStyle({value:formData.diagnosisPelvicRightVerticalShear, canEdit})
                                    }}
                                />
                                <FormControlLabel 
                                    control={
                                        <Checkbox checked={ formData.diagnosisPelvicRightAPCI  ? formData.diagnosisPelvicRightAPCI : false } onChange={updateFormData} name="diagnosisPelvicRightAPCI" />
                                    } 
                                    label="APCI" 
                                    sx={{
                                        ...hiddenFormInputStyle({value:formData.diagnosisPelvicRightAPCI, canEdit})
                                    }}
                                />
                                <FormControlLabel 
                                    control={
                                        <Checkbox checked={ formData.diagnosisPelvicRightAPCII  ? formData.diagnosisPelvicRightAPCII : false } onChange={updateFormData} name="diagnosisPelvicRightAPCII" />
                                    } 
                                    label="APCII" 
                                    sx={{
                                        ...hiddenFormInputStyle({value:formData.diagnosisPelvicRightAPCII, canEdit})
                                    }}
                                />
                                <FormControlLabel 
                                    control={
                                        <Checkbox checked={ formData.diagnosisPelvicRightAPCIIII  ? formData.diagnosisPelvicRightAPCIIII : false } onChange={updateFormData} name="diagnosisPelvicRightAPCIIII" />
                                    } 
                                    label="APC IIII"   
                                    sx={{
                                        ...hiddenFormInputStyle({value:formData.diagnosisPelvicRightAPCIIII, canEdit})
                                    }}
                                />
                        <Divider ></Divider>
                        </FormGroup>
                    </FormControl>
                </Grid>
                <Grid item xs={12} md={12} sx={{
                    ...hiddenFormInputStyle({value:formData.diagnosisPelvicCombined, canEdit})
                }}>
                    <FormControl sx={{ml: 4}}>
                        <FormGroup sx={{display:'inline'}}>
                            <FormControlLabel 
                                control={
                                    <Checkbox checked={ formData.diagnosisPelvicCombined ? formData.diagnosisPelvicCombined : false } onChange={updateFormData} name="diagnosisPelvicCombined" />
                                } 
                                label="Combined" 
                                
                            />
                        </FormGroup>
                        
                    </FormControl>
                </Grid>              
                <Grid item xs={12} md={12} sx={{
                    ...hiddenFormInputStyle({value:formData.diagnosisAcetabulum, canEdit}),
                    ml:2
                }}>
                    <FormControl error={errors.diagnosisAcetabulum ? true : false}>
                        <FormGroup>
                            <FormControlLabel 
                                control={
                                    <Checkbox checked={ formData.diagnosisAcetabulum  ? formData.diagnosisAcetabulum : false } onChange={updateFormData} name="diagnosisAcetabulum" />
                                } 
                                label="Acetabulum" 
                            />
                        </FormGroup>
                        { errors.diagnosisAcetabulum && <FormHelperText>Select left or right</FormHelperText> }
                    </FormControl>
                </Grid>
                <Grid item xs={12} md={12} sx={{
                    ...hiddenFormInputStyle({value:formData.diagnosisAcetabulumLeft, canEdit})
                }}>
                    <FormControl sx={{ml:4}}>
                        <FormGroup>
                            <FormControlLabel control={
                                    <Checkbox checked={ formData.diagnosisAcetabulumLeft ? formData.diagnosisAcetabulumLeft : false } onChange={updateFormData} name="diagnosisAcetabulumLeft" />
                                } label="Left"  />
                        </FormGroup>
                    </FormControl>
                </Grid>
                <Grid item xs={12} md={12} sx={{
                    ...hiddenFormInputStyle({
                        value: (
                            formData.diagnosisAcetabulumLeftPosteriorWall ||
                            formData.diagnosisAcetabulumLeftPosteriorColumn ||
                            formData.diagnosisAcetabulumLeftTransverse ||
                            formData.diagnosisAcetabulumLeftAnteriorWall ||
                            formData.diagnosisAcetabulumLeftAnteriorColumn ||
                            formData.diagnosisAcetabulumLeftBothColumn ||
                            formData.diagnosisAcetabulumLeftPosteriorColumnWithPosteriorWall ||
                            formData.diagnosisAcetabulumLeftTShaped ||
                            formData.diagnosisAcetabulumLeftTransverseWithPosteriorWall ||
                            formData.diagnosisAcetabulumLeftAnteriorColumnWithPosteriorHemiTransverse
                        ), 
                        canEdit
                    })
                }}>
                    <FormControl sx={{ml: 6}}>
                        <FormGroup sx={{display:'inline'}}>
                                <FormControlLabel control={
                                    <Checkbox checked={ formData.diagnosisAcetabulumLeftPosteriorWall ? formData.diagnosisAcetabulumLeftPosteriorWall : false } onChange={updateFormData} name="diagnosisAcetabulumLeftPosteriorWall" />
                                } label="Posterior wall" sx={{
                                    ...hiddenFormInputStyle({value:formData.diagnosisAcetabulumLeftPosteriorWall, canEdit})
                                }} />
                                <FormControlLabel control={
                                    <Checkbox checked={ formData.diagnosisAcetabulumLeftPosteriorColumn ? formData.diagnosisAcetabulumLeftPosteriorColumn : false } onChange={updateFormData} name="diagnosisAcetabulumLeftPosteriorColumn" />
                                } label="Posterior column" sx={{
                                    ...hiddenFormInputStyle({value:formData.diagnosisAcetabulumLeftPosteriorColumn, canEdit})
                                }} />
                                <FormControlLabel control={
                                    <Checkbox checked={ formData.diagnosisAcetabulumLeftTransverse ? formData.diagnosisAcetabulumLeftTransverse : false } onChange={updateFormData} name="diagnosisAcetabulumLeftTransverse" />
                                } label="Transverse" sx={{
                                    ...hiddenFormInputStyle({value:formData.diagnosisAcetabulumLeftTransverse, canEdit})
                                }} />
                                <FormControlLabel control={
                                    <Checkbox checked={ formData.diagnosisAcetabulumLeftAnteriorWall  ? formData.diagnosisAcetabulumLeftAnteriorWall : false } onChange={updateFormData} name="diagnosisAcetabulumLeftAnteriorWall" />
                                } label="Anterior wall" sx={{
                                    ...hiddenFormInputStyle({value:formData.diagnosisAcetabulumLeftAnteriorWall, canEdit})
                                }} />
                                <FormControlLabel control={
                                    <Checkbox checked={ formData.diagnosisAcetabulumLeftAnteriorColumn  ? formData.diagnosisAcetabulumLeftAnteriorColumn : false } onChange={updateFormData} name="diagnosisAcetabulumLeftAnteriorColumn" />
                                } label="Anterior column" sx={{
                                    ...hiddenFormInputStyle({value:formData.diagnosisAcetabulumLeftAnteriorColumn, canEdit})
                                }} />
                                <FormControlLabel control={
                                    <Checkbox checked={ formData.diagnosisAcetabulumLeftBothColumn  ? formData.diagnosisAcetabulumLeftBothColumn : false } onChange={updateFormData} name="diagnosisAcetabulumLeftBothColumn" />
                                } label="Both column" sx={{
                                    ...hiddenFormInputStyle({value:formData.diagnosisAcetabulumLeftBothColumn, canEdit})
                                }} />
                                <FormControlLabel control={
                                    <Checkbox checked={ formData.diagnosisAcetabulumLeftPosteriorColumnWithPosteriorWall  ? formData.diagnosisAcetabulumLeftPosteriorColumnWithPosteriorWall : false } onChange={updateFormData} name="diagnosisAcetabulumLeftPosteriorColumnWithPosteriorWall" />
                                } label="Posterior column with Posterior wall" sx={{
                                    ...hiddenFormInputStyle({value:formData.diagnosisAcetabulumLeftPosteriorColumnWithPosteriorWall, canEdit})
                                }} />
                                <FormControlLabel control={
                                    <Checkbox checked={ formData.diagnosisAcetabulumLeftTShaped  ? formData.diagnosisAcetabulumLeftTShaped : false } onChange={updateFormData} name="diagnosisAcetabulumLeftTShaped" />
                                } label="T-shaped" sx={{
                                    ...hiddenFormInputStyle({value:formData.diagnosisAcetabulumLeftTShaped, canEdit})
                                }} />
                                <FormControlLabel control={
                                    <Checkbox checked={ formData.diagnosisAcetabulumLeftTransverseWithPosteriorWall  ? formData.diagnosisAcetabulumLeftTransverseWithPosteriorWall : false } onChange={updateFormData} name="diagnosisAcetabulumLeftTransverseWithPosteriorWall" />
                                } label="Transverse with Posterior wall" sx={{
                                    ...hiddenFormInputStyle({value:formData.diagnosisAcetabulumLeftTransverseWithPosteriorWall, canEdit})
                                }} />
                                <FormControlLabel control={
                                    <Checkbox checked={ formData.diagnosisAcetabulumLeftAnteriorColumnWithPosteriorHemiTransverse  ? formData.diagnosisAcetabulumLeftAnteriorColumnWithPosteriorHemiTransverse : false } onChange={updateFormData} name="diagnosisAcetabulumLeftAnteriorColumnWithPosteriorHemiTransverse" />
                                } label="Anterior column with Posterior Hemi transverse" sx={{
                                    ...hiddenFormInputStyle({value:formData.diagnosisAcetabulumLeftAnteriorColumnWithPosteriorHemiTransverse, canEdit})
                                }} />

                        <Divider></Divider>
                        </FormGroup>
                    </FormControl>
                </Grid>                      
                <Grid item xs={12} md={12} sx={{
                                ...hiddenFormInputStyle({value:formData.diagnosisAcetabulumRight, canEdit})
                }}>
                    <FormControl sx={{ml:4}}>
                        <FormGroup>
                            <FormControlLabel control={
                                <Checkbox checked={ formData.diagnosisAcetabulumRight ? formData.diagnosisAcetabulumRight : false } onChange={updateFormData} name="diagnosisAcetabulumRight" />
                            } label="Right" />
                        </FormGroup>
                    </FormControl>
                </Grid>     
                <Grid item xs={12} md={12} sx={{
                    ...hiddenFormInputStyle({
                        value: (
                            formData.diagnosisAcetabulumRightPosteriorWall || 
                            formData.diagnosisAcetabulumRightPosteriorColumn || 
                            formData.diagnosisAcetabulumRightTransverse || 
                            formData.diagnosisAcetabulumRightAnteriorWall || 
                            formData.diagnosisAcetabulumRightAnteriorColumn || 
                            formData.diagnosisAcetabulumRightBothColumn || 
                            formData.diagnosisAcetabulumRightPosteriorColumnWithPosteriorWall || 
                            formData.diagnosisAcetabulumRightTShaped || 
                            formData.diagnosisAcetabulumRightTransverseWithPosteriorWall || 
                            formData.diagnosisAcetabulumRightAnteriorColumnWithPosteriorHemiTransverse 
                        ), 
                        canEdit
                    })
                }}>
                    <FormControl sx={{ml: 6}}>
                        <FormGroup sx={{display:'inline'}}>
                                <FormControlLabel control={
                                    <Checkbox checked={ formData.diagnosisAcetabulumRightPosteriorWall ? formData.diagnosisAcetabulumRightPosteriorWall : false } onChange={updateFormData} name="diagnosisAcetabulumRightPosteriorWall" />
                                } label="Posterior wall" sx={{
                                    ...hiddenFormInputStyle({value:formData.diagnosisAcetabulumRightPosteriorWall, canEdit})
                                }} />
                                <FormControlLabel control={
                                    <Checkbox checked={ formData.diagnosisAcetabulumRightPosteriorColumn ? formData.diagnosisAcetabulumRightPosteriorColumn : false } onChange={updateFormData} name="diagnosisAcetabulumRightPosteriorColumn" />
                                } label="Posterior column" sx={{
                                    ...hiddenFormInputStyle({value:formData.diagnosisAcetabulumRightPosteriorColumn, canEdit})
                                }} />
                                <FormControlLabel control={
                                    <Checkbox checked={ formData.diagnosisAcetabulumRightTransverse ? formData.diagnosisAcetabulumRightTransverse : false } onChange={updateFormData} name="diagnosisAcetabulumRightTransverse" />
                                } label="Transverse" sx={{
                                    ...hiddenFormInputStyle({value:formData.diagnosisAcetabulumRightTransverse, canEdit})
                                }} />
                                <FormControlLabel control={
                                    <Checkbox checked={ formData.diagnosisAcetabulumRightAnteriorWall  ? formData.diagnosisAcetabulumRightAnteriorWall : false } onChange={updateFormData} name="diagnosisAcetabulumRightAnteriorWall" />
                                } label="Anterior wall" sx={{
                                    ...hiddenFormInputStyle({value:formData.diagnosisAcetabulumRightAnteriorWall, canEdit})
                                }} />
                                <FormControlLabel control={
                                    <Checkbox checked={ formData.diagnosisAcetabulumRightAnteriorColumn  ? formData.diagnosisAcetabulumRightAnteriorColumn : false } onChange={updateFormData} name="diagnosisAcetabulumRightAnteriorColumn" />
                                } label="Anterior column" sx={{
                                    ...hiddenFormInputStyle({value:formData.diagnosisAcetabulumRightAnteriorColumn, canEdit})
                                }} />
                                <FormControlLabel control={
                                    <Checkbox checked={ formData.diagnosisAcetabulumRightBothColumn  ? formData.diagnosisAcetabulumRightBothColumn : false } onChange={updateFormData} name="diagnosisAcetabulumRightBothColumn" />
                                } label="Both column" sx={{
                                    ...hiddenFormInputStyle({value:formData.diagnosisAcetabulumRightBothColumn, canEdit})
                                }} />
                                <FormControlLabel control={
                                    <Checkbox checked={ formData.diagnosisAcetabulumRightPosteriorColumnWithPosteriorWall  ? formData.diagnosisAcetabulumRightPosteriorColumnWithPosteriorWall : false } onChange={updateFormData} name="diagnosisAcetabulumRightPosteriorColumnWithPosteriorWall" />
                                } label="Posterior column with Posterior wall" sx={{
                                    ...hiddenFormInputStyle({value:formData.diagnosisAcetabulumRightPosteriorColumnWithPosteriorWall, canEdit})
                                }} />
                                <FormControlLabel control={
                                    <Checkbox checked={ formData.diagnosisAcetabulumRightTShaped  ? formData.diagnosisAcetabulumRightTShaped : false } onChange={updateFormData} name="diagnosisAcetabulumRightTShaped" />
                                } label="T-shaped" sx={{
                                    ...hiddenFormInputStyle({value:formData.diagnosisAcetabulumRightTShaped, canEdit})
                                }} />
                                <FormControlLabel control={
                                    <Checkbox checked={ formData.diagnosisAcetabulumRightTransverseWithPosteriorWall  ? formData.diagnosisAcetabulumRightTransverseWithPosteriorWall : false } onChange={updateFormData} name="diagnosisAcetabulumRightTransverseWithPosteriorWall" />
                                } label="Transverse with Posterior wall" sx={{
                                    ...hiddenFormInputStyle({value:formData.diagnosisAcetabulumRightTransverseWithPosteriorWall, canEdit})
                                }} />
                                <FormControlLabel control={
                                    <Checkbox checked={ formData.diagnosisAcetabulumRightAnteriorColumnWithPosteriorHemiTransverse  ? formData.diagnosisAcetabulumRightAnteriorColumnWithPosteriorHemiTransverse : false } onChange={updateFormData} name="diagnosisAcetabulumRightAnteriorColumnWithPosteriorHemiTransverse" />
                                } label="Anterior column with Posterior Hemi transverse" sx={{
                                    ...hiddenFormInputStyle({value:formData.diagnosisAcetabulumRightAnteriorColumnWithPosteriorHemiTransverse, canEdit})
                                }} />
                        <Divider></Divider>
                        </FormGroup>
                    </FormControl>
                </Grid>                           
            </Grid>
            <Grid container spacing={0}>
                <Grid item xs={12} md={12}>
                    <FormLabel error={ errors.hipDislocation ? true: false } id="hip-dislocation-label">Hip Dislocation*</FormLabel>
                </Grid>
                <Grid item xs={12} md={12} sx={{ml:2}}>
                    <FormControl>
                        <RadioGroup
                            row
                            value={ formData.hipDislocation ? formData.hipDislocation : null }
                            aria-label='hip-dislocation-label'
                            onChange={updateFormData}
                            name="hipDislocation"
                        >
                            <FormControlLabel 
                                value="no" 
                                label="No" 
                                control={<Radio />} 
                                sx={{
                                    ...hiddenFormInputStyle({value:formData.hipDislocation ==='no' , canEdit})
                                }}
                            />
                            <FormControlLabel 
                                value="yes" 
                                label="Yes" 
                                control={<Radio />}  
                                sx={{
                                    ...hiddenFormInputStyle({value:formData.hipDislocation ==='yes' , canEdit})
                                }}
                            />
                        </RadioGroup>
                    </FormControl>
                </Grid>
            </Grid>
            <Grid container spacing={0}>
                <Grid item xs={12} md={12}>
                    <FormLabel error={ errors.femoralHeadFracture ? true: false } id="femoral-head-fracture-label">Femoral head fracture*</FormLabel>
                </Grid>
                <Grid item xs={12} md={12} sx={{ml:2}}>
                    <FormControl>
                        <RadioGroup
                            row
                            value={ formData.femoralHeadFracture ? formData.femoralHeadFracture : null }
                            aria-label='femoral-head-fracture-label'
                            onChange={updateFormData}
                            name="femoralHeadFracture"
                        >
                            <FormControlLabel 
                                value="no" 
                                label="No" 
                                control={<Radio />} 
                                sx={{
                                    ...hiddenFormInputStyle({value:formData.hipDislocation === 'no' , canEdit})
                                }}
                            />
                            <FormControlLabel 
                                value="yes" 
                                label="Yes" 
                                control={<Radio />} 
                                sx={{
                                    ...hiddenFormInputStyle({value:formData.hipDislocation === 'yes' , canEdit})
                                }}
                            />
                            
                        </RadioGroup>
                    </FormControl>
                </Grid>
            </Grid>
        </React.Fragment>
     );
});
 
export default Diagnosis;