export const AUTH_START = "auth_start"
export const AUTH_SUCCESS = "auth_success"
export const AUTH_FAIL = "auth_fail"
export const AUTH_LOGOUT = "auth_logout"
export const COMMENTS = "comments"
export const COMMENT = "comment"
export const UPDATE_COMMENT = "update_comment"
export const DELETE_COMMENT = "delete_comment"
export const TEMPLATE_TYPE_CONSULTATION = 'consultation_form'
export const NOTIFICATIONS = 'notifications'
export const UPDATE_NOTIFICATION = 'update_notification'
export const DELETE_NOTIFICATION = 'delete_notification'
