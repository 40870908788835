
import { Box, Button, Card, CardHeader, TextField, Typography } from "@mui/material";
import Moment from 'moment';
import React, { useEffect, useState } from "react";
import axiosInstance from "../../common/axios";
import { useNavigate } from "react-router-dom";
import ImageDialog from "../common/ImageDialog";
import ImageList from "../common/ImageList";
import FileList from "../common/FileList";
import VideoList from "../common/VideoList";
import fileDownload from 'js-file-download';
import axios from 'axios';


const NormalCommentCard = ({index,comment,editCommentHandle}) => {
    const navigate = useNavigate();
    const [ showImageDialog, setShowImageDialog ] =  useState(false);
    const [ showEditBtn, setShowEditBtn ] = useState(false)
    const [ imageIndex, setImageIndex ] =  useState(0);
    const [ imageFiles, setImageFiles ] = useState( [] )
    const [ videoFiles, setVideoFiles ] = useState([])
    const [ otherFiles, setOtherFiles ] = useState([] )

    const closeImage = ( ) =>{
        setShowImageDialog(false)
    }

    const imageClick = (index) => {
        setImageIndex(index)
        setShowImageDialog(true)
    }

    const downloadFile = (fileObject) => {
        axiosInstance.get(`/topic/topic/${comment.topic.id}/`).then((res)=>{
            let token = localStorage.getItem('token') ? JSON.parse(localStorage.getItem('token')).access : null; 
            axios.get(`${process.env.REACT_APP_BACKEND_URL}/topic/get-comment-file/${fileObject.id}/${token}`,{
                responseType: 'blob',
            }).then((res) => {
                fileDownload(res.data, fileObject.name)
            })
        }).catch((error)=>{
            navigate('/')
        });

    }

    useEffect(()=>{
        let token = localStorage.getItem('token') ? JSON.parse(localStorage.getItem('token')).access : null ;
        if (token) {
            const tokenParts = JSON.parse(atob(token.split('.')[1]));
            setShowEditBtn(tokenParts.user_id === comment.author.id )
        }
        setImageFiles(comment.files.filter( (file) => file.type === 'image' )) 
        setVideoFiles(comment.files.filter( (file) => file.type === 'video'  )) 
        setOtherFiles( comment.files.filter( (file) => file.type === 'other'  ))
    },[comment])

    return ( 
        <Card sx={{mt:3}} elevation={1}>
            <CardHeader 
                action={ showEditBtn? <Button variant="outlined" size="small" onClick={()=>{ editCommentHandle(comment) }}>Edit</Button> : null } 
                subheader={ `Comment #${index+1} created on ${Moment(comment.created).format('MMMM Do YYYY, h:mm:ss a')} by ${comment.author.first_name} ${comment.author.last_name}` } 
            />
            <Box sx={{ padding: 2 }}>
                <TextField multiline fullWidth inputProps={{readOnly:true}} value={comment.content} />
                <ImageList imageFiles={imageFiles} imageClick={imageClick} />
                <VideoList videoFiles={videoFiles} />
                <FileList otherFiles={otherFiles} downloadFile={downloadFile} /> 
            </Box>
            <ImageDialog showImageDialog={showImageDialog} closeImage={closeImage} imageIndex={imageIndex} setImageIndex={setImageIndex}  imageFiles={imageFiles} downloadFile={downloadFile} />
        </Card>
     );
}
 
export default NormalCommentCard;