import { Divider } from '@mui/material';
import React, { useEffect, useState, useReducer, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import CommentCard from '../components/comment/CommentCard';
import Topic from '../components/topic/Topic';
import { default as commentReducer } from '../store/reducer/comment';
import WebSocketInstance from '../components/topic/Websocket';
import axiosInstance from '../common/axios';
import EditComment from '../components/comment/EditComment';
import { TOPIC_STATUS_PROCESS } from '../config/form';
import NewComment from '../components/comment/NewComment';
import Loading from '../components/Loading';

const TopicRoom = () => {
    const newCommentRef = useRef();
    const [ showEditCommentDialog, setShowEditCommentDialog ] =  useState(false);
    const [ commentEdit, setCommentEdit ] = useState(null)
    const [ comments, commentDispatch ] = useReducer(commentReducer,[])
    const { topicId } = useParams();
    const [ topic, setTopic ] = useState(false);
    const [ showComment, setShowComment ] = useState(false);
    const [ loading, setLoading ] = useState(false);
    const navigate = useNavigate();

    const editCommentHandle = (comment) => {
        let token = localStorage.getItem('token') ? JSON.parse(localStorage.getItem('token')).access : null ;
        if (token) {
            const tokenParts = JSON.parse(atob(token.split('.')[1]));
            setShowEditCommentDialog(tokenParts.user_id === comment.author.id )
            setCommentEdit(comment)
        }
    }

    const verifyUserPermission = (callback) => {
        axiosInstance.get(`/topic/topic/${topicId}/`).then((res)=>{
            callback(true)
        }).catch((error)=>{
            callback(false)
        });
    }

    const getTopicDetail = async (callback) =>{
        axiosInstance.get(`/topic/topic/${topicId}/`).then((res)=>{
            setTopic(res.data)
            callback(res.data)
        }).catch((error)=>{
            navigate('/')
        });
    }

    useEffect(() => {
        getTopicDetail((topicDetail)=>{
            if(topicDetail.status === TOPIC_STATUS_PROCESS){
                setShowComment(true)
                WebSocketInstance.setVerifyUserPermission( verifyUserPermission )
                WebSocketInstance.addCommentDispatch( async (data)=>{
                    commentDispatch(data);
                });
                WebSocketInstance.connect(topicId);
            }else{
                setShowComment(false)
            }
        });
        return () => {
            WebSocketInstance.close();
         }
    }, [ ])// eslint-disable-line react-hooks/exhaustive-deps


    const sendComment = async (comment) => {
        WebSocketInstance.send(comment);
    }

    const refreshCommentTypeInNewComment = () => {
       newCommentRef.current.refreshCommentType()
    } 

    return ( 
     
        <React.Fragment>
            <Topic topic={topic} />
            { showComment && (
                <React.Fragment>
                    { comments.length > 0 && <Divider sx={{mt:3}}> { comments.filter((comment) => {return comment.topic.id === parseInt(topicId) } ).length } Comments</Divider>}
                    { comments.filter(
                            (comment) => {return comment.topic.id ===  parseInt(topicId) }
                        ).map((comment,index)=> <CommentCard index={index} sendComment={ sendComment } key={comment.id} comment={comment} editCommentHandle={editCommentHandle} /> ) }
                    <Divider sx={{mt:3}}>New comment</Divider>
                    <NewComment ref={newCommentRef} sendComment={ sendComment } topic={topic} />
                    <EditComment refreshCommentTypeInNewComment={refreshCommentTypeInNewComment} showEditCommentDialog={showEditCommentDialog} setShowEditCommentDialog={setShowEditCommentDialog} sendComment={sendComment} comment = {commentEdit} />
                </React.Fragment>
            )}
            <Loading loading={loading} />
        </React.Fragment>
  
    );
}
 
export default TopicRoom;