import React, { useState, forwardRef, useImperativeHandle, useContext } from "react";
import { Grid, FormLabel, TextField, Paper, TableContainer, Table, TableHead, TableRow, TableCell, TableBody } from '@mui/material';
import { verifyRequireField, updateFormDataWithPattern } from "../../../utils";
import { REG_NUMBER_WITHOUT_DEC } from "../../../../../config/format";
import { ClassesContext } from "../../../../../contexts/Classes";

const MotorPower = forwardRef( ({canEdit},ref) => {
    const [ errors, setErrors ] = useState({});
    const [ hipAdductionRight, setHipAdductionRight ] = useState('');
    const [ hipAdductionLeft, setHipAdductionLeft ] = useState('');
    const [ hipAdductionNote, setHipAdductionNote ] = useState('');
    const [ hipAbductionRight, setHipAbductionRight ] = useState('');
    const [ hipAbductionLeft, setHipAbductionLeft ] = useState('');
    const [ hipAbductionNote, setHipAbductionNote ] = useState('');
    const [ hipFlexorRight, setHipFlexorRight ] = useState('');
    const [ hipFlexorLeft, setHipFlexorLeft ] = useState('');
    const [ hipFlexorNote, setHipFlexorNote ] = useState('');
    const { hiddenFormInputStyle } = useContext(ClassesContext);

    useImperativeHandle(ref,()=>({
        setData(data){
            setHipAdductionRight( data ? data.hipAdductionRight : '' )
            setHipAdductionLeft( data ? data.hipAdductionLeft : '' )
            setHipAdductionNote( data ? data.hipAdductionNote : '' )
            setHipAbductionRight( data ? data.hipAdductionRight : '' )
            setHipAbductionLeft( data ? data.hipAdductionLeft : '' )
            setHipAbductionNote( data ? data.hipAdductionNote : '' )
            setHipFlexorRight( data ? data.hipFlexorRight : '' )
            setHipFlexorLeft( data ? data.hipFlexorLeft : '' )
            setHipFlexorNote( data ? data.hipFlexorNote : '' )
        },
        verifyData(){
            let requireFields = [
                { field: 'hipAdductionRight' ,errorName: 'hipAdductionRight' },
                { field: 'hipAdductionLeft' ,errorName: 'hipAdductionLeft' },
                { field: 'hipAdductionNote' ,errorName: 'hipAdductionNote' },
                { field: 'hipAbductionRight' ,errorName: 'hipAbductionRight' },
                { field: 'hipAbductionLeft' ,errorName: 'hipAbductionLeft' },
                { field: 'hipAbductionNote' ,errorName: 'hipAbductionNote' },
                { field: 'hipFlexorRight' ,errorName: 'hipFlexorRight' },
                { field: 'hipFlexorLeft' ,errorName: 'hipFlexorLeft' },
                { field: 'hipFlexorNote' ,errorName: 'hipFlexorNote' },
            ]
            let {isRequireFieldsValid,errorObject} = verifyRequireField(requireFields,getFormData());
            setErrors({ ...errorObject });
            return isRequireFieldsValid
        },
        getData(){
            return getFormData()
        }
    }))

    const getFormData = () => {
        return {
            hipAdductionRight,
            hipAdductionLeft,
            hipAdductionNote,
            hipAbductionRight,
            hipAbductionLeft,
            hipAbductionNote,
            hipFlexorRight,
            hipFlexorLeft,
            hipFlexorNote,
        }
    }

    return ( 
        <Grid container spacing={1} sx={{mt:1}}>
            <Grid item xs={12} md={12}>
                <FormLabel error={ errors.motorPower ? true: false } >1. Motor power*</FormLabel>
            </Grid>
            <Grid item xs={12} md={12} sx={{ml:2}}>
                
                <TableContainer component={Paper} >
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell> Motor (Gr.) </TableCell>
                                <TableCell> Right </TableCell>
                                <TableCell> Left </TableCell>
                                <TableCell> Note </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell>
                                    Hip adduction
                                </TableCell>
                                <TableCell>
                                    <TextField 
                                            fullWidth
                                            multiline
                                            sx={{
                                                ...hiddenFormInputStyle({value: hipAdductionRight, canEdit}),
                                                resize: "both"
                                            }}                                            
                                            size="small" 
                                            value={hipAdductionRight}
                                            error={errors.hipAdductionRight ? true : false}
                                            onChange={e=>{
                                                updateFormDataWithPattern(e.target.value,REG_NUMBER_WITHOUT_DEC,setHipAdductionRight)
                                            }}
                                            label="Right"
                                        />
                                </TableCell>
                                <TableCell>
                                    <TextField 
                                            fullWidth
                                            multiline
                                            sx={{
                                                ...hiddenFormInputStyle({value: hipAdductionLeft, canEdit}),
                                                resize: "both"
                                            }}
                                            size="small" 
                                            value={hipAdductionLeft}
                                            error={errors.hipAdductionLeft ? true : false}
                                            onChange={e=>{
                                                updateFormDataWithPattern(e.target.value,REG_NUMBER_WITHOUT_DEC,setHipAdductionLeft)
                                            }}
                                            label="Left"
                                        />
                                </TableCell>
                                <TableCell>
                                    <TextField 
                                            fullWidth
                                            multiline
                                            sx={{
                                                ...hiddenFormInputStyle({value: hipAdductionNote, canEdit}),
                                                resize: "both"
                                            }}
                                            size="small" 
                                            value={hipAdductionNote}
                                            error={errors.hipAdductionNote ? true : false}
                                            onChange={e=>setHipAdductionNote(e.target.value)}
                                            label="Note"
                                        />
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    Hip abduction
                                </TableCell>
                                <TableCell>
                                    <TextField 
                                            fullWidth
                                            multiline
                                            sx={{
                                                ...hiddenFormInputStyle({value: hipAbductionRight, canEdit}),
                                                resize: "both"
                                            }}
                                            size="small" 
                                            value={hipAbductionRight}
                                            error={errors.hipAbductionRight ? true : false}
                                            onChange={e=>{
                                                updateFormDataWithPattern(e.target.value,REG_NUMBER_WITHOUT_DEC,setHipAbductionRight)
                                            }}
                                            label="Right"
                                        />
                                </TableCell>
                                <TableCell>
                                    <TextField 
                                            fullWidth
                                            multiline
                                            sx={{
                                                ...hiddenFormInputStyle({value: hipAbductionLeft, canEdit}),
                                                resize: "both"
                                            }}
                                            size="small" 
                                            value={hipAbductionLeft}
                                            error={errors.hipAbductionLeft ? true : false}
                                            onChange={e=>{
                                                updateFormDataWithPattern(e.target.value,REG_NUMBER_WITHOUT_DEC,setHipAbductionLeft)
                                            }}
                                            label="Left"
                                        />
                                </TableCell>
                                <TableCell>
                                    <TextField 
                                            fullWidth
                                            multiline
                                            sx={{
                                                ...hiddenFormInputStyle({value: hipAbductionNote, canEdit}),
                                                resize: "both"
                                            }}
                                            size="small" 
                                            value={hipAbductionNote}
                                            error={errors.hipAbductionNote ? true : false}
                                            onChange={e=>setHipAbductionNote(e.target.value)}
                                            label="Note"
                                        />
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    Hip flexor
                                </TableCell>
                                <TableCell>
                                    <TextField 
                                            fullWidth
                                            multiline
                                            sx={{
                                                ...hiddenFormInputStyle({value: hipFlexorRight, canEdit}),
                                                resize: "both"
                                            }}
                                            size="small" 
                                            value={hipFlexorRight}
                                            error={errors.hipFlexorRight ? true : false}
                                            onChange={e=>{
                                                updateFormDataWithPattern(e.target.value,REG_NUMBER_WITHOUT_DEC,setHipFlexorRight)
                                            }}
                                            label="Right"
                                        />
                                </TableCell>
                                <TableCell>
                                    <TextField 
                                            fullWidth
                                            multiline
                                            sx={{
                                                ...hiddenFormInputStyle({value: hipFlexorLeft, canEdit}),
                                                resize: "both"
                                            }}
                                            size="small" 
                                            value={hipFlexorLeft}
                                            error={errors.hipFlexorLeft ? true : false}
                                            onChange={e=>{
                                                updateFormDataWithPattern(e.target.value,REG_NUMBER_WITHOUT_DEC,setHipFlexorLeft)
                                            }}
                                            label="Left"
                                        />
                                </TableCell>
                                <TableCell>
                                    <TextField 
                                            fullWidth
                                            multiline
                                            sx={{
                                                ...hiddenFormInputStyle({value: hipFlexorNote, canEdit}),
                                                resize: "both"
                                            }}
                                            size="small" 
                                            value={hipFlexorNote}
                                            error={errors.hipFlexorNote ? true : false}
                                            onChange={e=>setHipFlexorNote(e.target.value)}
                                            label="Note"
                                        />
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>

                </TableContainer>
            </Grid>
        </Grid>
     );
});
 
export default MotorPower;