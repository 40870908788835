export const getDataUpdate = (e) => {
    let dataUpdate = {};
    switch (e.target.type) {
        case 'checkbox':
            dataUpdate[e.target.name] = e.target.checked
            break;
        default:
            dataUpdate[e.target.name] = e.target.value
            break;
    }  
    return dataUpdate
}

export const checkData = (data,formData) => {
    var valid = null;
    switch (data.condition) {
        case 'length':
            valid = false
            if(formData[data.field]){
                if( data.conditionForCompare === 'gt' && formData[data.field].length > data.valueForCompare  ){
                    valid = true
                }
            }
            return valid
        case 'compareValue':
            valid = false;
            if(formData[data.field]){
                if(formData[data.field] === data.valueForCompare){
                    valid = true
                }
            }
            return valid
        case 'and':
            valid = true;
            for(let i=0; i<data.fields.length ;i++ ){
                if( !formData[data.fields[i]] ){
                    valid = false
                }
            }
            return valid
        case 'or':
            valid = false;
            for(let i=0; i<data.fields.length;i++ ){
                if( formData[data.fields[i]] ){
                    valid = true
                }
            }
            return valid
        default:
            return formData[data.field] ? true : false
    }
}

export const verifyRequireField = (requireFields,formData) =>{
    let isRequireFieldsValid = true;
    let errorObject = {};
    for( let i = 0; i < requireFields.length ; i++ ){
        let requiredField = requireFields[i];
        let fieldValid = null
        if( requiredField.parent ){
            fieldValid =  checkData(requiredField.parent,formData) ? checkData(requiredField,formData) : true;
        }else{
            fieldValid = checkData(requiredField,formData);   
        }
        isRequireFieldsValid = isRequireFieldsValid && fieldValid
        errorObject[requiredField.errorName] = fieldValid ? false : true;
    }
    return {isRequireFieldsValid,errorObject}
}

export const updateFormDataWithPattern = (value,dataPattern,setData) => {
    let pattern = new RegExp(dataPattern, 'g');
    let isValidPattern = pattern.test(value)
    if( isValidPattern ){
        setData(value)
    }
}

export const checkDataPattern = (value,dataPattern) => {
    let pattern = new RegExp(dataPattern, 'g');
    let isValidPattern = pattern.test(value)
    if( isValidPattern ){
        return value
    }else{
        return null
    }
}
