import React, { useState, forwardRef, useImperativeHandle, useContext } from "react";
import { Grid, FormLabel, TextField, FormControl, InputAdornment, FormGroup, FormControlLabel, Checkbox } from '@mui/material';
import { verifyRequireField } from "../../../utils";
import { ClassesContext } from "../../../../../contexts/Classes";

const PatientReportedOutcomeScore = forwardRef( ({canEdit},ref) => {
    const [ errors, setErrors ] = useState({});
    const [ painScore, setPainScore ] = useState('');
    const [ EQ5D5L, setEQ5D5L ] = useState('');
    const [ unableToPerformTheTestDueTo, setUnableToPerformTheTestDueTo ] = useState(false);
    const [ anAssistiveDeviceUsedNo, setAnAssistiveDeviceUsedNo ] = useState(false);
    const [ anAssistiveDeviceUsedAxillaryCrutches, setAnAssistiveDeviceUsedAxillaryCrutches ] = useState(false);
    const [ anAssistiveDeviceUsedForearmCrutches, setAnAssistiveDeviceUsedForearmCrutches ] = useState(false);
    const [ anAssistiveDeviceUsedCane, setAnAssistiveDeviceUsedCane ] = useState(false);
    const [ anAssistiveDeviceUsedOther, setAnAssistiveDeviceUsedOther ] = useState(false);
    const [ anAssistiveDeviceUsedOtherText, setAnAssistiveDeviceUsedOtherText ] = useState('');
    const [ unableToPerformTheTestDueToText, setUnableToPerformTheTestDueToText ] = useState('');
    const [ averageSelfSelectedVelocity, setAverageSelfSelectedVelocity ] = useState('');
    const { hiddenFormInputStyle } = useContext(ClassesContext);

    useImperativeHandle(ref,()=>({
        setData(data){
            if(data){            
                setPainScore( data.painScore ? data.painScore : '' )
                setEQ5D5L( data.EQ5D5L ? data.EQ5D5L : '' )
                setUnableToPerformTheTestDueTo( data.unableToPerformTheTestDueTo ? data.unableToPerformTheTestDueTo : false )
                setAnAssistiveDeviceUsedNo( data.anAssistiveDeviceUsedNo ? data.anAssistiveDeviceUsedNo : false )
                setAnAssistiveDeviceUsedAxillaryCrutches( data.anAssistiveDeviceUsedAxillaryCrutches ? data.anAssistiveDeviceUsedAxillaryCrutches : false )
                setAnAssistiveDeviceUsedForearmCrutches( data.anAssistiveDeviceUsedForearmCrutches ? data.anAssistiveDeviceUsedForearmCrutches : false )
                setAnAssistiveDeviceUsedCane( data.anAssistiveDeviceUsedCane ? data.anAssistiveDeviceUsedCane : false )
                setAnAssistiveDeviceUsedOther( data.anAssistiveDeviceUsedOther ? data.anAssistiveDeviceUsedOther : false )
                setAnAssistiveDeviceUsedOtherText( data.anAssistiveDeviceUsedOtherText ? data.anAssistiveDeviceUsedOtherText : '' )
                setAverageSelfSelectedVelocity( data.averageSelfSelectedVelocity ? data.averageSelfSelectedVelocity : '' )
                setUnableToPerformTheTestDueToText( data.unableToPerformTheTestDueToText ? data.unableToPerformTheTestDueToText : '' )
            }
        },
        verifyData(){
            let requireFields = [
                { field: 'painScore' ,errorName: 'painScore' },
                { field: 'EQ5D5L' ,errorName: 'EQ5D5L' },
            ]
            let {isRequireFieldsValid,errorObject} = verifyRequireField(requireFields,getFormData());
            setErrors({ ...errorObject });
            return isRequireFieldsValid
        },
        getData(){
            return getFormData()
        }
    }))

    const getFormData = () => {
        return {
            painScore,
            unableToPerformTheTestDueTo,
            anAssistiveDeviceUsedNo,
            anAssistiveDeviceUsedAxillaryCrutches,
            anAssistiveDeviceUsedForearmCrutches,
            anAssistiveDeviceUsedCane,
            anAssistiveDeviceUsedOther,
            averageSelfSelectedVelocity,
            anAssistiveDeviceUsedOtherText,
            unableToPerformTheTestDueToText,
            EQ5D5L
        }
    }

    return ( 
        <React.Fragment>        
            <Grid container>
                <Grid item xs={12} md={12}>
                    <FormControl>
                        <FormLabel error={ errors.patientReportedOutcomeScore ? true: false } >5. Patient-Reported Outcome Score: (at postoperative week 6, 12, 24, 52)*</FormLabel>
                    </FormControl>
                </Grid>
                <Grid item xs={12} md={12} sx={{mt:1,ml:2, ...hiddenFormInputStyle({value: painScore, canEdit}) }} >
                    <TextField 
                        label="Pain score (VAS)"
                        fullWidth
                        size="small" 
                        error={errors.painScore ? true : false }
                        value={ painScore }
                        onChange={ e=> setPainScore(e.target.value) }
                    />
                </Grid>
                <Grid item xs={12} md={12} sx={{mt:1,ml:2, ...hiddenFormInputStyle({value: EQ5D5L, canEdit})}}>
                    <TextField 
                        label="EQ-5D-5L: EQ-VAS"
                        fullWidth
                        size="small" 
                        error={errors.EQ5D5L ? true : false }
                        value={ EQ5D5L }
                        onChange={ e=> setEQ5D5L(e.target.value) }
                    />
                </Grid>
                <Grid item xs={12} md={12} sx={{mt:1, ml:2}}>
                    <FormControl>
                        <FormLabel error={ errors._10MWT ? true: false } >10MWT: (at postoperative week 12, 24, 52)</FormLabel>
                    </FormControl>
                </Grid>
                <Grid item xs={12} md={12} sx={{ml:4,  ...hiddenFormInputStyle({value: unableToPerformTheTestDueTo, canEdit})}}>
                    <FormControl>
                        <FormGroup>
                            <FormControlLabel control={
                                <Checkbox checked={ unableToPerformTheTestDueTo } onChange={ e=> setUnableToPerformTheTestDueTo(e.target.checked) } name="unableToPerformTheTestDueTo" />
                            } label="Unable to perform the test due to"/>
                        </FormGroup>
                    </FormControl>
                </Grid>
                { unableToPerformTheTestDueTo && (
                    <Grid item xs={12} md={12} sx={{...hiddenFormInputStyle({value: unableToPerformTheTestDueToText, canEdit}), ml:4}}>
                            <TextField 
                                value={ unableToPerformTheTestDueToText }
                                onChange={ (e)=> setUnableToPerformTheTestDueToText(e.target.value )} 
                                name="unableToPerformTheTestDueToText"
                                size="small"
                                fullWidth
                                multiline
                                sx={{
                                    resize: "both"
                                }}
                            />
                    </Grid>
                ) }
                <Grid item xs={12} md={12} sx={{ml:4, mt:1}}>
                    <FormControl>
                        <FormLabel error={ errors.anAssistiveDeviceUsed ? true: false } >An assistive device used</FormLabel>
                    </FormControl>
                </Grid>
                <Grid item xs={12} md={12} sx={{ml:6, ...hiddenFormInputStyle({value: anAssistiveDeviceUsedNo, canEdit}) }}>
                    <FormControl>
                        <FormGroup>
                            <FormControlLabel control={
                                <Checkbox checked={ anAssistiveDeviceUsedNo } onChange={ e=> setAnAssistiveDeviceUsedNo(e.target.checked) } name="anAssistiveDeviceUsedNo" />
                            } label="No" />
                        </FormGroup>
                    </FormControl>
                </Grid>
                <Grid item xs={12} md={12} sx={{ml:6,  ...hiddenFormInputStyle({value: anAssistiveDeviceUsedAxillaryCrutches, canEdit})}}>
                    <FormControl>
                        <FormGroup>
                            <FormControlLabel control={
                                <Checkbox checked={ anAssistiveDeviceUsedAxillaryCrutches } onChange={ e=> setAnAssistiveDeviceUsedAxillaryCrutches(e.target.checked) } name="anAssistiveDeviceUsedAxillaryCrutches" />
                            } label="Axillary crutches" />
                        </FormGroup>
                    </FormControl>
                </Grid>
                <Grid item xs={12} md={12} sx={{ml:6, ...hiddenFormInputStyle({value: anAssistiveDeviceUsedForearmCrutches, canEdit})}}>
                    <FormControl>
                        <FormGroup>
                            <FormControlLabel control={
                                <Checkbox checked={ anAssistiveDeviceUsedForearmCrutches } onChange={ e=> setAnAssistiveDeviceUsedForearmCrutches(e.target.checked) } name="anAssistiveDeviceUsedForearmCrutches" />
                            } label="Forearm crutches" />
                        </FormGroup>
                    </FormControl>
                </Grid>
                <Grid item xs={12} md={12} sx={{ml:6, ...hiddenFormInputStyle({value: anAssistiveDeviceUsedCane, canEdit})}}>
                    <FormControl>
                        <FormGroup>
                            <FormControlLabel control={
                                <Checkbox checked={ anAssistiveDeviceUsedCane } onChange={ e=> setAnAssistiveDeviceUsedCane(e.target.checked) } name="anAssistiveDeviceUsedCane" />
                            } label="Cane" />
                        </FormGroup>
                    </FormControl>
                </Grid>
                <Grid item xs={12} md={12} sx={{ml:6, ...hiddenFormInputStyle({value: anAssistiveDeviceUsedOther, canEdit})}}>
                    <FormControl>
                        <FormGroup>
                            <FormControlLabel control={
                                <Checkbox checked={ anAssistiveDeviceUsedOther } onChange={ e=> setAnAssistiveDeviceUsedOther(e.target.checked) } name="anAssistiveDeviceUsedOther" />
                            } label="Other"  />
                        </FormGroup>
                    </FormControl>
                </Grid>
                { anAssistiveDeviceUsedOther && (
                    <Grid item xs={12} md={12} sx={{...hiddenFormInputStyle({value: anAssistiveDeviceUsedOther, canEdit}),ml:6}}>
                        <TextField 
                            value={ anAssistiveDeviceUsedOtherText }
                            onChange={ (e)=> setAnAssistiveDeviceUsedOtherText(e.target.value )} 
                            name="anAssistiveDeviceUsedOtherText"
                            size="small"
                            fullWidth
                            label="Other"
                            multiline
                            sx={{
                                resize: "both"
                            }}
                        />
                    </Grid>
                )}

                <Grid item xs={12} md={12} sx={{ml:4,mt:1,...hiddenFormInputStyle({value: averageSelfSelectedVelocity, canEdit})}}>
                    <TextField 
                        label="Average Self-Selected Velocity"
                        fullWidth
                        size="small" 
                        error={errors.averageSelfSelectedVelocity ? true : false }
                        value={ averageSelfSelectedVelocity }
                        onChange={ e=> setAverageSelfSelectedVelocity(e.target.value) }
                        InputProps={{
                            endAdornment: <InputAdornment position="end">m/s</InputAdornment>,
                        }}
                        sx={{
                            resize: "both"
                        }}
                    />
                </Grid>
            </Grid>
        </React.Fragment>
     );
});
 
export default PatientReportedOutcomeScore;