import React, { useState, forwardRef, useImperativeHandle, useContext } from "react";
import { FormControlLabel, Grid, Checkbox, FormControl, FormLabel,RadioGroup,Radio, TextField, InputAdornment  } from '@mui/material';
import { checkDataPattern, verifyRequireField } from "../../../utils";
import { ClassesContext } from "../../../../../contexts/Classes";
import { REG_FLOAT_WITH_ONE_DEC } from "../../../../../config/format";

const RadioGraphicFindings = forwardRef( ({canEdit},ref) => {
    const [ errors, setErrors ] = useState({});
    const [ pelvicFracture, setPelvicFracture ] = useState(false);
    const [ acetabulumFracture, setAcetabulumFracture ] = useState(false);
    const [ otherXRaysCT, setOtherXRaysCT ] = useState(false);
    const [ otherXRaysCTText, setOtherXRaysCTText ] = useState('');
    const [ findings, setFindings ] = useState('');
    const [ apAndOutletViewsVerticalDisplacementOfHemipelvis, setAPAndOutletViewsVerticalDisplacementOfHemipelvis ] = useState(null);
    const [ inletViewAnteriorPosteriorDisplacementOfHemipelvis, setInletViewAnteriorPosteriorDisplacementOfHemipelvis ] = useState(null);
    const [ fractureGapAroundAcetabularJointSurface, setFractureGapAroundAcetabularJointSurface ] = useState(null);
    const [ fractureGapAroundAcetabularJointSurfaceYes, setFractureGapAroundAcetabularJointSurfaceYes ] = useState('');
    const [ signsOfImplantLooseningFailure, setSignsOfImplantLooseningFailure ] = useState(null);
    const [ signsOfImplantLooseningFailureYes, setSignsOfImplantLooseningFailureYes ] = useState('');
    const [ otherFractureOrImplantRelatedComplication, setOtherFractureOrImplantRelatedComplication ] = useState(null);
    const [ otherFractureOrImplantRelatedComplicationYes, setOtherFractureOrImplantRelatedComplicationYes ] = useState('');
    const [ stepAroundAcetabularJointSurface, setStepAroundAcetabularJointSurface ] = useState(null);
    const [ stepAroundAcetabularJointSurfaceYes, setStepAroundAcetabularJointSurfaceYes ] = useState('');
    const [ congruentReduction, setCongruentReduction ] = useState(null);
    const { hiddenFormInputStyle } = useContext(ClassesContext);

    useImperativeHandle(ref,()=>({
        setData(data){
            setPelvicFracture( data && data.pelvicFracture ? true : false);
            setAcetabulumFracture( data && data.acetabulumFracture ? true : false);
            setOtherXRaysCT( data && data.otherXRaysCT ? true : false);
            setOtherXRaysCTText( data && data.otherXRaysCTText ?  data.otherXRaysCTText : '');
            setFindings( data && data.findings ?  data.findings : '');
            setAPAndOutletViewsVerticalDisplacementOfHemipelvis(data && data.apAndOutletViewsVerticalDisplacementOfHemipelvis ? data.apAndOutletViewsVerticalDisplacementOfHemipelvis : null );
            setInletViewAnteriorPosteriorDisplacementOfHemipelvis(data && data.inletViewAnteriorPosteriorDisplacementOfHemipelvis ? data.inletViewAnteriorPosteriorDisplacementOfHemipelvis : null );
            setFractureGapAroundAcetabularJointSurface(data && data.fractureGapAroundAcetabularJointSurface ? data.fractureGapAroundAcetabularJointSurface : null );
            setFractureGapAroundAcetabularJointSurfaceYes(data && data.fractureGapAroundAcetabularJointSurfaceYes ? data.fractureGapAroundAcetabularJointSurfaceYes : '' );
            setSignsOfImplantLooseningFailure(data && data.signsOfImplantLooseningFailure ? data.signsOfImplantLooseningFailure : null );
            setSignsOfImplantLooseningFailureYes(data && data.signsOfImplantLooseningFailureYes ? data.signsOfImplantLooseningFailureYes : '' );
            setOtherFractureOrImplantRelatedComplication(data && data.otherFractureOrImplantRelatedComplication ? data.otherFractureOrImplantRelatedComplication : null );
            setOtherFractureOrImplantRelatedComplicationYes(data && data.otherFractureOrImplantRelatedComplicationYes ? data.otherFractureOrImplantRelatedComplicationYes : '' );
            setStepAroundAcetabularJointSurface(data && data.stepAroundAcetabularJointSurface ? data.stepAroundAcetabularJointSurface : null );
            setStepAroundAcetabularJointSurfaceYes(data && data.stepAroundAcetabularJointSurfaceYes ? data.stepAroundAcetabularJointSurfaceYes : '' );
            setCongruentReduction(data && data.congruentReduction ? data.congruentReduction : null );
        },
        verifyData(){
            let requireFields = [
                { field: 'signsOfImplantLooseningFailure' ,errorName: 'signsOfImplantLooseningFailure' },
                { field: 'otherFractureOrImplantRelatedComplication' ,errorName: 'otherFractureOrImplantRelatedComplication' },
                {   
                    errorName: 'radioGraphicFindings',
                    fields: [
                        'pelvicFracture', 
                        'acetabulumFracture',
                        'otherXRaysCT',
                    ] ,
                    condition: 'or'  ,
                },
            ]
            let {isRequireFieldsValid,errorObject} = verifyRequireField(requireFields,getFormData());
            setErrors({ ...errorObject });
            return isRequireFieldsValid
        },
        getData(){
            return getFormData()
        }
    }))

    const getFormData = () => {
        return {
            pelvicFracture,
            apAndOutletViewsVerticalDisplacementOfHemipelvis,
            inletViewAnteriorPosteriorDisplacementOfHemipelvis,
            acetabulumFracture,
            otherXRaysCT,
            otherXRaysCTText,
            findings,
            fractureGapAroundAcetabularJointSurface,
            fractureGapAroundAcetabularJointSurfaceYes,
            signsOfImplantLooseningFailure,
            signsOfImplantLooseningFailureYes,
            otherFractureOrImplantRelatedComplication,
            otherFractureOrImplantRelatedComplicationYes,
            stepAroundAcetabularJointSurface,
            stepAroundAcetabularJointSurfaceYes,
            congruentReduction
        }
    }


    return ( 
        <React.Fragment>
            <Grid container spacing={1} sx={{mt:1}}>
                <Grid item xs={12} md={12}>
                    <FormControl>
                        <FormLabel error={ errors.radioGraphicFindings ? true: false } >Radiographic findings*</FormLabel>
                    </FormControl>
                </Grid>
                <Grid item xs={12} md={12} sx={{ml:2,...hiddenFormInputStyle({value: pelvicFracture, canEdit}) }}>
                    <FormControlLabel control={ <Checkbox checked={ pelvicFracture } onChange={ e => setPelvicFracture(e.target.checked) } /> } label="Pelvic Fracture" />
                </Grid>
                <Grid item xs={12} md={12} sx={{ml:4,...hiddenFormInputStyle({value: pelvicFracture, canEdit})}}>
                    <FormLabel error={ errors.apAndOutletViewsVerticalDisplacementOfHemipelvis ? true: false } >AP and outlet views: Vertical displacement of hemipelvis</FormLabel>
                </Grid>
                <Grid item xs={12} md={12} sx={{
                    ml:6,
                    ...hiddenFormInputStyle({value: apAndOutletViewsVerticalDisplacementOfHemipelvis, canEdit})
                }}>
                    <FormControl>
                        <RadioGroup
                            row
                            value={ apAndOutletViewsVerticalDisplacementOfHemipelvis }
                            onChange={ e => setAPAndOutletViewsVerticalDisplacementOfHemipelvis(e.target.value) }
                            name="apAndOutletViewsVerticalDisplacementOfHemipelvis"
                        >
                            <FormControlLabel value="no" label="No (Both Ischium on the same level)" control={<Radio  />} sx={{
                                ...hiddenFormInputStyle({value: apAndOutletViewsVerticalDisplacementOfHemipelvis === 'no', canEdit})
                            }} />
                            <FormControlLabel value="yes" label="Yes (Ischium are on different levels)" control={<Radio />} sx={{
                                ...hiddenFormInputStyle({value: apAndOutletViewsVerticalDisplacementOfHemipelvis === 'yes', canEdit})
                            }} />
                        </RadioGroup>
                    </FormControl>
                </Grid>
                <Grid item xs={12} md={12} sx={{ml:4,...hiddenFormInputStyle({value: pelvicFracture, canEdit})}}>
                    <FormLabel error={ errors.inletViewAnteriorPosteriorDisplacementOfHemipelvis ? true: false } >Inlet view: Anterior/posterior displacement of hemipelvis</FormLabel>
                </Grid>
                <Grid item xs={12} md={12} sx={{
                    ml:6,
                    ...hiddenFormInputStyle({value: inletViewAnteriorPosteriorDisplacementOfHemipelvis, canEdit})
                }}>
                    <FormControl>
                        <RadioGroup
                            row
                            value={ inletViewAnteriorPosteriorDisplacementOfHemipelvis }
                            onChange={ e => setInletViewAnteriorPosteriorDisplacementOfHemipelvis(e.target.value) }
                            name="inletViewAnteriorPosteriorDisplacementOfHemipelvis"
                        >
                            <FormControlLabel value="no" label="No" control={<Radio  />} sx={{
                                ...hiddenFormInputStyle({value: inletViewAnteriorPosteriorDisplacementOfHemipelvis === 'no', canEdit})
                            }} />
                            <FormControlLabel value="yes" label="Yes" control={<Radio />} sx={{
                                ...hiddenFormInputStyle({value: inletViewAnteriorPosteriorDisplacementOfHemipelvis === 'yes', canEdit})
                            }} />
                        </RadioGroup>
                    </FormControl>
                </Grid>
                <Grid item xs={12} md={12} sx={{ml:2, ...hiddenFormInputStyle({value: acetabulumFracture, canEdit}) }}>
                    <FormControlLabel control={ <Checkbox checked={ acetabulumFracture } onChange={ e => setAcetabulumFracture(e.target.checked) } /> } label="Acetabulum Fracture : (X-ray: Pelvis AP, Judet views)"  />
                </Grid>
                <Grid item xs={12} md={12} sx={{ml:4, ...hiddenFormInputStyle({value: acetabulumFracture, canEdit}) }} >
                    <FormLabel error={ errors.fractureGapAroundAcetabularJointSurface ? true: false } >Fracture gap around acetabular joint surface</FormLabel>
                </Grid>
                <Grid item xs={12} md={12} sx={{
                    ml: 6,
                    ...hiddenFormInputStyle({value: fractureGapAroundAcetabularJointSurface, canEdit})
                }}>
                    <FormControl>
                        <RadioGroup
                            row
                            value={ fractureGapAroundAcetabularJointSurface }
                            onChange={ e => {
                                setFractureGapAroundAcetabularJointSurfaceYes('');
                                setFractureGapAroundAcetabularJointSurface(e.target.value);
                            } }
                            name="fractureGapAroundAcetabularJointSurface"
                        >
                            <FormControlLabel value="no" label="No visible gap" control={<Radio  />} sx={{
                                ...hiddenFormInputStyle({value: fractureGapAroundAcetabularJointSurface === 'no', canEdit})
                            }} />
                            <FormControlLabel value="yes" label="Yes" control={<Radio />} sx={{
                                ...hiddenFormInputStyle({value: fractureGapAroundAcetabularJointSurface === 'yes', canEdit})
                            }} />
                        </RadioGroup>
                        { fractureGapAroundAcetabularJointSurface === 'yes' && (
                            <TextField 
                                size="small" 
                                value={fractureGapAroundAcetabularJointSurfaceYes}
                                onChange={e=>{
                                    if( !e.target.value || checkDataPattern(e.target.value,REG_FLOAT_WITH_ONE_DEC)){
                                        setFractureGapAroundAcetabularJointSurfaceYes(e.target.value)
                                    }
                                }}
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">mm</InputAdornment>,
                                }}
                                sx={{
                                    ...hiddenFormInputStyle({value: fractureGapAroundAcetabularJointSurfaceYes, canEdit})
                                }}
                            />
                        ) }
                        
                    </FormControl>
                </Grid>
                <Grid item xs={12} md={12} sx={{ml: 4, ...hiddenFormInputStyle({value: acetabulumFracture, canEdit})}}>
                    <FormLabel error={ errors.stepAroundAcetabularJointSurface ? true: false } >Step around acetabular joint surface</FormLabel>
                </Grid>
                <Grid item xs={12} md={12} sx={{
                    ml: 6,
                    ...hiddenFormInputStyle({value: stepAroundAcetabularJointSurface, canEdit})
                }} >
                    <FormControl>
                        <RadioGroup
                            row
                            value={ stepAroundAcetabularJointSurface }
                            onChange={ e => {
                                setStepAroundAcetabularJointSurfaceYes('')
                                setStepAroundAcetabularJointSurface(e.target.value) 
                            }}
                            name="stepAroundAcetabularJointSurface"
                        >
                            <FormControlLabel value="no" label="No visible gap" control={<Radio  />} sx={{
                                    ...hiddenFormInputStyle({value: stepAroundAcetabularJointSurface === 'no', canEdit})
                            }} />
                            <FormControlLabel value="yes" label="Yes" control={<Radio />} sx={{
                                    ...hiddenFormInputStyle({value: stepAroundAcetabularJointSurface === 'yes', canEdit})
                            }} />
                        </RadioGroup>
                        { stepAroundAcetabularJointSurface === 'yes' && (
                            <TextField 
                                size="small" 
                                value={stepAroundAcetabularJointSurfaceYes}
                                onChange={ e=> {
                                    if( !e.target.value || checkDataPattern(e.target.value,REG_FLOAT_WITH_ONE_DEC)){
                                        setStepAroundAcetabularJointSurfaceYes(e.target.value) 
                                    }
                                    
                                }}
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">mm</InputAdornment>,
                                }}
                                sx={{
                                        ...hiddenFormInputStyle({value: stepAroundAcetabularJointSurfaceYes, canEdit})
                                }}
                            />
                        ) }
                        
                    </FormControl>
                </Grid>
                <Grid item xs={12} md={12} sx={{ml: 4, ...hiddenFormInputStyle({value: acetabulumFracture, canEdit}) }} >
                    <FormLabel error={ errors.congruentReduction ? true: false } >Congruent reduction</FormLabel>
                </Grid>
                <Grid item xs={12} md={12} sx={{
                    ml: 6,
                    ...hiddenFormInputStyle({value: congruentReduction, canEdit})
                }} >
                    <FormControl>
                        <RadioGroup
                            row
                            value={ congruentReduction }
                            onChange={ e => setCongruentReduction(e.target.value) }
                            name="congruentReduction"
                        >
                            <FormControlLabel value="no" label="No" control={<Radio  />} sx={{
                                ...hiddenFormInputStyle({value: congruentReduction === 'no', canEdit})
                            }} />
                            <FormControlLabel value="yes" label="Yes" control={<Radio />} sx={{
                                ...hiddenFormInputStyle({value: congruentReduction === 'yes', canEdit})
                            }} />
                        </RadioGroup>                        
                    </FormControl>
                </Grid>
                <Grid item xs={12} md={12} sx={{ml: 2}}>
                    <FormControlLabel 
                        control={ 
                            <Checkbox 
                                checked={ otherXRaysCT } 
                                onChange={ e => {
                                    setOtherXRaysCT(e.target.checked) 
                                    setFindings("");
                                    setOtherXRaysCTText('');
                                }} 
                            /> 
                        } 
                        label="Other x-rays / CT  (if any)" 
                        sx={{
                                ...hiddenFormInputStyle({value: otherXRaysCT, canEdit})
                        }} />
                </Grid>
                { otherXRaysCT && (
                    <React.Fragment>
                        <Grid item xs={12} md={12} sx={{ml: 4}}> 
                                <TextField 
                                    fullWidth
                                    multiline
                                    sx={{
                                        ...hiddenFormInputStyle({value: otherXRaysCTText, canEdit}),
                                        resize: "both"
                                    }}
                                    size="small" 
                                    value={otherXRaysCTText}
                                    onChange={e=>setOtherXRaysCTText(e.target.value)}
                                    label="Other"
                                />
                        </Grid>
                        <Grid item xs={12} md={12} sx={{ml: 4}}> 
                                <TextField 
                                    fullWidth
                                    multiline
                                    sx={{
                                        ...hiddenFormInputStyle({value: findings, canEdit}),
                                        resize: "both"
                                    }}
                                    size="small" 
                                    value={findings}
                                    onChange={e=>setFindings(e.target.value)}
                                    label="Findings"
                                />
                        </Grid>
                    </React.Fragment>
                ) }

                <Grid item xs={12} md={12}>
                    <FormLabel error={ errors.signsOfImplantLooseningFailure ? true: false } >Signs of implant loosening/failure*</FormLabel>
                </Grid>
                <Grid item xs={12} md={12} sx={{ml:2}}>
                    <FormControl>
                        <RadioGroup
                            row
                            value={ signsOfImplantLooseningFailure }
                            onChange={ e => {
                                setSignsOfImplantLooseningFailureYes('');
                                setSignsOfImplantLooseningFailure(e.target.value);
                            }}
                            name="signsOfImplantLooseningFailure"
                        >
                            <FormControlLabel value="no" label="No" control={<Radio  />} sx={{
                                ...hiddenFormInputStyle({value: signsOfImplantLooseningFailure === 'no', canEdit})
                            }} />
                            <FormControlLabel value="yes" label="Yes" control={<Radio />} sx={{
                                ...hiddenFormInputStyle({value: signsOfImplantLooseningFailure === 'yes', canEdit})
                            }} />
                        </RadioGroup>            
            
                    </FormControl>
                </Grid>
                { signsOfImplantLooseningFailure === 'yes' && (
                    <Grid item xs={12} md={12} sx={{ml:2}}>
                            <TextField 
                                fullWidth
                                multiline
                                sx={{
                                    ...hiddenFormInputStyle({value: signsOfImplantLooseningFailureYes, canEdit}),
                                    resize: "both"
                                }}
                                size="small" 
                                value={signsOfImplantLooseningFailureYes}
                                onChange={e=>setSignsOfImplantLooseningFailureYes(e.target.value)}
                            />
                    </Grid>
                ) }

                <Grid item xs={12} md={12} >
                    <FormLabel error={ errors.otherFractureOrImplantRelatedComplication ? true: false } >Other fracture or implant-related complication*</FormLabel>
                </Grid>

                <Grid item xs={12} md={12} sx={{ml:2}}>
                    <FormControl>
                        <RadioGroup
                            row
                            value={ otherFractureOrImplantRelatedComplication }
                            onChange={ e => { 
                                setOtherFractureOrImplantRelatedComplicationYes('');
                                setOtherFractureOrImplantRelatedComplication(e.target.value) 
                            }}
                            name="otherFractureOrImplantRelatedComplication"
                        >
                            <FormControlLabel value="no" label="No" control={<Radio  />} sx={{
                                ...hiddenFormInputStyle({value: otherFractureOrImplantRelatedComplication === 'no', canEdit})
                            }} />
                            <FormControlLabel value="yes" label="Yes" control={<Radio />} sx={{
                                ...hiddenFormInputStyle({value: otherFractureOrImplantRelatedComplication === 'yes', canEdit})
                            }} />
                        </RadioGroup>                     
                    </FormControl>
                </Grid>
                
                { otherFractureOrImplantRelatedComplication === 'yes' && (
                    <Grid item xs={12} md={12} sx={{ml:2}}>
                        <TextField 
                            fullWidth
                            multiline
                            sx={{
                                ...hiddenFormInputStyle({value: otherFractureOrImplantRelatedComplicationYes, canEdit}),
                                resize: "both"
                            }} 
                            size="small" 
                            value={otherFractureOrImplantRelatedComplicationYes}
                            onChange={e=>setOtherFractureOrImplantRelatedComplicationYes(e.target.value)}
                        />
                    </Grid>
                )}   
                
            </Grid>
        </React.Fragment>
     );
});
 
export default RadioGraphicFindings;