import React, { useState, forwardRef, useImperativeHandle, useContext } from "react";
import { FormGroup,Checkbox,FormControl, FormLabel, FormControlLabel, Grid, RadioGroup, Radio, TextField } from '@mui/material';
import { verifyRequireField } from "../../../utils";
import { ClassesContext } from "../../../../../contexts/Classes";

const IntraoperatioveComplication = forwardRef( ({canEdit},ref) => {
    const [ errors, setErrors ] = useState({});
    const [ intraoperatioveComplication, setIntraoperatioveComplication ] = useState(null);
    const [ vascularComplication, setVascularComplication ] = useState('');
    const [ sciaticNerve, setSciaticNerve ] = useState(false);
    const [ obturatorNerve, setObturatorNerve ] = useState(false);
    const [ femoralNerve, setFemoralNerve ] = useState(false);
    const [ otherComplications, setOtherComplications ] = useState(false);
    const [ otherComplicationsText, setOtherComplicationsText ] = useState('');
    const [ lateralFemoralCutaneousNerve, setLateralFemoralCutaneousNerve ] = useState(false);
    const { hiddenFormInputStyle } = useContext(ClassesContext);

    useImperativeHandle(ref,()=>({
        setData(data){
            setIntraoperatioveComplication(data.intraoperatioveComplication ? data.intraoperatioveComplication : null)
            setVascularComplication(data.vascularComplication ? data.vascularComplication : '')
            setSciaticNerve(data.sciaticNerve ? data.sciaticNerve : false)
            setObturatorNerve(data.obturatorNerve ? data.obturatorNerve : false)
            setFemoralNerve(data.femoralNerve ? data.femoralNerve : false)
            setOtherComplications(data.otherComplications ? data.otherComplications : false)
            setOtherComplicationsText(data.otherComplicationsText ? data.otherComplicationsText : '')
            setLateralFemoralCutaneousNerve(data.lateralFemoralCutaneousNerve ? data.lateralFemoralCutaneousNerve : false)
        },
        verifyData(){
            let requireFields = [
                { 
                    errorName: 'intraoperatioveComplication', 
                    fields: [
                        'vascularComplication',
                        'sciaticNerve',
                        'obturatorNerve',
                        'femoralNerve',
                        'lateralFemoralCutaneousNerve',
                        'otherComplications'
                    ] ,
                    condition: 'or' ,
                    parent: { 
                        field: 'intraoperatioveComplication' , 
                        condition: 'compareValue' , 
                        valueForCompare: "yes"  
                    } 
                },
            ]
            let {isRequireFieldsValid,errorObject} = verifyRequireField(requireFields,getFormData());
            setErrors({ ...errorObject });
            return isRequireFieldsValid
        },
        getData(){
            return getFormData()
        }
    }))

    const getFormData = () => {
        return {
                intraoperatioveComplication,
                vascularComplication,
                sciaticNerve,
                obturatorNerve,
                femoralNerve,
                lateralFemoralCutaneousNerve,
                otherComplications,
                otherComplicationsText,
            }
    }

    const intraoperatioveComplicationOnchange = (e)=>{
        setVascularComplication('');
        setSciaticNerve(false);
        setObturatorNerve(false);
        setFemoralNerve(false);
        setLateralFemoralCutaneousNerve(false);
        setOtherComplications(false);
        setOtherComplicationsText('');
        setIntraoperatioveComplication(e.target.value);
    }
    
    return ( 
        <React.Fragment>
                <Grid container spacing={1} sx={{mt:1}}>
                    <Grid item xs={12} md={12}>
                        <FormLabel error={ errors.intraoperatioveComplication ? true: false } >Intraoperatiove complication</FormLabel>
                    </Grid>
                    <Grid item xs={12} md={12} sx={{ml:2}}>
                            <FormControl>
                                <RadioGroup
                                    row
                                    value={ intraoperatioveComplication }
                                    onChange={ intraoperatioveComplicationOnchange}
                                    name="intraoperatioveComplication"
                                >
                                    <FormControlLabel value="no" label="No" control={<Radio  />} sx={{ 
                                        ...hiddenFormInputStyle({value: intraoperatioveComplication === 'no' ,canEdit}) 
                                    }} />
                                    <FormControlLabel value="yes" label="Yes" control={<Radio />} sx={{ 
                                        ...hiddenFormInputStyle({value: intraoperatioveComplication === 'yes' ,canEdit}) 
                                    }} />
                                </RadioGroup>
                            </FormControl>
                    </Grid>
                </Grid> 
                { intraoperatioveComplication === 'yes' && (
                    <React.Fragment>
                        <Grid container spacing={1} sx={{ 
                            ...hiddenFormInputStyle({value: vascularComplication ,canEdit}) 
                        }}>
                            <Grid item xs={12} md={6}>
                                <TextField 
                                    sx={{resize:"both"}} 
                                    multiline  
                                    name="vascularComplication" 
                                    value={ vascularComplication } 
                                    onChange={e=> setVascularComplication(e.target.value)} 
                                    size='small' fullWidth 
                                    label="Vascular complication" /> 
                            </Grid>
                        </Grid>
                        <Grid container spacing={1} sx={{mt:1}}>
                            <Grid item xs={12} md={12} >
                                <FormLabel >Neurological complication</FormLabel>
                            </Grid>
                            <Grid item xs={12} md={12} >
                               
                                <FormControl>
                                    <FormGroup sx={{display:'inline'}}>
                                        <FormControlLabel control={
                                            <Checkbox 
                                            checked={ sciaticNerve } 
                                            onChange={ e => setSciaticNerve(e.target.checked) } 
                                            name="sciaticNerve" />
                                        } label="Sciatic nerve" sx={{ 
                                            ...hiddenFormInputStyle({value: sciaticNerve ,canEdit}) 
                                        }} />
                                        <FormControlLabel control={
                                            <Checkbox 
                                            checked={ obturatorNerve } 
                                            onChange={ e=> setObturatorNerve(e.target.checked)  } 
                                            name="obturatorNerve" />
                                        } label="Obturator nerve" sx={{ 
                                            ...hiddenFormInputStyle({value: obturatorNerve ,canEdit}) 
                                        }} />
                                        <FormControlLabel control={
                                            <Checkbox 
                                                checked={ femoralNerve } 
                                                onChange={e=>setFemoralNerve(e.target.checked)} 
                                                name="femoralNerve" />
                                        } label="Femoral nerve" sx={{ 
                                            ...hiddenFormInputStyle({value: femoralNerve ,canEdit}) 
                                        }} />
                                        <FormControlLabel control={
                                            <Checkbox 
                                                checked={ lateralFemoralCutaneousNerve } 
                                                onChange={e=>setLateralFemoralCutaneousNerve(e.target.checked)} 
                                                name="lateralFemoralCutaneousNerve" />
                                        } label="Lateral femoral cutaneous nerve" sx={{ 
                                            ...hiddenFormInputStyle({value: lateralFemoralCutaneousNerve ,canEdit}) 
                                        }} />
                                    </FormGroup>
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Grid container spacing={1} >
                            <Grid item xs={12} md={12} >
                                <FormControl>
                                    <FormGroup sx={{display:'inline'}}>
                                        <FormControlLabel control={
                                            <Checkbox 
                                            checked={ otherComplications } 
                                            onChange={ e => setOtherComplications(e.target.checked) } 
                                            name="otherComplications" />
                                        } label="Other complications" sx={{ 
                                            ...hiddenFormInputStyle({value: otherComplications ,canEdit}) 
                                        }} />
                                        <TextField 
                                            multiline  
                                            name="otherComplicationsText" 
                                            value={ otherComplicationsText } 
                                            onChange={e=> setOtherComplicationsText(e.target.value)} 
                                            error={ errors.otherComplicationsText ? true : false } 
                                            size='small' fullWidth 
                                            label="Other complications"  
                                            sx={{ 
                                                ...hiddenFormInputStyle({value: otherComplicationsText ,canEdit}) ,
                                                resize:"both"
                                            }}
                                        /> 
                                    </FormGroup>
                                </FormControl>
                            </Grid>
                        </Grid>
                    </React.Fragment>
                )}
        </React.Fragment>
     );
});
 
export default IntraoperatioveComplication;