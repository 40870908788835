import React, { forwardRef, useImperativeHandle, useRef, useContext } from 'react';
import { Box ,Collapse, Divider} from '@mui/material';
import PostOperativeWeek from './PostOperativeWeek';
import RadioGraphicFindings from './RadiographicFindings';
import MotorPower from './MotorPower';
import NeurologicalFunction from './NeurologicalFunction';
import DorsalisPedisPosteriorTibial from './DorsalisPedisPosteriorTibial';
import OtherComplications from './OtherComplications';
import PatientReportedOutcomeScore from './PatientReportedOutcomeScore';
import OtherRemark from './OtherRemarks';
import NextAppointmentDate from './NextAppointmentDate';
import { ClassesContext } from "../../../../../contexts/Classes";
import AttendingSurgeonAndDateTime from '../../../common/AttendingSurgeonAndDateTime';
import FormLabel from '../../../common/FormLabel';


export const Main = forwardRef(({canEdit,topic},ref) => {
    const [expanded, setExpanded] = React.useState(canEdit);
    const { useStyles } = useContext(ClassesContext);
    const classes = useStyles(canEdit);
    const postOperativeWeekRef = useRef();
    const radioGraphicFindingsRef = useRef();
    const motorPowerRef = useRef();
    const neurologicalFunctionRef = useRef();
    const dorsalisPedisPosteriorTibialRef = useRef();
    const otherComplicationsRef = useRef();
    const patientReportedOutcomeScoreRef = useRef();
    const otherRemarkRef = useRef();
    const nextAppointmentDateRef = useRef();
    const attendingSurgeonAndDateTimeRef = useRef();
        


    useImperativeHandle(ref,()=>({
        setData(dataObject){
            postOperativeWeekRef.current.setData(dataObject.postOperativeWeek );
            radioGraphicFindingsRef.current.setData(dataObject.radioGraphicFindings);
            motorPowerRef.current.setData(dataObject.motorPower);
            neurologicalFunctionRef.current.setData(dataObject.neurologicalFunction);
            dorsalisPedisPosteriorTibialRef.current.setData(dataObject.dorsalisPedisPosteriorTibial);
            otherComplicationsRef.current.setData(dataObject.otherComplications);
            patientReportedOutcomeScoreRef.current.setData(dataObject.patientReportedOutcomeScore);
            otherRemarkRef.current.setData(dataObject.otherRemark);
            nextAppointmentDateRef.current.setData(dataObject.nextAppointmentDate);
            attendingSurgeonAndDateTimeRef.current.setData(dataObject.attendingSurgeonAndDateTime);
        },
        verifyRequireField(){
            let postOperativeWeek = postOperativeWeekRef.current.verifyData();
            let radioGraphicFindings = radioGraphicFindingsRef.current.verifyData();
            let motorPower = motorPowerRef.current.verifyData();
            let neurologicalFunction = neurologicalFunctionRef.current.verifyData();
            let dorsalisPedisPosteriorTibial = dorsalisPedisPosteriorTibialRef.current.verifyData();
            let otherComplications = otherComplicationsRef.current.verifyData();
            let patientReportedOutcomeScore = patientReportedOutcomeScoreRef.current.verifyData();
            let otherRemark = otherRemarkRef.current.verifyData();
            let nextAppointmentDate = nextAppointmentDateRef.current.verifyData();
            let attendingSurgeonAndDateTime = attendingSurgeonAndDateTimeRef.current.verifyData();
            let fieldValid = (
                postOperativeWeek &&
                radioGraphicFindings &&
                motorPower &&
                dorsalisPedisPosteriorTibial &&
                otherComplications &&
                patientReportedOutcomeScore &&
                otherRemark &&
                nextAppointmentDate &&
                attendingSurgeonAndDateTime &&
                neurologicalFunction
            )
            return fieldValid
        },
        getData() {
            let data = {
                postOperativeWeek : postOperativeWeekRef.current.getData(),
                radioGraphicFindings : radioGraphicFindingsRef.current.getData(),
                motorPower : motorPowerRef.current.getData(),
                neurologicalFunction : neurologicalFunctionRef.current.getData(),
                dorsalisPedisPosteriorTibial : dorsalisPedisPosteriorTibialRef.current.getData(),
                otherComplications : otherComplicationsRef.current.getData(),
                patientReportedOutcomeScore : patientReportedOutcomeScoreRef.current.getData(),
                otherRemark : otherRemarkRef.current.getData(),
                nextAppointmentDate : nextAppointmentDateRef.current.getData(),
                attendingSurgeonAndDateTime : attendingSurgeonAndDateTimeRef.current.getData(),
            }
            return data
        }
    }))

    return (
        <Box className={classes.root} sx={{padding:3}}>
            <FormLabel setExpanded={setExpanded} expanded={expanded} headerText="BDMS Pelvic and Acetabulum Fracture : OPD Follow Up Form" />
            <Divider ></Divider>
            <Collapse in={expanded}>
                <Box sx={{mt:1}}>
                    <PostOperativeWeek ref={postOperativeWeekRef} canEdit={canEdit} />
                    <RadioGraphicFindings ref={radioGraphicFindingsRef} canEdit={canEdit} />
                    <Divider sx={{mt:2,mb:2}}></Divider>
                    <MotorPower ref={motorPowerRef} canEdit={canEdit} />
                    <NeurologicalFunction ref={neurologicalFunctionRef} canEdit={canEdit} />
                    <DorsalisPedisPosteriorTibial ref={dorsalisPedisPosteriorTibialRef} canEdit={canEdit} />
                    <OtherComplications ref={otherComplicationsRef} canEdit={canEdit} />
                    <PatientReportedOutcomeScore ref={patientReportedOutcomeScoreRef} canEdit={canEdit} />
                    <OtherRemark ref={otherRemarkRef} canEdit={canEdit} />
                    <NextAppointmentDate ref={nextAppointmentDateRef} canEdit={canEdit} />
                    <AttendingSurgeonAndDateTime ref={attendingSurgeonAndDateTimeRef} canEdit={canEdit} />
                </Box>
            </Collapse>

        </Box>
    );
});