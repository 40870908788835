import React, { useState } from "react";
import { Box, Card, Grid, Button, TextField, Snackbar, Alert,CardActions, CardHeader, Divider } from "@mui/material";


const CreateUserComplete = () => {


    return ( 
        <React.Fragment>
            <Card sx={{mt:2}} elevation={0} component="form" noValidate>
                <Grid container>
                    <Grid item xs={12} md={12}>
                        <Alert severity={"success"} >
                            <p>Register complete, Pre-operation admin will assign hospital and permission to your account soon.</p>
                        </Alert>
                    </Grid>
                </Grid>
            </Card>
        </React.Fragment>
     );
}
 
export default CreateUserComplete;