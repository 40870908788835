import React, { useState, forwardRef, useImperativeHandle, useContext } from "react";
import { Grid, TextField} from '@mui/material';
import { getDataUpdate, verifyRequireField } from "../../../utils";
import { ClassesContext } from "../../../../../contexts/Classes";

const Remarks = forwardRef( ({canEdit},ref) => {
    const [ errors, setErrors ] = useState({});
    const [ formData, setFormData ] = useState({});
    const { hiddenFormInputStyle  } = useContext(ClassesContext);

    useImperativeHandle(ref,()=>({
        setData(data){
            setFormData(data ? data : {})
        },
        verifyData(){
            let requireFields = []
            let {isRequireFieldsValid,errorObject} = verifyRequireField(requireFields,formData)
            setErrors({ ...errorObject });
            return isRequireFieldsValid
        },
        getData(){
            return formData
        }
    }))

    const updateFormData = async (e) => {
        let dataUpdate = getDataUpdate(e);
        setFormData({...formData,...dataUpdate})
    }
    
    return ( 
        <React.Fragment>
            <Grid item xs={12} md={12} sx={{mt: 1,ml:2}}>
                <TextField sx={{...hiddenFormInputStyle({value:formData.remarks, canEdit}), resize:"both"}}  multiline value={formData.remarks ? formData.remarks : '' } name="remarks" onChange={updateFormData} label="Remarks" variant="outlined" fullWidth error={errors.remarks ? true: false} />
            </Grid> 
        </React.Fragment>
     );
});
 
export default Remarks;