import * as React from 'react';
import { Typography, IconButton, ListItemAvatar, ListItemText, ListItemButton, Avatar, ListItem  } from '@mui/material';
import moment from 'moment';
import { makeStyles } from '@mui/styles';
import { TOPIC_STATUS_DRAFT } from '../../config/form';
import axiosInstance from '../../common/axios';
import {FavoriteBorder, Favorite} from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import Divider from '@mui/material/Divider';

const useStyles = makeStyles({
    avatar : {
        backgroundColor: (topic) => {
            if(topic.status === TOPIC_STATUS_DRAFT){
                return 
            }else if(topic.specialty_detail.name.charAt(0) === 'P'){
                return "#FF6363 !important"
            }else{
                return 
            }
        }
    }
})

const TopicListCard = ({topic}) => {
    const classes = useStyles(topic)
    const [ isFavoriteTopic, setIsFavoriteTopic ] = React.useState(false);
    const [ attendingSurgeon, setAttendingSurgeon ] = React.useState('');
    const navigate = useNavigate()

    React.useEffect(()=>{
        if(topic){
            setFavoriteTopic(null);
            if(topic.consultation_form_detail && topic.consultation_form_detail.data.attendingSurgeonAndDateTime ){
                setAttendingSurgeon(topic.consultation_form_detail.data.attendingSurgeonAndDateTime.attendingSurgeon)
            }
        }
        
    },[topic])// eslint-disable-line react-hooks/exhaustive-deps

    const getFavoriteTopic = async () => {
        let token = localStorage.getItem('token') ? JSON.parse(localStorage.getItem('token')).access : null ;
        if (token) {
            let tokenParts = JSON.parse(atob(token.split('.')[1]));
            let res = await axiosInstance.get(`/topic/favorite-topic/?topic=${topic.id}&user=${tokenParts.user_id}`)
            return res.data.count > 0 ? res.data.results[0] : null
        }
    }

    const setFavoriteTopic = async (isFav) => {
        if( isFav === null ){
            let favTopic = await getFavoriteTopic()
            setIsFavoriteTopic( favTopic? true : false)
        }else{
            setIsFavoriteTopic(isFav)
            let favTopic = await getFavoriteTopic()
            let token = localStorage.getItem('token') ? JSON.parse(localStorage.getItem('token')).access : null ;
            if( isFav && favTopic === null && token ){
                let tokenParts = JSON.parse(atob(token.split('.')[1]));
                axiosInstance.post(`/topic/favorite-topic/`,{topic:topic.id,user: tokenParts.user_id})
            }else if(favTopic){
                axiosInstance.delete(`/topic/favorite-topic/${favTopic.id}/`)
            }
        }
    }

    return ( 
        <React.Fragment>
            <ListItem
                secondaryAction={
                    topic ? (
                        <React.Fragment>
                            { isFavoriteTopic ? (
                                <IconButton onClick={e=> setFavoriteTopic(false)}>
                                    <Favorite fontSize="large" color="error" />
                                </IconButton>
                            ) : (
                                <IconButton onClick={e=> setFavoriteTopic(true)} >
                                    <FavoriteBorder fontSize="large" />
                                </IconButton>
                            )}
                        </React.Fragment>
                    ) : null 
                }
            >
                <ListItemButton onClick={e=> navigate(`/topic/${topic.id}`)}>
                    <ListItemAvatar>
                        <Avatar className={classes.avatar}>
                            { topic.specialty_detail.name[0].toUpperCase() }
                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText  
                        primary={`${topic.specialty_detail.name}`} 
                        secondary={
                            <React.Fragment>
                              <Typography
                                component="span"
                                variant="body2"
                                color="text.primary"
                              >
                                 {`${ topic.patient_visit_detail !== null ? topic.patient_visit_detail.patient_detail.name : ''}`}
                                 <br></br>
                                 {`${topic.hospital_detail.name}`}
                                 <br></br>
                                 {`Surgeon ${attendingSurgeon}`}
                              </Typography>
                              <br></br>
                              {`${topic.user_created_detail.first_name} ${topic.user_created_detail.last_name} on ` + moment(topic.created).format('MMMM Do YYYY, h:mm a')}
                            </React.Fragment>
                          }
                    />
                </ListItemButton>
            </ListItem>
            <Divider  component="li" />
        </React.Fragment>
     );
}
 
export default TopicListCard;
