import { createContext } from "react";
import { makeStyles } from '@mui/styles';

export const ClassesContext = createContext();

const useStyles = makeStyles((theme)=>{
    return {
        hiddenFormInput: (data) => {
            let style = {}
            if( !data.canEdit && !data.value  ){
                style['display'] = 'none !important'  
            }   
            return style
        },
        root: (canEdit) => {
            let style = {}
            if(!canEdit){
                style["& .MuiFormLabel-root"] = {pointerEvents: 'none'}
                style['pointerEvents'] = 'none' 
            }
            return style
        },
        radioLabel: {
            fontSize: 'h6.fontSize'
        }
    }
})

export const ClassesProvider = (props) => {

    const hiddenFormInputStyle = (data) => {
        let style = {}
        if( !data.canEdit && !data.value  ){
            style['display'] = 'none !important'  
        }   
        return style
    }
    return (
        <ClassesContext.Provider value={{ useStyles,hiddenFormInputStyle }}> 
            {props.children}
        </ClassesContext.Provider>
    );
  
};