import { Box, Button, CardActions , TextField, CircularProgress,Alert  } from "@mui/material";
import SendIcon from '@mui/icons-material/Send';
import { useState } from "react";
import axiosInstance from "../../common/axios";
import FileComment from "./FileComment";
import Snackbar from '@mui/material/Snackbar';
import { useNavigate } from "react-router-dom";

const NormalComment = ({ sendComment, topic }) => {
    const [ selectedFiles, setSelectedFiles ] = useState([]);
    const [ isProcess, setIsProcess ] = useState(false);
    const [ details, setDetails] = useState('');
    const [ detailsError, setDetailsError] = useState(false);
    const [ alertMessage, setAlertMessage ] = useState(null);
    const [ alertOpen, setAlertOpen ] = useState(false);
    const navigate = useNavigate()
    
    const uploadCommentFile = async (file, comment) =>{
        let formData = new FormData();
        formData.append('type', file.type);
        formData.append('file', file.file);
        formData.append('size',file.file.size)
        formData.append('name', file.file.name);
        if(file.description){
            formData.append('description', file.description);
        }
        formData.append('comment',comment.id);
        try{
            let res = await axiosInstance.post('/topic/comment-file/',formData)
            return res.data
        }catch{
            return null
        }
        
    }

    const handleCommentSubmit = async (e) => {
        setIsProcess(true)
        e.preventDefault()
        setDetailsError( details === '' && selectedFiles.length === 0 )

        axiosInstance.get(`/topic/topic/${topic.id}/`).then( async (res)=>{
            if ( selectedFiles.length > 0 ) {
                let comment = await createComment(details)
                if(comment){
                    const files = Array.from(selectedFiles);
                    for ( let i=0 ; i < files.length ; i ++) {
                        let commentFile = await uploadCommentFile(files[i], comment);
                        if(!commentFile){
                            console.log(commentFile)
                        }
                    }
                    sendComment({ command: 'update_comment', comment_id: comment.id });
                }
                setDetails('');
                setDetailsError(false);
                setIsProcess(false);
                setSelectedFiles([]);
    
            }else if(details){
                let comment = { command: 'new_comment', content: details }
                sendComment(comment)
                setDetails('')
                setDetailsError(false)
                setIsProcess(false)
            }else{
                setIsProcess(false)
            }   
        }).catch((error)=>{
            setAlertMessage( 'Topic has deleted')
            setAlertOpen(true)
            setTimeout(()=>{
                navigate("/")
            },6000)
        })


    }

    const createComment = async (content) => {
        let token =  localStorage.getItem('token') ? JSON.parse(localStorage.getItem('token')).access : null; 
        if(token){
            let tokenParts = JSON.parse(atob(token.split('.')[1]));
            let author = tokenParts.user_id;
            let comment = { topic: topic.id , content: content , author: author }
            try {
                let res = await axiosInstance.post('topic/comment/',comment)
                return res.data
            } catch (error) {
                return null
            }
        }else{
            return null
        }
    }

    return (
        
        <form noValidate autoComplete="off" onSubmit={handleCommentSubmit}>
            { isProcess ? (                
                    <CardActions sx={{ p: 1.25, pt: 0, mt: 2, justifyContent: 'center' }}>
                        <CircularProgress />
                    </CardActions>
                ) : (
                    <Box sx={{padding: 3 }}>
                        <TextField value={details} sx={{resize:"both"}} onChange={(e) => setDetails(e.target.value)} label="Comment" variant="outlined" multiline fullWidth required error={detailsError} />
                        <FileComment  selectedFiles={selectedFiles} setSelectedFiles={setSelectedFiles} />
                        <CardActions sx={{ p: 1.25, pt: 0, justifyContent: 'center' }}>
                            <Button 
                                sx={{ ml: 2 }} 
                                type="submit" 
                                color="primary" variant="outlined" 
                                endIcon={<SendIcon />}>
                                Submit
                            </Button>
                        </CardActions>
                    </Box>
                )
            }
        <Snackbar open={alertOpen} autoHideDuration={6000} onClose={()=>setAlertOpen(false)}>
            <Alert onClose={()=>{ setAlertOpen(false) }} severity="error" sx={{ width: '100%' }}>
                {alertMessage}
            </Alert>
        </Snackbar>
        </form>
     );
}
 
export default NormalComment;