import * as actionTypes from '../action/actionTypes';
import { updateObject } from '../utils';

const authStart = ( state, action ) => {
    return updateObject(state,{
        error: null,
        logoutClick:false,
        loading: true
    })
}

const authSuccess = (state, action ) => {
    return updateObject( state, {
        error: null,
        loading: false,
        isAuthenticated: true
    } )
}



const authFail = (state, action) => {
    return updateObject(state,{
        error: action.error,
        loading: false,
        detail: null,
        isAuthenticated: false
    })
}

const authLogout = (state, action) => {
    localStorage.removeItem('token')
    return updateObject(state,{
        detail:null,
        logoutClick:true,
        isAuthenticated: false
    })
}

const reducer = (state,action) =>{
    switch(action.type) {
        case actionTypes.AUTH_START: 
            return authStart(state,action);
        case actionTypes.AUTH_SUCCESS:
            return authSuccess(state,action);
        case actionTypes.AUTH_FAIL:
            return authFail(state,action);
        case actionTypes.AUTH_LOGOUT:
            return authLogout(state,action);
            default:
                return state;
    }
}

const initialState = {
    detail: null,
    error: null,
    loading: false,
    logoutClick: false,
    isAuthenticated: localStorage.getItem('token') ? true : false
}
export {initialState,reducer};
