import { Alert,CardHeader, Box, Button, Card, CardActions, CircularProgress, Container, Link, TextField } from '@mui/material';
import React, { useContext, useEffect } from 'react';
import { AuthContext } from '../contexts/AuthContext';
import { authLogin } from '../store/action/auth';
import { useNavigate, useLocation } from "react-router-dom";



const Login = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const params = new URLSearchParams(location.search);
    const { user, dispatch } = useContext(AuthContext);
    const next = params.get('next') ? params.get('next') : null ;
    const handleSubmit = async (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        authLogin(dispatch, data.get('username'), data.get('password'))
    }

    useEffect(()=>{
        if(user.isAuthenticated){
            navigate( next && !next.startsWith('/login') ? next : `/`)
        }
    },[user] ) // eslint-disable-line react-hooks/exhaustive-deps

    return ( 
        <Container component="main" maxWidth="xs">
            <Card sx={{mt: 3}}>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    padding: 5,
                }}>
                    <CardHeader title="Login" />
                    <Box sx={{ mt: 1 }} component="form" onSubmit={handleSubmit} noValidate >
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="username"
                            label="Username"
                            name="username"
                            autoComplete="username"
                            autoFocus
                            />
                            
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label="Password"
                            type="password"
                            id="password"
                            autoComplete="current-password"
                            />

                                            
                            <Button 
                                type="submit"
                                fullWidth
                                variant="outlined"
                                sx={{ mt: 3, mb: 2 }}
                            >
                                {}
                                { user.loading ? <CircularProgress size={25} sx={{align:"center"}} /> : 'Sign In' }
                                
                                
                            </Button>

 
                            <CardActions sx={{ p: 1.25, pt: 0, justifyContent: 'center' }}>
                                <Link variant="body2">
                                    <Button onClick={(e)=> navigate('/forgot-password/')} size="small">Forgot password?<br></br>(For Local user only)</Button>
                                </Link>
                            </CardActions>
 
                            <CardActions sx={{ p: 1.25, pt: 0, justifyContent: 'center' }}>
                                <Link variant="body2">
                                    <Button onClick={(e)=> navigate('/register/')} size="small">Don't have an account? Sign Up</Button>
                                </Link>
                            </CardActions>
                            { user.error !== null && <Alert severity='error' sx={{ mt:2}}>Invalid username or password</Alert> }
                    </Box>
                </Box>
            </Card>
        </Container>
     );
}
 
export default Login;