import React, { useState, forwardRef, useImperativeHandle, useContext } from "react";
import { Box, FormLabel, OutlinedInput, Grid, InputAdornment } from '@mui/material';
import { checkDataPattern, verifyRequireField } from "../../../utils";
import { ClassesContext } from "../../../../../contexts/Classes";
import { REG_NUMBER_WITHOUT_DEC } from "../../../../../config/format";

const PostOperativeDrainageContent = forwardRef( ({canEdit},ref) => {
    const [ errors, setErrors ] = useState({});
    const [ postOperativeDrainageContent, setPostOperativeDrainageContent ] = useState('');
    const { hiddenFormInputStyle } = useContext(ClassesContext);
    useImperativeHandle(ref,()=>({
        setData(data){
            setPostOperativeDrainageContent(data ? data : '')
        },
        verifyData(){
            let requireFields = [
                { field: 'postOperativeDrainageContent' ,errorName: 'postOperativeDrainageContent' },
            ]
            let {isRequireFieldsValid,errorObject} = verifyRequireField(requireFields,{postOperativeDrainageContent});
            setErrors({ ...errorObject });
            return isRequireFieldsValid
        },
        getData(){
            return postOperativeDrainageContent
        }
    }))
    
    return ( 
        <React.Fragment>
                <Grid container spacing={1} sx={{mt:1}}>
                    <Grid item xs={12} md={12}>
                        <FormLabel error={ errors.postOperativeDrainageContent ? true: false } >Postoperative drainage content*</FormLabel>
                    </Grid>
                    <Grid item xs={12} md={4} sx={{ml:2}}>
                        <Box sx={{display:'flex'}}>
                            <OutlinedInput
                                name="postOperativeDrainageContent"
                                sx={{
                                    ...hiddenFormInputStyle({value: postOperativeDrainageContent, canEdit}),
                                }}
                                value={ postOperativeDrainageContent }
                                onChange={e=> {
                                    if( !e.target.value || checkDataPattern(e.target.value,REG_NUMBER_WITHOUT_DEC)){
                                        setPostOperativeDrainageContent(e.target.value)
                                    }
                                }}
                                endAdornment={<InputAdornment position="end">ml.</InputAdornment>}
                                size="small"
                            />
                        </Box>
                    </Grid>
                </Grid>
        </React.Fragment>
     );
});
 
export default PostOperativeDrainageContent;