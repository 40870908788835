import React, { useState, forwardRef, useImperativeHandle,useContext } from "react";
import { Grid, TextField } from '@mui/material';
import { verifyRequireField } from "../../../utils";
import { ClassesContext } from "../../../../../contexts/Classes";

const OtherOrdersAndRemarks = forwardRef( ({canEdit},ref) => {
    const [ errors, setErrors ] = useState({});
    const [ otherOrdersAndRemarks, setOtherOrdersAndRemarks ] = useState('');
    const { hiddenFormInputStyle } = useContext(ClassesContext);
    useImperativeHandle(ref,()=>({
        setData(data){
            if(data){
                setOtherOrdersAndRemarks(data)
            }
            
        },
        verifyData(){
            let requireFields = [
            ]
            let {isRequireFieldsValid,errorObject} = verifyRequireField(requireFields,{otherOrdersAndRemarks});
            setErrors({ ...errorObject });
            return isRequireFieldsValid
        },
        getData(){
            return otherOrdersAndRemarks
        }
    }))
    
    return ( 
        <React.Fragment>
                <Grid container spacing={1} sx={{mt:1}}>
                    <Grid item xs={12} md={6}>
                        <TextField
                            multiline  
                            name="otherOrdersAndRemarks" 
                            value={ otherOrdersAndRemarks } 
                            onChange={e=> setOtherOrdersAndRemarks(e.target.value)} 
                            error={ errors.otherOrdersAndRemarks ? true : false } 
                            size='small' 
                            fullWidth 
                            sx={{...hiddenFormInputStyle({value: otherOrdersAndRemarks,canEdit}) ,resize:"both"}} 
                            label="Other orders/remarks" /> 
                    </Grid>
                </Grid>
        </React.Fragment>
     );
});
 
export default OtherOrdersAndRemarks;