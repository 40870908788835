import React, {useEffect, useRef, useState} from "react";
import Moment from 'moment';
import { Box, Card, CardHeader, Button, IconButton } from "@mui/material";
import TopicDetailForm from "./TopicDetailForm";
import ImageList from "../common/ImageList";
import ImageDialog from "../common/ImageDialog";
import VideoList from "../common/VideoList";
import FileList from "../common/FileList";
import axiosInstance from "../../common/axios";
import PatientDetailForm from "./PatientDetailForm";
import { CONSULTATION_TEMPLATES } from "../../config/form";
import { useNavigate } from "react-router-dom";
import {FavoriteBorder, Favorite} from '@mui/icons-material';
import fileDownload from 'js-file-download';
import axios from 'axios';

const Topic = ({topic}) => {
    const navigate = useNavigate();
    const topicDetailRef = useRef();
    const patientDetailRef = useRef();
    const consultationTemplateRef = useRef();
    const [ imageFiles, setImageFiles ] = useState( [] )
    const [ showImageDialog, setShowImageDialog ] =  useState(false);
    const [ showEditBtn, setShowEditBtn ] = useState(false);
    const [ imageIndex, setImageIndex ] =  useState(0);
    const [ videoFiles, setVideoFiles ] = useState([]);
    const [ otherFiles, setOtherFiles ] = useState([] );
    const [ ConsultationComponent, setConsultationComponent ] = useState(null);
    const [ consultationData, setConsultationData ] = useState(null);
    const [ isFavoriteTopic, setIsFavoriteTopic ] = useState(false);
    
    const closeImage = ( ) =>{
        setShowImageDialog(false)
    }

    const downloadFile = (fileObject) => {
        axiosInstance.get(`/topic/topic/${topic.id}/`).then((res)=>{
            let token = localStorage.getItem('token') ? JSON.parse(localStorage.getItem('token')).access : null; 
            axios.get(`${process.env.REACT_APP_BACKEND_URL}/topic/get-topic-file/${fileObject.id}/${token}`,{
                responseType: 'blob',
            }).then((res) => {
                fileDownload(res.data, fileObject.name)
            })
        })
    }

    const imageClick = (index) => {
        setImageIndex(index)
        setShowImageDialog(true)
    }
    
    useEffect(()=>{
        if(consultationData && ConsultationComponent){
            consultationTemplateRef.current.setData(consultationData)
        }
    },[ConsultationComponent,consultationData])

    useEffect(()=>{
        if(topic){
            if(topic.consultation_form){
                setConsultationData(topic.consultation_form_detail.data);   
                setConsultationComponent(CONSULTATION_TEMPLATES[topic.consultation_form_detail.template])
            }
            if(topic.patient_visit){
                patientDetailRef.current.setData(topic.patient_visit_detail)
            }
            topicDetailRef.current.setData(topic)
            setImageFiles( topic.topic_file_detail.filter( (file) => file.type === 'image' ) ) 
            setVideoFiles( topic.topic_file_detail.filter( (file) => file.type === 'video'  ) ) 
            setOtherFiles( topic.topic_file_detail.filter( (file) => file.type === 'other'  ) )
            let token = localStorage.getItem('token') ? JSON.parse(localStorage.getItem('token')).access : null ;
            if (token) {
                const tokenParts = JSON.parse(atob(token.split('.')[1]));
                setShowEditBtn(tokenParts.user_id === topic.created_by )
            }
            setFavoriteTopic(null);
        }
    },[topic])// eslint-disable-line react-hooks/exhaustive-deps

    const getFavoriteTopic = async () => {
        let token = localStorage.getItem('token') ? JSON.parse(localStorage.getItem('token')).access : null ;
        if (token) {
            let tokenParts = JSON.parse(atob(token.split('.')[1]));
            let res = await axiosInstance.get(`/topic/favorite-topic/?topic=${topic.id}&user=${tokenParts.user_id}`)
            return res.data.count > 0 ? res.data.results[0] : null
        }
    }
    const setFavoriteTopic = async (isFav) => {
        if( isFav === null ){
            let favTopic = await getFavoriteTopic()
            setIsFavoriteTopic( favTopic? true : false)
        }else{
            setIsFavoriteTopic(isFav)
            let favTopic = await getFavoriteTopic()
            let token = localStorage.getItem('token') ? JSON.parse(localStorage.getItem('token')).access : null ;
            if( isFav && favTopic === null && token ){
                let tokenParts = JSON.parse(atob(token.split('.')[1]));
                axiosInstance.post(`/topic/favorite-topic/`,{topic:topic.id,user: tokenParts.user_id})
            }else if(favTopic){
                axiosInstance.delete(`/topic/favorite-topic/${favTopic.id}/`)
            }
        }
    }

    return (
        <React.Fragment>
            <Card>
                <CardHeader 
                    action={ 
                        topic ? (
                            <React.Fragment>
                                <Button sx={{ml:1}}  variant="outlined" size="small" onClick={()=>{ navigate(`/topic-file/${topic.id}`)  }}>All file</Button> 
                                { showEditBtn && <Button sx={{ml:1}} variant="outlined" size="small" onClick={()=>{ navigate(`/edit-topic/${topic.id}`)  }}>Edit Topic</Button> }
                            </React.Fragment>
                        ) : null 
                    } 
                />
            </Card>
            <Card sx={{mt:1}}>
                <CardHeader 
                    action={ 
                        topic ? (
                            <React.Fragment>
                                { isFavoriteTopic ? (
                                    <IconButton onClick={e=> setFavoriteTopic(false)}>
                                        <Favorite fontSize="large" color="error" />
                                    </IconButton>
                                ) : (
                                    <IconButton onClick={e=> setFavoriteTopic(true)} >
                                        <FavoriteBorder fontSize="large" />
                                    </IconButton>
                                )}
                            </React.Fragment>
                        ) : null 
                    } 
                subheader={topic && 'create on ' + Moment(topic.created).format('MMMM Do YYYY, h:mm:ss a') + ` by ${topic.user_created_detail.first_name} ${topic.user_created_detail.last_name}` } />
                { topic && (
                    <Box sx={{ padding: 3 }}>
                        <TopicDetailForm canEdit={false} specialtySelected={topic.specialty} specialties={[topic.specialty_detail]} hospitalSelected={topic.hospital} hospitals={[topic.hospital_detail]} ref={topicDetailRef} />
                        <ImageList imageFiles={imageFiles} imageClick={imageClick} />
                        <VideoList videoFiles={videoFiles} />
                        <FileList otherFiles={otherFiles} downloadFile={downloadFile} /> 
                        { topic.patient_visit && <PatientDetailForm canEdit={false} hospitalSelected={topic.hospital} ref={patientDetailRef} />}
                        { ConsultationComponent && <ConsultationComponent canEdit={false} ref={consultationTemplateRef} /> }
                    </Box>
                )}

                <ImageDialog showImageDialog={showImageDialog} closeImage={closeImage} imageIndex={imageIndex} setImageIndex={setImageIndex}  imageFiles={imageFiles} downloadFile={downloadFile} />
            </Card>
        </React.Fragment>
     );
}
 
export default Topic;