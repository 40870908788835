import { Button, Dialog, DialogActions, DialogContent, Typography } from "@mui/material";
import React from "react";
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";

const ImageDialog = ({ showImageDialog, closeImage, imageIndex, setImageIndex, imageFiles, downloadFile }) => {
    return ( 
        <Dialog open={showImageDialog} onClose={closeImage} fullWidth maxWidth="sm" > 
            <DialogActions>
                <Button onClick={e=> downloadFile(imageFiles[imageIndex]) }>Download</Button>
                <Button onClick={closeImage}>Close</Button>
            </DialogActions>
            <DialogContent>
                <Carousel 
                    selectedItem={imageIndex} 
                >
                    { imageFiles.map((file,index)=>{
                        return (
                                <div key={file.id}>
                                    <TransformWrapper>
                                            {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                                            <React.Fragment>
                                                <div className="tools">
                                                <button onClick={() => zoomIn()}>+</button>
                                                <button onClick={() => zoomOut()}>-</button>
                                                <button onClick={() => resetTransform()}>x</button>
                                                </div>
                                                <TransformComponent>
                                                <img style={{ width: '100%', height: '100%'  }} src={`${process.env.REACT_APP_BACKEND_URL}` + file.url  } alt={file.name} />
                                                </TransformComponent>
                                            </React.Fragment>
                                            )}
                                    </TransformWrapper>
                                    <Typography sx={{fontSize: 14 }} color="text.secondary" gutterBottom>
                                        {file.description}
                                    </Typography>
                                </div>
                        )
                    })}
                </Carousel>
            </DialogContent>
        </Dialog>
     );
}
 
export default ImageDialog;