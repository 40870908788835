import React from "react";
import { BrowserRouter } from "react-router-dom";
import AppBarAndDrawer from './AppBarAndDrawer';
import AppRoute from './AppRoute';

const Layout = () => {
    return ( 
        <React.Fragment>
          <BrowserRouter>
            <AppBarAndDrawer>
                  <AppRoute />
            </AppBarAndDrawer>
          </BrowserRouter>
        </React.Fragment>
     );
}
 
export default Layout;