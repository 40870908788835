import React, { useEffect,useState } from "react";
import { Box, Modal, Card, Grid, CardActions, Table,TableHead, TableRow, TableCell, TableBody, Button, TextField, Snackbar, Alert,Pagination, CardHeader, Divider, List, ListItem, ListItemText } from "@mui/material";
import axiosInstance from "../common/axios";
import {CheckOutlined,ClearOutlined} from '@mui/icons-material';
import EditUser from "./EditUser";
import { useNavigate } from 'react-router-dom';


const ManageUser = () => {
    const itemPerPage = 10;
    const [pageCount,setPageCount] = useState(1);
    const [page,setPage] = useState(1);
    const [ search, setSearch] = useState('');
    const [ users, setUsers ] = useState([])
    const [ showEditUserModal, setShowEditUserModal ] = useState(false);
    const [ userEdit,setUserEdit ] = useState(null);
    const [ specialties, setSpecialties ] = useState([])
    const [ hospitals, setHospitals ] = useState([])
    const navigate = useNavigate();

    useEffect(()=>{

        axiosInstance.get('/common/hospital/?limit=100').then((res)=>{
            if(res.data){
                setHospitals(res.data.results)
            }
        })

        axiosInstance.get(`/core/specialty/`).then((res)=>{
            if(res.data){
                setSpecialties(res.data.results )
            }
        })

        axiosInstance.get('/auth/users/get_user_detail/').then((res)=>{
            if(res.data.is_staff){
                getUserData(1,search)
            }else{
                navigate("/")
            }
        })
        
    },[])// eslint-disable-line react-hooks/exhaustive-deps
    const getUserData = (page,search) => {
        let offset = (page - 1) * itemPerPage;
        let url = `/auth/users/?limit=${itemPerPage}&offset=${offset}&search=${search}`;

        axiosInstance.get(url).then((res)=>{
            if(res.data){
                setPageCount(Math.ceil(res.data.count / itemPerPage))
                setUsers(res.data.results)
            }
            
        }).catch((error)=>{
            console.log(error)
        })
    }
    const handleChangePage = (event, page) => {
        setPage(page);
        getUserData(page,search);
    };

    const filter = () => {
        setPage(1);
        getUserData(1,search);
    }

    const editUser = (user) => {
        setUserEdit(user)
        setShowEditUserModal(true)
    }

    const refreshData = () => {
        getUserData(page,search)
    }


    return ( 
        <React.Fragment>
            <Card sx={{mt:2}} elevation={0} noValidate>
                <CardHeader title="Manage Users" />
                <Divider ></Divider>
                <Box sx={{
                    padding:3
                }}>
                <CardActions sx={{ p: 1.25, pt: 0,mt:3, justifyContent: 'left' }}>
                    <TextField size="small" onChange={(e)=> setSearch(e.target.value)} label="search" />
                    <Button sx={{ml:1}} onClick={(e)=> filter() } variant="outlined">Search</Button>
                </CardActions>

                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Username</TableCell>
                            <TableCell>First name</TableCell>
                            <TableCell>Last name</TableCell>
                            <TableCell>Email</TableCell>
                            <TableCell>Hospital</TableCell>
                            <TableCell>Specialty</TableCell>
                            <TableCell>Status</TableCell>
                            <TableCell>Action</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {users.map((user) => (
                            <TableRow
                            key={user.id}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell>{user.username}</TableCell>
                                <TableCell>{user.first_name}</TableCell>
                                <TableCell>{user.last_name}</TableCell>
                                <TableCell>{user.email}</TableCell>
                                <TableCell>
                                    <List>
                                        { user.hospitals.map((item)=> (
                                            <ListItem key={item.id}>
                                                <ListItemText>
                                                    - {item.code}
                                                </ListItemText>
                                            </ListItem>
                                        ))}
                                    </List>
                                </TableCell>
                                <TableCell>
                                <List>
                                    { user.specialties.map((item)=> (
                                            <ListItem key={item.id}>
                                                <ListItemText>
                                                   - {item.name}
                                                </ListItemText>
                                            </ListItem>
                                        ))}
                                    </List>
                                </TableCell>
                                <TableCell>
                                    { user.is_active && <CheckOutlined color="primary" /> }
                                    { !user.is_active && <ClearOutlined color="error" /> }
                                </TableCell>
                                <TableCell>
                                    <Button variant="outlined" onClick={(e)=>{ editUser(user) }} >Edit</Button>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>

                </Table>

                <CardActions sx={{ p: 1.25, pt: 0,mt:3, justifyContent: 'center' }}>
                    <Pagination onChange={handleChangePage} count={pageCount} page={page} variant="outlined" color="primary" />
                </CardActions>
                </Box>
            </Card>
            
            <EditUser refreshData={refreshData} specialties={specialties} hospitals={hospitals} showEditUserModal={showEditUserModal} setShowEditUserModal={setShowEditUserModal} userEdit={userEdit} />

        </React.Fragment>
     );
}
 
export default ManageUser;