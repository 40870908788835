import React, { useState, forwardRef, useImperativeHandle, useContext } from "react";
import { TextField, Grid } from '@mui/material';
import { verifyRequireField } from "../../../utils";
import moment from 'moment';
import { ClassesContext } from "../../../../../contexts/Classes";

const DrainRemovalDate = forwardRef( ({canEdit},ref) => {
    const [ errors, setErrors ] = useState({});
    const [ operationDate, setOperationDate ] = useState(null);
    const [ drainRemovalDate, setDrainRemovalDate ] = useState(null);
    const [ dayPostoperatively, setDayPostoperatively ] = useState('');
    const { hiddenFormInputStyle } = useContext(ClassesContext);
    useImperativeHandle(ref,()=>({
        setData(data){
            setOperationDate( data && data.operationDate ? data.operationDate  : null )
            setDrainRemovalDate( data && data.drainRemovalDate ? data.drainRemovalDate : null )
            setDayPostoperatively( data && data.dayPostoperatively ? data.dayPostoperatively : '' )
        },
        verifyData(){
            let requireFields = [
            ]
            let {isRequireFieldsValid,errorObject} = verifyRequireField(requireFields,getFormData());
            setErrors({ ...errorObject });
            return isRequireFieldsValid
        },
        getData(){
            return getFormData()
        }
    }))

    const getFormData = () => {
        return {
            operationDate,
            drainRemovalDate,
            dayPostoperatively
        }
    }

    const calculateDayPostoperatively = (operationDate,drainRemovalDate) => {
        if(operationDate && drainRemovalDate){
            operationDate = moment(operationDate).set({second:0,hour:0,minute:0}).toString()
            drainRemovalDate = moment(drainRemovalDate).set({second:0,hour:0,minute:0}).toString()
            let dayPostoperatively = moment(drainRemovalDate).diff( operationDate, 'days' )
            setDayPostoperatively(dayPostoperatively)
        }else{
            setDayPostoperatively('')
        }

    }
    
    return ( 
        <React.Fragment>
            <Grid container spacing={1} sx={{mt:1}}>
                <Grid item xs={12} md={4} >
                    <TextField
                        disabled
                        sx={{
                            ...hiddenFormInputStyle({value: operationDate, canEdit})
                        }}
                        label="Operation date*"
                        type="date"
                        value={ operationDate }
                        onChange={(e) => {
                            calculateDayPostoperatively( e.target.value, drainRemovalDate)
                            setOperationDate( e.target.value )
                        }}
                        size="small"
                        fullWidth
                        error={ errors.operationDate ? true : false }
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </Grid>
                <Grid item xs={12} md={4} >
                    <TextField
                        sx={{
                            ...hiddenFormInputStyle({value: drainRemovalDate, canEdit})
                        }}
                        label="Drain removal date"
                        type="date"
                        value={ drainRemovalDate }
                        onChange={(e) => {
                            let end = new Date(e.target.value);
                            if( operationDate ){
                                let start = new Date(operationDate);
                                if(start > end){
                                    calculateDayPostoperatively( operationDate, null )
                                    setDrainRemovalDate('')
                                }else{
                                    calculateDayPostoperatively( operationDate, e.target.value )
                                    setDrainRemovalDate( e.target.value  )
                                }
                            }else{
                                
                                setDrainRemovalDate( e.target.value  )
                            }   

                        }}
                        size="small"
                        fullWidth
                        error={ errors.drainRemovalDate ? true : false }
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </Grid>
                <Grid item xs={12} md={4}>
                    <TextField  
                    disabled  
                    sx={{
                        ...hiddenFormInputStyle({value: dayPostoperatively, canEdit})
                    }}
                    name="dayPostoperatively " 
                    value={ dayPostoperatively } 
                    onChange={e=>setDayPostoperatively(e.target.value)} 
                    error={ errors.dayPostoperatively ? true : false } 
                    size='small' fullWidth 
                    label="Day postoperatively " 
                    /> 
                </Grid>
            </Grid>
        </React.Fragment>
     );
});
 
export default DrainRemovalDate;