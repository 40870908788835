import React, { useState } from "react";
import { Box, Card, Grid, Button, TextField, Snackbar, Alert,CardActions, CardHeader, Divider } from "@mui/material";
import axiosInstance from "../common/axios";

const ForgotPassword = () => {
    const [openAlert, setOpenAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [alertType, setAlertType] = useState('success');
    const [ resetDone, setResetDone ] = useState(false);
    const [ email, setEmail ] = useState('');

    const verifyEmailPattern = (email) => {
        let pattern = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g
        return pattern.test(email)
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        setResetDone(false)
        if(verifyEmailPattern(email)){
            axiosInstance.get(`/auth/users/send_email_reset_password/?email=${email}`)
            setResetDone(true)
        }else{
            setAlertType('warning');
            setAlertMessage("email format invalid");
            setOpenAlert(true);
        }
    }

    const handleCloseAlert = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
        setOpenAlert(false);
      };

    return ( 
        <React.Fragment>
            <Card sx={{mt:2}} elevation={0} component="form" onSubmit={handleSubmit} noValidate>
                <CardHeader title="Forgot password (For Local user only)" />
                <Divider ></Divider>
                <Box sx={{
                    padding: 5,
                }}>
                    <Grid container spacing={1}>
                        <Grid item xs={12} md={12}>
                            <CardActions sx={{ p: 1.25, pt: 0, justifyContent: 'center' }}>
                                <TextField 
                                    label="Email"
                                    sx={{width:{xs:'100%',md: "40%"}}}
                                    size="small"
                                    onChange={(e)=>setEmail(e.target.value)}
                                />                           
                            </CardActions> 
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <CardActions sx={{ p: 1.25, pt: 0, justifyContent: 'center' }}>
                                <Button type="submit" variant="outlined" >
                                    Submit
                                </Button>                        
                            </CardActions> 
                        </Grid>
                        { resetDone && <Grid container spacing={1} sx={{mt:2}}>
                            <Grid item xs={12} md={12}>
                                <Alert severity={"success"} >
                                    <p>We've emailed you instructions for setting your password, if an account exists with the email you entered. You should receive them shortly.</p>
                                    <p>If you don't receive an email, please make sure you've entered the address you registered with, and check your spam folder.</p>
                                </Alert>
                            </Grid>
                        </Grid> }
                    </Grid>


 
                </Box>

                <Snackbar open={openAlert} autoHideDuration={6000} onClose={handleCloseAlert}>
                    <Alert onClose={handleCloseAlert} severity={alertType} sx={{ width: '100%' }}>
                        {alertMessage}
                    </Alert>
                </Snackbar>
            </Card>
        </React.Fragment>
     );
}
 
export default ForgotPassword;