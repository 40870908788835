import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

export default function Alert({open,message,handleClose}) {

  return (
    <React.Fragment>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
            { message && (
                <React.Fragment>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        { message.header && message.header }
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        { message.content && message.content }
                    </Typography>
                </React.Fragment>
            )}
        </Box>
      </Modal>
    </React.Fragment>
  );
}
