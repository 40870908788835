import React, { useState, forwardRef, useImperativeHandle, useEffect, useContext } from "react";
import { TextField, Grid, Button } from '@mui/material';
import { REG_NUMBER_WITHOUT_DEC } from "../../../../../config/format";
import { ClassesContext } from "../../../../../contexts/Classes";
import { checkDataPattern } from "../../../utils";

const ScrubNurse = forwardRef( ({canEdit,topic},ref) => {
    const [ scrubNurse, setScrubNurse ] = useState([null]);
    const [ spongeCount, setSpongeCount ] = useState('');
    const { hiddenFormInputStyle } = useContext(ClassesContext);

    useImperativeHandle(ref,()=>({
        setData(data){
            if( (data && data.scrubNurse && data.scrubNurse.length === 0) || (data && !data.scrubNurse) || !data ){
                setScrubNurse([null])
            }else{
                setScrubNurse(data.scrubNurse)
            }
            setSpongeCount( data && data.spongeCount ? data.spongeCount : '' );
        },
        verifyData(){
            return true
        },
        getData(){
            return getFormData()
        }
    }));

    const getFormData = () => {
        return {
            scrubNurse: scrubNurse.filter(item=> item != null),
            spongeCount
        }
    }

    const addScrubNurse = () => {
        let newScrubNurse = [ ...scrubNurse, null ]
        setScrubNurse(newScrubNurse)
    }

    const removeScrubNurse = (deleteIndex) => {
        if(scrubNurse.length > 1){
            let newScrubNurse = scrubNurse.filter( (item,index) => index !== deleteIndex )
            setScrubNurse(newScrubNurse)
        }
    }

    const setScrubNurseValue = (e,index) => {
        let value = e.target.value;
        let newScrubNurse = [...scrubNurse]
        newScrubNurse[index] = value;
        setScrubNurse(newScrubNurse)
    }

    return ( 
        <React.Fragment>
            <Grid container spacing={1} sx={{mt:1,...hiddenFormInputStyle({value: canEdit, canEdit})}}>
                <Grid item xs={12} md={12}>
                    <Button variant="outlined" onClick={(e)=> addScrubNurse() }>Add scrub nurse</Button>
                </Grid>
            </Grid>
            { scrubNurse.map( (item,index) => <React.Fragment key={index}>
                <Grid container spacing={1} sx={{mt:1}} >
                    <Grid item xs={12} md={4} >
                        <TextField fullWidth label="Scrub nurse" onChange={(e)=>setScrubNurseValue(e, index) } value={ item ? item : '' } name="scrubNurse" size="small" sx={{ 
                                ...hiddenFormInputStyle({value: item, canEdit})
                        }} />
                    </Grid>
                    <Grid item xs={12} md={4} sx={{...hiddenFormInputStyle({value: canEdit, canEdit})}}>
                        <Button variant="outlined" onClick={(e)=> removeScrubNurse(index) } color="error" >remove</Button>
                    </Grid>
                </Grid>
            </React.Fragment>
        )}
            <Grid container spacing={1} sx={{ 
                mt:1,
                ...hiddenFormInputStyle({value: spongeCount, canEdit})
            }}>
                <Grid item xs={12} md={4}>
                    <TextField 
                    fullWidth 
                    label="Sponge count" 
                    onChange={
                        (e)=> {
                            if( !e.target.value || checkDataPattern(e.target.value,REG_NUMBER_WITHOUT_DEC)){
                                setSpongeCount(e.target.value)
                            }
                    }} 
                    value={ spongeCount ? spongeCount : '' } 
                    name="spongeCount" 
                    size="small" 
                     />
                </Grid>
            </Grid>
        </React.Fragment>
     );
});
 
export default ScrubNurse;