import React, { useState, forwardRef, useImperativeHandle, useContext } from "react";
import { Grid, TextField } from '@mui/material';
import { verifyRequireField } from "../../../utils";
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { ClassesContext } from "../../../../../contexts/Classes";


const OperationDateTime = forwardRef( ({canEdit},ref) => {
    const [ errors, setErrors ] = useState({});
    const [ operationDateTime, setOperationDateTime ] = useState(null);
    const { hiddenFormInputStyle } = useContext(ClassesContext);
    useImperativeHandle(ref,()=>({
        setData(data){
            if(data){
                setOperationDateTime(data)
            }
            
        },
        verifyData(){
            let requireFields = [
                { field: 'operationDateTime' ,errorName: 'operationDateTime' },
            ]
            let {isRequireFieldsValid,errorObject} = verifyRequireField(requireFields,{operationDateTime});
            setErrors({ ...errorObject });
            return isRequireFieldsValid
        },
        getData(){
            return operationDateTime
        }
    }))
    
    return ( 
        <React.Fragment>
            <Grid container spacing={1}>
                <Grid item xs={12} md={4} >
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <TextField
                                label="Operation date/time*"
                                type="datetime-local"
                                value={operationDateTime}
                                size="small"
                                fullWidth
                                sx={{ 
                                    ...hiddenFormInputStyle({value:operationDateTime,canEdit}) 
                                }}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                error={ errors.operationDateTime ? true : false }
                                onChange={ e=> setOperationDateTime(e.target.value) }

                        />
                    </LocalizationProvider>
                </Grid>
            </Grid> 
        </React.Fragment>
     );
});
 
export default OperationDateTime;