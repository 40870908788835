import axios from 'axios';
const baseURL = `${process.env.REACT_APP_BACKEND_URL}/api/`

const axiosInstance = axios.create({
        baseURL: baseURL,
        timeout: 60000,
        headers: {
            Authorization: localStorage.getItem('token') ? 'Bearer '+ JSON.parse(localStorage.getItem('token')).access : null,
            'Content-Type': 'application/json',
            accept: 'application/json'
        },
});

axiosInstance.interceptors.response.use(
    (response)=>{
        return response
    },
    async function (error) {
        const originalRequest = error.config;
        
        if(typeof error.response === 'undefined') {
			return Promise.reject(error);
        }
        
		if ( error.response.status === 401 && originalRequest.url === baseURL + 'token-refresh/') {
            localStorage.removeItem('token');
			if( window.location.pathname !== '/login/' ){
				window.location.href = '/login/?next=' + window.location.pathname;
			}
			return Promise.reject(error);
		}
        
        if(
            error.response.data.code === 'token_not_valid' 
			// &&
			// error.response.status === 401 &&
			// error.response.statusText === 'Unauthorized'
        ){
            const refreshToken = localStorage.getItem('token') ? JSON.parse(localStorage.getItem('token')).refresh : null ;
            if (refreshToken) {
				const tokenParts = JSON.parse(atob(refreshToken.split('.')[1]));

				// exp date in token is expressed in seconds, while now() returns milliseconds:
				const now = Math.ceil(Date.now() / 1000);
				console.log(tokenParts.exp);

				if (tokenParts.exp > now) {
					return axiosInstance
						.post('/token-refresh/', { refresh: refreshToken })
						.then((response) => {
							localStorage.setItem('token', JSON.stringify(response.data) );
							axiosInstance.defaults.headers['Authorization'] =
								'Bearer ' + response.data.access;
							originalRequest.headers['Authorization'] =
								'Bearer ' + response.data.access;

							return axiosInstance(originalRequest);
						})
						.catch((err) => {
							console.log(err);
						});
				} else {
					console.log('Refresh token is expired', tokenParts.exp, now);
                    localStorage.removeItem('token');
					if( window.location.pathname !== '/login/' ){
						window.location.href = '/login/?next=' + window.location.pathname;
					}
				}
			} else {
				console.log('Refresh token not available.');
                localStorage.removeItem('token');
				if( window.location.pathname !== '/login/' ){
					window.location.href = '/login/?next=' + window.location.pathname;
				}
			}
        }

		if( error.response.status === 403 && error.response.statusText === 'Forbidden' ){
			localStorage.removeItem('token');
			if( window.location.pathname !== '/login/' ){
				window.location.href = '/login/?next=' + window.location.pathname;
			}
			
			return Promise.reject(error);
		}
		return Promise.reject(error);
		
    },
);

export default axiosInstance;

