class WebSocketService {
    
    static instance = null;
    disconnect = false
    checkToken = null
    dispatch = null
    verifyUserPermission = null
    static getInstance() {
        if(!WebSocketService.instance){
            WebSocketService.instance = new WebSocketService();
        }
        return WebSocketService.instance
    }

    constructor() {
        this.socketRef = null
    }

    async connect() {
        this.verifyUserPermission( (havePermission)=>{
            let token = localStorage.getItem('token') ? JSON.parse(localStorage.getItem('token')) : null;
            if(token && havePermission ){
                const path = `${process.env.REACT_APP_WS_URL}/ws/notification/?token=`+token.access;
                this.socketRef = new WebSocket(path)
                this.socketRef.onopen = () => {
                    console.log('websocket notification open');
                    this.send( { command: 'fetch_notifications' } )
                }
        
                this.socketRef.onmessage = (e) => {
                    let data = JSON.parse(e.data)
                    this.verifyUserPermission( (havePermission)=>{
                        if(havePermission){
                            this.dispatch(data)
                        }
                    })
                }
    
                this.socketRef.onerror = (e) => {
                    console.log(e.message);
                }
        
                this.socketRef.onclose = () => {
                    if(!this.disconnect){
                        setTimeout(()=>{
                            console.log("try to reconnect notification")
                            this.connect()
                        },5000);
                    }
                }
            }else{
                if(!this.disconnect){
                    setTimeout(()=>{
                        console.log("try to reconnect notification")
                        this.connect()
                    },5000)
                }
            }
        } )
    }   

    close(){
        if(this.socketRef){
            console.log('disconnect notification')
            this.disconnect = true;
            this.socketRef.close();
        }

    }

    addNotificationDispatch(dispatch){
        this.dispatch = dispatch
    }

    setVerifyUserPermission(verifyUserPermission){
        this.verifyUserPermission = verifyUserPermission
    }

    send(data){
        this.verifyUserPermission( (havePermission)=>{
            if(havePermission){
                try {
                    let token = localStorage.getItem('token') ? JSON.parse(localStorage.getItem('token')).access : null; 
                    data.token = token
                    this.socketRef.send(JSON.stringify( {...data} ))
                } catch (error) {
                    console.log(error.message)
                }
            }
        })
    }

    state(){
        return this.socketRef.readyState
    }

}

const WebSocketInstance = WebSocketService.getInstance();

export default WebSocketInstance;