import React, {useEffect, useState, useReducer,useContext } from 'react';
import {  Badge } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { InboxOutlined } from '@mui/icons-material';
import axiosInstance from '../../common/axios';
import WebSocketInstance from './Websocket';
import { NotificationContext } from '../../contexts/NotificationContext';


const NotificationIcon = () => {
    const theme = useTheme();
    const { notifications,dispatch } = useContext(NotificationContext);
    const [ numberOfNotification, setNumberOfNotification ] = useState(0);
    const verifyUserPermission = (callback) => {
        axiosInstance.get('/auth/users/get_user_detail/').then((res)=>{
            callback(true);
        }).catch((error)=>{
            callback(false)
        })
    }

    useEffect(()=>{
        let numberOfNotReadNotification = 0;
        for(let i = 0;i < notifications.length; i++){
            let notification = notifications[i];
            if(!notification.is_read){
                numberOfNotReadNotification = numberOfNotReadNotification + 1
            }
        }
        setNumberOfNotification(numberOfNotReadNotification)
    },[notifications])

    useEffect(()=>{
        WebSocketInstance.setVerifyUserPermission( verifyUserPermission )
        WebSocketInstance.addNotificationDispatch( async (data)=>{
            dispatch(data)
        });
        WebSocketInstance.connect();
        return () => {
            WebSocketInstance.close();
         }
    },[])// eslint-disable-line react-hooks/exhaustive-deps

    return ( 
        <Badge badgeContent={numberOfNotification} color="error">
            <InboxOutlined sx={{color: theme.palette.primary.light }} />
        </Badge>
     );
}
 
export default NotificationIcon;