import * as React from 'react';
import { useContext, useState } from "react";
import { styled, useTheme } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import IconButton from '@mui/material/IconButton';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { Grid,Avatar, Button, Typography } from '@mui/material';

import { makeStyles } from '@mui/styles';
import { AuthContext } from "../contexts/AuthContext";
import { useLocation, useNavigate } from 'react-router-dom';
import { AUTH_LOGOUT } from '../store/action/actionTypes';
import { SummarizeOutlined, ContactPageOutlined,VpnKeyOutlined,AddCircleOutline, ListAltOutlined, LogoutOutlined, MenuOutlined, AccountCircleOutlined } from '@mui/icons-material';
import NotificationIcon from './notification/NotificationIcon';
import axiosInstance from '../common/axios';

const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(7)} + 1px)`,
  },
});


const DrawerHeader = styled('div')(({ theme }) => ({
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  }));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    backgroundColor: '#000',
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);


const useStyles = makeStyles((theme)=>{
    return {
        logo: {
            height: theme.spacing(4),
            mr: theme.spacing(2),
        },    
        page:{
            display: 'block',
            width: '100%',
            padding: theme.spacing(3)
          },
        toolbar: {
            ...theme.mixins.toolbar
        },
        icon: {
            color: theme.palette.primary.light
        },
        iconBtSelected: {
            backgroundColor: "#1976d214 !important"
        }
    }
})


export default function AppBarAndDrawer({children}) {
    const { user,dispatch } = useContext(AuthContext);
    const navigate = useNavigate()
    const theme = useTheme();
    const [open, setOpen] = useState(false);
    const classes = useStyles(open);
    const [ userDetail,setUserDetail ] = useState({});
    const { pathname } = useLocation();

    const handleDrawer = () => {
        setOpen( !open );
    };
    const handleLogout = () => {
        dispatch({type: AUTH_LOGOUT  })
    }

    React.useEffect(()=>{
        if( localStorage.getItem('token') ){
            axiosInstance.get('/auth/users/get_user_detail/').then((res)=>{
                setUserDetail(res.data)
            })
        }
    },[user])
    
    const drawerList = [
        { 
            text: 'Notification',
            icon: <NotificationIcon  />,
            path: '/notification'
        },
        {
            text: 'Create Topic',
            icon: <AddCircleOutline className={classes.icon} />,
            path: '/create-topic/'
        },
        { 
            text: 'Topic List',
            icon:  <ListAltOutlined className={classes.icon} />,
            path: '/'
        },
        { 
            text: 'My account',
            icon:  <AccountCircleOutlined className={classes.icon} />,
            path: '/my-account'
        },
    ];

    return (
        <React.Fragment>
                <Box>
                    <AppBar elevation={0} position="fixed" sx={{ 
                        background: theme.palette.background.default ,
                        zIndex: theme.zIndex.drawer + 1 
                    }} >
                        <Toolbar>
                            <Box
                                component="span" sx={{display: { xs: 'none', md: 'block' },mr: 2 }}
                            >
                                <img src="/bdms-logo.png" className={classes.logo} alt="logo" />
                            </Box>
                            <IconButton
                                size="large"
                                edge="start"
                                color="primary"
                                aria-label="menu"
                                onClick={ ()=> handleDrawer() }
                            >
                                <Avatar sx={{ bgcolor: theme.palette.primary.light }} variant="rounded">
                                    <MenuOutlined />
                                </Avatar>
                            </IconButton>
                            
                            <Typography color="black" variant="h6" component="div" sx={{ flexGrow: 1 }}>
                                Pre-Operation Discussion
                            </Typography>
                            { !user.isAuthenticated && <Button color="primary" onClick={(e)=> navigate('/login') } >Login</Button> }

                        </Toolbar>
                    </AppBar>
                </Box>
                
                <Box sx={{display: 'flex' }}>
                        { user.isAuthenticated && (
                            <React.Fragment>
                                <Drawer sx={{display:{xs:'none',md: "block"}}} variant="permanent" open={open}>
                                    <DrawerHeader >
                                    </DrawerHeader>
                                    <List>
                                        {drawerList.map((item, index) => (
                                        <ListItem selected={ pathname === item.path } button key={index} onClick={()=> navigate(item.path) }>
                                            <ListItemIcon >
                                                {item.icon}
                                            </ListItemIcon>
                                            <ListItemText secondary={item.text} />
                                        </ListItem>
                                        ))}
                                        { userDetail && userDetail.login_detail && userDetail.login_detail.login_type ==='normal' && (
                                            <ListItem selected={ pathname === '/change-password/' } button onClick={()=> navigate('/change-password/') }>
                                                <ListItemIcon >
                                                    <VpnKeyOutlined sx={{color: theme.palette.primary.light }} />
                                                </ListItemIcon>
                                                <ListItemText secondary="Change password" />
                                            </ListItem>
                                        ) }

                                        { userDetail.is_staff && (
                                            <ListItem selected={ pathname === '/manage-user/' } button onClick={()=> navigate('/manage-user/') }>
                                                <ListItemIcon >
                                                    <ContactPageOutlined sx={{color: theme.palette.primary.light }} />
                                                </ListItemIcon>
                                                <ListItemText secondary="Manage users" />
                                            </ListItem>
                                        )}

                                        <ListItem button onClick={()=> window.open( process.env.REACT_APP_BACKEND_URL + "/core/user-manual") }>
                                            <ListItemIcon >
                                                <SummarizeOutlined sx={{color: theme.palette.primary.light }} />
                                            </ListItemIcon>
                                            <ListItemText secondary={`User manual`} />
                                        </ListItem>

                                        <ListItem button onClick={()=> handleLogout() }>
                                            <ListItemIcon >
                                                <LogoutOutlined sx={{color: theme.palette.primary.light }} />
                                            </ListItemIcon>
                                            <ListItemText secondary={`Logout (${userDetail.username})`} />
                                        </ListItem>
                                    </List>
                                </Drawer>

                                <AppBar position="fixed" color="primary" sx={{ display:{xs:'block',md: "none"}, top: 'auto', bottom: 0,background: theme.palette.background.default  }}>
                                    <Toolbar align = "center" justify = "center" alignItems = "center">
                                            <Grid item xs={12} style={{textAlign: "center"}}>

                                                    {drawerList.map((item, index) => (
                                                        <IconButton className={ pathname === item.path ? classes.iconBtSelected : null} sx={{ml:1}} key={index} onClick={()=> navigate(item.path) }>
                                                            {item.icon}
                                                        </IconButton>
                                                    ))}
                                                    { userDetail && userDetail.login_detail && userDetail.login_detail.login_type ==='normal' && (
                                                        <IconButton className={ pathname === '/change-password/'  ? classes.iconBtSelected : null} sx={{ml:1}} onClick={()=> navigate('/change-password/') }>
                                                            <VpnKeyOutlined className={classes.icon} />
                                                        </IconButton>
                                                    ) }
                                                    { userDetail.is_staff && (
                                                        <IconButton className={ pathname === '/manage-user/'  ? classes.iconBtSelected : null} sx={{ml:1}} onClick={()=> navigate('/manage-user/') }>
                                                            <ContactPageOutlined className={classes.icon} />
                                                        </IconButton>
                                                    )}
                                                    <IconButton sx={{ml:1}} onClick={()=> window.open( process.env.REACT_APP_BACKEND_URL + "/core/user-manual") } >
                                                        <SummarizeOutlined className={classes.icon} />
                                                    </IconButton>
                                                    <IconButton sx={{ml:1 }} onClick={()=> handleLogout() }>   
                                                        <LogoutOutlined className={classes.icon} />
                                                    </IconButton>

                                            </Grid>
                                    </Toolbar>
                                </AppBar>
                            </React.Fragment> 
                            )
                        }

                    <Box component="main" sx={{ flexGrow: 1}}>
                        <div className={classes.toolbar}></div>
                        <Box sx={{padding:2,mb:10}}>
                            {children}
                        </Box>
                    </Box>
                </Box>
        </React.Fragment>

    );
}
