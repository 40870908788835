import CreateTopic from '../pages/CreateTopic'
import EditTopic from '../pages/EditTopic'
import Login from '../pages/Login'
import TopicRoom from '../pages/TopicRoom'
import TopicList from '../pages/TopicList'
import Notification from '../pages/Notification'
import MyAccount from '../pages/MyAccount'
import ChangePassword from '../pages/ChangePassword'
import ForgotPassword from '../pages/ForgotPassword'
import ResetPassword from '../pages/ResetPassword'
import Register from '../pages/Register'
import CreateUserComplete from '../pages/CreateUserComplete'
import ManageUser from '../pages/ManageUser'
import TopicFile from '../pages/TopicFile'
const routes = [
    {
        path: '/login',
        component: <Login />,
        isPrivate: false,
    },
    {
        path: '/',
        component: <TopicList />,
        isPrivate: true,
    },
    {
        path: '/notification',
        component: <Notification />,
        isPrivate: true,
    },
    {
        path: '/topic/:topicId',
        component: <TopicRoom />,
        isPrivate: true,
    },
    {
        path: '/create-topic/',
        component:  <CreateTopic />,
        isPrivate: true,
    },
    {
        path: '/topic-file/:topicId',
        component:  <TopicFile />,
        isPrivate: true,
    },
    {
        path: '/edit-topic/:topicId',
        component:  <EditTopic />,
        isPrivate: true,
    },
    {
        path: '/my-account/',
        component:  <MyAccount />,
        isPrivate: true,
    },
    {
        path: '/change-password/',
        component:  <ChangePassword />,
        isPrivate: true,
    },
    {
        path: '/forgot-password/',
        component:  <ForgotPassword />,
        isPrivate: false,
    },
    {
        path: '/reset-password/',
        component:  <ResetPassword />,
        isPrivate: false,
    },
    {
        path: '/register/',
        component:  <Register />,
        isPrivate: false,
    },
    {
        path: '/create-user-complete/',
        component:  <CreateUserComplete />,
        isPrivate: false,
    },
    {
        path: '/manage-user/',
        component:  <ManageUser />,
        isPrivate: true,
    },
]

export default routes