import React, { useEffect,useState } from "react";
import { Box, Card, InputAdornment, CardActions, Button, TextField, Snackbar, Alert, CardHeader, Divider, IconButton, Typography } from "@mui/material";
import axiosInstance from "../common/axios";
import { useNavigate } from "react-router-dom";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import {CopyToClipboard} from 'react-copy-to-clipboard';

const MyAccount = () => {
    const [ formData, setFormData ] = useState({});
    const [openAlert, setOpenAlert] = React.useState(false);
    const [alertMessage, setAlertMessage] = React.useState('');
    const [alertType, setAlertType] = React.useState('success');
    const navigate = useNavigate();

    useEffect(()=>{
        axiosInstance.get('/auth/users/get_user_detail/').then((res)=>{
            let userDetail = res.data;
            setFormData(userDetail)
        })
    },[])

    const handleSubmit = (e) => {
        e.preventDefault()
        if( formData.first_name && formData.last_name && formData.email ){
            
            axiosInstance.get('/auth/users/get_user_detail/').then((res)=>{
                let userDetail = res.data;
                axiosInstance.patch(`/auth/users/${userDetail.id}/`,formData).then((res)=>{
                    if(res){
                        setAlertType('success');
                        setAlertMessage("update user success");
                        setOpenAlert(true);
                    }else{
                        setAlertType('error');
                        setAlertMessage("something went wrong");
                        setOpenAlert(true);
                    }

                }).catch((error)=>{
                    Object.keys(error.response.data).forEach(function(key) {
                        let errorMsg = error.response.data[key][0]
                        setAlertType('error');
                        setAlertMessage( key + " : " + errorMsg);
                        setOpenAlert(true);
                    })
                })
            })
            
        }else{
            setAlertType('warning')
            setAlertMessage("Please check first name, last name and email");
            setOpenAlert(true);
        }
    }

    const handleCloseAlert = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
    
        setOpenAlert(false);
      };

    const copyRegisterLineNotification = ( ) => {
        setAlertType('success');
        setAlertMessage("copied success");
        setOpenAlert(true);
    }

    return ( 
        <React.Fragment>
            <Card sx={{mt:2}} elevation={0} component="form" onSubmit={handleSubmit} noValidate>
                <CardHeader 
                    title="My account" 
                />    
                <Divider ></Divider>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    padding:3
                }}>
                    <TextField
                        value={formData.username ? formData.username : ''}
                        margin="normal"
                        sx={{width:{xs:'100%',md: "40%"}}}
                        id="username"
                        label="Username"
                        name="username"
                        autoComplete="username"
                        autoFocus
                        size="small"
                        onChange={(e)=> setFormData({ ...formData, username: e.target.value }) }
                    /> 
                    <TextField
                            value={formData.first_name ? formData.first_name : ''}
                            margin="normal"
                            sx={{width:{xs:'100%',md: "40%"}}}
                            label="First name"
                            name="first_name"
                            autoComplete="first name"
                            autoFocus
                            size="small"
                            onChange={(e)=> setFormData({ ...formData, first_name: e.target.value }) }
                    /> 
                    <TextField
                            value={formData.last_name ? formData.last_name : ''}
                            margin="normal"
                            sx={{width:{xs:'100%',md: "40%"}}}
                            label="Last name"
                            name="last_name"
                            autoComplete="last name"
                            autoFocus
                            size="small"
                            onChange={(e)=> setFormData({ ...formData, last_name: e.target.value }) }
                    /> 
                    <TextField
                            value={formData.email ? formData.email : ''}
                            margin="normal"
                            sx={{width:{xs:'100%',md: "40%"}}}
                            label="Email"
                            name="email"
                            autoComplete="email"
                            autoFocus
                            size="small"
                            onChange={(e)=> setFormData({ ...formData, email: e.target.value }) }
                    /> 
                    <TextField
                            value={formData.register_line_notification ? formData.register_line_notification : ''}
                            margin="normal"
                            sx={{width:{xs:'100%',md: "40%"}}}
                            label="Register line notification"
                            name="register_line_notification"
                            autoComplete="register_line_notification"
                            autoFocus
                            size="small"
                            InputProps={{
                                endAdornment: (
                                  <InputAdornment position="start">
                                    <CopyToClipboard text={formData.register_line_notification ? formData.register_line_notification : ''}
                                        onCopy={() => copyRegisterLineNotification() }>
                                        <IconButton>
                                            <ContentCopyIcon />
                                        </IconButton>
                                      </CopyToClipboard>
                                  </InputAdornment>
                                ),
                            }}

                    /> 
                        <Button type="submit" variant="outlined" >
                            Save
                        </Button>    
                </Box>

                <CardActions sx={{ p: 1.25, pt: 0, mt: 2, justifyContent: 'center' }}>
                    <img src={"/" + process.env.REACT_APP_LINE_OFFICIAL_ACCOUNT_QR_CODE } alt="line-ofiicial." />
                </CardActions>
                <CardActions sx={{ p: 1.25, pt: 0, mt: 1, justifyContent: 'center' }}>
                    <Typography color="text.secondary">
                        Pre-Operation Line official QR code
                    </Typography>
                </CardActions>
                <Snackbar open={openAlert} autoHideDuration={6000} onClose={handleCloseAlert}>
                    <Alert onClose={handleCloseAlert} severity={alertType} sx={{ width: '100%' }}>
                        {alertMessage}
                    </Alert>
                </Snackbar>
            </Card>
        </React.Fragment>
     );
}
 
export default MyAccount;