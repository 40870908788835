import React, { forwardRef, useState, useImperativeHandle } from 'react';
import { Switch, FormControlLabel, Divider, CardActions, CardContent, Card, Button, Box, FormControl, Grid, InputLabel, MenuItem, Select, TextField, Typography, Collapse } from '@mui/material';
import { DropzoneArea } from 'material-ui-dropzone';
import { makeStyles } from '@mui/styles';
import FilePreview from '../comment/FilePreview';
const useStyles = makeStyles((theme)=>{
    return {
        root: (canEdit) => {
            let style = {}
            if(!canEdit){
                style["& .MuiFormLabel-root"] = {pointerEvents: 'none'}
                style['pointerEvents'] = 'none' 
            }
            return style
        },
        hidden: (canEdit) => {
            let style = {}
            if(!canEdit){
                style['display'] = 'none !important'  
            }
            return style
        }
    }
})

const TopicDetailForm = forwardRef(({canEdit,specialtySelected,handleSpecialtyChange,specialties,hospitalSelected,handleHospitalChange,hospitals},ref) => {
    const [ selectedFiles, setSelectedFiles ] = useState([]);
    const [ deletedFiles, setDeletedFiles ] = useState([]);
    const classes = useStyles(canEdit);
    const [ showDropFile, setShowDropFile ] = useState(false);

    useImperativeHandle(ref,()=>({
        setData(data){
            if(canEdit){
                setSelectedFiles(data.topic_file_detail)
            }
        },
        verifyRequireField(){
            return true
        },
        getData(){
            let data = {
                detail: null,
                files : selectedFiles,
                deletedFiles: deletedFiles
            }
            return data
        }
    }));

    const deleteFile = (index) => {
        let file = selectedFiles[index];
        if( file.id ){
            setDeletedFiles([...deletedFiles,file])
        }
        setSelectedFiles( selectedFiles.filter((file,f_index)=>{ return f_index !== index }) )
    }

    const fileDescOnChange = (index,value) => {
        let files = selectedFiles.map((file,file_index)=>{
            if(file_index === index){
                file.description = value;
            }
            return file;
            
        })
        setSelectedFiles(files)
    }


    const selectFiles = (filesObject) => {
        if(filesObject.length > 0){
            let files = [];
            for(let i = 0; i < filesObject.length; i++){
                files.push({
                    file: filesObject[i],
                    detail: filesObject[i],
                    name: filesObject[i].name,
                    description : null,
                    size: filesObject[i].size,
                    type: [ 'image', 'video' ].includes( filesObject[i].type.split('/')[0] ) ?  filesObject[i].type.split('/')[0] : 'other'

                })
            }
            setSelectedFiles([...selectedFiles,...files]);
        }
    };


    return ( 
        <React.Fragment>
            <Box className={classes.root} sx={{padding:3}}>
                <Typography sx={{mb:2}} variant="h6" component="div">
                    Topic Detail
                </Typography>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={3}>
                        <FormControl fullWidth>
                            <InputLabel id="specialty-label">Specialty</InputLabel>
                            <Select 
                                size="small"
                                labelId='specialty-label'
                                id="specialty-select"
                                value={specialtySelected}
                                label="Specialty"
                                onChange={handleSpecialtyChange}
                            >
                                { specialties.map(item=> <MenuItem key={item.id} value={item.id} >{item.name}</MenuItem> ) }
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <FormControl fullWidth>
                            <InputLabel id="hospital-label">Hospital</InputLabel>
                            <Select 
                                size="small"
                                labelId='hospital-label'
                                id="hospital-select"
                                value={hospitalSelected}
                                label="Hospital"
                                onChange={handleHospitalChange}
                            >
                                { hospitals.map(item=> <MenuItem key={item.id} value={item.id} >{item.name}</MenuItem> ) }
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
                <Divider className={classes.hidden} sx={{mt:1}}> 
                    <FormControlLabel 
                        control={<Switch checked={showDropFile} onChange={ (e)=> setShowDropFile(e.target.checked) } />}
                        label="Drop file(s)"
                    />
                </Divider>
                <Box className={classes.hidden} >
                    <Grid container>         
                        { selectedFiles && selectedFiles.map((file,index)=>{
                            return (
                                <Grid key={index} item xs={12} md={3}>
                                    <Card elevation={0} >
                                        <CardContent>
                                            <FilePreview file={file} />
                                            <TextField value={file.description ? file.description : '' }  size="small" onChange={(e)=>{ fileDescOnChange(index,e.target.value) }} sx={{width: '100%', mt: 2}} label="description" variant="outlined"  />
                                        </CardContent>
                                        <CardActions sx={{float: 'right'}}>
                                            <Button onClick={ ()=>{ deleteFile(index) }  } size="small">Remove</Button>
                                        </CardActions>
                                    </Card>
                                </Grid>
                            )
                        }) }
                    </Grid>
                </Box>
                { canEdit && (
                    <Collapse in={showDropFile}>
                        <Box sx={{padding:3}} className={classes.hidden} >
                            <DropzoneArea clearOnUnmount={false}  key={selectedFiles.length} initialFiles={[]} previewText="" showPreviews={false} showPreviewsInDropzone={false} filesLimit={100} maxFileSize={30000000} onChange={(files) => { selectFiles(files); }} />
                        </Box>
                    </Collapse>
                )}
            </Box>
        </React.Fragment>
     );
})
 
export default TopicDetailForm;