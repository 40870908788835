import React, { useState, forwardRef, useImperativeHandle, useContext } from "react";
import { Grid, FormControl, FormLabel, RadioGroup, FormControlLabel, Radio, TextField} from '@mui/material';
import { verifyRequireField } from "../../../utils";
import { ClassesContext } from "../../../../../contexts/Classes";

const NeurologicalFunction = forwardRef( ({canEdit},ref) => {
    const [ errors, setErrors ] = useState({});
    const [ neurologicalFunction, setNeurologicalFunction ] = useState(null);
    const [ weaknessText, setWeaknessText ] = useState('');
    const { hiddenFormInputStyle } = useContext(ClassesContext);

    useImperativeHandle(ref,()=>({
        setData(data){
            setNeurologicalFunction( data ? data.neurologicalFunction : null )
            setWeaknessText( data ? data.weaknessText : '' )
        },
        verifyData(){
            let requireFields = [
                { field: 'neurologicalFunction' ,errorName: 'neurologicalFunction' },
            ]
            let {isRequireFieldsValid,errorObject} = verifyRequireField(requireFields,getFormData());
            setErrors({ ...errorObject });
            return isRequireFieldsValid
        },
        getData(){
            return getFormData()
        }
    }))

    const getFormData = () => {
        return {
            neurologicalFunction,
            weaknessText
        }
    }

    return ( 
        <React.Fragment>
            <Grid container spacing={1} sx={{mt:1}}>
                <Grid item xs={12} md={12} >
                    <FormLabel error={ errors.neurologicalFunction ? true: false } >2. Neurological function*</FormLabel>
                </Grid>
                <Grid item xs={12} md={12} sx={{ml:2}}>
                    <FormControl>
                        <RadioGroup
                            row
                            value={ neurologicalFunction }
                            onChange={ e => setNeurologicalFunction(e.target.value) }
                            name="neurologicalFunction"
                        >
                            <FormControlLabel value="intact" label="Intact" control={<Radio  />} sx={{
                                ...hiddenFormInputStyle({value: neurologicalFunction==='intact', canEdit})
                            }} />
                            <FormControlLabel value="weakness" label="Weakness" control={<Radio />} sx={{
                                ...hiddenFormInputStyle({value: neurologicalFunction==='weakness', canEdit})
                            }} />
                        </RadioGroup>                        
                    </FormControl>
                </Grid>
                { neurologicalFunction === 'weakness' && (
                    <Grid item xs={12} md={12} sx={{ml:2}}> 
                        <TextField 
                            fullWidth
                            multiline
                            sx={{
                                ...hiddenFormInputStyle({value: neurologicalFunction==='weakness', canEdit}),
                                resize: "both"
                            }}
                            size="small" 
                            value={weaknessText}
                            onChange={e=>setWeaknessText(e.target.value)}
                            label="please specify"
                        />
                    </Grid>
                ) }
            </Grid>

        </React.Fragment>
     );
});
 
export default NeurologicalFunction;