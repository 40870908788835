import React, { useEffect, useState, forwardRef, useImperativeHandle, useContext } from "react";
import { Grid, TextField} from '@mui/material';
import { verifyRequireField } from "../utils";
import { ClassesContext } from "../../../contexts/Classes";
import moment from 'moment';


const AttendingSurgeonAndDateTime = forwardRef( ({canEdit},ref) => {
    const [ errors, setErrors ] = useState({});
    const [ attendingSurgeon, setAttendingSurgeon ] = useState(null)
    const [ dateTime, setDateTime ] = useState(null)
    const { hiddenFormInputStyle  } = useContext(ClassesContext);

    useImperativeHandle(ref,()=>({
        setData(data){
            if( data && data.attendingSurgeon ){
                setAttendingSurgeon(data.attendingSurgeon)
            }
            if( data && data.dateTime && !canEdit ){
                setDateTime(data.dateTime)
            }
            
        },
        verifyData(){
            let requireFields = []
            let {isRequireFieldsValid,errorObject} = verifyRequireField(requireFields,getFormData())
            setErrors({ ...errorObject });
            return isRequireFieldsValid
        },
        getData(){
            return getFormData()
        }
    }))

    const getFormData = () => {
        return {
            attendingSurgeon,
            dateTime
        }
    }
    

    useEffect(()=>{
        if(canEdit){
            let now = new Date();
            setDateTime(moment(now).format('YYYY-MM-DDTHH:mm'))
        }
    },[canEdit])

    return ( 
        <React.Fragment>
            <Grid container spacing={1} sx={{mt:1}} >
                <Grid item xs={12} md={4}>
                    <TextField 
                        value={ attendingSurgeon ? attendingSurgeon : ''} 
                        onChange={e=>setAttendingSurgeon(e.target.value)} 
                        fullWidth 
                        size="small" 
                        multiline 
                        label="Attending surgeon's" 
                        sx={{ 
                            ...hiddenFormInputStyle({value:attendingSurgeon,canEdit}) 
                        }}
                    />
                </Grid>
                <Grid item xs={12} md={4}>
                    <TextField
                            label="Date/Time"
                            type="datetime-local"
                            value={ dateTime? dateTime : '' }
                            size="small"
                            defaultValue={ dateTime }
                            fullWidth
                            sx={{ 
                                ...hiddenFormInputStyle({value:dateTime,canEdit}) 
                            }}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            onChange={ e=> {
                                setDateTime(e.target.value)
                            }}

                        />                
                </Grid>
                
            </Grid>
        </React.Fragment>
     );
});
 
export default AttendingSurgeonAndDateTime;