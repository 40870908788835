import React, { useState, forwardRef, useImperativeHandle,useContext } from "react";
import { Box, FormLabel, OutlinedInput, Grid, InputAdornment } from '@mui/material';
import { verifyRequireField } from "../../../utils";
import { ClassesContext } from "../../../../../contexts/Classes";

const PlanOperative = forwardRef( ({canEdit},ref) => {
    const [ errors, setErrors ] = useState({});
    const [ planOperativeHours, setPlanOperativeHours ] = useState('');
    const [ planOperativeMinutes, setPlanOperativeMinutes ] = useState('');
    const { hiddenFormInputStyle } = useContext(ClassesContext);
    useImperativeHandle(ref,()=>({
        setData(data){
            setPlanOperativeHours(data && data.planOperativeHours)
            setPlanOperativeMinutes(data && data.planOperativeMinutes)
        },
        verifyData(){
            let requireFields = [
            ]
            let {isRequireFieldsValid,errorObject} = verifyRequireField(requireFields,{planOperativeMinutes,planOperativeHours});
            setErrors({ ...errorObject });
            return isRequireFieldsValid
        },
        getData(){
            return {planOperativeMinutes,planOperativeHours}
        }
    }))
    
    return ( 
        <React.Fragment>
                <Grid container spacing={1} sx={{mt:1}}>
                    <Grid item xs={12} md={4} sx={{...hiddenFormInputStyle({value: planOperativeHours || planOperativeMinutes ? true : false , canEdit})}}  >
                        <FormLabel error={ errors.planOperativeTime ? true: false } >Plan operative time</FormLabel>
                        <Box sx={{display:'flex', ml:2}}>
                            <OutlinedInput
                                type="number"
                                name="planOperativeHours"
                                value={ planOperativeHours }
                                onChange={(e)=> setPlanOperativeHours(e.target.value) }
                                endAdornment={<InputAdornment position="end">hours</InputAdornment>}
                                size="small"
                                inputProps={{
                                    'aria-label': 'hours',
                                }}
                            />
                            <OutlinedInput
                                    sx={{ml:1}} 
                                    type="number"
                                    name="planOperativeMinutes"
                                    value={ planOperativeMinutes }
                                    onChange={ e=> setPlanOperativeMinutes(e.target.value) }
                                    endAdornment={<InputAdornment position="end">minutes</InputAdornment>}
                                    size="small"
                                    inputProps={{
                                        'aria-label': 'minutes',
                                    }}
                                />
                        </Box>
        
                    </Grid>
                </Grid>
        </React.Fragment>
     );
});
 
export default PlanOperative;