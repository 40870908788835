import React, { forwardRef, useImperativeHandle, useRef, useContext } from 'react';
import { Box, Divider, Collapse } from '@mui/material';
import OperativeTime from './OperativeTime';
import PreOperativeDiagnosis from './PreOperativeDiagnosis';
import PostOperativeDiagnosis from './PostOperativeDiagnosis';
import Surgeon from './Surgeon';
import ScrubNurse from './ScrubNurse';
import Position from './Position';
import Approach from './Approach';
import ImplantInformation from './ImplantInformation';
import Anesthesia from './Anesthesia';
import OperativeFindings from './OperativeFindings';
import OperativeProcedureNote from './OperativeProcedureNote';
import EstimateBloodLoss from './EstimateBloodLoss';
import UseCellSaver from './UseCellSaver';
import QualityOfReduction from './QualityOfReduction';
import IntraoperatioveComplication from './IntraoperatioveComplication';
import OtherRemark from './OtherRemark';
import { ClassesContext } from "../../../../../contexts/Classes";
import AttendingSurgeonAndDateTime from '../../../common/AttendingSurgeonAndDateTime';
import FormLabel from '../../../common/FormLabel';

export const Main = forwardRef(({canEdit,topic},ref) => {
    const [expanded, setExpanded] = React.useState(canEdit);
    const { useStyles } = useContext(ClassesContext);
    const classes = useStyles(canEdit);
    const preOperativeDiagnosisRef = useRef();
    const postOperativeDiagnosisRef = useRef();
    const surgeonRef = useRef();
    const positionRef = useRef();
    const scrubNurseRef = useRef();
    const operativeTimeRef = useRef();
    const approachRef = useRef();
    const operativeFindingsRef = useRef();
    const operativeProcedureNoteRef = useRef();
    const estimateBloodLossRef = useRef();
    const qualityOfReductionRef = useRef();
    const otherRemarkRef = useRef();
    const intraoperatioveComplicationRef = useRef();
    const useCellSaverRef = useRef();
    const anesthesiaRef = useRef();
    const implantInformationRef = useRef();
    const attendingSurgeonAndDateTimeRef = useRef();


    useImperativeHandle(ref,()=>({
        setData(dataObject){
            operativeTimeRef.current.setData( dataObject.operativeTime ? dataObject.operativeTime : {});
            surgeonRef.current.setData( dataObject.surgeon ? dataObject.surgeon : {} );
            positionRef.current.setData( dataObject.position ? dataObject.position : {} );
            scrubNurseRef.current.setData( dataObject.scrubNurse ? dataObject.scrubNurse : [] );
            preOperativeDiagnosisRef.current.setData( dataObject.preOperativeDiagnosis ? dataObject.preOperativeDiagnosis : {} );
            postOperativeDiagnosisRef.current.setData( dataObject.postOperativeDiagnosis ? dataObject.postOperativeDiagnosis : {});
            approachRef.current.setData( dataObject.approach ? dataObject.approach : {});
            operativeFindingsRef.current.setData( dataObject.operativeFindings ? dataObject.operativeFindings : '');
            operativeProcedureNoteRef.current.setData( dataObject.operativeProcedureNote ? dataObject.operativeProcedureNote : '');
            estimateBloodLossRef.current.setData( dataObject.estimateBloodLoss ? dataObject.estimateBloodLoss : '');
            qualityOfReductionRef.current.setData( dataObject.qualityOfReduction ? dataObject.qualityOfReduction : {});
            otherRemarkRef.current.setData( dataObject.otherRemark ? dataObject.otherRemark : '');
            intraoperatioveComplicationRef.current.setData( dataObject.intraoperatioveComplication ? dataObject.intraoperatioveComplication : {});
            useCellSaverRef.current.setData( dataObject.useCellSaver ? dataObject.useCellSaver : null);
            implantInformationRef.current.setData( dataObject.implantInformation ? dataObject.implantInformation : {});
            attendingSurgeonAndDateTimeRef.current.setData( dataObject.attendingSurgeonAndDateTime ? dataObject.attendingSurgeonAndDateTime : {});
            anesthesiaRef.current.setData( dataObject.anesthesia ? dataObject.anesthesia : null );
        },
        verifyRequireField(){
            let operativeTime = operativeTimeRef.current.verifyData();
            let surgeon = surgeonRef.current.verifyData();
            let position = positionRef.current.verifyData();
            let scrubNurse = scrubNurseRef.current.verifyData();
            let preOperativeDiagnosis = preOperativeDiagnosisRef.current.verifyData();
            let postOperativeDiagnosis = postOperativeDiagnosisRef.current.verifyData();
            let approach = approachRef.current.verifyData();
            let operativeFindings = operativeFindingsRef.current.verifyData();
            let operativeProcedureNote = operativeProcedureNoteRef.current.verifyData();
            let estimateBloodLoss = estimateBloodLossRef.current.verifyData();
            let qualityOfReduction = qualityOfReductionRef.current.verifyData();
            let otherRemark = otherRemarkRef.current.verifyData();
            let intraoperatioveComplication = intraoperatioveComplicationRef.current.verifyData();
            let useCellSaver = useCellSaverRef.current.verifyData();
            let implantInformation = implantInformationRef.current.verifyData();
            let attendingSurgeonAndDateTime = attendingSurgeonAndDateTimeRef.current.verifyData();
            let anesthesia = anesthesiaRef.current.verifyData();
            let fieldValid = (
                operativeTime &&
                surgeon &&
                position &&
                scrubNurse &&
                preOperativeDiagnosis &&
                postOperativeDiagnosis &&
                approach &&
                operativeFindings &&
                operativeProcedureNote &&
                implantInformation &&
                estimateBloodLoss &&
                useCellSaver &&
                qualityOfReduction &&
                intraoperatioveComplication &&
                otherRemark &&
                attendingSurgeonAndDateTime &&
                anesthesia 
            )
            return fieldValid
        },
        getData() {
            let data = {
                operativeTime : operativeTimeRef.current.getData(),
                surgeon : surgeonRef.current.getData(),
                position : positionRef.current.getData(),
                approach : approachRef.current.getData(),
                operativeFindings : operativeFindingsRef.current.getData(),
                operativeProcedureNote : operativeProcedureNoteRef.current.getData(),
                estimateBloodLoss : estimateBloodLossRef.current.getData(),
                qualityOfReduction : qualityOfReductionRef.current.getData(),
                otherRemark : otherRemarkRef.current.getData(),
                intraoperatioveComplication : intraoperatioveComplicationRef.current.getData(),
                useCellSaver : useCellSaverRef.current.getData(),
                implantInformation : implantInformationRef.current.getData(),
                anesthesia : anesthesiaRef.current.getData(),
                scrubNurse : scrubNurseRef.current.getData(),
                preOperativeDiagnosis : preOperativeDiagnosisRef.current.getData(),
                postOperativeDiagnosis : postOperativeDiagnosisRef.current.getData(),
                attendingSurgeonAndDateTime : attendingSurgeonAndDateTimeRef.current.getData(),
            }
            return data
        }
    }))

    return (
        <Box className={classes.root} sx={{padding:3}}>
            <FormLabel setExpanded={setExpanded} expanded={expanded} headerText=" BDMS Pelvic and Acetabulum Fracture : Operative Note" />
            <Divider ></Divider>
            <Collapse in={expanded}>
                <Box sx={{mt:1}}>
                    <OperativeTime canEdit={canEdit} ref={operativeTimeRef} />
                    <Divider sx={{mt:1}}></Divider>
                    <PreOperativeDiagnosis canEdit={canEdit} ref={preOperativeDiagnosisRef} /> 
                    <Divider></Divider>
                    <PostOperativeDiagnosis canEdit={canEdit} ref={postOperativeDiagnosisRef} />
                    <Surgeon canEdit={canEdit} ref={surgeonRef} topic={topic} /> 
                    <Anesthesia ref={anesthesiaRef} canEdit={canEdit} />
                    <ScrubNurse canEdit={canEdit} ref={scrubNurseRef} />
                    <Position canEdit={canEdit} ref={positionRef} />
                    <Approach canEdit={canEdit} ref={approachRef} />
                    <OperativeFindings canEdit={canEdit} ref={operativeFindingsRef} />
                    <ImplantInformation canEdit={canEdit} ref={implantInformationRef} />
                    <OperativeProcedureNote canEdit={canEdit} ref={operativeProcedureNoteRef} />
                    <EstimateBloodLoss canEdit={canEdit} ref={estimateBloodLossRef} />
                    <UseCellSaver canEdit={canEdit} ref={useCellSaverRef} />
                    <QualityOfReduction canEdit={canEdit} ref={qualityOfReductionRef} />
                    <IntraoperatioveComplication canEdit={canEdit} ref={intraoperatioveComplicationRef} />
                    <OtherRemark canEdit={canEdit} ref={otherRemarkRef} />
                    <AttendingSurgeonAndDateTime canEdit={canEdit} ref={attendingSurgeonAndDateTimeRef} />
                </Box>
            </Collapse>

        </Box>
    );
});