import React, { useEffect,useState } from "react";
import { Box, Card, CardActions, Grid, Button, TextField, Snackbar, Alert, CardHeader, Divider } from "@mui/material";
import axiosInstance from "../common/axios";
import { useNavigate } from "react-router-dom";


const ChangePassword = () => {
    const [ formData, setFormData ] = useState({});
    const [ errors, setErrors ] = useState({
        confirm_password: false,
        current_password: false,
        new_password: false
    })
    const [openAlert, setOpenAlert] = React.useState(false);
    const [alertMessage, setAlertMessage] = React.useState('');
    const [alertType, setAlertType] = React.useState('success');
    const navigate = useNavigate();

    useEffect(()=>{
        axiosInstance.get('/auth/users/get_user_detail/').then((res)=>{
            let userDetail = res.data;
            setFormData(userDetail)
        })
    },[])


    const handleSubmit = async (e) => {
        e.preventDefault()
        setErrors({
            confirm_password: false  ,
            current_password: false  ,
            new_password: false  ,
        })
        if( formData.confirm_password && formData.current_password && formData.new_password ){
            if( formData.confirm_password === formData.new_password ){
                axiosInstance.post('/auth/users/check_password/',{password: formData.current_password }).then((res)=>{
                    if(res.data.password){
                        axiosInstance.post('/auth/users/set_new_password/',{password: formData.new_password }).then((res)=>{
                            if(res.data){
                                setAlertType('success');
                                setAlertMessage("change password success");
                                setOpenAlert(true);
                                setTimeout(()=>{
                                    navigate('/my-account')
                                },2000)
                            }
                        })
                    }else{
                        setAlertType('error');
                        setAlertMessage("Current password invalid");
                        setOpenAlert(true);
                        setErrors({
                            confirm_password: false  ,
                            current_password: true  ,
                            new_password: false  ,
                        })
                    }
                })
            }else{
                setErrors({
                    current_password: false,
                    confirm_password: true  ,
                    new_password:  true  ,
                })
            }
        }else{
            setErrors({
                confirm_password: formData.confirm_password ? false : true  ,
                current_password: formData.current_password ? false : true  ,
                new_password: formData.new_password ? false : true  ,
            })
        }
    }

    const handleCloseAlert = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
        setOpenAlert(false);
      };

    return ( 
        <React.Fragment>
            <Card sx={{mt:2}} elevation={0} component="form" onSubmit={handleSubmit} noValidate>
                <CardHeader title="Change password" />
                <Divider ></Divider>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    padding:3
                }}>
                        <TextField
                                    error={errors.current_password}
                                    value={formData.current_password ? formData.current_password : ''}
                                    margin="normal"
                                    sx={{width:{xs:'100%',md: "40%"}}}
                                    id="current_password"
                                    label="Current password"
                                    name="current_password"
                                    size="small"
                                    type="password"
                                    onChange={(e)=> setFormData({ ...formData, current_password: e.target.value }) }
                            /> 
                            <TextField
                                    error={errors.new_password}
                                    value={formData.new_password ? formData.new_password : ''}
                                    margin="normal"
                                    sx={{width:{xs:'100%',md: "40%"}}}
                                    label="New password"
                                    name="new_password"
                                    size="small"
                                    type="password"
                                    onChange={(e)=> setFormData({ ...formData, new_password: e.target.value }) }
                            /> 
                            <TextField
                                    error={errors.confirm_password}
                                    value={formData.confirm_password ? formData.confirm_password : ''}
                                    margin="normal"
                                    sx={{width:{xs:'100%',md: "40%"}}}
                                    label="Confirm new password"
                                    name="confirm_password"
                                    type="password"
                                    size="small"
                                    onChange={(e)=> setFormData({ ...formData, confirm_password: e.target.value }) }
                            /> 

                    <CardActions sx={{ p: 1.25, pt: 0, justifyContent: 'center' }}>
                        <Button type="submit" variant="outlined" >
                            Submit
                        </Button>                        
                    </CardActions>
                </Box>
                <Snackbar open={openAlert} autoHideDuration={6000} onClose={handleCloseAlert}>
                    <Alert onClose={handleCloseAlert} severity={alertType} sx={{ width: '100%' }}>
                        {alertMessage}
                    </Alert>
                </Snackbar>
            </Card>
        </React.Fragment>
     );
}
 
export default ChangePassword;