import { Card, FormControl, InputLabel, MenuItem, Select, Box, Grid } from "@mui/material";
import React, { useEffect, useState, forwardRef,useImperativeHandle } from "react";
import axiosInstance from "../../common/axios";
import NormalComment from "./NormalComment";
import { FORM_TYPE_EXPERT_FORM, FORM_TYPE_OPERATIVE_NOTE,FORM_TYPE_EXPERT_SUMMARY, COMMENT_TYPE_NORMAL, FORM_TYPE_FOLLOW_UP } from "../../config/form";
import FormComment from "./FormComment";

const NewComment = forwardRef( ({ sendComment, topic },ref) => {
    
    const [ commentTypeList,setCommentTypeList ] = useState([])
    const [ commentType, setCommentType ] = useState(COMMENT_TYPE_NORMAL);

    useImperativeHandle(ref,()=>({
        refreshCommentType(){
            addFormToComment();
        }
    }))

    const verifyFormInTopic = async (form) => {
        let canCreate = false
        try {
            let res = await axiosInstance.get(`/topic/comment/?form__template__type=${form.key}&topic=${topic.id}`)
            if(res.data.count === 0 || form.allowMoreThanOne ){
                canCreate = true
            }
        } catch (error) {}
        return canCreate
    }

    const addFormToComment = async () => {
        let commentFormType = [
            FORM_TYPE_EXPERT_FORM,
            FORM_TYPE_OPERATIVE_NOTE,
            FORM_TYPE_EXPERT_SUMMARY,
            FORM_TYPE_FOLLOW_UP
        ]
        let token =  localStorage.getItem('token') ? JSON.parse(localStorage.getItem('token')).access : null; 
        if(token){
            let formList = [COMMENT_TYPE_NORMAL]
            for( let i = 0 ; i < commentFormType.length; i ++ ){
                let form = commentFormType[i]
                let canCreate = false;
                if( form.authenticatedUserCanCreate ){
                    canCreate = true && await verifyFormInTopic(form)
                }

                if(canCreate){
                    formList.push( form )
                }
            }
            setCommentTypeList( [...formList] )
            setCommentType(COMMENT_TYPE_NORMAL)
        }

    }



    useEffect(()=>{
        addFormToComment()
    },[])// eslint-disable-line react-hooks/exhaustive-deps


    return ( 
        <Card elevation={1} sx={{mt: 3}} >
            <Box sx={{padding: 3 }}>
                <Grid container>
                    <Grid item xs={12} md={4}>
                        <FormControl fullWidth>
                            <InputLabel id="comment-type-label">Comment type</InputLabel>
                            <Select 
                                size="small"
                                labelId = 'comment-type-label'
                                value={commentType}
                                label="Comment type"
                                onChange={(e)=> {
                                    setCommentType(e.target.value)
                                }}
                            >
                                { commentTypeList.map((item)=>{
                                    return <MenuItem key={item.key} value={item}>{item.label}</MenuItem>
                                }) }
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>

            </Box>
            { commentType.key === COMMENT_TYPE_NORMAL.key ? ( 
                <NormalComment sendComment={ sendComment } topic={topic} /> 
                ) : ( 
                <FormComment addFormToComment={addFormToComment} setCommentType={setCommentType} sendComment={ sendComment } topic={topic} form={commentType} /> 
                ) }

        </Card>

     );
})
 
export default NewComment;