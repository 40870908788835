import React, { useState, forwardRef, useImperativeHandle, useContext } from "react";
import { FormControl, FormLabel, FormControlLabel, Grid, TextField, RadioGroup, Radio } from '@mui/material';
import { getDataUpdate, verifyRequireField } from "../../../utils";
import { ClassesContext } from "../../../../../contexts/Classes";


const PatientHistory = forwardRef( ({canEdit},ref) => {

    const [ errors, setErrors ] = useState({});
    const [ formData, setFormData ] = useState({});
    const { hiddenFormInputStyle } = useContext(ClassesContext);

    useImperativeHandle(ref,()=>({
        setData(data){
            setFormData(data ? data:{})
        },
        verifyData(){
            let requireFields = [
                { field: 'dateOfInjury' ,errorName: 'dateOfInjury' },
                { field: 'causeOfInjury' ,errorName: 'causeOfInjury' },
                { field: 'causeOfInjuryText' , errorName: 'causeOfInjuryText' , parent: { field: 'causeOfInjury' , condition: 'compareValue' , valueForCompare: "other"  } },
                { field: 'medicalComorbidity' , errorName: 'medicalComorbidity'  },
                { field: 'medicalComorbidityText' , errorName: 'medicalComorbidityText', parent: { field: 'medicalComorbidity' , condition: 'compareValue' , valueForCompare: "yes"  } },
            ]
            let {isRequireFieldsValid,errorObject} = verifyRequireField(requireFields,formData)
            setErrors({ ...errorObject });
            return isRequireFieldsValid
        },
        getData(){
            return formData
        }
    }))

    const updateFormData = async (e) => {
        let dataUpdate = getDataUpdate(e);
        setFormData({...formData,...dataUpdate})
    }
    
    const handleMedicalComorbidity = (e)=>{
        let dataUpdate = getDataUpdate(e);
        setFormData({...formData,...dataUpdate , medicalComorbidityText: '' })
    }

    
    return ( 
        <React.Fragment>
            <Grid container spacing={0}>
                <Grid item xs={12} md={3} >
                    <TextField
                        sx={{ 
                            ...hiddenFormInputStyle({value:formData.dateOfInjury,canEdit}) 
                        }}
                        label="Date of injury*"
                        type="date"
                        value={ formData.dateOfInjury ? formData.dateOfInjury : null }
                        onChange={(e) => {
                            setFormData( {...formData, dateOfInjury: e.target.value } )
                        }}
                        size="small"
                        fullWidth
                        error={ errors.dateOfInjury ? true : false } 
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </Grid>
            </Grid>            
            <Grid container spacing={0} sx={{mt:1}}>
                <Grid item xs={12} md={12}>
                    <FormLabel error={ errors.causeOfInjury ? true: false } id="cause-of-injury-label">Cause of injury*</FormLabel>
                </Grid>
                <Grid item xs={12} md={12} sx={{ml:2}}>
                    <FormControl>
                        <RadioGroup
                            row
                            value={ formData.causeOfInjury ? formData.causeOfInjury : null }
                            aria-label='cause-of-injury-label'
                            onChange={updateFormData}
                            name="causeOfInjury"
                        >
                            <FormControlLabel 
                                value="motorcycleAccident" 
                                label="Motorcycle accident" 
                                control={<Radio />} 
                                sx={{ 
                                    ...hiddenFormInputStyle({ value: formData.causeOfInjury === 'motorcycleAccident' , canEdit}) 
                                }}
                            />
                            <FormControlLabel 
                                value="carAccident" 
                                label="Car accident" 
                                control={<Radio />} 
                                sx={{ 
                                    ...hiddenFormInputStyle({ value: formData.causeOfInjury === 'carAccident' , canEdit}) 
                                }}
                            />
                            <FormControlLabel 
                                value="pedestrianAccident" 
                                label="Pedestrian accident" 
                                control={<Radio />} 
                                sx={{ 
                                    ...hiddenFormInputStyle({ value: formData.causeOfInjury === 'pedestrianAccident' , canEdit}) 
                                }}
                            />
                            <FormControlLabel 
                                value="fallFromHeight" 
                                label="Fall from height" 
                                control={<Radio />} 
                                sx={{ 
                                    ...hiddenFormInputStyle({ value: formData.causeOfInjury === 'fallFromHeight' , canEdit}) 
                                }}
                            />
                            <FormControlLabel 
                                value="other" 
                                label={ formData.causeOfInjury === 'other' ? "Other*" : "Other" }
                                control={<Radio />} 
                                sx={{ 
                                    ...hiddenFormInputStyle({ value: formData.causeOfInjury === 'other' , canEdit}) 
                                }}
                            />
                        </RadioGroup>
                    </FormControl>
                </Grid>
                <Grid item xs={12} md={12} 
                    sx={{ 
                        ...hiddenFormInputStyle({value:formData.causeOfInjuryText,canEdit}),
                        ml:2 
                    }}
                >
                { formData.causeOfInjury && (
                        <TextField
                            sx={{resize:"both"}} 
                            multiline  
                            label="Cause of injury" 
                            name="causeOfInjuryText" 
                            size="small" 
                            error={ errors.causeOfInjuryText ? true : false } 
                            fullWidth 
                            value={ formData.causeOfInjuryText } 
                            onChange={ updateFormData } 
                        />
                    
                    )
                }
                </Grid> 
            </Grid>
            <Grid container spacing={0} sx={{mt: 1}}>
                <Grid item xs={12} md={12}>
                    <FormLabel error={ errors.medicalComorbidity ? true: false } id="medical-comorbidity-label">Medical comorbidity*</FormLabel>
                </Grid>
                <Grid item xs={12} md={12} sx={{ml:2}}>
                    <FormControl>
                        <RadioGroup
                            row
                            value={ formData.medicalComorbidity ? formData.medicalComorbidity : null }
                            aria-label='medical-comorbidity-label'
                            onChange={handleMedicalComorbidity}
                            name="medicalComorbidity"
                        >
                            <FormControlLabel 
                                value="no" 
                                label="No" 
                                sx={{ 
                                    ...hiddenFormInputStyle({value:formData.medicalComorbidity==='no',canEdit}) 
                                }}
                                control={<Radio />} 
                            />
                            <FormControlLabel 
                                value="yes" 
                                label="Yes" 
                                sx={{ 
                                    ...hiddenFormInputStyle({value:formData.medicalComorbidity==='yes',canEdit}) 
                                }}
                                control={<Radio />} 
                            />

                        </RadioGroup>
                    </FormControl>
                </Grid>
                <Grid item xs={12} md={12} sx={{ml:2}}>
                    { formData.medicalComorbidity === 'yes' && (
                            <TextField sx={{resize:"both"}} multiline  label="Medical comorbidity" name="medicalComorbidityText" size="small" error={ errors.medicalComorbidityText ? true : false } fullWidth value={ formData.medicalComorbidityText } onChange={ updateFormData } />
                        )
                    }
                 </Grid> 
            </Grid>
        </React.Fragment>
     );
})
 
export default PatientHistory;