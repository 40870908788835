import React, { useState, forwardRef, useImperativeHandle,useEffect, useContext } from "react";
import { Button,FormControl, FormLabel, FormControlLabel, FormGroup, Checkbox, Card, TextField, Grid, ListItem, ListItemText, IconButton } from '@mui/material';
import { getDataUpdate, verifyRequireField } from "../../../utils";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import DeleteIcon from '@mui/icons-material/Delete';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { REG_FLOAT_WITH_ONE_DEC, REG_NUMBER_WITHOUT_DEC } from "../../../../../config/format";
import { ClassesContext } from "../../../../../contexts/Classes";

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const move = (source, destination, droppableSource, droppableDestination) => {
  const sourceClone = Array.from(source);
  const destClone = Array.from(destination);
  const [removed] = sourceClone.splice(droppableSource.index, 1);

  destClone.splice(droppableDestination.index, 0, removed);

  const result = {};
  result[droppableSource.droppableId] = sourceClone;
  result[droppableDestination.droppableId] = destClone;

  return result;
};

const OperativeProcedure = forwardRef( ({canEdit},ref) => {
    const [ errors, setErrors ] = useState({});
    const [ formData, setFormData ] = useState({
        openReductionAndInternalFixationImplantsPlates:[{}],
        openReductionAndInternalFixationImplantsScrews:[{}]
    });
    const [ approachItem,setApproachItem ] = useState([
        { id: 'Ilioinguinal' , label: 'Ilioinguinal' },
        { id: 'stoppa' , label: 'Stoppa' },
        { id: 'pararectus' , label: 'Pararectus' },
        { id: 'kocherLangenbeck' , label: 'Kocher Langenbeck' },
        { id: 'trochantericFlip' , label: 'Trochanteric Flip' },
        { id: 'extendedIliofemoral' , label: 'Extended Iliofemoral' },
        { id: 'smithPeterson' , label: 'Smith Peterson' },
        { id: 'other' , label: 'Other' },
    ])
    const [ approachSelected,setApproachSelected ] = useState([]);

    const { hiddenFormInputStyle } = useContext(ClassesContext);

    useImperativeHandle(ref,()=>({
        setData(dataObject){
            if( dataObject ){
                let approachId = dataObject.approach ? dataObject.approach.map( item => item.id ) : [];
                setApproachItem( approachItem.filter( item => !approachId.includes(item.id) ) );
                setApproachSelected( dataObject.approach ?  dataObject.approach : []);
            
                setFormData(  {...dataObject, approach:null});
            }
            
        },
        verifyData(){
            let requireFields = [
                {   
                    errorName: 'operativeProcedure',
                    fields: [
                        'openReductionAndInternalFixation',
                        'totalHipArthroplasty',
                        'operativeProcedureOther',
                    ] ,
                    condition: 'or'  
                },
                { 
                    parent: { 
                        field: 'openReductionAndInternalFixation' , 
                    } ,
                    field: 'approach' , 
                    errorName: 'approach' , 
                    condition: 'length',
                    conditionForCompare: 'gt' ,
                    valueForCompare: 0
                },

            ]
            let data = {...formData, approach: approachSelected};
            let {isRequireFieldsValid,errorObject} = verifyRequireField(requireFields,data)
            setErrors({ ...errorObject });
            return isRequireFieldsValid
        },
        getData(){
            let data = {...formData, approach: approachSelected};
            return data
        }
    }))

    const updateFormData = async (e) => {
        let dataUpdate = getDataUpdate(e);
        setFormData({...formData,...dataUpdate})
    }

    const updateProcedureOther = async(e) => {
            let dataUpdate = getDataUpdate(e);
            setFormData({...formData,...dataUpdate, operativeProcedureOtherText: null })
    }

    const updateInstrumentsOther = async(e) => {
            let dataUpdate = getDataUpdate(e);
            setFormData({...formData,...dataUpdate, openReductionAndInternalFixationInstrumentsOtherText: null })
    }

    const updateInstrumentsSpecialEquipmentOther = async(e) => {
            let dataUpdate = getDataUpdate(e);
            setFormData({...formData,...dataUpdate, openReductionAndInternalFixationSpecialEquipmentOtherText: null })
    }

    useEffect(()=>{
        if(approachSelected.filter( (item)=> item.id==='other' ).length === 0){
            setFormData({...formData,otherApproach:null})
        }
    },[approachSelected])// eslint-disable-line react-hooks/exhaustive-deps
    

    const deleteApproach = (approachDelete) => {
        setApproachItem([ ...approachItem, approachDelete ])
        setApproachSelected( approachSelected.filter((item)=> item.id !== approachDelete.id ) )
    }
    
    const addApproach = (approachAdd) => {
        setApproachSelected([ ...approachSelected, approachAdd ])
        setApproachItem( approachItem.filter((item)=> item.id !== approachAdd.id ) )
    }

    const approachOnDragEnd = (result) => {
        const { source, destination } = result;
        const id2List = {
            approachItem: approachItem,
            approachSelected: approachSelected
        };
        // dropped outside the list
        if (!destination) {
          return;
        }
    
        if (source.droppableId === destination.droppableId) {
            const items = reorder(
                id2List[source.droppableId],
                source.index,
                destination.index
            );
    
            if (source.droppableId === "approachSelected") {
                    setApproachSelected(items)
            }else{
                    setApproachItem(items)
            }

        } else {
          const result = move(
            id2List[source.droppableId],
            id2List[destination.droppableId],
            source,
            destination
          );
          setApproachItem(result.approachItem)
          setApproachSelected(result.approachSelected)
        }


    };

    const deletePlate = (indexDelete) => {
        let plates = formData.openReductionAndInternalFixationImplantsPlates.filter((item,index)=> index !== indexDelete  )
        if(plates.length > 0){
            setFormData({...formData,openReductionAndInternalFixationImplantsPlates:plates})
        }
    }

    const addPlate = () =>{
        let plates = formData.openReductionAndInternalFixationImplantsPlates;
        plates.push({})
        setFormData({...formData,openReductionAndInternalFixationImplantsPlates:plates})
    }

    const updateOpenReductionAndInternalFixationImplantsPlates = (key,value,pattern,index) => {
        let isValidPattern = true
        if( pattern ){
            pattern = new RegExp(pattern, 'g');
            isValidPattern = pattern.test(value)
        }

        if(isValidPattern){
            let plates = formData.openReductionAndInternalFixationImplantsPlates;
            plates[index][key] = value;
            setFormData({...formData,openReductionAndInternalFixationImplantsPlates:plates})
        }

    }

    const deleteScrew = (indexDelete) => {
        let plates = formData.openReductionAndInternalFixationImplantsScrews.filter((item,index)=> index !== indexDelete  )
        if(plates.length > 0){
            setFormData({...formData,openReductionAndInternalFixationImplantsScrews:plates})
        }
    }

    const addScrew = () =>{
        let plates = formData.openReductionAndInternalFixationImplantsScrews;
        plates.push({})
        setFormData({...formData,openReductionAndInternalFixationImplantsScrews:plates})
    }

    const updateOpenReductionAndInternalFixationImplantsScrew = (key,value,pattern,index) => {
        let isValidPattern = true
        if( pattern ){
            pattern = new RegExp(pattern, 'g');
            isValidPattern = pattern.test(value)
        }

        if(isValidPattern){
            let plates = formData.openReductionAndInternalFixationImplantsScrews;
            plates[index][key] = value;
            setFormData({...formData,openReductionAndInternalFixationImplantsScrews:plates})
        }

    }

    return ( 
        <React.Fragment>
            <Grid container spacing={1} sx={{mt:1}}>
                <Grid item sx={12} md={12}>
                    <FormLabel error={ errors.operativeProcedure ? true: false } id="operative-procedure-label">Operative procedure*</FormLabel>
                </Grid>
                <Grid item xs={12} md={12} sx={{ 
                    ml:2,
                    ...hiddenFormInputStyle({value: formData.openReductionAndInternalFixation,canEdit}) 
                }}>
                    <FormControl>
                        <FormGroup>
                            <FormControlLabel control={
                                <Checkbox checked={ formData.openReductionAndInternalFixation ? formData.openReductionAndInternalFixation : false } onChange={updateFormData} name="openReductionAndInternalFixation" />
                            } label="Open Reduction and Internal Fixation"  />
                        </FormGroup>
                    </FormControl>
                </Grid>
            </Grid>
            <Grid container spacing={1} sx={{ml:4}}>
                <Grid item xs={12} md={12} >
                    <FormControl>
                        <FormLabel error={ errors.openReductionAndInternalFixationImplants ? true: false }>Implants</FormLabel>
                    </FormControl>
                </Grid>
                <Grid item xs={12} md={12} sx={{ 
                    ml:2,
                    ...hiddenFormInputStyle({value: canEdit,canEdit}) 
                }} >
                    <Button variant="outlined" onClick={e=>{ addPlate() }} sx={{ 
                        ...hiddenFormInputStyle({value: canEdit,canEdit}) 
                    }}>
                        Add Plate
                    </Button>
                    <Button variant="outlined" onClick={e=>{addScrew()}} sx={{ 
                        ...hiddenFormInputStyle({value: canEdit,canEdit}) , 
                        ml:1
                    }}>
                        Add Screw
                    </Button>
                </Grid>
            </Grid>
            { formData.openReductionAndInternalFixationImplantsPlates && formData.openReductionAndInternalFixationImplantsPlates.map((item,index)=>{
                    return (
                        <React.Fragment key={index}>
                            <Grid container spacing={1} sx={{ml:6,mt:1 }}>
                                <Grid item xs={10} md={4} sx={{ ...hiddenFormInputStyle({value: item.name,canEdit}) }}>
                                    <TextField 
                                        sx={{ resize:"both" }} 
                                        multiline  
                                        value={ item.name ? item.name : '' } 
                                        onChange={ (e)=> {
                                            updateOpenReductionAndInternalFixationImplantsPlates(
                                                'name',
                                                e.target.value, 
                                                null,
                                                index
                                            )
                                        }} 
                                        size='small' 
                                        fullWidth 
                                        label="Plate name" /> 
                                </Grid>
                                <Grid item xs={10} md={3} sx={{ ...hiddenFormInputStyle({value: item.size,canEdit}) }}>
                                    <TextField  
                                        value={ item.size ? item.size : '' } 
                                        onChange={ (e)=> {
                                            updateOpenReductionAndInternalFixationImplantsPlates(
                                                'size',
                                                e.target.value, 
                                                REG_FLOAT_WITH_ONE_DEC,
                                                index
                                            )
                                        }} 
                                        size='small' 
                                        fullWidth 
                                        label="Size" 
                                    /> 
                                </Grid>
                                <Grid item xs={10} md={3} sx={{ ...hiddenFormInputStyle({value: item.amount,canEdit}) }}>
                                    <TextField 
                                        value={ item.amount ? item.amount : '' } 
                                        onChange={ (e)=> {
                                            updateOpenReductionAndInternalFixationImplantsPlates(
                                                'amount',
                                                e.target.value, 
                                                REG_NUMBER_WITHOUT_DEC,
                                                index
                                            )
                                        }}
                                        size='small' 
                                        fullWidth 
                                        label="Amount"
                                        /> 
                                </Grid>
                                <Grid item xs={10} md={2} sx={{ ...hiddenFormInputStyle({value: canEdit,canEdit}) }}>
                                    <Button onClick={e=>deletePlate(index)} variant="outlined" color="error">
                                        Delete
                                    </Button>
                                </Grid>
                            </Grid>
                        </React.Fragment>
                    )
                })}
                { formData.openReductionAndInternalFixationImplantsScrews && formData.openReductionAndInternalFixationImplantsScrews.map((item,index)=>{
                    return (
                        <React.Fragment key={index}>
                            <Grid container spacing={1} sx={{ml:6, mt:1}}>
                                <Grid item xs={10} md={4} sx={{ ...hiddenFormInputStyle({value: item.name,canEdit}), resize:"both" }}>
                                    <TextField 
                                        multiline  
                                        value={ item.name ? item.name : '' } 
                                        onChange={ (e)=> {
                                            updateOpenReductionAndInternalFixationImplantsScrew(
                                                'name',
                                                e.target.value, 
                                                null,
                                                index
                                            )
                                        }} 
                                        size='small' 
                                        fullWidth 
                                        label="Screw name" /> 
                                </Grid>
                                <Grid item xs={10} md={3} sx={{ ...hiddenFormInputStyle({value: item.size,canEdit}) }}>
                                    <TextField
                                        value={ item.size ? item.size : '' } 
                                        onChange={ (e)=> {
                                            updateOpenReductionAndInternalFixationImplantsScrew(
                                                'size',
                                                e.target.value, 
                                                REG_FLOAT_WITH_ONE_DEC,
                                                index
                                            )
                                        }} 
                                        size='small' 
                                        fullWidth 
                                        label="Size" 
                                    /> 
                                </Grid>
                                <Grid item xs={10} md={3} sx={{ ...hiddenFormInputStyle({value: item.amount,canEdit}) }}>
                                    <TextField 
                                        value={ item.amount ? item.amount : '' } 
                                        onChange={ (e)=> {
                                            updateOpenReductionAndInternalFixationImplantsScrew(
                                                'amount',
                                                e.target.value, 
                                                REG_NUMBER_WITHOUT_DEC,
                                                index
                                            )
                                        }}
                                        size='small' 
                                        fullWidth 
                                        label="Amount"
                                        /> 
                                </Grid>
                                <Grid item xs={10} md={2} sx={{ ...hiddenFormInputStyle({value: canEdit,canEdit}) }}>
                                    <Button onClick={e=>deleteScrew(index)} variant="outlined" color="error" >
                                        Delete
                                    </Button>
                                </Grid>
                            </Grid>
                        </React.Fragment>
                    )
                })}

            
            
            <Grid container spacing={1} sx={{mt:1, ml:4}} >
                <Grid item xs={12} md={12} >
                    <FormLabel error={ errors.openReductionAndInternalFixationPosition ? true: false }>Position</FormLabel>
                </Grid>
                <Grid item xs={10} md={12}  sx={{
                    ml:2,
                    ...hiddenFormInputStyle({
                        value: ( 
                            formData.openReductionAndInternalFixationPositionSupine || 
                            formData.openReductionAndInternalFixationPositionProne || 
                            formData.openReductionAndInternalFixationPositionLateral
                        ),
                        canEdit
                    }) 
                }} >
                    <FormControl>
                        <FormGroup sx={{display:'inline'}}>
                            <FormControlLabel control={
                                <Checkbox checked={ formData.openReductionAndInternalFixationPositionSupine ? formData.openReductionAndInternalFixationPositionSupine : false } onChange={updateFormData} name="openReductionAndInternalFixationPositionSupine" />
                            } label="Supine" sx={{
                                ...hiddenFormInputStyle({value: formData.openReductionAndInternalFixationPositionSupine,canEdit}) 
                            }} />
                            <FormControlLabel control={
                                <Checkbox checked={ formData.openReductionAndInternalFixationPositionProne ? formData.openReductionAndInternalFixationPositionProne : false } onChange={updateFormData} name="openReductionAndInternalFixationPositionProne" />
                            } label="Prone" sx={{
                                ...hiddenFormInputStyle({value: formData.openReductionAndInternalFixationPositionProne,canEdit}) 
                            }} />
                            <FormControlLabel control={
                                <Checkbox checked={ formData.openReductionAndInternalFixationPositionLateral ? formData.openReductionAndInternalFixationPositionLateral : false } onChange={updateFormData} name="openReductionAndInternalFixationPositionLateral" />
                            } label="Lateral" sx={{
                                ...hiddenFormInputStyle({value: formData.openReductionAndInternalFixationPositionLateral,canEdit}) 
                            }} />
                        </FormGroup>
                    </FormControl>
                </Grid>
            </Grid>

            <Grid container spacing={1} sx={{mt:1, ml:4}} >
                <DragDropContext onDragEnd={approachOnDragEnd}>
                    { canEdit && (
                        <Grid item xs={10} md={4} >
                            <FormLabel > Approach list </FormLabel>
                            <Droppable droppableId="approachItem">
                                {(provided, snapshot) => (
                                    <Card
                                        ref={provided.innerRef}
                                        sx={{minHeight: 200}}
                                    >
                                    { approachItem && approachItem.map((item, index) => (
                                        <Draggable
                                            key={item.id}
                                            draggableId={item.id}
                                            index={index}>
                                            {(provided, snapshot) => (
                                                    <ListItem 
                                                        component="div"
                                                        ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}
                                                        secondaryAction={
                                                            <IconButton edge="end" onClick={(e)=> addApproach(item)} aria-label="delete">
                                                            <AddCircleOutlineIcon />
                                                            </IconButton>
                                                        }
                                                    >
                                                        <ListItemText primary={item.label} />
                                                    </ListItem>
                                            )}
                                        </Draggable>
                                    ))}
                                    {provided.placeholder}
                                    </Card>
                                )}
                            </Droppable>                        
                        </Grid>
                    )}

                    <Grid item xs={10} md={4}>
                        <FormLabel error={errors.approach ? true : false} > { formData && formData.openReductionAndInternalFixation ? "Approach selected*" : "Approach selected" } </FormLabel>
                        <Droppable droppableId="approachSelected">
                            {(provided, snapshot) => (
                                <Card 
                                    ref={provided.innerRef}
                                    sx={{minHeight: 100}}
                                >
                                    { approachSelected && approachSelected.map((item, index) => (
                                        <Draggable key={item.id} draggableId={item.id} index={index}>
                                            {(provided, snapshot) => (
                                            <ListItem 
                                                component="div"
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                                secondaryAction={
                                                    <IconButton edge="end" onClick={(e)=> deleteApproach(item)} aria-label="delete">
                                                        { canEdit &&  <DeleteIcon /> }
                                                    </IconButton>
                                                  }
                                            >
                                                <ListItemText primary={item.label} />
                                            </ListItem>
                                            )}
                                        </Draggable>
                                    ))}
                                    {provided.placeholder}
                                </Card>
                            )}
                        </Droppable>
                    </Grid>
                </DragDropContext>
                { approachSelected.filter( (item)=> item.id==='other' ).length === 1 && (
                    <Grid item xs={10} md={3}>
                        <TextField 
                                sx={{ 
                                        ...hiddenFormInputStyle({value: formData.otherApproach,canEdit}),
                                        resize:"both" 
                                }} 
                                fullWidth 
                                multiline
                                size="small" 
                                label="Other Approach" 
                                name="otherApproach" 
                                value={ formData.otherApproach ? formData.otherApproach : '' } 
                                onChange={updateFormData} 
                            />
                    </Grid>
                )}

            </Grid>

            <Grid container spacing={1} sx={{mt:1, ml:4}}>
                <Grid item xs={12} md={12} >
                    <FormLabel error={ errors.openReductionAndInternalFixationInstruments ? true: false }>Instruments</FormLabel>
                </Grid>
                <Grid item xs={12} md={12} sx={{ 
                    ml:2,
                    ...hiddenFormInputStyle({
                        value: (
                            formData.openReductionAndInternalFixationInstrumentsBHQPelvicSet || 
                            formData.openReductionAndInternalFixationInstrumentsOther 
                        ),
                        canEdit
                    })
                }} >
                    <FormControl>
                        <FormGroup sx={{display:'inline'}}>
                            <FormControlLabel control={
                                <Checkbox checked={ formData.openReductionAndInternalFixationInstrumentsBHQPelvicSet  ? formData.openReductionAndInternalFixationInstrumentsBHQPelvicSet : false } onChange={updateFormData} name="openReductionAndInternalFixationInstrumentsBHQPelvicSet" />
                            } label="BHQ Pelvic Set" sx={{ 
                                ...hiddenFormInputStyle({value: formData.openReductionAndInternalFixationInstrumentsBHQPelvicSet,canEdit})
                            }}  />
                            <FormControlLabel control={
                                <Checkbox checked={ formData.openReductionAndInternalFixationInstrumentsOther ? formData.openReductionAndInternalFixationInstrumentsOther : false } onChange={updateInstrumentsOther} name="openReductionAndInternalFixationInstrumentsOther" />
                            } label="Other" sx={{ 
                                ...hiddenFormInputStyle({value: formData.openReductionAndInternalFixationInstrumentsOther,canEdit})
                            }} />
                        </FormGroup>
                    </FormControl>
                </Grid>
                { formData.openReductionAndInternalFixationInstrumentsOther && (
                    <Grid item xs={12} md={4} sx={{...hiddenFormInputStyle({value: formData.openReductionAndInternalFixationInstrumentsOtherText,canEdit}),ml:2}}>
                        <TextField 
                            sx={{ 
                                resize:"both"
                            }} 
                            fullWidth 
                            size="small" 
                            label = "Other" 
                            onChange={updateFormData} 
                            value={ formData.openReductionAndInternalFixationInstrumentsOtherText ? formData.openReductionAndInternalFixationInstrumentsOtherText : '' } 
                            name="openReductionAndInternalFixationInstrumentsOtherText" 
                        />
                    </Grid>
                ) }

            </Grid>

            <Grid container spacing={1} sx={{mt:1, ml:4}}>
                <Grid item xs={12} md={12}>
                    <FormLabel error={ errors.openReductionAndInternalFixationSpecialEquipment ? true: false }>Special equipment</FormLabel>
                </Grid>
                <Grid item xs={12} md={12} sx={{
                        ...hiddenFormInputStyle({
                            value: (
                                formData.openReductionAndInternalFixationSpecialEquipmentFluoroscope ||
                                formData.openReductionAndInternalFixationSpecialEquipmentFiberTable ||
                                formData.openReductionAndInternalFixationSpecialEquipmentOther
                            ),
                            canEdit
                        }), 
                        ml:2
                    }} 
                >
                    <FormControl>
                        <FormGroup sx={{
                            display:'inline'
                        }}>
                            <FormControlLabel control={
                                <Checkbox checked={ formData.openReductionAndInternalFixationSpecialEquipmentFluoroscope  ? formData.openReductionAndInternalFixationSpecialEquipmentFluoroscope : false } onChange={updateFormData} name="openReductionAndInternalFixationSpecialEquipmentFluoroscope" />
                            } label="Fluoroscope" sx={{ 
                                ...hiddenFormInputStyle({value: formData.openReductionAndInternalFixationSpecialEquipmentFluoroscope,canEdit})
                            }} />
                            <FormControlLabel control={
                                <Checkbox checked={ formData.openReductionAndInternalFixationSpecialEquipmentFiberTable  ? formData.openReductionAndInternalFixationSpecialEquipmentFiberTable : false } onChange={updateFormData} name="openReductionAndInternalFixationSpecialEquipmentFiberTable" />
                            } label="Fiber table" sx={{ 
                                ...hiddenFormInputStyle({value: formData.openReductionAndInternalFixationSpecialEquipmentFiberTable,canEdit})
                            }}  />
                            <FormControlLabel control={
                                <Checkbox checked={ formData.openReductionAndInternalFixationSpecialEquipmentOther ? formData.openReductionAndInternalFixationSpecialEquipmentOther : false } onChange={updateInstrumentsSpecialEquipmentOther} name="openReductionAndInternalFixationSpecialEquipmentOther" />
                            } label="Other" sx={{ 
                                ...hiddenFormInputStyle({value: formData.openReductionAndInternalFixationSpecialEquipmentOther,canEdit})
                            }}  />
                        </FormGroup>
                    </FormControl>
                </Grid>
                { formData.openReductionAndInternalFixationSpecialEquipmentOther && (
                    <Grid item xs={12} md={4} sx={{ml:2}}>
                        <TextField 
                            sx={{ 
                                ...hiddenFormInputStyle({value: formData.openReductionAndInternalFixationSpecialEquipmentOtherText,canEdit}),
                                resize:"both"
                            }} 
                            multiline
                            fullWidth size="small" label = "Other" onChange={updateFormData} value={ formData.openReductionAndInternalFixationSpecialEquipmentOtherText ? formData.openReductionAndInternalFixationSpecialEquipmentOtherText : '' } name="openReductionAndInternalFixationSpecialEquipmentOtherText" />
                    </Grid>
                ) }

            </Grid>

            <Grid container spacing={1} sx={{ 
                ml:2,
                ...hiddenFormInputStyle({value: formData.totalHipArthroplasty,canEdit})
            }}>
                <Grid item xs={12} md={12}>
                    <FormControl>
                        <FormGroup>
                            <FormControlLabel control={
                                <Checkbox checked={ formData.totalHipArthroplasty ? formData.totalHipArthroplasty : false } onChange={updateFormData} name="totalHipArthroplasty" />
                            } label="Total hip arthroplasty"  />
                        </FormGroup>
                    </FormControl>
                </Grid>
            </Grid>

            <Grid container spacing={1} sx={{...hiddenFormInputStyle({value: formData.operativeProcedureOther,canEdit}),ml:2}}>
                <Grid item xs={12} md={12}>
                    <FormControl>
                        <FormGroup>
                            <FormControlLabel control={
                                <Checkbox checked={ formData.operativeProcedureOther ? formData.operativeProcedureOther : false } onChange={ updateProcedureOther } name="operativeProcedureOther" />
                            } label="Other" />
                        </FormGroup>
                    </FormControl>
                </Grid>
                { formData.operativeProcedureOther && (
                    <Grid item xs={12} md={6}>
                        <TextField 
                        sx={{ 
                            ...hiddenFormInputStyle({value: formData.operativeProcedureOtherText,canEdit}),
                            resize:"both"
                        }}  multiline  name="operativeProcedureOtherText" value={ formData.operativeProcedureOtherText ? formData.operativeProcedureOtherText : '' } onChange={updateFormData} error={ errors.operativeProcedureOtherText ? true : false } size='small' fullWidth label="Other" /> 
                    </Grid>
                )} 
            </Grid>

        </React.Fragment>
     );
});
 
export default OperativeProcedure;