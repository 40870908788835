import React, { useState, forwardRef, useImperativeHandle, useContext } from "react";
import { TextField, Grid } from '@mui/material';
import { verifyRequireField } from "../../../utils";
import { ClassesContext } from "../../../../../contexts/Classes";

const NextAppointmentDate = forwardRef( ({canEdit},ref) => {
    const [ errors, setErrors ] = useState({});
    const [ nextAppointmentDate, setNextAppointmentDate ] = useState('');
    const { hiddenFormInputStyle } = useContext(ClassesContext);

    useImperativeHandle(ref,()=>({
        setData(data){
            setNextAppointmentDate(data ? data : '')
        },
        verifyData(){
            let requireFields = [
                {field:"nextAppointmentDate", errorName: 'nextAppointmentDate'}
            ]
            let {isRequireFieldsValid,errorObject} = verifyRequireField(requireFields,{nextAppointmentDate});
            setErrors({ ...errorObject });
            return isRequireFieldsValid
        },
        getData(){
            return nextAppointmentDate
        }
    }))
    
    return ( 
        <React.Fragment>
                <Grid container spacing={1} sx={{mt:1}}>
                    <Grid item xs={12} md={4} >
                        <TextField
                            id="datetime-local"
                            label="Next appointment date*"
                            type="datetime-local"
                            value={nextAppointmentDate}
                            size="small"
                            sx={{
                                ...hiddenFormInputStyle({value: nextAppointmentDate, canEdit})
                            }}
                            fullWidth
                            InputLabelProps={{
                                shrink: true,
                            }}
                            error={ errors.nextAppointmentDate ? true : false }
                            onChange={ e=> setNextAppointmentDate(e.target.value) }
                        />
                    </Grid>
                </Grid>
        </React.Fragment>
     );
});
 
export default NextAppointmentDate;