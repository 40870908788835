import React, { forwardRef, useImperativeHandle, useRef, useContext } from 'react';
import { Box ,Typography, Collapse, Divider} from '@mui/material';
import DrainRemovalDate from './DrainRemovalDate';
import PostOperativeDrainageContent from './PostOperativeDrainageContent';
import ThePatientGetsOutOfBedDate from './ThePatientGetsOutOfBedDate';
import AmbulatoryStatusBeforeDischarge from './AmbulatoryStatusBeforeDischarge';
import ComplicationsDuringAdmission from './ComplicationsDuringAdmission';
import OtherRemark from './OtherRemark';
import AppointmentDate from './AppointmentDate';
import { ClassesContext } from "../../../../../contexts/Classes";
import PatientContact from './PatientContact';
import AttendingSurgeonAndDateTime from '../../../common/AttendingSurgeonAndDateTime';
import FormLabel from '../../../common/FormLabel';

export const Main = forwardRef(({canEdit,topic},ref) => {
    const [expanded, setExpanded] = React.useState(canEdit);
    const { useStyles } = useContext(ClassesContext);
    const classes = useStyles(canEdit);
    const drainRemovalDateRef = useRef();
    const postOperativeDrainageContentRef = useRef();
    const thePatientGetsOutOfBedDateRef = useRef();
    const ambulatoryStatusBeforeDischargeRef = useRef();
    const complicationsDuringAdmissionRef = useRef();
    const otherRemarkRef = useRef();
    const patientContactRef = useRef();
    const appointmentDateRef = useRef();
    const attendingSurgeonAndDateTimeRef = useRef();


    useImperativeHandle(ref,()=>({
        setData(dataObject){
            drainRemovalDateRef.current.setData(dataObject.drainRemovalDate);
            postOperativeDrainageContentRef.current.setData(dataObject.postOperativeDrainageContent);
            thePatientGetsOutOfBedDateRef.current.setData(dataObject.thePatientGetsOutOfBedDate);
            ambulatoryStatusBeforeDischargeRef.current.setData(dataObject.ambulatoryStatusBeforeDischarge);
            complicationsDuringAdmissionRef.current.setData(dataObject.complicationsDuringAdmission);
            otherRemarkRef.current.setData(dataObject.otherRemark);
            patientContactRef.current.setData(dataObject.patientContact);
            appointmentDateRef.current.setData(dataObject.appointmentDate);
            attendingSurgeonAndDateTimeRef.current.setData(dataObject.attendingSurgeonAndDateTime);
        },
        verifyRequireField(){
            let drainRemovalDate = drainRemovalDateRef.current.verifyData();
            let postOperativeDrainageContent = postOperativeDrainageContentRef.current.verifyData();
            let thePatientGetsOutOfBedDate = thePatientGetsOutOfBedDateRef.current.verifyData();
            let ambulatoryStatusBeforeDischarge = ambulatoryStatusBeforeDischargeRef.current.verifyData();
            let complicationsDuringAdmission = complicationsDuringAdmissionRef.current.verifyData();
            let otherRemark = otherRemarkRef.current.verifyData();
            let patientContact = patientContactRef.current.verifyData();
            let appointmentDate = appointmentDateRef.current.verifyData();
            let attendingSurgeonAndDateTime = attendingSurgeonAndDateTimeRef.current.verifyData();
            let fieldValid = (
                drainRemovalDate && 
                thePatientGetsOutOfBedDate && 
                ambulatoryStatusBeforeDischarge && 
                complicationsDuringAdmission && 
                otherRemark && 
                appointmentDate && 
                postOperativeDrainageContent && 
                attendingSurgeonAndDateTime && 
                patientContact
            )
            return fieldValid
        },
        getData() {
            let data = {
                drainRemovalDate : drainRemovalDateRef.current.getData(),
                postOperativeDrainageContent : postOperativeDrainageContentRef.current.getData(),
                thePatientGetsOutOfBedDate : thePatientGetsOutOfBedDateRef.current.getData(),
                ambulatoryStatusBeforeDischarge : ambulatoryStatusBeforeDischargeRef.current.getData(),
                complicationsDuringAdmission : complicationsDuringAdmissionRef.current.getData(),
                otherRemark : otherRemarkRef.current.getData(),
                patientContact : patientContactRef.current.getData(),
                appointmentDate : appointmentDateRef.current.getData(),
                attendingSurgeonAndDateTime : attendingSurgeonAndDateTimeRef.current.getData(),
            }
            return data
        }
    }))

    return (
        <Box className={classes.root} sx={{padding:3}}>
            <FormLabel setExpanded={setExpanded} expanded={expanded} headerText="BDMS Pelvic and Acetabulum Fracture : Discharge Summary Form" />
            <Divider ></Divider>
            <Collapse in={expanded}>
                <Box sx={{mt:1}}>
                    <DrainRemovalDate canEdit={canEdit} ref={drainRemovalDateRef} />
                    <PostOperativeDrainageContent canEdit={canEdit} ref={postOperativeDrainageContentRef} />
                    <ThePatientGetsOutOfBedDate canEdit={canEdit} ref={thePatientGetsOutOfBedDateRef} />
                    <AmbulatoryStatusBeforeDischarge canEdit={canEdit} ref={ambulatoryStatusBeforeDischargeRef} />
                    <ComplicationsDuringAdmission canEdit={canEdit} ref={complicationsDuringAdmissionRef} />
                    <OtherRemark canEdit={canEdit} ref={otherRemarkRef} />
                    <AppointmentDate canEdit={canEdit} ref={appointmentDateRef} />
                    <PatientContact canEdit={canEdit} ref={patientContactRef} />
                    <AttendingSurgeonAndDateTime canEdit={canEdit} ref={attendingSurgeonAndDateTimeRef} />
                    <Typography sx={{mt:2}}>
                        * กรณี PAMT เป็นแพทย์ผู้ผ่าตัด ในการนัดติดตามอาการครั้งแรกภายหลังออกจากโรงพยาบาล กรุณานัดหมายกับ PAMT เพื่อให้แพทย์ผู้ผ่าตัดได้ร่วมติดตามอาการผ่าน Tele-conference
                    </Typography>
                </Box>
            </Collapse>

        </Box>
    );
});