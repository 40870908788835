import { Button, Dialog, DialogActions, DialogContent, DialogContentText } from "@mui/material";
import React, { useEffect, useState, useRef } from "react";
import axiosInstance from "../../common/axios";
import { INCLUDE_FILE_FORM_ID, EXPERT_FORM_TEMPLATE, EXPERT_SUMMARY_TEMPLATE, FOLLOW_UP_TEMPLATE, FORM_TYPE_EXPERT_FORM, FORM_TYPE_EXPERT_SUMMARY, FORM_TYPE_FOLLOW_UP, FORM_TYPE_OPERATIVE_NOTE, OPERATIVE_NOTE_TEMPLATE } from "../../config/form";
import Alert from '../Alert';
import Loading from '../Loading';
import EditFileComment from "./EditFileComment";


const EditFormComment = ({ refreshCommentTypeInNewComment, showEditCommentDialog, setShowEditCommentDialog, comment, sendComment}) => {
    const formRef = useRef();
    const [ alertMessage, setAlertMessage ] = useState(null);
    const [ alertOpen, setAlertOpen ] = useState(false);
    const [ loading, setLoading ] = useState(false);
    const [ FormComponent,setFormComponent] = useState(null);
    const [ deleteCommentDialogOpen, setDeleteCommentDialogOpen ] = useState(false);
    const [ includeFileComment, setIncludeFileComment ] = useState(false);
    const [ selectedFiles, setSelectedFiles ] = useState([]);
    const [ deletedFiles, setDeletedFiles ] = useState([]);

    const updateCommentForm = async (comment,data, userId) => {
        let context = null
        try{
            let dataObject = {
                data:data,
                updated_by: userId
            }
            await axiosInstance.patch(`/topic/form/${comment.form.id}/`,dataObject).then((res)=>{
                context = res.data
            })
        }catch (error){}
        return context
    }

    const updateCommentFile = async (commentFile) => {
        let token =  localStorage.getItem('token') ? JSON.parse(localStorage.getItem('token')).access : null; 
        if(token){
            let tokenParts = JSON.parse(atob(token.split('.')[1]));
            if(tokenParts.user_id === comment.author.id){
                try {
                    let formData = new FormData();
                    formData.append('description', commentFile.description === null ? '' : commentFile.description);
                    let res = await axiosInstance.patch(`topic/comment-file/${commentFile.id}/`, formData)
                    return res.data
                } catch (error) {
                    return null
                }
            }

        }else{
            return null
        }
    }

    const createCommentFile = async (file) =>{
        let token =  localStorage.getItem('token') ? JSON.parse(localStorage.getItem('token')).access : null; 
        if(token){
            let tokenParts = JSON.parse(atob(token.split('.')[1]));
            if(tokenParts.user_id === comment.author.id){
                let fileType = ['image','video' ].includes(file.detail.type.split('/')[0]) ? file.detail.type.split('/')[0] : 'other';
                let formData = new FormData();
                formData.append('type', fileType);
                formData.append('file', file.detail);
                formData.append('size',file.size)
                formData.append('name', file.name);
                if(file.description){
                    formData.append('description', file.description);
                }
                formData.append('comment',comment.id);
                try{
                    let res = await axiosInstance.post('/topic/comment-file/',formData)
                    return res.data
                }catch{
                    return null
                }
            }
        }        
    }

    const deleteCommentFile = async (commentFile) => {
        let token =  localStorage.getItem('token') ? JSON.parse(localStorage.getItem('token')).access : null; 
        if(token){
            let tokenParts = JSON.parse(atob(token.split('.')[1]));
            if(tokenParts.user_id === comment.author.id){
                try {
                    let res = await axiosInstance.delete(`topic/comment-file/${commentFile.id}/`)
                    return res.data
                } catch (error) {
                    return null
                }
            }
        }else{
            return null
        }
    }

    const handleSubmitEditComment = async (e) => {
        let token = localStorage.getItem('token') ? JSON.parse(localStorage.getItem('token')).access : null ;
        if (token) {
            const tokenParts = JSON.parse(atob(token.split('.')[1]));
            if(tokenParts.user_id === comment.author.id){
                setLoading(true)
                let formReqValid = formRef.current.verifyRequireField();
                if(formReqValid){
                    let data = formRef.current.getData()
                    setFormComponent(null);
                    setShowEditCommentDialog(false);
                    await updateCommentForm(comment,data,tokenParts.user_id);
                    
                    for ( let i=0 ; i < selectedFiles.length ; i ++) {
                        let file = selectedFiles[i];
                        if(file.id){
                            await updateCommentFile(file);
                        }else{
                            await createCommentFile(file)
                        }
                    }

                    for ( let i=0 ; i < deletedFiles.length ; i ++) {
                        let file = deletedFiles[i];
                        await deleteCommentFile(file)
                    }

                    sendComment({ command: 'update_comment', comment_id: comment.id });
                    setSelectedFiles([]);
                    setDeletedFiles([]);
                    setLoading(false)
                }else{
                    setAlertMessage({header: 'warning !!!', content: 'please complete require fields' })
                    setAlertOpen(true)
                    setLoading(false)
                }
            }
        }
    }

    useEffect(()=>{
        if(showEditCommentDialog && FormComponent && comment){
            formRef.current.setData(comment.form.data);
        }
    },[FormComponent,comment,showEditCommentDialog])


    useEffect(()=>{
        if(showEditCommentDialog){
            let token = localStorage.getItem('token') ? JSON.parse(localStorage.getItem('token')).access : null ;
            if (token) {
                
                const tokenParts = JSON.parse(atob(token.split('.')[1]));
                setIncludeFileComment(INCLUDE_FILE_FORM_ID.includes(comment.form.template.id))
                setSelectedFiles(comment.files)
                if(tokenParts.user_id === comment.author.id){
                    switch (comment.form.template.type) {
                        case FORM_TYPE_EXPERT_FORM.key:
                            setFormComponent(EXPERT_FORM_TEMPLATE[comment.form.template.id])
                            break;
                        case FORM_TYPE_OPERATIVE_NOTE.key:
                            setFormComponent(OPERATIVE_NOTE_TEMPLATE[comment.form.template.id])
                            break;
                        case FORM_TYPE_EXPERT_SUMMARY.key:
                            setFormComponent(EXPERT_SUMMARY_TEMPLATE[comment.form.template.id])
                            break;
                        case FORM_TYPE_FOLLOW_UP.key:
                            setFormComponent(FOLLOW_UP_TEMPLATE[comment.form.template.id])
                            break;
                        default:
                            break;
                    }
                }else{
                    setShowEditCommentDialog(false)
                }
            }else{
                setShowEditCommentDialog(false)
            }
        }

    },[showEditCommentDialog,comment])// eslint-disable-line react-hooks/exhaustive-deps

    const deleteComment = () => {
        setLoading(true)
        axiosInstance.delete(`topic/comment/${comment.id}/`).then(()=>{
            sendComment({ command: 'delete_comment', comment_id: comment.id  });
            refreshCommentTypeInNewComment()
            setFormComponent(null);
            setDeleteCommentDialogOpen(false)
            setLoading(false)
            setShowEditCommentDialog(false)
        }).catch((error)=>{
            setLoading(false)
        })
    }

    return ( 
        <Dialog open={showEditCommentDialog} fullWidth maxWidth="lg">
            <DialogContent>
                { FormComponent && <FormComponent canEdit={true} topic={comment.topic} ref={formRef} /> }
                { includeFileComment && (
                    <EditFileComment selectedFiles={selectedFiles} setSelectedFiles={setSelectedFiles} deletedFiles={deletedFiles} setDeletedFiles={setDeletedFiles} />
                ) }
            </DialogContent>
            <DialogActions>
                <Button variant="outlined" onClick={handleSubmitEditComment} >Save</Button>
                <Button variant="outlined" color="error" onClick={()=>setDeleteCommentDialogOpen(true)} >Delete</Button>
                <Button variant="outlined" onClick={()=>{ 
                    setFormComponent(null);
                    setShowEditCommentDialog(false);
                }}>Close</Button>
            </DialogActions>
            <Alert open={alertOpen} message={alertMessage} handleClose={()=>{ setAlertOpen(false) }} />
            <Loading loading={loading} />
            <Dialog 
                open={deleteCommentDialogOpen}
                onClose={ (e) => setDeleteCommentDialogOpen(false)}
            >
                <DialogContent>
                    <DialogContentText>
                        Would you like to delete this comment? Click confirm to delete.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button variant='outlined' onClick={(e) => setDeleteCommentDialogOpen(false)}>Cancel</Button>
                    <Button color='error' variant='outlined' onClick={deleteComment} autoFocus>
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>
        </Dialog>
     );
}
 
export default EditFormComment;