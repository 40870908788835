import { Main as PelvicAndAcetabulumFractureV1 } from '../components/form/consultation/PelvicAndAcetabulumFracture/V1/Main';
import { Main as PelvicAndAcetabulumFractureV1Part2 } from '../components/form/expertForm/PelvicAndAcetabulumFracture/V1/Main';
import { Main as PelvicAndAcetabulumFractureOperativeNote } from '../components/form/operativeNote/PelvicAndAcetabulumFracture/V1/Main';
import { Main as PelvicAndAcetabulumFractureExpertSummary } from '../components/form/expertSummary/PelvicAndAcetabulumFracture/V1/Main';
import { Main as PelvicAndAcetabulumFractureFollowUp } from '../components/form/followUp/PelvicAndAcetabulumFracture/V1/Main';

export const FORM_OPRO_EQ5D5L_ID = 8;
export const TOPIC_STATUS_DRAFT = 'D';
export const TOPIC_STATUS_PROCESS = 'P';
export const COMMENT_TYPE_NORMAL = { key: 'comment',value:'comment', label:'Comment', authenticatedUserCanCreate: true }
export const FORM_TYPE_CONSULTATION_FORM = { key:'consultation_form', label:'Consultation form', authenticatedUserCanCreate: true, allowMoreThanOne: false };//authorCanCreate: true };
export const FORM_TYPE_EXPERT_FORM = { key:'expert_form', label:'Pre Operative Order', authenticatedUserCanCreate: true, allowMoreThanOne: false }; //expertCanCreate:true, authorCanCreate: true };
export const FORM_TYPE_OPERATIVE_NOTE = { key:'operative_note', label:'Operative note', authenticatedUserCanCreate: true, allowMoreThanOne: false }; //expertCanCreate:true, authorCanCreate: true  }
export const FORM_TYPE_EXPERT_SUMMARY = { key:'expert_summary', label:'Discharge summary', authenticatedUserCanCreate: true, allowMoreThanOne: false } //expertCanCreate:true, authorCanCreate: false  }
export const FORM_TYPE_FOLLOW_UP = { key:'follow_up_form', label:'Follow up', authenticatedUserCanCreate: true, allowMoreThanOne: true } //expertCanCreate:true, authorCanCreate: false  }
export const EXPERT_FORM_PELVIC_AND_ACETABULUM_TEMPLATE_ID = 4;
export const OPERATIVE_NOTE_PELVIC_AND_ACETABULUM_TEMPLATE_ID = 5;
export const EXPERT_SUMMARY_PELVIC_AND_ACETABULUM_TEMPLATE_ID = 6;
export const FOLLOW_UP_PELVIC_AND_ACETABULUM_TEMPLATE_ID = 7;
export const CONSULTATION_TEMPLATES = {
    '3' : PelvicAndAcetabulumFractureV1,
}

export const EXPERT_FORM_TEMPLATE = {
    '4': PelvicAndAcetabulumFractureV1Part2,
}

export const OPERATIVE_NOTE_TEMPLATE = {
    '5' : PelvicAndAcetabulumFractureOperativeNote
}

export const EXPERT_SUMMARY_TEMPLATE = {
    '6' : PelvicAndAcetabulumFractureExpertSummary
}

export const FOLLOW_UP_TEMPLATE = {
    '7' : PelvicAndAcetabulumFractureFollowUp
}

export const INCLUDE_FILE_FORM_ID = [4,5,6,7]
