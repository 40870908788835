import React, { useState, forwardRef, useImperativeHandle, useContext } from "react";
import { FormControl, FormLabel, FormControlLabel, Grid, RadioGroup, Radio } from '@mui/material';
import { verifyRequireField } from "../../../utils";
import { ClassesContext } from "../../../../../contexts/Classes";

const AmbulatoryStatusBeforeDischarge = forwardRef( ({canEdit},ref) => {
    const [ errors, setErrors ] = useState({});
    const [ ambulatoryStatusBeforeDischarge, setAmbulatoryStatusBeforeDischarge ] = useState(null);
    const { hiddenFormInputStyle } = useContext(ClassesContext);
    useImperativeHandle(ref,()=>({
        setData(data){
            setAmbulatoryStatusBeforeDischarge(data ? data : null)
        },
        verifyData(){
            let requireFields = [
                { field: 'ambulatoryStatusBeforeDischarge' ,errorName: 'ambulatoryStatusBeforeDischarge' },
            ]
            let {isRequireFieldsValid,errorObject} = verifyRequireField(requireFields,{ambulatoryStatusBeforeDischarge});
            setErrors({ ...errorObject });
            return isRequireFieldsValid
        },
        getData(){
            return ambulatoryStatusBeforeDischarge
        }
    }))
    
    return ( 
        <React.Fragment>
                <Grid container spacing={1} sx={{mt:1}}>
                    <Grid item xs={12} md={12}>
                        <FormLabel error={ errors.ambulatoryStatusBeforeDischarge ? true: false } >Ambulatory status before discharge*</FormLabel>
                    </Grid>
                    <Grid item xs={12} md={12} sx={{ml:2}}>
                            <FormControl>
                                <RadioGroup
                                    row
                                    value={ ambulatoryStatusBeforeDischarge }
                                    onChange={ e=> setAmbulatoryStatusBeforeDischarge(e.target.value) }
                                    name="ambulatoryStatusBeforeDischarge"
                                >
                                    <FormControlLabel value="stretcher" label="Stretcher" control={<Radio  />} sx={{
                                        ...hiddenFormInputStyle({value: ambulatoryStatusBeforeDischarge==='stretcher', canEdit})
                                    }} />
                                    <FormControlLabel value="wheelchair" label="Wheelchair" control={<Radio />} sx={{
                                        ...hiddenFormInputStyle({value: ambulatoryStatusBeforeDischarge==='wheelchair', canEdit})
                                    }} />
                                    <FormControlLabel value="walker" label="Walker" control={<Radio />} sx={{
                                        ...hiddenFormInputStyle({value: ambulatoryStatusBeforeDischarge==='walker', canEdit})
                                    }}/>
                                    <FormControlLabel value="crutch" label="Crutch" control={<Radio />} sx={{
                                        ...hiddenFormInputStyle({value: ambulatoryStatusBeforeDischarge==='crutch', canEdit})
                                    }}/>
                                </RadioGroup>
                            </FormControl>
                    </Grid>
                </Grid>
        </React.Fragment>
     );
});
 
export default AmbulatoryStatusBeforeDischarge;