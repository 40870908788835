
import * as actionTypes from '../action/actionTypes';


const sortNotifications = (notifications) => {
    notifications = notifications.sort(function(a, b) {
            return b.id  - a.id;
    })
    return notifications
}

const reducer = (state, action) => {
    let notifications = []
    switch ( action.command ) {
        case actionTypes.NOTIFICATIONS:
            state = action.notifications
            return state
        case actionTypes.UPDATE_NOTIFICATION:
            notifications = state.filter( (obj) => {return action.notification.id !== obj.id} )
            state =sortNotifications([...notifications, action.notification]) 
            return state
        case actionTypes.DELETE_NOTIFICATION:
            notifications = state.filter( (obj) => {return action.notification_id !== obj.id} )
            state =sortNotifications([...notifications]) 
            return state
        default:
            return state
    }
}

export default reducer;

