import React, { useState, forwardRef, useImperativeHandle,useContext } from "react";
import { FormControl, FormLabel, FormControlLabel, Grid, TextField, RadioGroup, Radio } from '@mui/material';
import { getDataUpdate, verifyRequireField } from "../../../utils";
import { ClassesContext } from "../../../../../contexts/Classes";

const PelvicAndAcetabulumFractureAssociatedInjury = forwardRef( ({canEdit},ref) => {
    const [ errors, setErrors ] = useState({});
    const [ formData, setFormData ] = useState({});
    const { hiddenFormInputStyle } = useContext(ClassesContext);

    useImperativeHandle(ref,()=>({
        setData(data){
            setFormData(data ? data : {})
        },
        verifyData(){
            let requireFields = [
                { field: 'pelvicAndAcetabulumFractureAssociatedInjury',errorName:'pelvicAndAcetabulumFractureAssociatedInjury'},
                {   
                    errorName: 'pelvicAndAcetabulumFractureAssociatedInjuryYes',
                    fields: [
                        'pelvicAndAcetabulumFractureAssociatedInjuryNerve', 
                        'pelvicAndAcetabulumFractureAssociatedInjuryPerineum',
                        'pelvicAndAcetabulumFractureAssociatedInjuryVascular',
                        'pelvicAndAcetabulumFractureAssociatedInjuryOthers',
                    ] ,
                    condition: 'or'  ,
                    parent: { field: 'pelvicAndAcetabulumFractureAssociatedInjury' , condition: 'compareValue' , valueForCompare: "yes"  }
                },
            ]
            let {isRequireFieldsValid,errorObject} = verifyRequireField(requireFields,formData)
            setErrors({ ...errorObject });
            return isRequireFieldsValid
        },
        getData(){
            return formData
        }
    }))

    const updateFormData = async (e) => {
        let dataUpdate = getDataUpdate(e);
        setFormData({...formData,...dataUpdate})
    }

    const handlePelvicAndAcetabulumFractureAssociatedInjury = (e)=>{
        let dataUpdate = getDataUpdate(e);
        setFormData({
            ...formData,
            ...dataUpdate , 
            pelvicAndAcetabulumFractureAssociatedInjuryNerve: '', 
            pelvicAndAcetabulumFractureAssociatedInjuryPerineum: '',
            pelvicAndAcetabulumFractureAssociatedInjuryVascular: '',
            pelvicAndAcetabulumFractureAssociatedInjuryOthers: '',
        })
    }
    
    return ( 
        <React.Fragment>
            <Grid container spacing={1}>
                <Grid item xs={12} md={12}>
                    <FormLabel error={ errors.pelvicAndAcetabulumFractureAssociatedInjury ? true: false } id="pelvic-and-acetabulum-fracture-associated-injury-label">Pelvic and acetabulum fracture associated injury*</FormLabel>
                </Grid>
                <Grid item xs={12} md={12} sx={{ml:2}}>
                    <FormControl>
                        <RadioGroup
                            row
                            value={ formData.pelvicAndAcetabulumFractureAssociatedInjury ? formData.pelvicAndAcetabulumFractureAssociatedInjury : null }
                            aria-label='pelvic-and-acetabulum-fracture-associated-injury-label'
                            onChange={handlePelvicAndAcetabulumFractureAssociatedInjury}
                            name="pelvicAndAcetabulumFractureAssociatedInjury"
                        >
                            <FormControlLabel 
                                value="no" 
                                label="No" 
                                control={<Radio />} 
                                sx={{
                                    ...hiddenFormInputStyle({value:formData.pelvicAndAcetabulumFractureAssociatedInjury ==='no' , canEdit}) 
                                }}
                            />
                            <FormControlLabel 
                                value="yes" 
                                label="Yes" 
                                control={<Radio />} 
                                sx={{
                                    ...hiddenFormInputStyle({value:formData.pelvicAndAcetabulumFractureAssociatedInjury ==='yes' , canEdit}) 
                                }}
                            />
                            
                        </RadioGroup>
                    </FormControl>
                </Grid>
                { formData.pelvicAndAcetabulumFractureAssociatedInjury === 'yes' && (
                    <React.Fragment>
                        <Grid item xs={12} md={12} sx={{...hiddenFormInputStyle({value:formData.pelvicAndAcetabulumFractureAssociatedInjuryNerve, canEdit}), ml:2}} >
                            <TextField 
                                sx={{ resize:"both"}} 
                                multiline 
                                label="Nerve" 
                                name="pelvicAndAcetabulumFractureAssociatedInjuryNerve" 
                                size="small" 
                                error={ errors.pelvicAndAcetabulumFractureAssociatedInjuryYes ? true : false } 
                                fullWidth 
                                value={ formData.pelvicAndAcetabulumFractureAssociatedInjuryNerve ? formData.pelvicAndAcetabulumFractureAssociatedInjuryNerve : '' } 
                                onChange={ updateFormData }
                                />
                        </Grid> 
                        <Grid item xs={12} md={12} sx={{...hiddenFormInputStyle({value:formData.pelvicAndAcetabulumFractureAssociatedInjuryPerineum, canEdit}), ml:2}}>
                            <TextField sx={{ resize:"both"}} multiline label="Perineum" name="pelvicAndAcetabulumFractureAssociatedInjuryPerineum" size="small" error={ errors.pelvicAndAcetabulumFractureAssociatedInjuryYes ? true : false } fullWidth value={ formData.pelvicAndAcetabulumFractureAssociatedInjuryPerineum ? formData.pelvicAndAcetabulumFractureAssociatedInjuryPerineum : '' } onChange={ updateFormData } />
                        </Grid> 
                        <Grid item xs={12} md={12} sx={{...hiddenFormInputStyle({value:formData.pelvicAndAcetabulumFractureAssociatedInjuryVascular, canEdit}), ml:2}}>
                            <TextField sx={{ resize:"both"}} multiline label="Vascular" name="pelvicAndAcetabulumFractureAssociatedInjuryVascular" size="small" error={ errors.pelvicAndAcetabulumFractureAssociatedInjuryYes ? true : false } fullWidth value={ formData.pelvicAndAcetabulumFractureAssociatedInjuryVascular ? formData.pelvicAndAcetabulumFractureAssociatedInjuryVascular : ''  } onChange={ updateFormData } />
                        </Grid>
                        <Grid item xs={12} md={12} sx={{...hiddenFormInputStyle({value:formData.pelvicAndAcetabulumFractureAssociatedInjuryOthers, canEdit}), ml:2}}>
                            <TextField sx={{ resize:"both"}} multiline label="Others" name="pelvicAndAcetabulumFractureAssociatedInjuryOthers" size="small" error={ errors.pelvicAndAcetabulumFractureAssociatedInjuryYes ? true : false } fullWidth value={ formData.pelvicAndAcetabulumFractureAssociatedInjuryOthers ? formData.pelvicAndAcetabulumFractureAssociatedInjuryOthers : '' } onChange={ updateFormData } />
                        </Grid> 
                    </React.Fragment>
                    )
                }
            </Grid>
        </React.Fragment>
     );
});
 
export default PelvicAndAcetabulumFractureAssociatedInjury;