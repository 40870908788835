
import * as actionTypes from '../action/actionTypes';

const sortComment = (comments) => {
    comments = comments.sort(function(a, b) {
            return a.id  - b.id;
    })
    return comments
}

const newComment = (state,comment) => {
    let isExistArray = state.filter( (obj) => {return comment.id === obj.id} )
    if( isExistArray.length === 0 ){
        return [...state, comment]
    }else{
        return state
    }
}

const updateComment = (state,comment) => {
    let comments = state.filter( (obj) => {return comment.id !== obj.id} )
    return [...comments, comment]

}

const deleteComment = (state,comment_id) => {
    let comments = state.filter( (obj) => {return comment_id !== obj.id} )
    return [...comments ]
}

const reducer = (state, action) => {
    var comments;
    switch ( action.command ) {
        case actionTypes.COMMENTS:
            state = [...action.comments]
            return state
        case actionTypes.COMMENT:
            comments = newComment(state, action.comment)
            comments = sortComment(comments)
            state = [...comments]
            return state
        case actionTypes.UPDATE_COMMENT:
            comments = updateComment(state, action.comment)
            comments = sortComment(comments)
            state = [...comments]
            return state
        case actionTypes.DELETE_COMMENT:
            comments = deleteComment(state, action.comment_id)
            state = [...comments]
            return state
        default:
            comments = sortComment([...state])
            state = [...comments]
            return state
    }
}

export default reducer;

