import React, { useEffect, useRef, useState } from "react";
import axiosInstance from "../../common/axios";
import { 
    EXPERT_FORM_TEMPLATE, 
    FORM_TYPE_EXPERT_FORM, 
    FORM_TYPE_OPERATIVE_NOTE, 
    OPERATIVE_NOTE_TEMPLATE,
    OPERATIVE_NOTE_PELVIC_AND_ACETABULUM_TEMPLATE_ID, 
    EXPERT_SUMMARY_PELVIC_AND_ACETABULUM_TEMPLATE_ID, 
    FORM_TYPE_EXPERT_SUMMARY, 
    EXPERT_SUMMARY_TEMPLATE ,
    FORM_TYPE_FOLLOW_UP,
    FOLLOW_UP_TEMPLATE,
    FOLLOW_UP_PELVIC_AND_ACETABULUM_TEMPLATE_ID,
    INCLUDE_FILE_FORM_ID,
    FORM_OPRO_EQ5D5L_ID,
    EXPERT_FORM_PELVIC_AND_ACETABULUM_TEMPLATE_ID
} from "../../config/form";
import {  Button, CardActions,Snackbar, Alert as MuiAlert  } from "@mui/material";
import SendIcon from '@mui/icons-material/Send';
import Alert from '../Alert';
import Loading from '../Loading';
import FileComment from "./FileComment";
import moment from "moment";
import { useNavigate } from "react-router-dom";


const FormComment = ({sendComment, topic, form, addFormToComment}) => {
    const formRef = useRef();
    const [ alertOpen, setAlertOpen ] = useState(false);
    const [ openSnackbar, setOpenSnackbar ] = useState(false);
    const [ loading, setLoading ] = useState(false);
    const [ alertMessage, setAlertMessage ] = useState(null);
    const [ snackbarMessage, setSnackbarMessage ] = useState(null);
    const [ FormComponent,setFormComponent ] = useState(null);
    const [ includeFileComment, setIncludeFileComment ] = useState(false);
    const [ template,setTemplate ] = useState(null);
    const [ selectedFiles, setSelectedFiles ] = useState([]);
    const navigate = useNavigate()

    useEffect(()=>{
        setFormComponent(null);
        setTemplate(null)
        if(topic){
            axiosInstance.get(`/core/specialty/${topic.specialty}/`).then((res)=>{
                if( res.data ){
                    for(let i=0;i < res.data.templates.length; i ++) {
                        let template = res.data.templates[i]
                        if( template.type === form.key ){

                            setIncludeFileComment(INCLUDE_FILE_FORM_ID.includes(template.id))

                            switch (form.key) {
                                case FORM_TYPE_EXPERT_FORM.key:
                                    setTemplate(template)
                                    setFormComponent(EXPERT_FORM_TEMPLATE[template.id])
                                    break;
                                case FORM_TYPE_OPERATIVE_NOTE.key:
                                    setTemplate(template)
                                    setFormComponent( OPERATIVE_NOTE_TEMPLATE[template.id] )
                                    break
                                case FORM_TYPE_EXPERT_SUMMARY.key:
                                    setTemplate(template)
                                    setFormComponent( EXPERT_SUMMARY_TEMPLATE[template.id] )
                                    break;
                                case FORM_TYPE_FOLLOW_UP.key:
                                    setTemplate(template)
                                    setFormComponent( FOLLOW_UP_TEMPLATE[template.id] )
                                    break;
                                default:
                                    break;
                            }
                        }
                    }
                }  
            })
        }
        return () => {
            return null
         }
    },[topic,form])// eslint-disable-line react-hooks/exhaustive-deps

    const getDefaultAttendingSurgeonAndDateTime = async () => {
        let now = new Date();
        let userDetails = await axiosInstance.get('/auth/users/get_user_detail/');
        let data = {
            attendingSurgeonAndDateTime: {
                dateTime: moment(now).format('YYYY-MM-DDTHH:mm'),
                attendingSurgeon: `${userDetails.data.first_name} ${userDetails.data.last_name}`
            }
        }

        return data
       
    }

    const setDefaultDataPelvicAndAcetabulumExpertForm = async () => {
        let defaultData = await getDefaultAttendingSurgeonAndDateTime();
        formRef.current.setData(defaultData)
    }

    const setDefaultDataPelvicAndAcetabulumOperativeNote = async () => {
        let expertComment = await axiosInstance.get(`/topic/comment/?form__template__type=${FORM_TYPE_EXPERT_FORM.key}&topic=${topic.id}`)
        let attendingSurgeonAndDateTime = await getDefaultAttendingSurgeonAndDateTime();
        let data = {
            ...attendingSurgeonAndDateTime,
            preOperativeDiagnosis: topic.consultation_form_detail.data.diagnosis ,
            postOperativeDiagnosis: topic.consultation_form_detail.data.diagnosis,
            position: {},
            approach: {},
            surgeon: null,
            useCellSaver: 'no',
            qualityOfReduction: {qualityOfReduction: "perfect"},
            intraoperatioveComplication: {intraoperatioveComplication:"no"}
        }

        if(expertComment.data.count > 0) {
            let expertFormId = expertComment.data.results[0].form;
            let expertForm = await axiosInstance.get(`/topic/form/${expertFormId}/`)
            if(expertForm.data ){
                if(expertForm.data.data.surgeon){
                    data.surgeon = expertForm.data.data.surgeon
                }
                if(expertForm.data.data.operativeProcedure){
                    data.position = {
                        prone : expertForm.data.data.operativeProcedure.openReductionAndInternalFixationPositionProne ? true : false,
                        supine : expertForm.data.data.operativeProcedure.openReductionAndInternalFixationPositionSupine ? true : false,
                        lateral : expertForm.data.data.operativeProcedure.openReductionAndInternalFixationPositionLateral ? true : false,
                    }
                    data.approach = {
                        approach: expertForm.data.data.operativeProcedure.approach,
                        otherApproach: expertForm.data.data.operativeProcedure.otherApproach,
                    }
                }

            }
        }
        formRef.current.setData(data)
    }

    const setDefaultDataPelvicAndAcetabulumExpertSummary = async () => {
        let operativeFormComment = await axiosInstance.get(`/topic/comment/?form__template__type=${FORM_TYPE_OPERATIVE_NOTE.key}&topic=${topic.id}`)
        let attendingSurgeonAndDateTime = await getDefaultAttendingSurgeonAndDateTime();
        let defaultData = {
            ...attendingSurgeonAndDateTime,
            patientContact: {
                phoneNumber: topic && topic.patient_visit_detail ? topic.patient_visit_detail.patient_detail.phone_number : '',
                email: topic && topic.patient_visit_detail ? topic.patient_visit_detail.patient_detail.email : ''
            }
        }
        
        if(operativeFormComment.data.count > 0) {
            let operativeCommentFormId = operativeFormComment.data.results[0].form;
            let operativeForm = await axiosInstance.get(`/topic/form/${operativeCommentFormId}/`)
            if(operativeForm.data ){
                let operativeFormData = operativeForm.data.data;
                defaultData = {
                    ...defaultData,
                    drainRemovalDate: {
                        operationDate: operativeFormData.operativeTime.startOperativeDateTime ? operativeFormData.operativeTime.startOperativeDateTime.split('T')[0] : null
                    },
                    thePatientGetsOutOfBedDate: {
                        operationDate: operativeFormData.operativeTime.startOperativeDateTime ? operativeFormData.operativeTime.startOperativeDateTime.split('T')[0] : null
                    },
                }
                
            }
        }

        formRef.current.setData(defaultData)
    }

    const setDefaultDataPelvicAndAcetabulumFollowUp = async () => {
        let attendingSurgeonAndDateTime = await getDefaultAttendingSurgeonAndDateTime();
        let defaultData =  {
            ...attendingSurgeonAndDateTime,
            postOperativeWeek: {
                operationDate: ''
            },
            patientReportedOutcomeScore: {
                EQ5D5L: ''
            }
        }
        let operativeFormComment = await axiosInstance.get(`/topic/comment/?form__template__type=${FORM_TYPE_OPERATIVE_NOTE.key}&topic=${topic.id}`)
        
        if(operativeFormComment.data.count > 0) {
            let operativeCommentFormId = operativeFormComment.data.results[0].form;
            let operativeForm = await axiosInstance.get(`/topic/form/${operativeCommentFormId}/`)
            if(operativeForm.data){
                let operativeFormData = operativeForm.data.data;
                defaultData.postOperativeWeek.operationDate=operativeFormData.operativeTime.startOperativeDateTime ? operativeFormData.operativeTime.startOperativeDateTime.split('T')[0] : null
            }
        }

        let EQ5D5LScoreResponse = await axiosInstance.get(`/topic/patient-visit/get_opro_score/?hospital=${topic.hospital_detail.number}&en=${topic.patient_visit_detail.en}&visit_date=${topic.patient_visit_detail.visit_date}&form=${FORM_OPRO_EQ5D5L_ID}`)
        let EQ5D5LScoreList = EQ5D5LScoreResponse.data.results;
        let EQ5D5LScore =  EQ5D5LScoreList.length > 0 ? EQ5D5LScoreList[0].score : null;
        defaultData.patientReportedOutcomeScore.EQ5D5L = EQ5D5LScore
        
        formRef.current.setData(defaultData)
    }



    useEffect(()=>{
        if( FormComponent && template ){
            switch (template.id) {
                case EXPERT_FORM_PELVIC_AND_ACETABULUM_TEMPLATE_ID :
                    setDefaultDataPelvicAndAcetabulumExpertForm();
                    break;
                case OPERATIVE_NOTE_PELVIC_AND_ACETABULUM_TEMPLATE_ID:
                    setDefaultDataPelvicAndAcetabulumOperativeNote();
                    break;
                case EXPERT_SUMMARY_PELVIC_AND_ACETABULUM_TEMPLATE_ID:
                    setDefaultDataPelvicAndAcetabulumExpertSummary();  
                    break;
                case FOLLOW_UP_PELVIC_AND_ACETABULUM_TEMPLATE_ID:
                    setDefaultDataPelvicAndAcetabulumFollowUp()
                    break;
                default:
                    break;
            }
        }
    
    },[template,FormComponent])// eslint-disable-line react-hooks/exhaustive-deps

    const createCommentForm = async (data,template,topic) =>{
        let token =  localStorage.getItem('token') ? JSON.parse(localStorage.getItem('token')).access : null; 
        let context = null
        if(token){
            let tokenParts = JSON.parse(atob(token.split('.')[1]));
            let userId = tokenParts.user_id;
            try{
                let commentFormData = {
                    template: template.id,
                    data: data,
                    created_by: userId
                }
                await axiosInstance.post('/topic/form/',commentFormData).then((res)=>{
                    context = res.data
                })
            }catch (error){}
        }
        return context
    }

    const createComment = async (topicForm) => {
        let token =  localStorage.getItem('token') ? JSON.parse(localStorage.getItem('token')).access : null; 
        let context = null
        if(token){
            let tokenParts = JSON.parse(atob(token.split('.')[1]));
            let userId = tokenParts.user_id;
            try{
                let commentData = {
                    topic: topic.id,
                    form: topicForm.id,
                    author: userId
                }
                await axiosInstance.post('/topic/comment/',commentData).then((res)=>{
                    context = res.data
                })
            }catch (error){}
        }
        return context        
    }

    const uploadCommentFile = async (file, comment) =>{
        let formData = new FormData();
        formData.append('type', file.type);
        formData.append('file', file.file);
        formData.append('size',file.file.size)
        formData.append('name', file.file.name);
        if(file.description){
            formData.append('description', file.description);
        }
        formData.append('comment',comment.id);
        try{
            let res = await axiosInstance.post('/topic/comment-file/',formData)
            return res.data
        }catch{
            return null
        }
        
    }

    const handleFormCommentSubmit = async (e) => {
        setLoading(true)
        e.preventDefault();
        
        let formReqValid = formRef.current.verifyRequireField();
        axiosInstance.get(`/topic/topic/${topic.id}/`).then( async (res)=>{
            if(formReqValid){
                let canCreateForm = await verifyPermission();
                if(canCreateForm){
                    let data = formRef.current.getData()
                    let topicForm = await createCommentForm(data,template,topic);
                    let comment = await createComment(topicForm);
                    if(comment){
                        const files = Array.from(selectedFiles);
                        for ( let i=0 ; i < files.length ; i ++) {
                            let commentFile = await uploadCommentFile(files[i], comment);
                            if(!commentFile){
                                console.log(commentFile)
                            }
                        }
                    }
                    addFormToComment();
                    sendComment({ command: 'update_comment', comment_id: comment.id });
                    
                }else{
                    window.location.reload();
                }
                setLoading(false)

            }else{
                setAlertMessage({header: 'warning !!!', content: 'please complete require fields' })
                setAlertOpen(true)
                setLoading(false)
            }
        }).catch((error)=>{
            setSnackbarMessage( 'Topic has deleted')
            setOpenSnackbar(true)
            setTimeout(()=>{
                navigate("/")
            },6000)
        })
        

    }

    const verifyPermission = async () => {
        let canCreate = false
        try {
            let res = await axiosInstance.get(`/topic/comment/?form__template__type=${form.key}&topic=${topic.id}`)
            if(res.data.count === 0 || form.allowMoreThanOne){
                canCreate = true
            }
        } catch (error) {}

        return canCreate
    }

    return ( 
        <React.Fragment>
            <form noValidate autoComplete="off" onSubmit={handleFormCommentSubmit}>
                { FormComponent && <FormComponent canEdit={true} topic={topic} ref={formRef} /> }
                
                { includeFileComment && (
                    <FileComment selectedFiles={selectedFiles} setSelectedFiles={setSelectedFiles}  />
                ) }

                <CardActions sx={{ p: 1.25, pt: 0, justifyContent: 'center' }}>
                    <Button 
                        sx={{ ml: 2 }} 
                        type="submit" 
                        color="primary" variant="outlined" 
                        endIcon={<SendIcon />}>
                        Submit
                    </Button>
                </CardActions>
            </form>
            <Alert open={alertOpen} message={alertMessage} handleClose={()=>{ setAlertOpen(false) }} />
            <Loading loading={loading} />
            <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={()=>setOpenSnackbar(false)}>
                <MuiAlert onClose={()=>{ setOpenSnackbar(false) }} severity="error" sx={{ width: '100%' }}>
                    {snackbarMessage}
                </MuiAlert>
            </Snackbar>
        </React.Fragment>
     );
}
 
export default FormComment;