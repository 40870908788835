import React, { useState, forwardRef, useImperativeHandle, useContext } from "react";
import { FormControl, FormLabel, FormControlLabel, FormGroup, Checkbox, Grid } from '@mui/material';
import { getDataUpdate, verifyRequireField } from "../../../utils";
import { ClassesContext } from "../../../../../contexts/Classes";

const Position = forwardRef( ({canEdit},ref) => {
    const [ errors, setErrors ] = useState({});
    const [ formData, setFormData ] = useState({});
    const { hiddenFormInputStyle } = useContext(ClassesContext);
    useImperativeHandle(ref,()=>({
        setData(data){
            setFormData(data)
        },
        verifyData(){
            let requireFields = [

            ]
            let {isRequireFieldsValid,errorObject} = verifyRequireField(requireFields,formData);
            setErrors({ ...errorObject });
            return isRequireFieldsValid
        },
        getData(){
            return formData
        }
    }))

    const updateFormData = async (e) => {
        let dataUpdate = getDataUpdate(e);
        setFormData({...formData,...dataUpdate})
    }
    
    return ( 
        <React.Fragment>
            <Grid container spacing={0} sx={{mt:1}}>
                <Grid item xs={12} md={12}>
                    <FormLabel error={ errors.position ? true: false }>Position</FormLabel>
                </Grid>
                <Grid item xs={12} md={12} sx={{ 
                    ml:2,
                    ...hiddenFormInputStyle({
                        value: (
                            formData.supine ||
                            formData.prone ||
                            formData.lateral
                        ), 
                        canEdit
                    })
                }} >
                    <FormControl>
                        <FormGroup sx={{display:'inline'}}>
                            <FormControlLabel control={
                                <Checkbox checked={ formData.supine ? formData.supine : false } onChange={updateFormData} name="supine" />
                            } label="Supine" sx={{ 
                                ...hiddenFormInputStyle({value: formData.supine, canEdit})
                            }} />
                            <FormControlLabel control={
                                <Checkbox checked={ formData.prone ? formData.prone : false } onChange={updateFormData} name="prone" />
                            } label="Prone" sx={{ 
                                ...hiddenFormInputStyle({value: formData.prone, canEdit})
                            }} />
                            <FormControlLabel control={
                                <Checkbox checked={ formData.lateral ? formData.lateral : false } onChange={updateFormData} name="lateral" />
                            } label="Lateral" sx={{ 
                                ...hiddenFormInputStyle({value: formData.lateral, canEdit})
                            }} />
                        </FormGroup>
                    </FormControl>
                </Grid>
            </Grid>
        </React.Fragment>
     );
});
 
export default Position;