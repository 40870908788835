import * as actionTypes from './actionTypes';
import axiosInstance from '../../common/axios';


export const authStart = () => {
    return {
        type: actionTypes.AUTH_START
    }
}

export const authSuccess = () => {
    return {
        type: actionTypes.AUTH_SUCCESS,
    }
}

export const authFail = error => {
    return {
        type: actionTypes.AUTH_FAIL,
        error: error,
    }
}

export const logout = () => {
    return {
        type: actionTypes.AUTH_LOGOUT
    }
}

export const authLogout = async (dispatch) => {
    dispatch(logout())
}

export const authLogin = (dispatch,username, password) => {
    dispatch(authStart());
    axiosInstance.post('token/',{
        username:username,
        password:password
    }).then((res) => {
        localStorage.setItem('token',JSON.stringify(res.data))
        axiosInstance.defaults.headers['Authorization'] = 'Bearer ' + res.data.access;
        setTimeout( ()=>{dispatch(authSuccess());},2000)
        
    }).catch((error)=>{
        dispatch(authFail(error))
    });
}





