import React, {useState} from 'react';
import { Divider, FormControlLabel, Switch, Button,Grid, Box, Card, CardActions, CardContent, TextField, Collapse } from "@mui/material";
import { DropzoneArea } from 'material-ui-dropzone';
import FilePreview from './FilePreview';

const EditFileComment = ({selectedFiles, setSelectedFiles, deletedFiles, setDeletedFiles}) => {
    const [ showFileComment, setShowFileComment ] = useState(false);
    const deleteFile = (index) => {
        let file = selectedFiles[index];
        if( file.id ){
            setDeletedFiles([...deletedFiles,file])
        }
        setSelectedFiles( selectedFiles.filter((file,f_index)=>{ return f_index !== index }) )
    }

    const fileDescOnChange = (index,value) => {
        let files = selectedFiles.map((file,file_index)=>{
            if(file_index === index){
                file.description = value;
            }
            return file;
            
        })
        setSelectedFiles(files)
    }

    const selectFiles = (filesObject) => {
        if(filesObject.length > 0){
            let files = [];
            for(let i = 0; i < filesObject.length; i++){
                files.push({
                    file: filesObject[i],
                    detail: filesObject[i],
                    name: filesObject[i].name,
                    description : null,
                    size: filesObject[i].size,
                    type: [ 'image', 'video' ].includes( filesObject[i].type.split('/')[0] ) ?  filesObject[i].type.split('/')[0] : 'other'

                })
            }
            setSelectedFiles([...selectedFiles,...files]);
        }
    }

    return ( 
        <React.Fragment>
            <Divider sx={{mt: 2, mb:1}} > 
                <FormControlLabel 
                    control={<Switch checked={showFileComment} onChange={ (e)=> setShowFileComment(e.target.checked) } />}
                    label="Drop file(s)"
                />
            </Divider>
            <Box sx={{padding:2}}>
                <Grid container spacing={3}>                            
                    { selectedFiles && selectedFiles.map((file,index)=>{
                            return (
                                <Grid item key={index} xs={12} md={4} >
                                    <Card  sx={{mt:2}}  >
                                        <CardContent>
                                            <FilePreview file={file} />
                                            <TextField value={file.description ? file.description : '' } onChange={(e)=>{ fileDescOnChange(index,e.target.value) }} sx={{width: '100%', mt: 2}} label="description" variant="outlined"  />
                                        </CardContent>
                                        <CardActions sx={{float: 'right'}}>
                                            <Button onClick={ ()=>{ deleteFile(index) }  } size="small">Remove</Button>
                                        </CardActions>
                                    </Card>
                                </Grid>
                            )
                    }) }
                </Grid>
            </Box>
            <Collapse in={showFileComment}>
                <Box sx={{padding:2}}>
                    <DropzoneArea clearOnUnmount={false}  key={selectedFiles.length} initialFiles={[]} previewText="" showPreviews={false} showPreviewsInDropzone={false} filesLimit={100} maxFileSize={30000000} onChange={(files) => { selectFiles(files); }} />
                </Box>
            </Collapse>

        </React.Fragment>
    );
}
 
export default EditFileComment;