import React, { useState, forwardRef, useImperativeHandle, useContext } from "react";
import { Checkbox,FormControl, FormLabel, FormControlLabel, Grid, RadioGroup, Radio, TextField } from '@mui/material';
import { verifyRequireField } from "../../../utils";
import { ClassesContext } from "../../../../../contexts/Classes";

const ComplicationsDuringAdmission = forwardRef( ({canEdit},ref) => {
    const [ errors, setErrors ] = useState({});
    const [ complicationsDuringAdmission, setComplicationsDuringAdmission ] = useState('');
    const [ woundInfection, setWoundInfection ] = useState(false);
    const [ woundInfectionText, setWoundInfectionText ] = useState('');
    const [ deepVeinThrombosis, setDeepVeinThrombosis ] = useState(false);
    const [ deepVeinThrombosisText, setDeepVeinThrombosisText ] = useState('');
    const [ pulmonaryEmboli, setPulmonaryEmboli ] = useState(false);
    const [ pulmonaryEmboliText, setPulmonaryEmboliText ] = useState('');
    const [ others, setOthers ] = useState(false);
    const [ othersText, setOthersText ] = useState('');
    const { hiddenFormInputStyle } = useContext(ClassesContext);
    useImperativeHandle(ref,()=>({
        setData(data){
            setComplicationsDuringAdmission( data && data.complicationsDuringAdmission ? data.complicationsDuringAdmission : null)
            setWoundInfection( data && data.woundInfection ? data.woundInfection : false)
            setWoundInfectionText( data && data.woundInfectionText ? data.woundInfectionText : '')
            setDeepVeinThrombosis( data && data.deepVeinThrombosis ? data.deepVeinThrombosis : false)
            setDeepVeinThrombosisText( data && data.deepVeinThrombosisText ? data.deepVeinThrombosisText : '')
            setPulmonaryEmboli( data && data.pulmonaryEmboli ? data.pulmonaryEmboli : false)
            setPulmonaryEmboliText( data && data.pulmonaryEmboliText ? data.pulmonaryEmboliText : '')
            setOthers( data && data.others ? data.others : false)
            setOthersText( data && data.othersText ? data.othersText : '')
        },
        verifyData(){
            let requireFields = [
                { field: 'complicationsDuringAdmission' ,errorName: 'complicationsDuringAdmission' },
                { 
                    errorName: 'complicationsDuringAdmissionChild', 
                    fields: [
                        'woundInfection',
                        'deepVeinThrombosis',
                        'pulmonaryEmboli',
                        'others',
                    ] ,
                    condition: 'or' ,
                    parent: { 
                        field: 'complicationsDuringAdmission' , 
                        condition: 'compareValue' , 
                        valueForCompare: "yes"  
                    } 
                },
            ]
            let {isRequireFieldsValid,errorObject} = verifyRequireField(requireFields,getFormData());
            setErrors({ ...errorObject });
            return isRequireFieldsValid
        },
        getData(){
            return getFormData()
        }
    }))

    const getFormData = () => {
        return {
                complicationsDuringAdmission,
                woundInfection,
                woundInfectionText,
                deepVeinThrombosis,
                deepVeinThrombosisText,
                pulmonaryEmboli,
                pulmonaryEmboliText,
                othersText,
                others,
            }
    }

    const complicationsDuringAdmissionOnchange = (e)=>{
        setWoundInfectionText('');
        setWoundInfection(false);
        setDeepVeinThrombosisText('');
        setDeepVeinThrombosis(false);
        setPulmonaryEmboliText('');
        setPulmonaryEmboli(false);
        setOthersText('');
        setOthers(false);
        setComplicationsDuringAdmission(e.target.value);
    }
    
    return ( 
        <React.Fragment>
                <Grid container spacing={1} sx={{mt:1}}>
                    <Grid item xs={12} md={12}>
                        <FormLabel error={ errors.complicationsDuringAdmission || errors.complicationsDuringAdmissionChild ? true: false } >Complications during admission*</FormLabel>
                    </Grid>
                    <Grid item xs={12} md={12} sx={{ml:2}}>
                            <FormControl>
                                <RadioGroup
                                    row
                                    value={ complicationsDuringAdmission }
                                    onChange={complicationsDuringAdmissionOnchange}
                                    name="complicationsDuringAdmission"
                                >
                                    <FormControlLabel value="no" label="No" control={<Radio  />} sx={{
                                        ...hiddenFormInputStyle({value: complicationsDuringAdmission === 'no', canEdit})
                                    }} />
                                    <FormControlLabel value="yes" label="Yes" control={<Radio />} sx={{
                                        ...hiddenFormInputStyle({value: complicationsDuringAdmission === 'yes', canEdit})
                                    }} />
                                </RadioGroup>
                            </FormControl>
                    </Grid>
                </Grid> 
                { complicationsDuringAdmission === 'yes' && (
                    <React.Fragment>
                        <Grid container spacing={1} sx={{ml:2}}>
                            <Grid item xs={11} md={4}>
                                <FormControlLabel 
                                control={
                                    <Checkbox 
                                        checked={ woundInfection } 
                                        onChange={ e => setWoundInfection(e.target.checked) } 
                                        name="woundInfection" />
                                } label="Wound infection" sx={{
                                    ...hiddenFormInputStyle({value: woundInfection, canEdit})
                                }} />
                            </Grid>
                            <Grid item xs={11} md={4} >
                            <TextField  
                                name="woundInfectionText" 
                                value={ woundInfectionText } 
                                onChange={e=>setWoundInfectionText(e.target.value)} 
                                error={ errors.woundInfectionText ? true : false } 
                                size='small' 
                                fullWidth 
                                label="Wound infection" 
                                sx={{
                                    ...hiddenFormInputStyle({value: woundInfectionText, canEdit}),
                                    resize:"both"
                                }}
                                /> 
                            </Grid>
                        </Grid>
                        <Grid container spacing={1} sx={{ml:2}}>
                            <Grid item xs={11} md={4}>
                                <FormControlLabel 
                                control={
                                    <Checkbox 
                                        checked={ deepVeinThrombosis } 
                                        onChange={ e => setDeepVeinThrombosis(e.target.checked) } 
                                        name="deepVeinThrombosis" />
                                } label="Deep vein thrombosis" sx={{
                                    ...hiddenFormInputStyle({value: deepVeinThrombosis, canEdit})
                                }} />
                            </Grid>
                            <Grid item xs={11} md={4} >
                            <TextField  
                                sx={{
                                    ...hiddenFormInputStyle({value: deepVeinThrombosisText, canEdit}),
                                    resize:"both"
                                }}
                                name="deepVeinThrombosisText" 
                                value={ deepVeinThrombosisText } 
                                onChange={e=>setDeepVeinThrombosisText(e.target.value)} 
                                error={ errors.deepVeinThrombosisText ? true : false } 
                                size='small' 
                                fullWidth 
                                label="Deep vein thrombosis" 
                                /> 
                            </Grid>
                        </Grid>
                        <Grid container spacing={1} sx={{ml:2}}>
                            <Grid item xs={11} md={4}>
                                <FormControlLabel 
                                control={
                                    <Checkbox 
                                        checked={ pulmonaryEmboli } 
                                        onChange={ e => setPulmonaryEmboli(e.target.checked) } 
                                        name="pulmonaryEmboli" />
                                } label="Pulmonary emboli" sx={{
                                    ...hiddenFormInputStyle({value: pulmonaryEmboli, canEdit})
                                }} />
                            </Grid>
                            <Grid item xs={11} md={4} >
                            <TextField  
                                sx={{
                                    ...hiddenFormInputStyle({value: pulmonaryEmboliText, canEdit}),
                                    resize:"both"
                                }}
                                name="pulmonaryEmboliText" 
                                value={ pulmonaryEmboliText } 
                                onChange={e=>setPulmonaryEmboliText(e.target.value)} 
                                error={ errors.pulmonaryEmboliText ? true : false } 
                                size='small' 
                                fullWidth 
                                label="Pulmonary emboli" 
                                /> 
                            </Grid>
                        </Grid>
                        <Grid container spacing={1} sx={{ml:2}}>
                            <Grid item xs={11} md={4}>
                                <FormControlLabel 
                                control={
                                    <Checkbox 
                                        checked={ others } 
                                        onChange={ e => setOthers(e.target.checked) } 
                                        name="others" />
                                } label="Others" sx={{
                                    ...hiddenFormInputStyle({value: others, canEdit})
                                }} />
                            </Grid>
                            <Grid item xs={11} md={4} >
                            <TextField  
                                sx={{
                                    ...hiddenFormInputStyle({value: othersText, canEdit}),
                                    resize:"both"
                                }}
                                name="othersText" 
                                value={ othersText } 
                                onChange={e=>setOthersText(e.target.value)} 
                                error={ errors.othersText ? true : false } 
                                size='small' 
                                fullWidth 
                                label="Others" 
                                /> 
                            </Grid>
                        </Grid>

                    </React.Fragment>
                )}
        </React.Fragment>
     );
});
 
export default ComplicationsDuringAdmission;