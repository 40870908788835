import React, { useState, forwardRef, useImperativeHandle, useEffect, useContext } from "react";
import { TextField, Grid } from '@mui/material';
import { verifyRequireField } from "../../../utils";
import moment from 'moment';
import { ClassesContext } from "../../../../../contexts/Classes";

const PostOperativeWeek = forwardRef( ({canEdit},ref) => {
    const [ errors, setErrors ] = useState({});
    const [ operationDate, setOperationDate ] = useState('');
    const [ followUpDate, setFollowUpDate ] = useState('');
    const [ postOperativeWeek, setPostOperativeWeek ] = useState('');
    const { hiddenFormInputStyle } = useContext(ClassesContext);

    useImperativeHandle(ref,()=>({
        setData(data){
            setOperationDate( data && data.operationDate ? data.operationDate : '' )
            setPostOperativeWeek( data ? data.postOperativeWeek : '' )
            setFollowUpDate( data ? data.followUpDate : '' )
        },
        verifyData(){
            let requireFields = [
                { field: 'operationDate' ,errorName: 'operationDate' },
                { field: 'followUpDate' ,errorName: 'followUpDate' },
                { field: 'postOperativeWeek' ,errorName: 'postOperativeWeek' },
            ]
            let {isRequireFieldsValid,errorObject} = verifyRequireField(requireFields,getFormData());
            setErrors({ ...errorObject });
            return isRequireFieldsValid
        },
        getData(){
            return getFormData()
        }
    }))

    const getFormData = () => {
        return {
            operationDate,
            followUpDate,
            postOperativeWeek
        }
    }

    useEffect(()=>{
        if(followUpDate && operationDate){
            let start = moment(operationDate).set({second:0,hour:0,minute:0}).toString()
            let end = moment(followUpDate).set({second:0,hour:0,minute:0}).toString()
            let totalDays = moment(end).diff( start, 'days' )
            let weeks = Math.floor(totalDays/7)
            let days =totalDays % 7
            setPostOperativeWeek(`${weeks} wks ${days} d (${totalDays})`)
        }
        
    },[followUpDate, operationDate]) // eslint-disable-line react-hooks/exhaustive-deps
 
    return ( 
        <React.Fragment>
            <Grid container spacing={1} sx={{mt:1}}>
                <Grid item xs={12} md={4} >
                    <TextField
                        disabled
                        label="Date of operation*"
                        type="date"
                        value={ operationDate }
                        onChange={(e) => {
                            setOperationDate( e.target.value  )
                        }}
                        size="small"
                        fullWidth
                        error={ errors.operationDate ? true : false }
                        InputLabelProps={{
                            shrink: true,
                        }}
                        sx={{
                            ...hiddenFormInputStyle({value: operationDate, canEdit})
                        }}
                    />
                </Grid>
                <Grid item xs={12} md={4} >
                    <TextField
                        label="Follow up date*"
                        type="date"
                        value={ followUpDate }
                        onChange={(e) => {
                            setFollowUpDate( e.target.value  )
                        }}
                        size="small"
                        fullWidth
                        error={ errors.followUpDate ? true : false }
                        InputLabelProps={{
                            shrink: true,
                        }}
                        sx={{
                            ...hiddenFormInputStyle({value: followUpDate, canEdit})
                        }}
                    />
                </Grid>
                <Grid item xs={12} md={4}>
                        <TextField disabled value={postOperativeWeek} onChange={(e)=>{ setPostOperativeWeek(e.target.value) }} fullWidth size="small" label="Post-operative week" name="postOperativeWeek" sx={{
                            ...hiddenFormInputStyle({value: postOperativeWeek, canEdit})
                        }} />
                </Grid>
            </Grid>
        </React.Fragment>
     );
});
 
export default PostOperativeWeek;