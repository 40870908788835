import React, { useState, forwardRef, useImperativeHandle, useContext } from "react";
import { TextField, Grid } from '@mui/material';
import { verifyRequireField } from "../../../utils";
import { ClassesContext } from "../../../../../contexts/Classes";

const AppointmentDate = forwardRef( ({canEdit},ref) => {
    const [ errors, setErrors ] = useState({});
    const [ appointmentDate, setAppointmentDate ] = useState(null);
    const { hiddenFormInputStyle } = useContext(ClassesContext);
    useImperativeHandle(ref,()=>({
        setData(data){
            setAppointmentDate( data ? data : null )
        },
        verifyData(){
            let requireFields = [
                { field: 'appointmentDate' ,errorName: 'appointmentDate' },
            ]
            let {isRequireFieldsValid,errorObject} = verifyRequireField(requireFields,getFormData());
            setErrors({ ...errorObject });
            return isRequireFieldsValid
        },
        getData(){
            return appointmentDate
        }
    }))

    const getFormData = () => {
        return {
            appointmentDate
        }
    }

   
 
    return ( 
        <React.Fragment>
            <Grid container spacing={1} sx={{mt:1}}>
                <Grid item xs={12} md={4} >
                    <TextField
                        label="Appointment date/time*"
                        type="datetime-local"
                        value={ appointmentDate }
                        onChange={(e) => {
                            setAppointmentDate( e.target.value )
                        }}
                        size="small"
                        fullWidth
                        error={ errors.appointmentDate ? true : false }
                        InputLabelProps={{
                            shrink: true,
                        }}
                        sx={{
                            ...hiddenFormInputStyle({value: appointmentDate, canEdit})
                        }}
                    />
                </Grid>
            </Grid>
        </React.Fragment>
     );
});
 
export default AppointmentDate;