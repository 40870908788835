import React, { useState, forwardRef, useImperativeHandle, useContext } from "react";
import { FormControl, FormLabel, FormControlLabel, Grid, RadioGroup, Radio } from '@mui/material';
import { verifyRequireField } from "../../../utils";
import { ClassesContext } from "../../../../../contexts/Classes";

const Anesthesia = forwardRef( ({canEdit},ref) => {
    const [ errors, setErrors ] = useState({});
    const [ anesthesia, setAnesthesia ] = useState(null);
    const { hiddenFormInputStyle } = useContext(ClassesContext);
    useImperativeHandle(ref,()=>({
        setData(data){
            if(data){
                setAnesthesia(data)
            }
        },
        verifyData(){
            let requireFields = [
                { field: 'anesthesia' ,errorName: 'anesthesia' },
            ]
            let {isRequireFieldsValid,errorObject} = verifyRequireField(requireFields,{anesthesia});
            setErrors({ ...errorObject });
            return isRequireFieldsValid
        },
        getData(){
            return anesthesia
        }
    }))
    
    return ( 
        <React.Fragment>
            <Grid container spacing={1} sx={{mt:1}}>
                    <Grid item xs={12} md={12}>
                        <FormLabel error={ errors.anesthesia ? true: false } id="anesthesia-label">Anesthesia*</FormLabel>
                    </Grid>
                    <Grid item xs={12} md={12} sx={{ml:2}}>
                        <FormControl>
                            <RadioGroup
                                row
                                value={ anesthesia }
                                aria-label='anesthesia-label'
                                onChange={(e)=>setAnesthesia(e.target.value)}
                                name="anesthesia"
                            >
                                <FormControlLabel value="general" label="General" control={<Radio  />} sx={{ 
                                    ...hiddenFormInputStyle({value: anesthesia==='general',canEdit}) 
                                }} />
                                <FormControlLabel value="regional" label="Regional" control={<Radio />} sx={{ 
                                    ...hiddenFormInputStyle({value: anesthesia==='regional',canEdit}) 
                                }} />
                            </RadioGroup>
                        </FormControl>
                    </Grid>
            </Grid>
        </React.Fragment>
     );
});
 
export default Anesthesia;