
import { ThemeProvider,createTheme } from "@mui/material/styles";
import { Component } from "react";
import Layout from "./components/Layout";
import {AuthProvider} from "./contexts/AuthContext";
import { ClassesProvider } from "./contexts/Classes";
import { NotificationProvider } from "./contexts/NotificationContext"

const theme = createTheme({
  components: {
    MuiFormLabel: {
      styleOverrides: {
        root: {
          color: "#000",
          fontWeight: "bold"
        },
      },
    },
    MuiInputLabel:{
      styleOverrides: {
        root: {
          color: "#000000",
          opacity: 0.6,
          fontWeight: "normal"
        },
      }, 
    }
  },
});


class App extends Component {
 
  render() {
  
    return (
        <AuthProvider>
          <NotificationProvider>
            <ThemeProvider theme={theme}>
              <ClassesProvider>
                <Layout />
              </ClassesProvider>
            </ThemeProvider>
          </NotificationProvider>
        </AuthProvider>
    );
  }
}

export default App;
