import React, { useState, forwardRef, useImperativeHandle, useContext } from "react";
import { RadioGroup,Radio, FormControl, FormLabel, FormControlLabel, FormGroup, Checkbox, Grid, TextField, Typography } from '@mui/material';
import { getDataUpdate, verifyRequireField } from "../../../utils";
import { ClassesContext } from "../../../../../contexts/Classes";


const AssociatedNonOrthopedicSurgery = forwardRef( ({canEdit},ref) => {
    const [ errors, setErrors ] = useState({});
    const [ formData, setFormData ] = useState({});
    const { hiddenFormInputStyle  } = useContext(ClassesContext);

    useImperativeHandle(ref,()=>({
        setData(data){
            setFormData(data ? data : {})
        },
        verifyData(){
            let requireFields = [
                {field: 'associatedNonOrthopedicSurgery', errorName: 'associatedNonOrthopedicSurgery'}
            ]
            let {isRequireFieldsValid,errorObject} = verifyRequireField(requireFields,formData)
            setErrors({ ...errorObject });
            return isRequireFieldsValid
        },
        getData(){
            return formData
        }
    }))

    const updateFormData = async (e) => {
        let dataUpdate = getDataUpdate(e);
        setFormData({...formData,...dataUpdate})
    }

    const handleAssociatedNonOrthopedicSurgeryOtherInjuries = (e)=>{
        let dataUpdate = getDataUpdate(e);
        setFormData({...formData,...dataUpdate , associatedNonOrthopedicSurgeryOtherInjuriesText: '' })
    }
    const handleAssociatedNonOrthopedicSurgery = (e)=>{
        let dataUpdate = getDataUpdate(e);
        setFormData({...formData,...dataUpdate , 
            associatedNonOrthopedicSurgeryHeadInjury: false ,
            associatedNonOrthopedicSurgeryBluntTraumaChest: false ,
            associatedNonOrthopedicSurgeryBluntTraumaAbdomen: false ,
            associatedNonOrthopedicSurgeryOtherInjuries: false ,
        })
    }
    
    return ( 
        <React.Fragment>
            <Grid container spacing={0} sx={{mt:1}}>
                <Grid item xs={12} md={12} >
                    <FormLabel error={ errors.associatedNonOrthopedicSurgery ? true: false } id="associated-non-orthopedic-surgery-label">Associated non - orthopedic surgery*</FormLabel>
                </Grid>
                <Grid item xs={12} md={12} sx={{ml:2}} >
                    <FormControl>
                        <RadioGroup
                            row
                            value={ formData.associatedNonOrthopedicSurgery ? formData.associatedNonOrthopedicSurgery : null }
                            aria-label='associated-non-orthopedic-surgery-label'
                            onChange={handleAssociatedNonOrthopedicSurgery}
                            name="associatedNonOrthopedicSurgery"
                        >
                            <FormControlLabel value="no" label="No" control={<Radio />} sx={{ 
                                ...hiddenFormInputStyle({value:formData.associatedNonOrthopedicSurgery === 'no', canEdit}) 
                            }} />
                            <FormControlLabel value="yes" label="Yes" control={<Radio />} sx={{
                                ...hiddenFormInputStyle({value:formData.associatedNonOrthopedicSurgery === 'yes', canEdit}) 
                            }} />
                            
                        </RadioGroup>
                    </FormControl>
                </Grid>

                { formData.associatedNonOrthopedicSurgery === 'yes' && (
                    <Grid item xs={12} md={12}  sx={{ml:2}} >
                        <FormControl component="fieldset" variant="standard">
                            <FormGroup sx={{display:'inline'}}>
                                <FormControlLabel
                                    control={
                                    <Checkbox checked={ formData.associatedNonOrthopedicSurgeryHeadInjury ? formData.associatedNonOrthopedicSurgeryHeadInjury : false } onChange={updateFormData} name="associatedNonOrthopedicSurgeryHeadInjury" />
                                    }
                                    label="Head injury"
                                    sx={{...hiddenFormInputStyle({value:formData.associatedNonOrthopedicSurgeryHeadInjury, canEdit})}}
                                />        
                                <FormControlLabel
                                    control={
                                    <Checkbox checked={ formData.associatedNonOrthopedicSurgeryBluntTraumaChest ? formData.associatedNonOrthopedicSurgeryBluntTraumaChest : false } onChange={updateFormData} name="associatedNonOrthopedicSurgeryBluntTraumaChest" />
                                    }
                                    label="Blunt trauma chest"
                                    sx={{...hiddenFormInputStyle({value:formData.associatedNonOrthopedicSurgeryBluntTraumaChest, canEdit})}}
                                />
                                <FormControlLabel
                                    control={
                                    <Checkbox checked={ formData.associatedNonOrthopedicSurgeryBluntTraumaAbdomen ? formData.associatedNonOrthopedicSurgeryBluntTraumaAbdomen : false } onChange={updateFormData} name="associatedNonOrthopedicSurgeryBluntTraumaAbdomen" />
                                    }
                                    label="Blunt trauma abdomen"
                                    sx={{...hiddenFormInputStyle({value:formData.associatedNonOrthopedicSurgeryBluntTraumaAbdomen, canEdit})}}
                                />     
                            </FormGroup>
                            <FormGroup >
                                <FormControlLabel 
                                    control={
                                    <Checkbox checked={ formData.associatedNonOrthopedicSurgeryOtherInjuries ? formData.associatedNonOrthopedicSurgeryOtherInjuries : false } onChange={handleAssociatedNonOrthopedicSurgeryOtherInjuries} name="associatedNonOrthopedicSurgeryOtherInjuries" />
                                    }
                                    label="Other injuries"
                                    sx={{...hiddenFormInputStyle({value:formData.associatedNonOrthopedicSurgeryOtherInjuries, canEdit})}}
                                />
                                { formData.associatedNonOrthopedicSurgeryOtherInjuries && (
                                    <TextField sx={{...hiddenFormInputStyle({value:formData.associatedNonOrthopedicSurgeryOtherInjuries, canEdit}), resize:"both" }} multiline  name="associatedNonOrthopedicSurgeryOtherInjuriesText" value={ formData.associatedNonOrthopedicSurgeryOtherInjuriesText ? formData.associatedNonOrthopedicSurgeryOtherInjuriesText : '' } onChange={updateFormData} error={ errors.associatedNonOrthopedicSurgeryOtherInjuriesText ? true : false } size='small' fullWidth label="Other" /> 
                                )} 
                            </FormGroup>
                        </FormControl>
                    </Grid>
                ) }
            </Grid>
        </React.Fragment>
     );
});
 
export default AssociatedNonOrthopedicSurgery;